import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'lib/hooks/redux';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import styles from 'components/common/Cart/Cart.module.scss';
import Skeleton from 'components/common/Skeleton/Skeleton';
import { PriceSummary } from 'components/common/PriceSummary/PriceSummary';
import Card from 'components/common/Card';
import { getCartSummary, getCartTotal } from 'containers/CartContainer/CartContainer.utils';
import { PE, country } from 'consts';

export const SummarySection: React.FC = () => {
    const { t: format } = useTranslation('format');
    const { t: trans } = useTranslation();

    const app = useAppSelector((state) => state.app);
    const dm = useAppSelector((state) => state.deliveryMethod);
    const schedule = useDeliveryMethod();
    const cart = useAppSelector((state) => state.cart);
    const pm = useAppSelector((state) => state.paymentMethod);
    const isMobile = app.data?.isMobile;

    if (!isMobile) {
        return null;
    }

    if (!schedule.hasSchedules()) {
        return null;
    }

    if (!cart.data || !dm.data) {
        return null;
    }

    const giftCard = pm.types.ripleyGiftCard;
    const details = getCartSummary(cart.data, pm.data.selected, dm.selected, format, trans);
    const totals = getCartTotal(cart.data, pm.data.selected, giftCard, format);

    if (app.status == 'ok') {
        return (
            <section>
                <Card title="" isSection>
                    <PriceSummary
                        totals={totals}
                        details={details}
                        country={country == PE ? 'pe' : 'cl'}
                        className={styles['price-summary']}
                    />
                </Card>
            </section>
        );
    }

    return <Skeleton width={100} height={210} />;
};
