import React from 'react';
import { KIOSKO } from 'consts';

// hooks
import { useAnalytics } from 'lib/hooks/analytics';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useRules } from 'lib/hooks/rules';

// components
import AlertBadge from 'components/common/AlertBadge';
import CreditCard from 'components/payment/CreditCard';
import Modal from 'components/common/Modal';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';

// store
import { setCard } from 'store/card/cybersource';
import { setPaymentMethod } from 'store/paymentMethod/cybersource.slice';
import { setPaymentPayload } from './CybersourceAdder.utils';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export function CybersourceAdder(): JSX.Element {
    const analytics = useAnalytics();
    const dm = useDeliveryMethod();
    const rules = useRules();
    const dispatch = useAppDispatch();
    const area = useAppSelector((state: RootState) => state.area);
    const cart = useAppSelector((state: RootState) => state.cart);
    const user = useAppSelector((state: RootState) => state.user);
    const addresses = useAppSelector((state: RootState) => state.address);
    const paymentMethod = useAppSelector((state: RootState) => state.paymentMethod);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const fingerprint = useAppSelector((state: RootState) => state.card.fingerprint);
    const allowRemember = rules.feature('cardOnFile') && channel != KIOSKO;
    const cybersource = paymentMethod.types.cybersource;
    const [isOpen, setIsOpen] = React.useState(false);
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    const handleOnClick = async () => {
        if (cart.data == null || user.data == null || addresses.data == null || area.data == null) {
            return;
        }
        setIsOpen(true);
        const payload = setPaymentPayload(cart.data, user.data, addresses.data, area.data);
        dispatch(setPaymentMethod(payload));
    };

    const handleOnSubmit = async (card: Card) => {
        if (user.data == null || cybersource.data == null) {
            return;
        }
        try {
            await dispatch(setCard({ card, user: user.data, payment: cybersource.data })).unwrap();
            setIsOpen(false);
        } catch {
            //
        }
    };

    const isLoading =
        paymentMethod.data.status === 'pending' &&
        paymentMethod.data.loading.includes('Cybersource');

    const isBlocked =
        (paymentMethod.data.status === 'pending' && !isLoading) ||
        couponLoading ||
        !dm.hasSchedules();

    if (cybersource.status === 'error') {
        analytics.push('checkout_error', 'errorCreditInput');

        sendErrorToGA4({
            channel: channel,
            error: cybersource.error?.message ?? 'Error en el medio de pago cybersource',
            section: 'PaymentSection',
            type: 'Checkout_presentational_error',
        });
    }

    return (
        <>
            <PaymentMethodItem
                paymentMethodName="Crédito"
                type="credito"
                widthIcon="63px"
                onClick={handleOnClick}
                blocked={isBlocked}
                selected={isOpen}
                disabled={fingerprint == null}
            />
            <Modal
                onToggle={() => {
                    // TODO: Reset payment
                    setIsOpen(false);
                }}
                isOpen={isOpen}
                header=" "
            >
                <CreditCard
                    disableRemember={!allowRemember}
                    disabled={cybersource.status !== 'ok'}
                    method="Cybersource"
                    onSubmit={handleOnSubmit}
                    pending={cybersource.status === 'pending'}
                >
                    {cybersource.status === 'error' && (
                        <AlertBadge theme="danger" size="large">
                            Revisa que los datos sean correctos
                        </AlertBadge>
                    )}
                </CreditCard>
            </Modal>
        </>
    );
}
