import React, { useContext } from 'react';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import { setPaymentMethod as setWebpayCredito } from 'store/paymentMethod/webpayCredito.slice';
import { ErrorContext } from 'containers/aio/PaymentMethodsContainer/context';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { RootState } from 'types/store';

/**
 * WebpayCreditSelector shows the option to select WebpayCredito as payment method
 * @returns JSX.Element
 */
export function WebpayCreditSelector(): JSX.Element {
    const { t } = useTranslation('error');
    const dispatch = useAppDispatch();
    const ctx = useContext(ErrorContext);
    const pm = useAppSelector((state) => state.paymentMethod);
    const dm = useDeliveryMethod();
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    const isSelected = pm.data.selected.includes('WebpayCredito');
    const isLoading = pm.data.loading.includes('WebpayCredito');
    const isBlocked =
        (pm.data.status === 'pending' && !isLoading) || couponLoading || !dm.hasSchedules();

    async function setWebpayCreditoPayment() {
        try {
            await dispatch(setWebpayCredito()).unwrap();
        } catch (e) {
            ctx.setError(t('defaultPaymentError.message'));
        }
    }

    return (
        <PaymentMethodItem
            paymentMethodName="Webpay Crédito"
            type="webpayplus"
            widthIcon="75px"
            onClick={setWebpayCreditoPayment}
            selected={isSelected}
            blocked={isBlocked}
            isLoading={isLoading}
        />
    );
}
