import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';
import { FULFILLMENT, ANY_DELIVERY_METHOD, LARGEST_PRICE_GROUP } from 'consts';
import { fetchCartById } from 'store/cart';

export const checkShippingCompatibility = createAsyncThunk<
    CompatibleGroupsData,
    void,
    { state: RootState }
>('shippingCompatibility/check', (_, { getState }) => {
    const state = getState();

    const shipping = state.shipping.data;
    if (shipping == null) {
        throw { code: 'errInternal', message: 'No se esperaba recibir datos de agenda vacíos' };
    }

    const products = state.cart.data?.products;
    if (products == null) {
        throw { code: 'errInternal', message: 'No se esperaba no tener datos de productos' };
    }

    const rules = state.rule.data;
    if (rules == null) {
        throw { code: 'errInternal', message: 'No se esperaban reglas de negocio vacías' };
    }

    const shippingGroups: CompatibleGroupsData = {};
    Object.entries(shipping.selectedSchedules).forEach(([key, scheduleId]) => {
        const selectedIndex = scheduleId.split(':')[2];
        const group = shipping.groups.byId[key];
        if (group == null) return; // continue

        const index = parseInt(selectedIndex);
        const schedule = group.schedules[index];

        let compatibilityKey = ANY_DELIVERY_METHOD;
        if (rules.separateFulfillment && schedule.isFulfillment) {
            compatibilityKey = FULFILLMENT;
            if (rules.separateFulfillmentPromises) {
                const sch = group.schedules.find((s) => s.id == shipping.selectedSchedules[key]);
                compatibilityKey += `:${sch?.customFields.coordinateIds || 'unknown'}`;
            }
        }

        const groupProducts = group.products
            .map((sp) => products.find((p) => p.id == sp.id || p.sku == sp.sku))
            .filter((p): p is Product => p != null);

        const groupAmount = groupProducts
            .map((p) => {
                const price = parseFloat(p?.prices['discount']?.value ?? p?.prices['master'].value);
                return p.quantity * price;
            })
            .reduce((a, b) => a + b, 0);

        const memo = shippingGroups[compatibilityKey];
        if (memo == null) {
            shippingGroups[compatibilityKey] = {
                products: groupProducts,
                amount: groupAmount,
            };
            return; // continue
        }

        shippingGroups[compatibilityKey] = {
            products: memo.products.concat(groupProducts),
            amount: memo.amount + groupAmount,
        };
    });
    return shippingGroups;
});

export const checkPickupCompatibility = createAsyncThunk<
    CompatibleGroupsData,
    void,
    { state: RootState }
>('pickupCompatibility/check', async (_, { getState }) => {
    const state = getState();

    const pickup = state.pickup.data;
    if (pickup == null) {
        throw { code: 'errInternal', message: 'No se esperaba recibir datos de agenda vacíos' };
    }

    const products = state.cart.data?.products;
    if (products == null) {
        throw { code: 'errInternal', message: 'No se esperaba no tener datos de productos' };
    }

    const rules = state.rule.data;
    if (rules == null) {
        throw { code: 'errInternal', message: 'No se esperaban reglas de negocio vacías' };
    }

    const shippingGroups: CompatibleGroupsData = {};
    Object.entries(pickup.selectedSchedules).forEach(([key, scheduleId]) => {
        const selectedIndex = scheduleId.split(':')[2];
        const group = pickup.groups.byId[key];
        if (group == null) return; // continue

        const index = parseInt(selectedIndex);
        const schedule = group.schedules[index];

        let compatibilityKey = ANY_DELIVERY_METHOD;
        if (rules.separateFulfillment && schedule.isFulfillment) {
            compatibilityKey = FULFILLMENT;
            if (rules.separateFulfillmentPromises) {
                const sch = group.schedules.find((s) => s.id == pickup.selectedSchedules[key]);
                compatibilityKey += `:${sch?.customFields.coordinateIds || 'unknown'}`;
            }
        }

        const groupProducts = group.products
            .map((sp) => products.find((p) => p.id == sp.id || p.sku == sp.sku))
            .filter((p): p is Product => p != null);

        const groupAmount = groupProducts
            .map((p) => {
                const price = parseFloat(p?.prices['discount']?.value ?? p?.prices['master'].value);
                return p.quantity * price;
            })
            .reduce((a, b) => a + b, 0);

        const memo = shippingGroups[compatibilityKey];
        if (memo == null) {
            shippingGroups[compatibilityKey] = {
                products: groupProducts,
                amount: groupAmount,
            };
            return; // continue
        }

        shippingGroups[compatibilityKey] = {
            products: memo.products.concat(groupProducts),
            amount: memo.amount + groupAmount,
        };
    });
    return shippingGroups;
});

export const separateByCompatibleGroup = createAsyncThunk(
    'shippingCompatibility/separate',
    async (key: string, { getState, dispatch }) => {
        const state = getState() as RootState;
        const cartId = state.cart.data?.id ?? '';

        if (key == LARGEST_PRICE_GROUP) {
            key = largestPriceGroup(state.shippingCompatibility);
        }

        const isFulfillment = key.startsWith(FULFILLMENT);
        const { products } = state.shippingCompatibility.data.byId[key];
        const response = await services.fulfillment.setFulfillment(cartId, isFulfillment, products);
        await dispatch(fetchCartById());
        return response;
    },
);

const initialState: ShippingCompatibilityState = {
    status: 'idle',
    data: { byId: {}, allIds: [], show: false },
    error: null,
};

export const slice = createSlice({
    name: 'shippingCompatibility',
    initialState,
    reducers: {
        toggleShow(state: ShippingCompatibilityState) {
            state.data.show = !state.data.show;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkShippingCompatibility.pending, (state: ShippingCompatibilityState) => {
            state.status = 'pending';
        });
        builder.addCase(
            checkShippingCompatibility.rejected,
            (state: ShippingCompatibilityState, { error }) => {
                state.status = 'error';
                state.error = error;
            },
        );
        builder.addCase(
            checkShippingCompatibility.fulfilled,
            (state: ShippingCompatibilityState, { payload }) => {
                state.status = 'ok';
                state.data.byId = payload;
                state.data.allIds = Object.keys(payload);
            },
        );

        builder.addCase(checkPickupCompatibility.pending, (state: ShippingCompatibilityState) => {
            state.status = 'pending';
        });
        builder.addCase(
            checkPickupCompatibility.rejected,
            (state: ShippingCompatibilityState, { error }) => {
                state.status = 'error';
                state.error = error;
            },
        );
        builder.addCase(
            checkPickupCompatibility.fulfilled,
            (state: ShippingCompatibilityState, { payload }) => {
                state.status = 'ok';
                state.data.byId = payload;
                state.data.allIds = Object.keys(payload);
            },
        );

        builder.addCase(separateByCompatibleGroup.pending, (state: ShippingCompatibilityState) => {
            state.status = 'pending';
        });
        builder.addCase(
            separateByCompatibleGroup.rejected,
            (state: ShippingCompatibilityState, { error }) => {
                state.status = 'error';
                state.error = error;
            },
        );
        builder.addCase(
            separateByCompatibleGroup.fulfilled,
            (state: ShippingCompatibilityState) => {
                state.status = 'ok';
            },
        );
    },
});

export type ShippingCompatibilityState = {
    status: 'idle' | 'pending' | 'error' | 'ok';
    error: SerializedError | null;
    data: {
        byId: CompatibleGroupsData;
        allIds: string[];
        show: boolean;
    };
};

export function largestPriceGroup(state: ShippingCompatibilityState): string {
    let selected = state.data.allIds[0];
    let currentMax = 0;
    state.data.allIds.forEach((id: string) => {
        const group = state.data.byId[id];
        if (group == null) return;

        if (
            (group.amount == currentMax && id.startsWith(FULFILLMENT)) ||
            group.amount >= currentMax
        ) {
            currentMax = group.amount;
            selected = id;
        }
    });
    return selected;
}
