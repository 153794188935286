// Flow summary
// https://www.transbankdevelopers.cl/documentacion/webpay-plus
//
// The return_url URL is always reached by POST, although from version 1.1 of
// the API, onwards, the redirection is by GET. To summarize the different flows
// that may exist, and the different responses that can be expected: There are 4
// different flows, each one arriving with different data:
//
// Normal Flow: When finishing the transaction (either approved or rejected)
// will receive just token_ws.
//
// Timeout (more than 10 minutes in the Transbank form): It will only arrive
// TBK_ID_SESION that contains the session_id sent when creating the
// transaction, TBK_ORDEN_COMPRA that represents the sent buy_order. No token
// will arrive.
//
// Aborted payment (with cancel purchase button in the Webpay form): It will
// arrive TBK_TOKEN (note that it is not called token_ws, but it also contains
// the transaction token), TBK_ID_SESION, TBK_ORDEN_COMPRA
//
// Click "return to site" after error: (replicable only in production if you
// start a transaction, open the payment form, close the Chrome tab and then you
// retrieve it) It will arrive token_ws, TBK_TOKEN, TBK_ID_SESION,
// TBK_ORDEN_COMPRA.
//
export function getWebpayStatus(): string {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token_ws') ?? '';
    const tokenAbort = params.get('TBK_TOKEN') ?? '';

    if (token == '' && tokenAbort == '') {
        return 'timeout';
    } else if (token == '' && tokenAbort != '') {
        return 'abort';
    } else if (token != '' && tokenAbort == '') {
        return 'ok';
    } else {
        return 'error';
    }
}

export function getAlignetStatus(): string {
    const params = new URLSearchParams(window.location.search);
    const result = params.get('authorizationResult') ?? '';

    if (result === '00') {
        return 'ok';
    } else if (result === '05') {
        return 'abort';
    } else {
        return 'error';
    }
}
