import React, { FC, FormEvent, ReactElement, useEffect } from 'react';
import styles from './InvoiceForm.module.scss';

import SelectField from '../../common/SelectField';
import FieldWrapper from '../../common/FieldWrapper';
import TextField from 'components/common/TextField';
import PhoneField from 'components/common/PhoneField';

import { Option } from 'components/common/Select';
import { CL, PE } from 'consts';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { format as formatRUT } from 'rut.js';

export type InvoiceFormData = {
    companyName: string;
    nin: string;
    streetName: string;
    streetNumber: string;
    homeNumber: string;
    phoneNumber: string;
    phoneNumberFormatted: string;
    regionCode: string;
    localityCode: string;
    region: string;
    locality: string;
    activityCode: string;
    activity: string;
};

export type SelectOptions = {
    regions: Option[];
    districts: Option[];
    localities: Option[];
    activities: Option[];
};

export type InvoiceFormProps = {
    form: UseFormReturn<InvoiceFormData>;
    defaultValues?: InvoiceFormData;
    selectOptions: SelectOptions;
    country: 'CL' | 'PE';
    isLoading?: boolean;
    onSubmit: (event: FormEvent) => void;
};

export const InvoiceForm: FC<InvoiceFormProps> = ({
    form,
    isLoading,
    selectOptions,
    country,
    onSubmit,
}: InvoiceFormProps): ReactElement => {
    const { t } = useTranslation();

    const errors = form.formState.errors;
    const defaultValues = form.formState.defaultValues;

    const regionCode = form.watch('regionCode');
    const localityCode = form.watch('localityCode');
    const activityCode = form.watch('activityCode');

    const regionName = selectOptions.regions.find((r) => r.value === regionCode)?.label;
    const localityName = selectOptions.localities.find((l) => l.value === localityCode)?.label;
    const activityName = selectOptions.activities.find((l) => l.value === activityCode)?.label;

    const patternRegex = /[^a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\d\s.,'#!-]/;
    const patternString = patternRegex.source;

    useEffect(() => {
        form.setValue('region', regionName ?? '');
        form.setValue('locality', localityName ?? '');
        form.setValue('activity', activityName ?? '');
    }, [form, regionName, localityName, activityName]);

    const nin = form.watch('nin');
    useEffect(() => {
        if (country == CL && nin) form.setValue('nin', formatRUT(nin));
    }, [country, form, nin]);

    return (
        <form onSubmit={onSubmit} data-testid="address-form">
            {country === PE && (
                <>
                    <FieldWrapper className={styles['field-wrapper']} error={errors.nin?.message}>
                        <TextField
                            label={t('companyDNI')}
                            disabled={isLoading}
                            defaultValue={defaultValues?.nin}
                            isInvalid={!!errors.nin?.message}
                            maxLength={11}
                            {...form.register('nin')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.companyName?.message}
                    >
                        <TextField
                            label={t('companyName')}
                            disabled={isLoading}
                            defaultValue={defaultValues?.companyName}
                            maxLength={128}
                            isInvalid={!!errors.companyName?.message}
                            {...form.register('companyName')}
                        />
                    </FieldWrapper>
                </>
            )}

            {country === CL && (
                <>
                    <p className={styles['info']}>
                        Aseguráte de ingresar los datos exactos del documento{' '}
                        <strong>Rol único tributario</strong>
                        de tu empresa para evitar errores.
                    </p>

                    <FieldWrapper className={styles['field-wrapper']} error={errors.nin?.message}>
                        <TextField
                            label={t('companyDNI')}
                            disabled={isLoading}
                            placeholder={'__.___.___-_'}
                            defaultValue={defaultValues?.nin}
                            isInvalid={!!errors.nin?.message}
                            maxLength={12}
                            {...form.register('nin')}
                        />
                        {!errors.nin?.message && (
                            <span className={styles['help-text']}>Sin punto ni guión</span>
                        )}
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.companyName?.message}
                    >
                        <TextField
                            label={t('companyName')}
                            disabled={isLoading}
                            defaultValue={defaultValues?.companyName}
                            isInvalid={!!errors.companyName?.message}
                            maxLength={20}
                            pattern={patternString}
                            placeholder="Ej: Comercial ECCSA S.A."
                            {...form.register('companyName')}
                        />
                        {!errors.companyName?.message && (
                            <span className={styles['help-text']}>Nombre inscrito en el SII</span>
                        )}
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.activityCode?.message}
                    >
                        <SelectField
                            label={t('companyActivity')}
                            options={selectOptions.activities}
                            disabled={isLoading}
                            defaultValue={defaultValues?.activityCode}
                            isInvalid={!!errors.activityCode?.message}
                            empty="Seleccione una opción"
                            {...form.register('activityCode')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.streetName?.message}
                    >
                        <TextField
                            label={'Dirección'}
                            disabled={isLoading}
                            defaultValue={defaultValues?.streetName}
                            isInvalid={!!errors.streetName?.message}
                            maxLength={20}
                            pattern={patternString}
                            {...form.register('streetName')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.streetNumber?.message}
                    >
                        <TextField
                            label={'Número'}
                            disabled={isLoading}
                            defaultValue={defaultValues?.streetNumber}
                            isInvalid={!!errors.streetNumber?.message}
                            maxLength={20}
                            pattern={patternString}
                            {...form.register('streetNumber')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.homeNumber?.message}
                    >
                        <TextField
                            label={'Depto/Casa/Oficina'}
                            disabled={isLoading}
                            defaultValue={defaultValues?.homeNumber}
                            isInvalid={!!errors.homeNumber?.message}
                            maxLength={20}
                            pattern={patternString}
                            {...form.register('homeNumber')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.regionCode?.message}
                    >
                        <SelectField
                            label={'Región'}
                            options={selectOptions.regions}
                            disabled={isLoading}
                            defaultValue={defaultValues?.regionCode}
                            isInvalid={!!errors.regionCode?.message}
                            empty="Seleccione una región"
                            {...form.register('regionCode')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.localityCode?.message}
                    >
                        <SelectField
                            label={'Comuna'}
                            options={selectOptions.localities}
                            disabled={isLoading}
                            defaultValue={defaultValues?.localityCode}
                            isInvalid={!!errors.localityCode?.message}
                            empty="Seleccione una comuna"
                            {...form.register('localityCode')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['field-wrapper']}
                        error={errors.phoneNumber?.message}
                    >
                        <PhoneField
                            disabled={isLoading}
                            defaultValue={defaultValues?.phoneNumber}
                            country={country}
                            onChangeMasked={(_, formatted) => {
                                form.setValue('phoneNumberFormatted', formatted);
                            }}
                            isInvalid={!!errors.phoneNumber?.message}
                            {...form.register('phoneNumber')}
                        />
                    </FieldWrapper>
                </>
            )}
        </form>
    );
};
