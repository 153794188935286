import { createAction, createSlice } from '@reduxjs/toolkit';

export const setBillingInfo = createAction<PartialPaymentInfo>('billing/setInfo');

export type BillingState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: PartialPaymentInfo | null;
    error: SerializedError | null;
};

const initialState: BillingState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setBillingInfo, (state, action) => {
            const { payload } = action;
            state.status = 'ok';
            state.data = payload;
        });
    },
});

export default slice;
