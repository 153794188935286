import React, { useEffect, useState } from 'react';

import TimerAlert from 'components/aio/TimerAlert';
import { useAppSelector } from 'lib/hooks/redux';
import { useCountdown, useIdle } from 'lib/hooks/timer';
import { getEnvironment } from 'config';

export const AppTimerContainer = () => {
    // Acceso a datos de estado global para configurar temporizadores.
    const app = useAppSelector((state) => state.app);
    const rules = useAppSelector((state) => state.rule);
    const envs = getEnvironment();
    const url = envs.app.tiendaUrl;

    // Control de inactividad y temporizadores para redirección y sesión.
    const { isIdle, startIdleTimer } = useIdle();
    const redirectCountdown = useCountdown();
    const sessionCountdown = useCountdown();
    const [isOpen, setIsOpen] = useState(false);

    // Configuración específica basada en el canal de la aplicación.
    const config = rules.data.checkoutTimer?.find((r) => r.channel == app.data?.channel);

    // Inicia temporizadores basados en la configuración.
    useEffect(() => {
        if (config?.idleTime) startIdleTimer(config.idleTime);
        if (config?.checkTime) sessionCountdown.startCountdown(Date.now() + config.checkTime);
    }, [config]);

    // Abre alerta cuando el usuario está inactivo.
    useEffect(() => {
        if (isIdle) setIsOpen(true);
    }, [isIdle]);

    // Inicia el temporizador de redirección al abrir la alerta.
    useEffect(() => {
        if (isOpen) redirectCountdown.startCountdown(Date.now() + 60000);
    }, [isOpen]);

    // Acciones al finalizar los temporizadores.
    useEffect(() => {
        // Si el modal esta abierto y la cuenta regresiva llega a cero se ejecuta la redirección
        if (isOpen && redirectCountdown.timeRemaining == 0) window.location.href = url;
    }, [redirectCountdown.timeRemaining]);

    useEffect(() => {
        if (sessionCountdown.timeRemaining == 0) setIsOpen(true);
    }, [sessionCountdown.timeRemaining]);

    // Reinicia el temporizador y cierra la alerta.
    const onCloseHandler = () => {
        if (config?.checkTime && sessionCountdown.timeRemaining == 0)
            sessionCountdown.startCountdown(Date.now() + config.checkTime);
        setIsOpen(false);
    };

    let countdownText = `${redirectCountdown.timeRemaining} segundos`;
    if (redirectCountdown.timeRemaining == 1)
        countdownText = `${redirectCountdown.timeRemaining} segundo`;
    if (redirectCountdown.timeRemaining == 0) countdownText = 'Redirigiendo...';
    return <TimerAlert isOpen={isOpen} onClose={onCloseHandler} countdown={countdownText} />;
};
