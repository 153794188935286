import React, { FunctionComponent } from 'react';
import styles from './Title.module.scss';

export type TitleProps = {
    children: string;
    size?: 'small' | 'medium' | 'large';
};

const defaultProps: TitleProps = {
    children: '',
    size: 'large',
};

export const Title: FunctionComponent<TitleProps> = ({ size, children }: TitleProps) => {
    let tag = 'h1';
    if (size === 'medium') tag = 'h2';
    if (size === 'small') tag = 'h4';
    const Tag = tag as keyof JSX.IntrinsicElements;
    const className = `${styles['title']} ${styles[`title-${size}`]}`;
    return <Tag className={className}>{children}</Tag>;
};

Title.defaultProps = defaultProps;

export default Title;
