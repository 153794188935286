import React, { FunctionComponent } from 'react';
import styles from './NavList.module.scss';

export type NavListProps = {
    children: React.ReactNode;
};

export const NavList: FunctionComponent<NavListProps> = (props: NavListProps) => {
    return <ul className={styles['container']}>{props.children}</ul>;
};
