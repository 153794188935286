import React from 'react';

import PickupSummary from 'components/aio/PickupSummary';
import PickupModalContainer from '../PickupModalContainer';
import StoreModalContainer from '../StoreModalContainer';

import { DD, PE, country } from 'consts';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { formatBusinessHours, formatStoreAddress, formatStoreName } from 'lib/utils/pickup';
import { togglePickupModal, toggleStoreModal, changeDeliveryMethod } from 'store/deliveryMethod';
import { getSelectedStore, setProvisionalStore } from 'store/store';
import { getDeliveries } from 'containers/aio/PickupSummaryContainer/PickupSummaryContainer.utils';
import { RootState } from 'types/store';

export const PickupSummaryContainer: React.FC = () => {
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const store = useAppSelector(getSelectedStore);
    const { pickupForm } = useAppSelector((state: RootState) => state.store);
    const { isOpenPickupModal, isOpenStoreModal } = useAppSelector(
        (state: RootState) => state.deliveryMethod,
    );
    const deliveries = getDeliveries(cart);
    const dispatch = useAppDispatch();

    const address = store && formatStoreAddress(store);
    const storeName = store && formatStoreName(store);
    const storeBusinessHours = store && formatBusinessHours(store);

    const changeMethod = () => {
        (async () => {
            await dispatch(changeDeliveryMethod(DD));
        })();
    };

    return (
        <>
            <PickupSummary
                title="Retiro en tienda"
                storeName={storeName || ''}
                address={address || ''}
                storeBusinessHours={country == PE ? storeBusinessHours : ''}
                deliverables={deliveries}
                pickupForm={pickupForm}
                changeStore={() => dispatch(togglePickupModal(true))}
                changeDelivery={() => dispatch(toggleStoreModal(true))}
                changeMethod={changeMethod}
            />

            {isOpenPickupModal && (
                <PickupModalContainer
                    isOpen={isOpenPickupModal}
                    onToggle={() => dispatch(togglePickupModal(false))}
                />
            )}

            {!isOpenPickupModal && isOpenStoreModal && (
                <StoreModalContainer
                    isOpen={isOpenStoreModal}
                    onContinue={() => dispatch(toggleStoreModal(false))}
                    onToggle={() => {
                        dispatch(toggleStoreModal(false));
                        dispatch(setProvisionalStore(null));
                    }}
                />
            )}
        </>
    );
};
