import React, { FC } from 'react';
import styles from './PaymentMethodList.module.scss';
import PaymentMethodIcon from 'components/payment/PaymentMethodIcon';
import { PaymentMethod } from '../Confirmation/Confirmation';
import CreditCardTitle from '../CreditCardTitle';

export type PaymentMethodListProps = {
    payments: PaymentMethod[];
    card?: ExternalCard | null;
};

export const PaymentMethodList: FC<PaymentMethodListProps> = ({
    payments,
    card,
}: PaymentMethodListProps) => {
    return (
        <>
            {payments.map((payment, index) => {
                const isNiubiz = payment.name == 'Niubiz' || payment.name == 'RipleyNiubiz';
                return (
                    <div
                        key={index}
                        className={isNiubiz ? styles['payment'] : styles['payment-method']}
                        data-testid="paymentMethodContent"
                    >
                        <div data-testid="paymentMethodIcon">
                            <PaymentMethodIcon width="32px" type={payment.icon} />
                        </div>
                        <div>
                            <span data-testid="paymentMethodName">{payment.label}</span>
                            {(payment.name == 'Cybersource' ||
                                payment.name == 'RipleyCard' ||
                                payment.name == 'RipleyChekCard') &&
                                card && <CreditCardTitle card={card} />}
                        </div>
                        {isNiubiz ? payment.cardNumber : <></>}
                    </div>
                );
            })}
        </>
    );
};

export default PaymentMethodList;
