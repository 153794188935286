// Import the storage key constant from the constants file
import { STORAGE_KEY, STORAGE_PREFIX } from '../../consts';

/**
 * Gets the value stored in localStorage for a given key.
 *
 * @param key - The key of the item to retrieve from localStorage.
 * @returns The value stored in localStorage for the given key or null if it doesn't exist.
 */
export function getLocalStorageItem(key: string): string | null {
    return localStorage.getItem(key);
}

/**
 * Gets a specific slice of the object stored in localStorage under the STORAGE_KEY.
 *
 * @param slice - The name of the slice of the object to retrieve.
 * @returns The value of the slice of the object stored in localStorage or null if it doesn't exist.
 */
export function getSlice<T>(slice: string): T | null {
    const store = getLocalStorageItem(`${STORAGE_PREFIX}${STORAGE_KEY}`);

    if (store) {
        const parsedStore = JSON.parse(store);
        const data = parsedStore[slice];
        return (data && (JSON.parse(data) as T)) || null;
    }

    return null;
}
