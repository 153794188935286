import * as React from 'react';
import styles from './Clipboard.module.scss';
import { copyElement } from '../../../lib/utils/clipboard';

export type ClipboardProps = {
    /**
     * Text that will be copied to clipboard on click
     */
    children?: React.ReactNode;
};

export const Clipboard: React.FC<ClipboardProps> = ({ children }: ClipboardProps) => {
    const [checked, setChecked] = React.useState(false);

    const handleCopy = (event: React.MouseEvent<HTMLElement>) => {
        if (checked) return;

        copyElement(event.currentTarget?.firstElementChild);
        setChecked(true);
        setTimeout(() => setChecked(false), 1000);
    };

    let buttonClass = styles['clipboard'];
    if (checked) {
        buttonClass += ' ' + styles['clipboard--checked'];
    }

    return (
        <button className={buttonClass} onClick={handleCopy}>
            <div className={styles['content']}>{children}</div>
            <div className={styles['icon']}></div>
            {true && <div className={styles['tooltip']}>¡Copiado!</div>}
        </button>
    );
};
