import services from 'services';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REDIRECT_FULFILLMENT } from 'consts';
import { RootState } from 'types/store';

export const setFulfillmentProducts = createAsyncThunk(
    'fulfillment/fulfillment_products',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const cart = state.cart.data;
        const shipping = state.shipping.data;
        const pickup = state.pickup.data;

        if (cart) {
            const dispatchGroups = shipping?.groups.byId as GroupsById;
            const pickupGroups = pickup?.groups.byId as GroupsById;
            let fulfillmentSkus: string[] = [];

            // search shipping fulfillment products
            dispatchGroups &&
                Object.keys(dispatchGroups).forEach(function (key) {
                    // search for the field isFulfillment to validate if the group has fullfillment products
                    const isFulfillment = dispatchGroups[key].schedules.some(
                        (schedule) => schedule.isFulfillment,
                    );

                    if (isFulfillment) {
                        // string array of fulfillment skus
                        const groupSkus = dispatchGroups[key].products.map(
                            (product) => product.sku,
                        );
                        // new array with all fulfillment skus in the cart
                        fulfillmentSkus = [...fulfillmentSkus, ...groupSkus];
                    }
                });

            // search pickup fulfillment products
            pickupGroups &&
                Object.keys(pickupGroups).forEach(function (key) {
                    // search for the field isFulfillment to validate if the group has fullfillment products
                    const isFulfillment = pickupGroups[key].schedules.some(
                        (schedule) => schedule.isFulfillment,
                    );
                    if (isFulfillment) {
                        // string array of fulfillment skus
                        const groupSkus = pickupGroups[key].products.map((product) => product.sku);
                        // new array with all fulfillment skus in the cart
                        fulfillmentSkus = [...fulfillmentSkus, ...groupSkus];
                    }
                });

            // remove duplicates
            fulfillmentSkus = fulfillmentSkus.filter(
                (item, index) => fulfillmentSkus.indexOf(item) === index,
            );

            // Product array of fulfillment products
            const products = cart.products.filter((p) =>
                fulfillmentSkus.find((sp) => p.sku === sp),
            ) as Product[];

            products.length && services.fulfillment.setFulfillmentProducts(cart.id, products);
        }
    },
);

export const redirectMixedGroup = createAsyncThunk<boolean>(
    'fulfillment/redirect_mixed_group',
    async (_, { getState }): Promise<boolean> => {
        const state = getState() as RootState;
        const shipping = state.shipping.data;

        if (REDIRECT_FULFILLMENT?.length && shipping) {
            let isFulfillmentGroup = false;
            let isNotFulfillmentGroup = false;

            const dispatchGroups = shipping.groups.byId;
            // Iterates through the dispatch groups looking for the existence of
            // fulfilled products and non-fulfilled products
            Object.keys(dispatchGroups).forEach(function (key) {
                const isFulfillment = dispatchGroups[key].schedules.some(
                    (group) => group.isFulfillment,
                );
                if (isFulfillment) {
                    isFulfillmentGroup = true;
                } else {
                    isNotFulfillmentGroup = true;
                }
            });
            // If there are fulfillment products and non-fulfillment products,
            // the user is redirected to the old checkout
            if (isFulfillmentGroup && isNotFulfillmentGroup) {
                window.location.href = REDIRECT_FULFILLMENT;
                return true;
            }
        }
        return false;
    },
);

const initialState: FulfillmentState = {
    status: 'idle',
    error: null,
};

export const slice = createSlice({
    name: 'shippingCompatibility',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setFulfillmentProducts.pending, (state: FulfillmentState) => {
            state.status = 'pending';
        });
        builder.addCase(setFulfillmentProducts.rejected, (state: FulfillmentState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(setFulfillmentProducts.fulfilled, (state: FulfillmentState) => {
            state.status = 'ok';
        });
        builder.addCase(redirectMixedGroup.fulfilled, (state: FulfillmentState) => {
            state.status = 'ok';
        });
    },
});

export type FulfillmentState = {
    status: 'idle' | 'pending' | 'error' | 'ok';
    error: SerializedError | null;
};

export default slice;
