import React from 'react';
import styles from './ChekPushNotification.module.scss';
import Button from 'components/common/Button';

import chekImage from './images/chek.svg';
import mobile from './images/mobile.svg';

export type ChekPushNotificationProps = {
    amount: string;
    phoneNumber: string;
    time: string;
    isRedirecting?: boolean;
    onClick: () => void;
};

export const ChekPushNotification: React.FC<ChekPushNotificationProps> = (
    props: ChekPushNotificationProps,
) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div>
                    <img src={chekImage} alt="Logo chek" data-testid="image" />
                </div>
                <div>
                    <img src={mobile} alt="Mobile" data-testid="mobile" />
                </div>
                <div>
                    <h1>Autoriza el pago en Chek</h1>
                    <p>
                        Te enviamos el cobro de <b>{props.amount}</b>
                        <br /> al siguiente número <b>{`+569 ` + props.phoneNumber}</b>
                    </p>
                    {!props.isRedirecting && (
                        <b>
                            Confirma en <time>{props.time}</time> min
                        </b>
                    )}
                    {props.isRedirecting && (
                        <p>
                            <b>
                                Tiempo de espera <span>agotado</span>
                            </b>
                            <br />
                            <b>Redirigiendo...</b>
                        </p>
                    )}
                    <Button theme="secondary" size="medium" onClick={props.onClick}>
                        Elegir otro medio de pago
                    </Button>
                </div>
            </div>
        </div>
    );
};
