import { getEnvironment } from 'config';
import Cookies from 'js-cookie';

export function hasMarketplaceProducts(products: Product[]): boolean {
    return products.some((p) => p.details.sellerId !== '1');
}

export function getCartId(): string {
    const { cart } = getEnvironment();
    const cookieName = cart.idCookie;
    const paramName = cart.queryParam;
    const query = new URLSearchParams(window.location.search);
    return query.get(paramName) || Cookies.get(cookieName) || '';
}
