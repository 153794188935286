import { disablePaymentWithoutCvv } from 'store/cardToken/cardToken.slice';
import { useAppDispatch } from './redux';
import { resetPayments } from 'store/paymentMethod/actions';

import { getEnvironment } from 'config';

const { app } = getEnvironment();

interface HookRespose {
    post: () => Promise<PostResponse>;
}

type PostResponse = {
    status: 'ok' | 'error';
    message?: string;
    id?: string;
};

type Message = {
    type: string;
    status: string;
    data: {
        id: string;
    };
};

const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*ripley\.cl?$/;

export function useFaceId(): HookRespose {
    const dispatch = useAppDispatch();

    async function post(): Promise<PostResponse> {
        return new Promise((resolve, reject) => {
            // Función que maneja el evento message
            function handleMessage(message: any) {
                // Valida que el origen del mensaje sea de un dominio ripley.cl
                if (message.origin && !regex.test(message.origin)) {
                    return;
                }

                let data: Message;
                try {
                    data = JSON.parse(message.data);
                } catch (e) {
                    // No continuar en caso que el mensaje no sea un objeto
                    return;
                }

                // No continuar en caso que el mensaje no sea de tipo face_id
                if (data.type !== 'face_id') {
                    return;
                }

                // En caso que la validación sea exitosa
                if (data.type === 'face_id' && data.status == 'ok' && data?.data?.id) {
                    resolve({
                        message: 'validación biométrica exitosa',
                        status: 'ok',
                        id: data.data.id,
                    });
                } else {
                    // En caso que la validación falle
                    reject(new Error('Error en la validación biométrica'));
                }
            }

            if (window?.ReactNativeWebView?.postMessage) {
                // Envía solicitud para activar el face id
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ type: 'face_id', data: {} }),
                );

                // Registra el event listener para el evento 'message'
                // Nota: para que el evento funcione en android hay que agregar el listener al 'document' mientras que en ios a 'window'
                window.addEventListener('message', handleMessage);
                document.addEventListener('message', handleMessage);

                // Configura un temporizador para rechazar la promesa después de un minuto
                setTimeout(() => {
                    // Desregistra el event listener después del tiempo límite
                    window.removeEventListener('message', handleMessage);
                    document.removeEventListener('message', handleMessage);

                    // Rechaza la promesa con un mensaje de timeout
                    reject(new Error('Tiempo de espera agotado'));
                }, app.biometricVerificationTimeout); // 30000 milisegundos por defecto (1/2 minuto)
            } else {
                dispatch(resetPayments());
                dispatch(disablePaymentWithoutCvv());

                reject(
                    new Error(
                        'La validación biométrica no se pudo realizar. Por favor, selecciona nuevamente el medio de pago e inténtalo de nuevo',
                    ),
                );
            }
        });
    }

    return {
        post,
    };
}
