import React from 'react';
import TaxDocumentSummary from 'containers/aio/TaxDocumentSummary';

export const TaxDocumentSection: React.FC = () => {
    return (
        <section>
            <TaxDocumentSummary />
        </section>
    );
};
