import React, { useEffect, useState } from 'react';
import Modal from 'components/common/Modal';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import AlertBadge from 'components/common/AlertBadge';
import Button from 'components/common/Button';
import AddressForm, { AddressFormData, SelectOptions } from 'components/aio/AddressForm';
import {
    getCreateAddressPayload,
    getSelectOptions,
    guestUserValidationSchema,
    registeredUserValidationSchema,
} from 'lib/utils/addresses';
import { useForm } from 'react-hook-form';
import { country } from 'consts';
import { createAddress } from 'store/address';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch, RootState } from 'types/store';

export type AddNewAddressProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export const AddNewAddress: React.FC<AddNewAddressProps> = ({
    isOpen,
    setIsOpen,
}: AddNewAddressProps) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<null | string>('');

    const address = useAppSelector((state: RootState) => state.address);
    const userData = useAppSelector((state: RootState) => state.user.data) as NormalizedUser;
    const areasData = useAppSelector((state: RootState) => state.area.data) as ExtArea;
    const isLoading = address.status === 'pending';

    const schema = userData?.registered
        ? registeredUserValidationSchema
        : guestUserValidationSchema;

    const form = useForm<AddressFormData>({
        resolver: yupResolver(schema),
    });

    const handleSubmit = async (data: AddressFormData) => {
        const dataPackage: DataPackage = { data, user: userData, areas: areasData };
        await saveForm(dataPackage, dispatch, setIsOpen, setError);
    };

    const onSubmit = (event: React.FormEvent) => {
        (async (event) => {
            const handler = form.handleSubmit(handleSubmit);
            await handler(event);
        })(event);
    };
    const regionCode = form.watch('regionCode');
    const districtCode = form.watch('districtCode');

    const [selectOptions, setSelectOptions] = useState<SelectOptions>(
        getSelectOptions(areasData, regionCode, districtCode),
    );

    useEffect(() => {
        setSelectOptions(getSelectOptions(areasData, regionCode, districtCode));
    }, [areasData, regionCode, districtCode]);

    return (
        <Modal
            isOpen={isOpen}
            onToggle={() => setIsOpen(false)}
            header="Agregue nueva dirección"
            footer={
                <>
                    {error && (
                        <AlertBadge theme="danger" timeout={5000} callback={() => setError('')}>
                            {error}
                        </AlertBadge>
                    )}
                    <Button
                        theme="primary"
                        size="large"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={onSubmit}
                    >
                        Guardar
                    </Button>
                </>
            }
        >
            <AddressForm
                form={form}
                selectOptions={selectOptions}
                country={country}
                onSubmit={onSubmit}
                isRegisteredUser={userData.registered}
                isLoading={isLoading}
            />
        </Modal>
    );
};

type DataPackage = {
    data: AddressFormData;
    user: NormalizedUser;
    areas: ExtArea;
};

export const saveForm = async (
    dataPackage: DataPackage,
    dispatch: AppDispatch,
    setIsOpen: (open: boolean) => void,
    setError: (error: string) => void,
): Promise<void> => {
    const { user, data, areas } = dataPackage;
    const payload = getCreateAddressPayload(user, data, areas);
    try {
        await dispatch(createAddress(payload)).unwrap();
        setIsOpen(false);
    } catch (e) {
        setError('Hubo un problema agregando la dirección');
    }
};
