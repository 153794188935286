import React, { useState } from 'react';

import { SHOW_UNAVAILABLE_STORES } from 'consts';
import { useAppSelector } from 'lib/hooks/redux';
import { formatStoreAddress, formatStoreName } from 'lib/utils/pickup';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';
import { ProductThumbAvailability } from 'components/common/ProductThumb/ProductThumb';

import PickupStoreError from 'components/pickup/PickupStoreError';
import PickupStoreStock from 'components/pickup/PickupStoreStock';
import Skeleton from 'components/common/Skeleton';
import { RootState } from 'types/store';

export type StockSelectorContainer = {
    /**
     * Triggered when a store stock is selected.
     */
    onSetStore: (storeId: string) => Promise<void>;
};

export const StockSelectorContainer: React.FC<StockSelectorContainer> = ({
    onSetStore,
}: StockSelectorContainer) => {
    const [isLoading, setIsloading] = useState<boolean | string>(false);
    const stocks = useAppSelector((state: RootState) => state.store.stocks);
    const stores = useAppSelector((state: RootState) => state.store);
    const products = useAppSelector((state: RootState) => state.cart.data?.products);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);

    if (stocks.status === 'idle') {
        return null;
    }

    if (stocks.status === 'pending') {
        return <Skeleton height={130} />;
    }

    if (
        stores.data == null ||
        stocks.data == null ||
        stores.status == 'error' ||
        stocks.status == 'error' ||
        stocks.data?.allIds.length === 0
    ) {
        sendErrorToGA4({
            channel: channel,
            error: 'No hay tiendas disponible',
            section: 'DeliverySection',
            type: 'Checkout_presentational_error',
        });
        return <PickupStoreError />;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {stocks.data.allIds.map((storeId) => {
                const store = stores.data?.byId[storeId];
                const productsThumbs = stocks.data?.byId[storeId].products.map((p) => ({
                    availability:
                        p.pickup || p.siteToStore
                            ? ('available' as ProductThumbAvailability)
                            : ('warning' as ProductThumbAvailability),
                    src: products?.find((cp) => cp.sku === p.sku)?.details.thumbnail ?? '',
                    forceVertical: true,
                }));
                if (store == null || productsThumbs == null) {
                    return;
                }
                const storeUnavailable = productsThumbs.every(
                    (pt) => pt.availability === 'warning',
                );
                if (storeUnavailable && !SHOW_UNAVAILABLE_STORES) {
                    return;
                }
                return (
                    <PickupStoreStock
                        key={store.name}
                        storeId={store.id}
                        isModal={true}
                        isLoading={isLoading && isLoading == store.id ? true : false}
                        products={storeUnavailable ? [] : productsThumbs}
                        storeAddress={formatStoreAddress(store)}
                        storeName={formatStoreName(store)}
                        storeType={store.type}
                        onClick={() => {
                            (async (storeId) => {
                                try {
                                    setIsloading(storeId);
                                    await onSetStore(storeId);
                                } catch (e) {
                                    setIsloading(false);
                                }
                            })(storeId);
                        }}
                    />
                );
            })}
        </div>
    );
};
