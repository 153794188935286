import { configureStore } from '@reduxjs/toolkit';
import { STORAGE_KEY } from '../consts';

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducer';
import { getEnvironment } from 'config';

const envs = getEnvironment();

const persistConfig = {
    key: STORAGE_KEY,
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: envs.app.env !== 'production',
});

const persistor = persistStore(store);

// If the location is the root path, we clean the persisted state in the local storage
const location = window.location;
if (location.pathname == '/' || location.pathname == '/pickup') {
    persistor.purge();
}

export { store, persistor };
