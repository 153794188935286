import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { resetPayments } from 'store/paymentMethod/actions';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useRules } from 'lib/hooks/rules';
import { setCard } from 'store/card/niubiz';
import { setPaymentMethod, NiubizFormState } from 'store/paymentMethod/niubiz.slice';

import AlertBadge from 'components/common/AlertBadge';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';

import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import NiubizForm from 'components/payment/NiubizForm';
import NiubizOptions from 'components/payment/NiubizOptions';

import { paymentMethodItemValues } from './NiubizSelector.utils';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { RootState } from 'types/store';

import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export type NiubizSelectorProps = {
    isRipley: boolean;
};

export function NiubizSelector({ isRipley }: NiubizSelectorProps): JSX.Element {
    // hooks
    const dispatch = useAppDispatch();
    const rules = useRules();
    const { t } = useTranslation();
    const dm = useDeliveryMethod();
    const pm = isRipley ? 'RipleyNiubiz' : 'Niubiz';

    // redux
    const niubiz = useAppSelector((state: RootState) =>
        isRipley ? state.paymentMethod.types.ripleyNiubiz : state.paymentMethod.types.niubiz,
    );
    const paymentMethods = useAppSelector((state: RootState) => state.paymentMethod.data);
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status === 'pending',
    );
    const registered = useAppSelector((state: RootState) => state.user.data?.registered);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const isKiosko = channel == 'KIOSKO';

    const [isValid, setIsValid] = useState(false);
    const [remember, setRemember] = useState(false);
    const [reloadAlreadyCalled, setReloadAlreadyCalled] = useState(false);

    const isSelected = paymentMethods.selected.includes(pm);
    const isBlocked =
        !isSelected && (paymentMethods.status === 'pending' || couponLoading || !dm.hasSchedules());
    /* NiubizForm will check `niubis.status`. It means the card data is being
     * validated, while here, loading means a new payment is being created. */
    const isLoading = paymentMethods.loading.includes(pm) && !niubiz.selectedByToken;
    const isTokenizing = niubiz.status === 'pending';
    const isDisabled = !rules.feature(pm);

    const allowRemember = rules.feature('cardOnFile') && !isKiosko && registered;

    const [resetNiubizStatus, setResetNiubizStatus] = useState<boolean>(false);

    useEffect(() => {
        if (niubiz.error) {
            sendErrorToGA4({
                channel: channel,
                error: niubiz.error.message ?? t('niubizError'),
                section: 'PaymentSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [niubiz.error]);

    const handleOnChange = (form: NiubizFormState) => {
        setIsValid(form.isValid);
    };

    const handleOnClick = () => {
        (async () => {
            await dispatch(resetPayments());
            await dispatch(setPaymentMethod({ isRipley, onChange: handleOnChange }));
        })();
    };

    const handleOnSubmit = () => {
        (async () => {
            if (!isLoading) {
                await dispatch(setCard({ isRipley, remember: remember }));
            }
        })();
    };

    const paymentValues = paymentMethodItemValues({
        isRipley,
        isSelected,
        isSelectedByToken: niubiz.selectedByToken,
        bin: niubiz.card?.bin ?? '',
        error: niubiz.error,
    });

    if (paymentMethods.selected.includes('Alignet')) {
        paymentValues.selected = false;
    }

    const onEdit = useCallback(() => {
        (async () => {
            setResetNiubizStatus(true);
            await dispatch(resetPayments());
            await dispatch(setPaymentMethod({ isRipley, onChange: handleOnChange }));
            setResetNiubizStatus(false);
        })();
    }, [isRipley, dispatch, setResetNiubizStatus]);

    const onClick = !paymentValues.selected ? handleOnClick : () => {};

    /**
     * Reload the form if any of these errors show up.
     * It only will reload 1 time
     */
    useEffect(() => {
        const regex = /(?:Cannot read|TypeError)/;
        const includesError = regex.test(niubiz.error?.message ?? '');
        if (includesError && !reloadAlreadyCalled) {
            onEdit();
            setReloadAlreadyCalled(true);
        }
    }, [niubiz.error, reloadAlreadyCalled, onEdit]);

    return (
        <PaymentMethodItem
            paymentMethodName={t('niubizCards')}
            type={paymentValues.type}
            widthIcon="63px"
            onClick={onClick}
            onEdit={onEdit}
            additionalInfo={paymentValues.additionalInfo}
            blocked={isBlocked}
            disabled={isDisabled}
            selected={paymentValues.selected}
            resetNiubizStatus={resetNiubizStatus}
        >
            {isLoading && niubiz.card == null && <Spinner />}
            {niubiz.card == null && !niubiz.selectedByToken ? (
                <NiubizForm
                    disabled={!isValid}
                    loading={isLoading}
                    remember={remember}
                    setRemember={setRemember}
                    allowRemember={allowRemember}
                    submit={
                        <Button
                            theme="primary"
                            size="large"
                            disabled={isTokenizing}
                            onClick={handleOnSubmit}
                        >
                            {isTokenizing ? (
                                <Spinner size={20} strokeWidth={2} theme="none" />
                            ) : (
                                t('useCard')
                            )}
                        </Button>
                    }
                />
            ) : (
                <NiubizOptions isRipley={isRipley} />
            )}

            {niubiz.error != null && (
                <AlertBadge theme="warning" size="large">
                    {niubiz.error.message || t('niubizError')}
                </AlertBadge>
            )}
        </PaymentMethodItem>
    );
}
