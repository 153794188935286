import { scheduleListSchema } from './normalizers';
import { DD, SERVICE_TYPE, RC, RT } from 'consts';
import jsonata from 'jsonata';
import { getAddressComponentsWithNames, getStoreComponentValue } from 'lib/utils/addresses';

/**
 *  Select a default schedule before setting the data in redux.
 */
const selectDefaultSchedule = (delivery: Delivery): Delivery => {
    delivery.groups?.allIds.forEach((groupId) => {
        const group = delivery.groups.byId[groupId];
        // we set the first schedule by default
        delivery.selectedSchedules[groupId] = group.schedules[0].id;
    });

    return delivery;
};

/** Normalize response for schema */
export const normalizeDeliverySchedule = async (
    responseSchedules: HttpResponse,
): Promise<Delivery> => {
    const jsonataTransformation = await jsonata(scheduleListSchema).evaluate(
        responseSchedules.data,
    );
    return selectDefaultSchedule(jsonataTransformation);
};

/** Return payload for setSchedule in the cart */
export const getPayloadSetSchedule = (args: SetScheduleArgs): SetSchedulePayload => {
    const { cartId, delivery, deliveryMethod, address, user, store } = args;
    const groups: {
        id: string;
        deliveryMethod: string;
        products: ScheduleProducts[];
        schedule: Schedule;
    }[] = [];

    delivery.groups.allIds.map((groupId) => {
        const schedulesGroup = delivery.groups.byId[groupId];

        let scheduleSelected = schedulesGroup.schedules.find(
            (schedule) => schedule.id === delivery.selectedSchedules[groupId],
        );

        if (scheduleSelected) {
            if (deliveryMethod === DD && address) {
                scheduleSelected = {
                    ...scheduleSelected,
                    customer: {
                        nin: user.nin,
                        email: user.email,
                        firstName: user.firstname,
                        lastName: user.lastname,
                        phoneNumber: address.phoneNumber,
                    },
                };
            } else if (deliveryMethod === RT && store) {
                scheduleSelected = {
                    ...scheduleSelected,
                    location: {
                        ...scheduleSelected.location,
                        addressComponents: [
                            ...(scheduleSelected?.location?.addressComponents || []),
                            { key: 'businessHours', value: store.businessHours },
                        ],
                        description: '',
                    },
                    customer: {
                        nin: store.checkbox === 'me' ? user.nin : store.nin,
                        email: user.email,
                        firstName: store.checkbox === 'me' ? user.firstname : store.firstName,
                        lastName: store.checkbox === 'me' ? user.lastname : store.lastName,
                        phoneNumber:
                            store.checkbox === 'me' ? store.userPhoneNumber : store.phoneNumber,
                    },
                };
            }
        }

        if (scheduleSelected) {
            groups.push({
                id: scheduleSelected.id,
                deliveryMethod: schedulesGroup.consolidationDeliveryMethod,
                products: schedulesGroup.products,
                schedule: scheduleSelected,
            });
        }
    });

    const schedule = groups.filter((group) => {
        // Discard Site-to-Store group if there's a RT group for the same products.
        if (group.schedule.typeOfService === SERVICE_TYPE.STS) {
            const alternativeServices = groups.filter(
                (g) => g.products[0].id === group.products[0].id && g.id !== group.id,
            );
            if (alternativeServices.length > 0) {
                return false;
            }
        }
        return true;
    });

    return { cartId, schedule };
};

/** Builds payload to get DD schedule */
export const getPayloadGetScheduleDD = (
    cartId: string,
    areas: ExtArea,
    selectedAddress?: ExternalAddress,
): GetSchedulePayload => {
    if (selectedAddress == null) {
        throw new Error('Direccion seleccionada no fue encontrada');
    }

    const components = getAddressComponentsWithNames(selectedAddress.addressComponents, areas);
    const address = Object.fromEntries(components.map((c) => [c.key, c.value]));
    return {
        cart_id: cartId,
        delivery_method: 'DP',
        ...address,
    } as GetSchedulePayload;
};

/** Builds payload to get RT schedule */
export const getPayloadGetScheduleRT = (cartId: string, store: ExtStore): GetSchedulePayload => {
    return {
        cart_id: cartId,
        delivery_method: store.type === 'pickupPoint' ? RC : RT,
        store: store.id,
        country: getStoreComponentValue(store, 'country'),
        region: getStoreComponentValue(store, 'region'),
        region_code: getStoreComponentValue(store, 'region_code'),
        city: getStoreComponentValue(store, 'city'),
        locality_code: getStoreComponentValue(store, 'city_code'),
        district: getStoreComponentValue(store, 'district'),
        district_code: getStoreComponentValue(store, 'district_code'),
        streetName: store.address,
        streetNumber: '',
        homeNumber: store.attributes.find((a) => a.key === 'attentionPlace')?.value || '',
        nickname: store.name,
        zipCode: '',
        addressId: store.id,
    };
};
