import React from 'react';
import { createRoot } from 'react-dom/client';

import './lib/translator';
import './sass/base.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from 'ErrorBoundary';
import json from '../package.json';

import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';
import { createDataDogReporter } from 'lib/dataDog';
import { country } from 'consts';
import { isMobile } from 'store/app';
import AppTimer from 'containers/aio/AppTimerContainer';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const { version } = json;

createDataDogReporter(country, isMobile());

root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <PersistGate persistor={persistor}>
                <Helmet>
                    <meta name="version" content={`v${version} built with ♥ by Checkout team.`} />
                </Helmet>
                <App />
                <AppTimer />
            </PersistGate>
        </ErrorBoundary>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
