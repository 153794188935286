import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'lib/hooks/redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    getCreateAddressPayload,
    getSelectOptions,
    guestUserValidationSchema,
    registeredUserValidationSchema,
} from 'lib/utils/addresses';

import { country } from 'consts';
import { createAddress } from 'store/address';
import { selectAddress } from 'store/address/address.slice';

import AddressForm, { AddressFormData, SelectOptions } from 'components/aio/AddressForm';

import Button from 'components/common/Button';
import AlertBadge from 'components/common/AlertBadge';
import Card from 'components/common/Card';
import { useTranslation } from 'react-i18next';
import { RootState } from 'types/store';

export const CreateAddressContainer: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const address = useAppSelector((state: RootState) => state.address);
    const user = useAppSelector((state: RootState) => state.user);
    const areas = useAppSelector((state: RootState) => state.area);
    const [selectOptions, setSelectOptions] = useState<SelectOptions>(getSelectOptions(areas.data));

    const schema = user.data?.registered
        ? registeredUserValidationSchema
        : guestUserValidationSchema;

    const form = useForm<AddressFormData>({
        resolver: yupResolver(schema),
    });
    const [error, setError] = useState<null | string>();

    const isLoading = address.status === 'pending';

    const saveForm = async (data: AddressFormData) => {
        const payload = getCreateAddressPayload(user.data as NormalizedUser, data, areas.data);
        try {
            const result = await dispatch(createAddress(payload)).unwrap();
            const addressId = result.data.id;
            await dispatch(selectAddress(addressId)).unwrap();
            history.push('/');
        } catch (e) {
            setError('Hubo un problema registrando la dirección');
        }
    };

    const onSubmit = (event: React.FormEvent) => {
        (async (event) => {
            const handler = form.handleSubmit(saveForm);
            await handler(event);
        })(event);
    };

    const regionCode = form.watch('regionCode');
    const districtCode = form.watch('districtCode');

    useEffect(() => {
        setSelectOptions(getSelectOptions(areas.data, regionCode, districtCode));
    }, [areas.data, regionCode, districtCode]);

    return (
        <Card title={t('delivery')} link={() => {}}>
            <h3 style={{ marginTop: 30, fontWeight: 600 }}>Ingresa tu dirección</h3>

            <AddressForm
                form={form}
                selectOptions={selectOptions}
                country={country}
                onSubmit={onSubmit}
                isRegisteredUser={user.data?.registered}
                isLoading={isLoading}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
                {error && (
                    <AlertBadge theme="danger" timeout={5000} callback={() => setError('')}>
                        {error}
                    </AlertBadge>
                )}
            </div>
            <Button
                theme="primary"
                size="large"
                disabled={isLoading}
                loading={isLoading}
                onClick={onSubmit}
            >
                Continuar
            </Button>
        </Card>
    );
};
