import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'types/store';
import services from '../../services';

export const setPaymentMethod = createAsyncThunk(
    'niubiz3Ds/setPayment',
    async (_, { getState }) => {
        const { cart, billing } = getState() as RootState;
        const cartData = cart.data;
        const billingData = billing.data;

        if (cartData && billingData) {
            const payload = getPaymentPayload(cartData, billingData);
            const resp = await services.cart.setPayment(cartData.id, payload);
            const payment = resp.paymentInfo[0];

            return {
                action: payment.customFields?.action,
                amount: payment.customFields?.amount,
                channel: payment.customFields?.channel,
                merchantId: payment.customFields?.merchantId,
                expirationTime: payment.customFields?.expirationTime,
                purchaseNumber: payment.customFields?.purchaseNumber,
                merchantlogo: payment.customFields?.merchantlogo,
                formButtonColor: payment.customFields?.formButtonColor,
                sessionToken: payment.customFields?.sessionToken,
                timeoutUrl: payment.customFields?.timeoutUrl,
            };
        } else {
            throw new Error('Datos incompletos para usar Niubiz 3Ds');
        }
    },
);

/**
 * Returns the payload to create a Niubiz3Ds payment
 * @returns
 */
function getPaymentPayload(cart: Cart, billing: PartialPaymentInfo): PaymentInfo[] {
    const returnUrl = window.location.origin + '/checkout';
    const merchantLogo = window.location.origin + '/ripley-banner.png';
    const timeoutUrl = window.location.origin;
    const formButtonColor = '#6a148e';
    const price = cart.grandTotal['discount'] || cart.grandTotal['master'];

    return [
        {
            documentType: billing.documentType,
            paymentMethod: 'Niubiz3DS',
            amount: price,
            billingInfo: {
                business: billing.billingInfo.business,
                customer: billing.billingInfo.customer,
                address: billing.billingInfo.address,
            },
            customFields: {
                returnUrl: returnUrl,
                merchantLogo: merchantLogo,
                formButtonColor: formButtonColor,
                timeoutUrl: timeoutUrl,
            },
        },
    ];
}

const initialState: Niubiz3DsState = {
    status: 'idle',
    error: null,
    data: null,
};

export const slice = createSlice({
    name: 'niubiz3Ds',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPaymentMethod.pending, (state) => {
            state.status = 'pending';
            state.data = null;
            state.error = null;
        });
        builder.addCase(setPaymentMethod.rejected, (state, { error }) => {
            state.status = 'error';
            state.data = null;
            state.error = error;
        });
        builder.addCase(setPaymentMethod.fulfilled, (state, { payload }) => {
            state.status = 'ok';
            state.data = {
                action: payload.action,
                amount: payload.amount,
                channel: payload.channel,
                merchantId: payload.merchantId,
                expirationTime: payload.expirationTime,
                purchaseNumber: payload.purchaseNumber,
                merchantlogo: payload.merchantlogo,
                formButtonColor: payload.formButtonColor,
                sessionToken: payload.sessionToken,
                timeoutUrl: payload.timeoutUrl,
            };
            state.error = null;
        });
    },
});

export type Niubiz3DsData = {
    action: string;
    amount: number;
    channel: string;
    merchantId: number;
    expirationTime: number;
    purchaseNumber: number;
    merchantlogo: string;
    formButtonColor: string;
    sessionToken: string;
    timeoutUrl: string;
};

/* export type Niubiz3DsCard = {}; */

export type Niubiz3DsState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    error: SerializedError | null;
    data: Niubiz3DsData | null;
    /* card: Niubiz3DsCard | null; */
};
