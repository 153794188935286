import api from '../../api';

export default class EventsService implements EventsService {
    async getCtsRegistry(cartId: string): Promise<CtsResponse[]> {
        const response = await api.bff.getCtsRegistry(cartId);
        return response.data;
    }

    async deleteCtsRegistry(cartId: string, documentId: string): Promise<any> {
        const response = await api.bff.deleteCtsRegistry(cartId, documentId);
        return response.data;
    }
}
