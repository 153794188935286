import React, { Component, ReactNode } from 'react';
import RipleyError from 'components/common/RipleyError';
import { sendLog } from 'store/logs';
import { connect } from 'react-redux';
import { getEnvironment } from 'config';

type ErrorBoundaryState = {
    error: SerializedError | string | null;
};

class ErrorBoundary extends Component<any> {
    state: ErrorBoundaryState;

    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error: error.message };
    }

    componentDidCatch(error: Error, errorInfo: unknown): void {
        try {
            // error payload
            const payload: LogPayload = {
                level: 50,
                message: error.name,
                description: error.message,
            };

            // checks if `errorInfo` has `componentStack` data to know error origin
            const hasErrorInfo = typeof errorInfo == 'object' && errorInfo !== null;
            const hasTraces = hasErrorInfo && 'componentStack' in errorInfo;

            if (hasTraces) {
                // adds stack in the payload
                payload.meta = {
                    stack: errorInfo.componentStack,
                };
            }

            const { app } = getEnvironment();
            if (app.isActiveLogSending) {
                // send information to the bff
                this.props.sendLog(payload);
            }
        } catch {
            //
        }
    }

    render(): ReactNode {
        if (this.state.error) {
            return <RipleyError error={this.state.error} />;
        }
        const element = this.props.children;
        return element;
    }
}

const mapDispatchToProps = {
    sendLog,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
