import api from 'api';
import { normalizeAuth } from './normalizer';

class AuthService {
    async authenticate(): Promise<Auth> {
        const provider = api.bff;
        const response = await provider.authenticate();
        return normalizeAuth(response.data);
    }
}

export default AuthService;
