import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';

export type ModalProps = {
    /**
     * Callback triggered when the arrow is clicked.
     * TODO: this may be better called onClose()
     */
    onToggle?: () => void;
    /**
     * Sets if the modal is displayed or not
     */
    isOpen?: boolean;
    /**
     * Sets the modal size
     */
    size?: 'xs' | 'sm' | 'md' | 'lg';
    /**
     *  Sets the content that will be shown in the header of the modal
     */
    header?: React.ReactNode;
    /**
     * Sets the content that will be shown in the footer of the modal
     */
    footer?: React.ReactNode;
    /**
     * Sets the element where the modal will be added
     */
    root?: HTMLElement;
    /**
     * Sets the content that will be shown in the body of the modal
     */
    children?: React.ReactNode;
    /*
     * Hide the close button
     */
    hideCloseButton?: boolean;
};

/**
 * Dynamically create the element where the modal is mounted through a portal.
 * @param props
 * @returns
 */
export function Modal(props: ModalProps): JSX.Element | null {
    // Create the container where the modal will be mounted
    const [container] = useState(document.createElement('div'));

    // In case the root element isn't received, we assign the document.body
    const root = props.root ? props.root : document.body;

    useEffect(() => {
        // We add the container as soon as the component is mounted
        root.appendChild(container);

        return () => {
            // We remove the container when the component is dismounted
            root.removeChild(container);
        };
    }, [container, root]);

    return container && props.isOpen
        ? ReactDOM.createPortal(<PureModal {...props} />, container)
        : null;
}

/**
 * UI modal component
 * @param props
 * @returns
 */
export function PureModal(props: ModalProps): JSX.Element {
    return (
        <div className={styles.wrapper}>
            <div
                className={`${styles.modal} ${props.size && styles[props.size]}`}
                data-testid="modal"
            >
                {props.header && (
                    <div className={styles['modal__header']}>
                        <div>{props.header}</div>
                        {!props.hideCloseButton && (
                            <button onClick={props.onToggle} data-testid="close-button" />
                        )}
                    </div>
                )}
                <div className={styles['modal__body']}>{props.children}</div>
                {props.footer && <div className={styles['modal__footer']}>{props.footer}</div>}
            </div>
        </div>
    );
}

Modal.defaultProps = {
    size: 'xs',
};
