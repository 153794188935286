import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './TimerAlert.module.scss';
import Button from 'components/common/Button';
import { ReactComponent as ClockImage } from './clock.svg';

export type TimerAlertProps = {
    onClose?: () => void;
    isOpen?: boolean;
    countdown?: string;
};

export const TimerAlert = (props: TimerAlertProps) => {
    const [container] = useState(document.createElement('div'));
    const root = document.body;

    useEffect(() => {
        root.appendChild(container);
        return () => {
            root.removeChild(container);
        };
    }, [container, root]);

    const component = (
        <div className={styles.wrapper}>
            <div className={`${styles.modal}`} data-testid="modal">
                <div className={styles.header}>
                    <button onClick={props.onClose} data-testid="close-button" />
                </div>
                <div className={styles.body}>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <div>
                                <ClockImage />
                            </div>
                            <div>¿Sigues ahí?</div>
                            <div>
                                Para asegurar tu compra, haz click en Continuar. De lo contrario,
                                serás redirigido al home en:
                            </div>
                            <div>{props.countdown}</div>
                        </div>
                    </div>
                    <Button theme="primary" size="large" onClick={props.onClose}>
                        Continuar compra
                    </Button>
                </div>
            </div>
        </div>
    );

    return props.isOpen ? ReactDOM.createPortal(component, container) : null;
};
