import React, { FC } from 'react';
import { GiftCardResumePreviewProps } from './GiftCardResumePreview.interface';
import styles from './GiftCardResumePreview.module.scss';

export const GiftCardResumePreview: FC<GiftCardResumePreviewProps> = (
    props: GiftCardResumePreviewProps,
) => {
    const { giftCardBalance, usedAmount, differenceAmount, purchaseAmount } = props;
    return (
        <div className={styles['container']}>
            <div className={`${styles['wrapper']} ${styles['space-bottom']}`}>
                <div className={styles['left']}>Saldo Gift Card</div>
                <div className={styles['right']}>{giftCardBalance}</div>
            </div>

            {usedAmount && (
                <div className={`${styles['wrapper']} ${styles['space-bottom']}`}>
                    <div className={styles['left']}>Monto a utilizar</div>
                    <div className={`${styles['right']} ${styles['green-color']}`}>
                        {usedAmount}
                    </div>
                </div>
            )}

            {purchaseAmount && (
                <div className={`${styles['wrapper']} ${styles['space-bottom']}`}>
                    <div className={styles['left']}>Monto de tu compra</div>
                    <div className={`${styles['right']} ${styles['red-color']}`}>
                        {purchaseAmount}
                    </div>
                </div>
            )}

            {differenceAmount && (
                <>
                    <hr></hr>
                    <div className={`${styles['wrapper']} ${styles['total']}`}>
                        <div className={styles['left']}>Diferencia a pagar</div>
                        <div className={`${styles['right']} ${styles['total-number']}`}>
                            {differenceAmount}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
