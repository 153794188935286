import { BFFError } from 'api/bff/BFFConnector';
import { getAddressComponentsWithNames } from 'lib/utils/addresses';
import api from '../../api';
import { normalizeCart } from './normalizers';

/**
 * `CartService' handles the business logic of the cart domain
 */
class CartService implements CartService {
    provider = api.bff;

    /**
     * `getById' Gets a cart with the given id
     * @param cartId
     * @returns
     */
    async getById(cartId: string, light?: boolean): Promise<Cart> {
        if (cartId === '') {
            // TODO: we should provide a way to throw a BFFError outside axios.
            throw { name: 'GetCartByID', code: 'errCartNotFound' }; // HACK
        }
        const response = await this.provider.getCart(cartId, light);
        return normalizeCart(response.data);
    }

    /**
     * `doCheckout` triggers the checkout process of a cart
     * @param cartId
     * @returns
     */
    async doCheckout(cartId: string, customFields: CustomFields): Promise<null> {
        const response = await this.provider.doCheckout(cartId, customFields);

        // Se hace esta verificacion ya que el endpoint devuelve errores con status 200
        if (response.error !== null || (response.data.error && response.data.error.message)) {
            throw new BFFError('errCartCheckout', 'CartCheckout', response.data.error.message);
        }

        return response.data;
    }

    /**
     * `addCoupon` add a coupon to the coupons array in the cart
     * @param cartId
     * @param prevCoupons
     * @param coupon
     * @returns
     */
    async addCoupon(cartId: string, prevCoupons: string[], coupon: string): Promise<string[]> {
        const newCoupons = [...prevCoupons, coupon.trim()];
        const response = await this.provider.setCoupons(cartId, newCoupons);
        return response.data;
    }

    async deleteCoupon(cartId: string, prevCoupons: string[], coupon: string): Promise<string[]> {
        const newCoupons = prevCoupons.filter((prevCoupon) => prevCoupon.trim() != coupon.trim());
        const response = await this.provider.setCoupons(cartId, newCoupons);
        return response.data;
    }

    async setCustomer({ cartId, user, addresses, areas }: SetCustomerArgs): Promise<unknown> {
        if (user == null || addresses == null || areas == null) {
            throw new Error('the payload is not valid for setting up the customer');
        }
        const addressComponents = addresses?.byId[addresses.selected]?.addressComponents || [];

        const customer: ExternalCustomer = {
            id: user.id,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            nin: user.nin,
            phoneNumber: user.phoneNumber,
            createdAt: user.createdAt,
            type: user.registered ? 'Registered' : 'Guest',
            address: getAddressComponentsWithNames(addressComponents, areas),
        };

        const response = await this.provider.setCustomer(cartId, customer);
        return response.data;
    }

    async setBillingInfo(payload: SetBillingInfoPayload): Promise<HttpResponse> {
        const response = await this.provider.setBillingInfo(payload);
        return response.data;
    }

    /**
     * `setPayment' configures the payment methods in the cart
     * @param cartId
     * @param payload
     * @returns
     */
    async setPayment(cartId: string, payload: PaymentInfo[]): Promise<SetPaymentResponse> {
        // Sets a dummy payment to get promotions
        await this.provider.setPayment(cartId, payload, /*dummy*/ true, /*overwrite*/ true);

        // Updates the cart to get the grand total with the payment method configured
        const cart = await this.getById(cartId);

        // After we had configured a dummy payment method,
        // we must update the payload with the new grand total because
        // it could have been updated with some promotion
        const computedPayload = payload.map((p) => {
            const newPayment = { ...p };
            // We select the discounted price in case it does not come
            // we select the master price

            let price = cart.grandTotal['discount'] || cart.grandTotal['master'];

            if (
                newPayment.paymentMethod == 'RipleyChek' ||
                newPayment.paymentMethod == 'RipleyCard' ||
                newPayment.paymentMethod == 'Alignet'
            ) {
                price = cart.grandTotal['ripley_promo'] || price;
            }

            // Update the value if price was found otherwase
            // we return the same value
            if (price != null) {
                newPayment.amount = { ...p.amount, value: price.value };
            }
            return newPayment;
        });

        // Creates the true payment
        const setPayment = await this.provider.setPayment(
            cartId,
            computedPayload,
            /* dummy */ false,
            /* overwrite */ true,
        );

        if (setPayment.httpStatus == 200 && setPayment.data) {
            cart.paymentInfo = setPayment.data;
        }

        return {
            paymentInfo: setPayment.data,
            cart: cart,
        };
    }

    async resetPayments(cartId: string): Promise<HttpResponse<PaymentInfo[]>> {
        //sets the dummy payment to an empty array
        const response = await this.provider.setPayment(cartId, [], true, true);
        return response;
    }

    /**
     * `setPaymentOnce' configures the payment methods in the cart without doing
     * both dummy true and dummy false, neither an intermediate call to getCart.
     */
    async setPaymentOnce(
        cartId: string,
        payload: PaymentInfo[],
        isDummy = false,
        isOverwrite = true,
    ): Promise<PaymentInfo[]> {
        const response = await this.provider.setPayment(cartId, payload, isDummy, isOverwrite);
        return response.data;
    }

    async getPayment(cartId: string, paymentId: string): Promise<PaymentInfo> {
        const response = await this.provider.getPayment(cartId, paymentId);
        return response.data;
    }

    async getPaymentStatus(cartId: string, paymentId: string): Promise<HttpResponse> {
        const response = await this.provider.getPaymentStatus(cartId, paymentId);
        return response.data;
    }

    async evaluate(cartId: string, orderId: string): Promise<HttpResponse> {
        const response = await this.provider.evaluate(cartId, orderId);
        return response.data;
    }

    async deleteProducts(cartId: string, payload: SeparatedProduct[]): Promise<null> {
        if (!cartId) throw new Error('missing cart');
        const productIds = payload.map((p) => p.id);
        return await this.provider.deleteProducts(cartId, productIds);
    }
}

export default CartService;
