import React, { useState } from 'react';
import { useAppSelector } from 'lib/hooks/redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Products from 'components/common/Products';
import DeliveryAlert from 'components/aio/DeliveryAlert';

import { Product } from 'components/common/ProductWithWarranty';
import { getProducts } from 'containers/CartContainer/CartContainer.utils';
import { RootState } from 'types/store';

type SeparatedProductsContainerProps = {
    onClose: () => Promise<void>;
    onContinue: () => Promise<void>;
};

export const SeparatedProductsContainer: React.FC<SeparatedProductsContainerProps> = (
    props: SeparatedProductsContainerProps,
) => {
    const { t } = useTranslation();
    const { t: location } = useTranslation('format');
    const separatedProducts = useAppSelector((state: RootState) => state.separatedProducts);
    const separatedProductsList: Product[] = [];
    const [isContinueButtonLoading, setContinueButtonLoading] = useState(false);
    const [isCancelButtonLoading, setCancelButtonLoading] = useState(false);

    if (separatedProducts.data.length > 0) {
        separatedProducts.data.forEach((sp) => {
            const relatedProduct = sp.related ? getProducts(sp.related, location) : null;
            separatedProductsList.push({
                sku: sp.sku,
                name: sp.details.name,
                tags: sp.details.attributes.map((attr) => `${attr.name} ${attr.value}`),
                type: sp.type,
                thumbnail: sp.details.thumbnail,
                quantity: sp.quantity,
                availability: 'na',
                related: relatedProduct,
            });
        });
    }

    return (
        <Modal
            isOpen={true}
            header={t('notAvailable')}
            onToggle={() => {
                (async () => {
                    await props.onClose();
                })();
            }}
            footer={
                <>
                    <Button
                        theme="primary"
                        size="large"
                        loading={isCancelButtonLoading}
                        disabled={isCancelButtonLoading || isContinueButtonLoading}
                        onClick={() => {
                            (async () => {
                                try {
                                    setCancelButtonLoading(true);
                                    await props.onClose();
                                } catch (e) {
                                    setCancelButtonLoading(false);
                                }
                            })();
                        }}
                    >
                        {t('chooseAnotherDeliveryOption')}
                    </Button>
                    <Button
                        size="large"
                        loading={isContinueButtonLoading}
                        disabled={isContinueButtonLoading || isCancelButtonLoading}
                        onClick={() => {
                            (async () => {
                                try {
                                    setContinueButtonLoading(true);
                                    await props.onContinue();
                                } catch (e) {
                                    setContinueButtonLoading(false);
                                }
                            })();
                        }}
                    >
                        {t('continueWithOutProduct')}
                    </Button>
                </>
            }
        >
            <DeliveryAlert
                title={t('unavailableProductsTitle', { count: separatedProductsList.length })}
                message={t('unavailableProductsText')}
            >
                <Products list={separatedProductsList} />
            </DeliveryAlert>
        </Modal>
    );
};
