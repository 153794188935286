import React from 'react';
import { getCardType, getIconType } from 'lib/utils/payments';

export type NiubizCardNumberProps = { bin?: string };
export const NiubizCardNumber: React.FC<NiubizCardNumberProps> = ({
    bin,
}: NiubizCardNumberProps) => {
    if (!bin) return null;
    return <span style={{ fontWeight: 500 }}>Nº {bin.padEnd(16, '·')}</span>;
};

type PaymentMethodItemValues = {
    type: IconType;
    selected: boolean;
    additionalInfo: JSX.Element | null;
};

type PaymentMethodItemValuesArg = {
    isRipley: boolean;
    isSelected: boolean;
    isSelectedByToken: boolean;
    bin: string;
    error: SerializedError | null;
};

export const paymentMethodItemValues = ({
    isRipley,
    isSelected,
    isSelectedByToken,
    bin,
    error,
}: PaymentMethodItemValuesArg): PaymentMethodItemValues => {
    const paymentMethod = isRipley ? 'RipleyNiubiz' : 'Niubiz';
    if (isSelectedByToken) {
        const type = getIconType(paymentMethod);
        const selected = false;
        const additionalInfo = null;
        return { type, selected, additionalInfo };
    }
    const type = getIconType(paymentMethod, getCardType(bin));
    const selected = error != null || isSelected;
    const additionalInfo = <NiubizCardNumber bin={bin} />;
    return { type, selected, additionalInfo };
};

export const MUST_CREATE_NIUBIZ = 'MUST_CREATE_NIUBIZ';
export const MUST_CREATE_RIPLEY_NIUBIZ = 'MUST_CREATE_RIPLEY_NIUBIZ';
