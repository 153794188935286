import React from 'react';
import styles from './ValidationItem.module.scss';
import check from './check.svg';
import ellipse from './ellipse.svg';

export type ValidationItemProps = {
    valid: boolean;
    description: string;
};

export const ValidationItem: React.FC<ValidationItemProps> = ({
    description,
    valid,
}: ValidationItemProps) => (
    <div className={styles['container']}>
        <img src={valid ? check : ellipse} className={styles['check']} alt="Check" />
        <span className={styles[`text--${valid ? 'valid' : 'invalid'}`]}>{description}</span>
    </div>
);
