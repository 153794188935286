import React, { FC, useState } from 'react';

import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Link from 'components/common/Link';
import Spinner from 'components/common/Spinner';
import TextInput from 'components/common/TextInput';
import styles from './CreditCard.module.scss';
import { standarizeCard } from './CreditCard.functions';
import { Code, Number, ExpirationDate } from './CreditCard.components';
import { FormProps } from './CreditCard.interface';
import { getCardType, isRipleyCard, cvvLength, isRipleyCreditCard } from 'lib/utils/payments';

export type CreditCardProps = {
    /** It allow to determine the method to process the credit card  */
    method: string;

    /** It determine the classname to be computed*/
    className?: string;

    /** Disallow submit button of this form **/
    disabled?: boolean;

    /** Hides remember checkbox, and set 'remember' as false */
    disableRemember?: boolean;

    /** Direction or action for cancel link */
    onCancel?: string | (() => void);

    /** Triggers when the checkbox is toggled */
    onRemember?: (checked: boolean) => void;

    /** Triggers when PAN is modified */
    onChange?: (value: string) => void;

    /** This is used to pass the form data to container */
    onSubmit: (data: Card) => void;

    /** If true, disables main button and puts a loader on it **/
    pending?: boolean;

    /** Section to allow information or error messages */
    children?: React.ReactNode;
};

export const CreditCard: FC<CreditCardProps> = (props: CreditCardProps) => {
    const {
        children,
        className,
        disableRemember = false,
        disabled,
        onCancel,
        onChange,
        onRemember,
        onSubmit,
        pending = false,
        method,
    } = props;

    const [pan, setPan] = useState({ raw: '', value: '' });
    const [cvc, setCvc] = useState({ raw: '', value: '' });
    const [expiry, setExpiry] = useState({ raw: '', value: '' });

    const [focused, setFocused] = useState('');
    const [issuer, setIssuer] = useState('');
    const [isRipley, setIsRipley] = useState(false);
    const [isRipleyCredit, setIsRipleyCredit] = useState(false);
    const [isChecked, setChecked] = React.useState(true);
    const [showPanPassword, setShowPanPassword] = useState(false);
    const [showCvcPassword, setShowCvcPassword] = useState(false);

    let variant = issuer;
    if (isRipley && method === 'Ripley') {
        variant = issuer === 'mastercard' ? `${issuer}-ripley` : 'normal-ripley';
    }

    if (isRipley && method === 'Chek') {
        variant = `mastercard-chek`;
    }

    let calculatedClassName = `${styles['card']} ${styles['card-' + variant]}`;

    if (focused === 'cvc' && issuer !== 'amex') {
        calculatedClassName += ` ${styles['card-flipped']}`;
    }

    const handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
        setFocused((e.target as HTMLInputElement).name); // HACK
    };

    const handleCancel = onCancel ?? (() => {});

    const handleChangeRemember = (value: boolean) => {
        setChecked(value);
        onRemember?.(value);
    };

    const handleChangePan = (value: string, raw: string) => {
        setIsRipley(isRipleyCard(value));
        setIsRipleyCredit(isRipleyCreditCard(value));
        setIssuer(getCardType(value));
        setPan({ value, raw });
        onChange?.(value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data: FormProps = {
            number: pan.value,
            expiry: expiry.value,
            cvc: cvc.value,
            remember: isChecked,
        };
        onSubmit(standarizeCard(data));
    };

    return (
        <div className={styles['wrapper']}>
            <div className={styles['container'] + ' ' + className ?? ''}>
                <div className={calculatedClassName}>
                    <div className={styles['card-front']}>
                        <div className={styles['card-chip']} />
                        <div className={styles['card-issuer']} />
                        <Code cvc={cvc.raw} focused={focused} />
                        <Number
                            number={
                                showPanPassword
                                    ? pan.raw
                                    : pan.raw.replace(/./g, String.fromCharCode(8226))
                            }
                            focused={focused}
                        />
                        <ExpirationDate expiry={expiry.raw} focused={focused} />
                    </div>
                    <div className={styles['card-back']}>
                        <div className={styles['card-stripe']} />
                        <div className={styles['card-signature']} />
                        <div
                            className={`${styles['card-cvc']} ${
                                focused === 'cvc' ? styles['focused'] : ''
                            } tlPrivate`}
                        >
                            {showCvcPassword
                                ? cvc.value
                                : cvc.value.replace(/./g, String.fromCharCode(8226))}
                        </div>
                        <div className={styles['card-issuer']} />
                    </div>
                </div>
            </div>

            <form
                className={styles['containerForm']}
                onSubmit={handleSubmit}
                data-testid="formcard"
            >
                <div className={styles['containerForm_number']} data-testid="divinput">
                    <TextInput // Pan
                        className={styles['containerForm_input']}
                        label="Numero de la tarjeta"
                        placeholder="5555 5555 5555 5555"
                        maxlength={20}
                        format="#### #### #### ####"
                        name="number"
                        value={pan.raw}
                        onChangeMasked={handleChangePan}
                        onFocus={handleFocus}
                        id="numbernumber"
                        showPassword={showPanPassword}
                        setShowPassword={setShowPanPassword}
                    />
                </div>

                <div className={styles['containerForm_bottom']}>
                    <div className={styles['containerForm_bottom_expiry']}>
                        <TextInput
                            className={styles['containerForm_input']}
                            label="Mes/Año"
                            placeholder="Ej. 12/24"
                            maxlength={6}
                            format="##/##"
                            name="expiry"
                            value={expiry.raw}
                            onChangeMasked={(value, raw) => {
                                setExpiry({ value, raw });
                            }}
                            onFocus={handleFocus}
                            id="expirynumber"
                        />
                    </div>

                    <div className={styles['containerForm_bottom_cvc']}>
                        <TextInput // Cvc
                            className={styles['containerForm_input']}
                            type="text"
                            label="CVV"
                            placeholder="Ej. 256"
                            maxlength={4}
                            name="cvc"
                            format={'#'.repeat(cvvLength(pan.value))}
                            value={cvc.raw}
                            onChangeMasked={(value, raw) => {
                                setCvc({ value, raw });
                            }}
                            onFocus={handleFocus}
                            id="cvcnumber"
                            showPassword={showCvcPassword}
                            setShowPassword={setShowCvcPassword}
                        />
                    </div>
                </div>
                {children && <div>{children}</div>}
                <div className={styles['containerForm_info']}>
                    {!disableRemember && (
                        <div className={styles['containerForm_info_remember']}>
                            <label htmlFor="remembercard" className={styles['wrapper']}>
                                <Checkbox
                                    id="remembercard"
                                    checked={isChecked}
                                    onChange={handleChangeRemember}
                                />
                                Recordar Tarjeta
                            </label>
                        </div>
                    )}
                    <div className={styles['containerForm_info_continue']}>
                        <Button
                            theme="primary"
                            size="large"
                            disabled={disabled || !isRipleyCredit}
                            type="submit"
                        >
                            {pending ? (
                                <Spinner size={20} theme={disabled ? 'none' : 'secondary'} />
                            ) : (
                                'Continuar'
                            )}
                        </Button>
                    </div>
                    {onCancel && (
                        <div className={styles['containerForm_info_cancel']}>
                            <Link to={handleCancel} title="Cancelar">
                                Cancelar
                            </Link>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

export default CreditCard;
