import jsonata from 'jsonata';
import api from 'api';
import { isRipleyCredit } from 'lib/utils/payments';

export default class PaymentHubCardService implements CardService {
    async fetchByUser(deviceId: string): Promise<NormalizedCardTokens> {
        const r = await api.bff.getCards(deviceId);
        const n = normalizeBffCards(r.data);

        // Remove non-credit Ripley cards
        const badIds: string[] = [];
        n.allIds?.forEach((id) => {
            const c = n.byId[id];
            if (c.paymentMethod !== 'RipleyCard') {
                return;
            }
            if (!isRipleyCredit(c.maskedPan)) {
                badIds.push(id);
            }
        });
        badIds.forEach((id) => {
            const i = n.allIds.indexOf(id);
            if (i >= 0) {
                n.allIds.splice(i, 1);
            }
        });

        return n;
    }

    async deleteById(id: string): Promise<unknown> {
        return await window.paymenthub.deleteCard(id);
    }

    async setByToken(cardId: string, paymentId: string): Promise<unknown> {
        return await window.paymenthub.setCard(paymentId, cardId);
    }
}

export const normalizeBffCards = (card: unknown): NormalizedCardTokens => {
    // https://try.jsonata.org/tGDpxzie4
    const schema = `{
        "byId": list{
            id: {
                "id": id,
                "isRipley": isRipley,
                "maskedPan": maskedPan,
                "paymentMethod": paymentMethod,
                "type": type,
                "settings": settings
            }
        },
        "allIds": [list.id],
        "selected": []
    }`;

    return jsonata(schema).evaluate(card) as any;
};
