import React, { useEffect } from 'react';
import useWindowSize from 'lib/hooks/useWindowSize';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import Row from 'components/common/Skeleton';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useRules } from 'lib/hooks/rules';
import { TokenItem } from './TokenItem';
import { NiubizTokenItem } from './NiubizTokenItem';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { fetchCardsByUser } from 'store/cardToken/cardToken.slice';

export function TokenSelector(): JSX.Element {
    const dispatch = useAppDispatch();
    const dm = useDeliveryMethod();
    const rules = useRules();
    const app = useAppSelector((state) => state.app);
    const tokens = useAppSelector((state) => state.cardToken);
    const [width] = useWindowSize();
    const isMobile = width <= 768;

    useEffect(() => {
        if (tokens.status == 'idle' && app.data?.channel) dispatch(fetchCardsByUser());
    }, [dispatch, tokens, app.data?.channel]);

    if (tokens.status === 'pending') {
        return (
            <PaymentMethodItem
                paymentMethodName=""
                type="credito"
                widthIcon="63px"
                onClick={() => {}}
                additionalInfo={<Row width={isMobile ? 90 : 50} />}
                disabled
                blocked
            />
        );
    }

    let validTokens;

    if (app.data?.paymentMode === 'copago') {
        validTokens = tokens.data.allIds.filter(
            (tokenId) =>
                tokens.data.byId[tokenId].paymentMethod !== 'RipleyChekCard' &&
                rules.feature(tokens.data.byId[tokenId].paymentMethod),
        );
    } else {
        validTokens = tokens.data.allIds.filter((tokenId) =>
            rules.feature(tokens.data.byId[tokenId].paymentMethod),
        );
    }

    const disabled = !dm.hasSchedules();

    return (
        <>
            {validTokens.map((tokenId) => {
                const tokenObject = tokens.data.byId[tokenId];
                switch (tokenObject.paymentMethod) {
                    case 'Niubiz':
                    case 'RipleyNiubiz':
                        return (
                            <NiubizTokenItem
                                key={tokenId}
                                token={tokenObject}
                                disabled={disabled}
                            />
                        );
                    default:
                        return <TokenItem key={tokenId} token={tokenObject} disabled={disabled} />;
                }
            })}
        </>
    );
}
