import AreaService from './area/AreaService';
import CardService from './card/CardService';
import CartService from './cart/CartService';
import DeliveryMethodService from './deliveryMethod/DeliveryMethodService';
import FulfillmentService from './fulfillment/FulfillmentService';
import AuthService from './auth/AuthService';
import ScheduleService from './schedule/ScheduleService';
import RuleService from './rule/RuleService';
import StoreService from './store/StoreService';
import UserService from './user/UserService';
import EventsService from './events/EventsService';

const services: AppServices = {
    area: new AreaService(),
    auth: new AuthService(),
    card: new CardService(),
    cart: new CartService(),
    deliveryMethod: new DeliveryMethodService(),
    fulfillment: new FulfillmentService(),
    rule: new RuleService(),
    schedule: new ScheduleService(),
    store: new StoreService(),
    user: new UserService(),
    events: new EventsService(),
};

export default services;
