import { createSlice } from '@reduxjs/toolkit';
import { setOptions } from 'store/card/options';
import { NiubizCard, setCard, getOptions, setCardByToken } from 'store/card/niubiz';
import { setPaymentMethod, setPaymentByToken } from './niubiz.slice';
import { resetPayments } from './actions';

const initialState: RipleyNiubizState = {
    status: 'idle',
    error: null,
    data: null,
    card: null,
    options: {
        status: 'idle',
        error: null,
        data: { installments: [], deferred: [] },
    },
    selectedByToken: false,
};

export const slice = createSlice({
    name: 'ripleyNiubiz',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPayments.fulfilled, (state: RipleyNiubizState) => {
            state.status = 'idle';
            state.card = null;
            state.data = null;
            state.error = null;
            state.selectedByToken = false;
        });

        builder.addCase(setPaymentMethod.pending, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'pending';
                state.data = null;
                state.error = null;
            }
        });
        builder.addCase(setPaymentMethod.rejected, (state: RipleyNiubizState, { error, meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'error';
                state.data = null;
                state.error = error;
            }
        });
        builder.addCase(
            setPaymentMethod.fulfilled,
            (state: RipleyNiubizState, { payload, meta }) => {
                if (meta.arg.isRipley) {
                    state.status = 'ok';
                    const { id } = payload;
                    state.data = { id };
                    state.error = null;
                }
            },
        );

        builder.addCase(setPaymentByToken.pending, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'pending';
                state.selectedByToken = true;
            }
        });
        builder.addCase(setPaymentByToken.rejected, (state: RipleyNiubizState, { error, meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'error';
                state.error = error;
                state.selectedByToken = false;
            }
        });
        builder.addCase(
            setPaymentByToken.fulfilled,
            (state: RipleyNiubizState, { payload, meta }) => {
                if (meta.arg.isRipley) {
                    state.status = 'ok';
                    state.data = payload.cart;
                    state.selectedByToken = true;
                }
            },
        );

        builder.addCase(setCard.pending, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'pending';
            }
        });
        builder.addCase(setCard.rejected, (state: RipleyNiubizState, { error, meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'error';
                state.card = null;
                state.error = error;
            }
        });

        builder.addCase(setCard.fulfilled, (state: RipleyNiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'ok';
                state.card = payload;
                state.error = null;
            }
        });

        builder.addCase(setCardByToken.pending, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'pending';
            }
        });

        builder.addCase(setCardByToken.rejected, (state: RipleyNiubizState, { error, meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'error';
                state.card = null;
                state.error = error;
            }
        });

        builder.addCase(setCardByToken.fulfilled, (state: RipleyNiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) {
                state.status = 'ok';
                state.card = payload;
                state.error = null;
            }
        });

        builder.addCase(getOptions.pending, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.isRipley) {
                state.options.status = 'pending';
            }
        });
        builder.addCase(getOptions.rejected, (state: RipleyNiubizState, { error, meta }) => {
            if (meta.arg.isRipley) {
                state.options.status = 'error';
                state.options.error = error;
            }
        });
        builder.addCase(getOptions.fulfilled, (state: RipleyNiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) {
                state.options.status = 'ok';
                state.options.data = payload;
            }
        });

        builder.addCase(setOptions.pending, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.paymentMethod === 'RipleyNiubiz') {
                state.options.status = 'pending';
            }
        });
        builder.addCase(setOptions.fulfilled, (state: RipleyNiubizState, { meta }) => {
            if (meta.arg.paymentMethod === 'RipleyNiubiz') {
                state.options.status = 'ok';
                if (state.card) {
                    // state.card.deferred = meta.arg.deferred;
                    state.card.installments = meta.arg.installments ?? 1;
                }
            }
        });
    },
});

export type RipleyNiubizData = {
    id: string;
};

export type RipleyNiubizState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    error: SerializedError | null;
    data: RipleyNiubizData | null;
    card: RipleyNiubizCard | null;
    options: {
        status: 'idle' | 'pending' | 'ok' | 'error';
        error: SerializedError | null;
        data: CardOptions;
    };
    selectedByToken: boolean;
};

export type RipleyNiubizCard = NiubizCard;
