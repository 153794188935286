import React from 'react';
import CouponFormContainer from 'containers/CouponFormContainer';

export const CouponSection: React.FC = () => {
    return (
        <section>
            <CouponFormContainer />
        </section>
    );
};
