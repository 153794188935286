import * as React from 'react';
import styles from './Addon.module.scss';

export interface AddonProps {
    /**
     * Element that decorates this component children
     */
    element: React.ReactNode;
    /**
     * Sets where the add-on is placed
     */
    position?: 'left' | 'right';
    /**
     * Main component, decorated by and add-on
     */
    children?: React.ReactNode;
}

export const Addon: React.FC<AddonProps> = (props: AddonProps) => (
    <div className={styles['addon'] + ' ' + styles['addon--' + props.position]}>
        <div className={styles['addon-action']}>{props.children}</div>
        <div className={styles['addon-addon']}>{props.element}</div>
    </div>
);

Addon.defaultProps = {
    position: 'right',
};
