import { createContext } from 'react';

interface ErrContextInterface {
    error: string | null;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
}
export const ErrorContext = createContext<ErrContextInterface>({
    error: null,
    setError: () => {},
});
