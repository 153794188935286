import * as React from 'react';
import ProductWithWarranty from '../ProductWithWarranty';
import { Product } from '../ProductWithWarranty';
import styles from './Products.module.scss';
export type { Product } from '../ProductWithWarranty';

export type ProductsProps = {
    /**
     * List of products added to a cart
     */
    list: Product[];
    /**
     *  Identifies where the Products component is being rendered
     */
    type?: 'cart';
    /**
     * country
     */
    country?: 'cl' | 'pe';
};

export const Products: React.FC<ProductsProps> = (props: ProductsProps) => (
    <>
        <p className={styles['unitPrice']}>Precio unidad</p>
        {props.list.map((product: Product, index) => (
            <ProductWithWarranty
                product={product}
                key={`${product.sku}-${index}`}
                type={props.type}
                country={props.country}
            />
        ))}
    </>
);
