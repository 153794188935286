import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';

/**
 * Authenticates with our backend and sets the HTTP headers
 * in the Axios client with the returned bearer token and user info.
 *
 * Right after the token is fetched, a timeout is set to refresh the token
 * just before it expires, this saves us from blocking requests in the
 * meantime cause the current token will still be valid.
 *
 * If it fails we retry a defined number of times with a defined wait
 * in between. If unsuccessful, a Ripley bear error screen is displayed.
 */
export const authenticate = createAsyncThunk('auth/authenticate', async () => {
    return await services.auth.authenticate();
});

const initialState: AuthState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(authenticate.pending, (state) => {
            state.status = 'pending';
        });
        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.status = 'ok';
            if (action.payload) state.data = action.payload;
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error;
        });
    },
});

export default slice;
