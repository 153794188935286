import React, { FC } from 'react';
import styles from './PaymentMethodItem.module.scss';
import PaymentMethodIcon from 'components/payment/PaymentMethodIcon';
import iconHelp from './images/help.svg';
import Spinner from 'components/common/Spinner';
import { keyDownAsClick } from 'lib/utils/events';
import { ReactComponent as EditIcon } from './edit.svg';
import { CollapsibleSelector } from 'components/common/CollapsibleSelector/CollapsibleSelector';

export type PaymentMethodItemProps = {
    /** This field determine the name for the payment method */
    paymentMethodName: string;

    /** It shows another component if it's neccesary */
    additionalInfo?: React.ReactNode;

    /** This shows the saved amount if it exist */
    discount?: string;

    /** This will show the installments form or any other component */
    children?: React.ReactNode;

    /** It determine if the payment option is disabled */
    disabled?: React.ReactNode;

    /** This parameter will set type of the icon */
    type: IconType;

    /** It determinine if the item is selected */
    selected?: boolean;

    /** It allows to call a function that displays a modal */
    onClick: (selected: boolean) => void;

    /** Sets the size of the icon */
    widthIcon?: string;

    /** It shows or hides the spinner */
    isLoading?: boolean;

    /** It allows to show the item in blocked mode */
    blocked?: boolean;

    /** It allows to edit payments */
    onEdit?: () => void;

    /** Determine if the payment was reset */
    resetNiubizStatus?: boolean;

    /** Determine if the component have collapse with instructions */
    haveCollapse?: boolean;

    /** Determine if the instructions are visible */
    openCollapsible?: boolean;
};

export const PaymentMethodItem: FC<PaymentMethodItemProps> = (props: PaymentMethodItemProps) => {
    const {
        paymentMethodName,
        additionalInfo,
        discount,
        disabled,
        children,
        type,
        widthIcon,
        selected,
        isLoading,
        blocked,
        onEdit,
        onClick,
        resetNiubizStatus,
        haveCollapse,
        openCollapsible,
    } = props;

    let computedClassName = styles[`card_default`];
    if (selected) {
        computedClassName = children ? styles['card_enabled_with_child'] : styles['card_enabled'];
    }
    if (disabled) {
        computedClassName = styles['card_disabled'];
    }
    if (blocked) {
        computedClassName = styles['card_blocked'];
    }

    const toggle = () => {
        !disabled && !blocked && !isLoading && onClick(!selected);
    };

    const handleChildrenClick = (ev: React.MouseEvent | React.KeyboardEvent) => {
        ev.stopPropagation();
    };

    return (
        <div
            className={computedClassName}
            onClick={toggle}
            onKeyDown={keyDownAsClick(toggle)}
            role="button"
            tabIndex={0}
            data-testid="icon-item"
        >
            <div className={styles['container']}>
                <div className={styles['container__icon']}>
                    <PaymentMethodIcon disabled={!!disabled} type={type} width={widthIcon} />
                </div>
                <div className={styles['container__paymentMethodName']}>
                    <div data-testid="paymentMethodName">{paymentMethodName}</div>
                    {additionalInfo && <div data-testid="additionalInfo">{additionalInfo}</div>}
                </div>
                {haveCollapse ? (
                    <div
                        data-testid="button-collapsible"
                        onKeyDown={() => {}}
                        role="switch"
                        aria-expanded={openCollapsible}
                        aria-checked={openCollapsible}
                        tabIndex={0}
                    >
                        <div className={styles['collapse-icon']} />
                    </div>
                ) : null}
                {isLoading && (
                    <div className={styles['container__spinner']}>
                        <Spinner />
                    </div>
                )}
                {disabled && (
                    <div className={styles['tooltip']}>
                        <img src={iconHelp} alt="Ayuda" />
                        <div className={styles['bottom']}>
                            {typeof disabled !== 'boolean'
                                ? disabled
                                : 'En estos momentos, este medio de pago presenta intermitencia. Recarga e inténtalo nuevamente.'}
                            <i className={styles['question-mark-icon']}></i>
                        </div>
                    </div>
                )}
                {discount && !disabled && (
                    <div className={styles['container__discount']}>
                        <span data-testid="discount_info">{discount}</span>
                    </div>
                )}
                {selected && !isLoading && onEdit && (
                    <button
                        className={styles['container__resetPayment']}
                        onClick={onEdit}
                        onKeyDown={keyDownAsClick(onEdit)}
                        tabIndex={0}
                        disabled={resetNiubizStatus}
                    >
                        <span className={styles['container__resetPayment__text']}>Modificar</span>
                        <EditIcon title="Modificar" />
                    </button>
                )}
            </div>
            {/* The <div> has a onClick handler to prevent receiving clicks from children */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                onClick={handleChildrenClick}
                onKeyDown={handleChildrenClick}
                className={styles['container__form']}
            >
                {selected && !disabled && (
                    <div className={styles['container__form']}>{children}</div>
                )}
            </div>
            {haveCollapse ? (
                <CollapsibleSelector open={openCollapsible}>
                    Pago con Yape:
                    <ol>
                        <li style={{ marginBottom: '20px' }}>
                            Haz clic en <strong>“Pagar” </strong>y luego selecciona la opción{' '}
                            <strong>“Pago con Yape”</strong>.
                        </li>
                        <li style={{ marginBottom: '20px' }}>Ingresa tu número de celular.</li>
                        <li style={{ marginBottom: '20px' }}>
                            Busca el código de aprobación en tu app de Yape presionando{' '}
                            <strong>“Ver más”</strong> y luego{' '}
                            <strong>“Código de aprobación”.</strong> Ingresa el código y ¡listo!
                        </li>
                        <ul>
                            Recuerda que tienes 2 minutos para ingresar tu código. ¡No dejes que se
                            cancele tu compra! Aplica para compras hasta S/ 2000.
                        </ul>
                    </ol>
                </CollapsibleSelector>
            ) : null}
        </div>
    );
};

export default PaymentMethodItem;
