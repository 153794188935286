import React, { FC } from 'react';
import styles from './DateBadge.module.scss';

export type DateBadgeProps = {
    /**
     * Already formatted date string, eg: "13 de marzo".
     */
    date: string;
};

export const DateBadge: FC<DateBadgeProps> = (props: DateBadgeProps) => {
    const { date } = props;
    return <span className={styles['date-badge']}>{date}</span>;
};

export default DateBadge;
