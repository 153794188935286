import React from 'react';

import styles from './Clock.module.scss';

export type ClockProps = {
    /**
     * Set a message in the top section of the component
     */
    message?: string;
    /**
     * Specifies the date
     */
    date: DateLimit;
};

export type DateLimit = {
    minutes: string;
    hours: string;
    day: string;
    month: string;
    year: string;
};

export const Clock: React.FC<ClockProps> = ({ date, message = '' }: ClockProps) => {
    const { minutes, hours, day, month, year } = date;

    // Prepends a 0 if there is a single character

    const padedMinutes = minutes.padStart(2, '0');
    const padedHours = hours.padStart(2, '0');
    const padedDay = day.padStart(2, '0');
    const padedMoanth = month.padStart(2, '0');

    const dayMonthYear = `${padedDay}/${padedMoanth}/${year}`;
    return (
        <>
            <section className={styles['container']}>
                <div className={styles['message']}>{message}</div>
                <div className={styles['clock']}>
                    {padedHours}:{padedMinutes}
                </div>
                <div className={styles['date']}>Del {dayMonthYear}</div>
            </section>
        </>
    );
};
