import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addMilliseconds } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useRules } from 'lib/hooks/rules';
import { useHistory } from 'react-router';
import { country } from 'consts';

import { getPaymentStatus, initializePayment } from 'store/paymentMethod/ripleyChek.slice';

import Button from 'components/common/Button';
import AlertBadge from 'components/common/AlertBadge';
import ChekQR from 'components/chek/ChekQR';
import ChekPushNotification from 'components/chek/ChekPushNotification';
import ChekPhoneNumberForm, { FormData, formValidation } from 'components/chek/ChekPhoneNumberForm';
import LoadingPayment from 'components/common/LoadingPayment';
import { RootState } from 'types/store';

export const Authorize: React.FC = () => {
    const { t } = useTranslation('format');

    const history = useHistory();
    const rules = useRules();
    const [error, setError] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const app = useAppSelector((state: RootState) => state.app.data);
    const chek = useAppSelector((state: RootState) => state.paymentMethod.types.ripleyChek);
    const cart = useAppSelector((state: RootState) => state.cart.data);

    // Estos valores deben ser en milisegundos
    const interval = rules.parameter('chekStatusPollingInterval') || 1000;
    const timeLimit = rules.parameter('chekAuthorizationTimeout') || 300000; // Equivale a 5min

    const cartId = cart?.id;
    const payments = cart?.paymentInfo;
    const payment = payments?.find((p) => p.paymentMethod == 'RipleyChek');
    const paymentId = payment?.id;
    const isMobile = app?.isMobile;

    const amount = payment && t('amount', { value: payment.amount.value });
    const url = chek.data?.resource;
    const phoneNumber = chek.data?.phoneNumber;

    const createdAt = chek.data?.createdAt || new Date().toISOString();
    const countDownDate = addMilliseconds(new Date(createdAt), timeLimit);
    const countdown = useCountDown(countDownDate);

    const form = useForm<FormData>({
        defaultValues: { phoneNumber: '', phoneNumberFormatted: '' },
        resolver: yupResolver(formValidation),
    });

    useEffect(() => {
        (async () => {
            // On desktop we must initialize the payment when
            // the user arrives at the page in order to display the QR code
            if (!isMobile) {
                await dispatch(initializePayment(null)).unwrap();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // We redirect the user if the time has expired
        if (countdown?.minutes <= 0 && countdown?.seconds <= 0) {
            setTimeout(() => {
                history.push('/');
            }, 3000);
        }
    }, [countdown, history]);

    useEffect(() => {
        let id: NodeJS.Timeout | null = null;

        if (chek.data && chek.data.status == 'authorized') {
            history.push('/checkout');
            return;
        }

        if (chek.data && chek.data.status == 'voided') {
            history.push('/');
            return;
        }

        if (chek.status == 'error' && chek.error) {
            history.push('/');
            return;
        }

        if (cartId && paymentId && chek.data && chek.data.updatedAt) {
            id = setTimeout(() => {
                (async () => {
                    await dispatch(getPaymentStatus({ cartId, paymentId }));
                })();
            }, interval);
        }

        return () => {
            if (id) clearTimeout(id);
        };
    }, [dispatch, history, cartId, paymentId, chek, interval]);

    /**
     * Initialize the payment after submitting the form with the phone number
     * @param data
     */
    const initialize = async (data: FormData) => {
        try {
            setLoading(true);
            const phoneNum = data.phoneNumber;
            await dispatch(initializePayment(phoneNum)).unwrap();
        } catch (e) {
            setLoading(false);
            setError('Hubo un problema generando el cobro en Chek.');
        }
    };

    /**
     * Handle the submit event of the form if the validations are ok it call
     * initialize function
     * @param event
     */
    const handleSubmit = (event: React.FormEvent) => {
        (async (event) => {
            const handler = form.handleSubmit(initialize);
            await handler(event);
        })(event);
    };

    // On desktop if the payment is initialized, we show the QR code
    if (!isMobile && url && amount) {
        return (
            <ChekQR
                url={url}
                amount={amount}
                onClick={() => {
                    history.push('/');
                }}
            />
        );
    }

    // On mobile if the phone number is added and the payment is initilized
    // we show notification view
    if (isMobile && phoneNumber && amount) {
        return (
            <ChekPushNotification
                amount={amount}
                phoneNumber={phoneNumber}
                time={`${countdown.minutes}:${countdown.seconds}`}
                isRedirecting={countdown.minutes <= 0 && countdown.seconds <= 0}
                onClick={() => {
                    history.push('/');
                }}
            />
        );
    }

    // On mobile if the phone number is not complete we show the form
    if (isMobile && !phoneNumber) {
        return (
            <ChekPhoneNumberForm form={form} country={country} onSubmit={handleSubmit}>
                <>
                    {error && (
                        <AlertBadge theme="danger" timeout={5000} callback={() => setError(null)}>
                            {error}
                        </AlertBadge>
                    )}

                    <Button
                        size="large"
                        theme="primary"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={loading}
                    >
                        Continuar
                    </Button>
                </>
            </ChekPhoneNumberForm>
        );
    }

    return <LoadingPayment content="Espere un momento." />;
};

/**
 * Hook to create count down
 * @param countDownDate
 * @returns
 */
function useCountDown(countDownDate: Date) {
    const difference = countDownDate.getTime() - new Date().getTime();

    const [countDown, setCountDown] = useState({
        minutes: difference > 0 ? Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)) : 0,
        seconds: difference > 0 ? Math.floor((difference % (1000 * 60)) / 1000) : 0,
    });

    useEffect(() => {
        const id = setInterval(() => {
            const difference = countDownDate.getTime() - new Date().getTime();

            setCountDown({
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            });

            if (difference < 0) {
                clearInterval(id);
            }
        }, 1000);

        return () => {
            if (id) clearInterval(id);
        };
    }, [countDownDate]);

    return countDown;
}
