import React from 'react';
import { useAppSelector } from 'lib/hooks/redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Products from 'components/common/Products';
import DeliveryAlert from 'components/shipping/DeliveryAlert';

import { Product } from 'components/common/ProductWithWarranty';
import { getProducts } from 'containers/CartContainer/CartContainer.utils';
import { RootState } from 'types/store';

type SeparatedProductsContainerProps = {
    onClose: () => void;
    onContinue: () => void;
};

export const SeparatedProductsContainer: React.FC<SeparatedProductsContainerProps> = (
    props: SeparatedProductsContainerProps,
) => {
    const separatedProducts = useAppSelector((state: RootState) => state.separatedProducts);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const separatedProductsList: Product[] = [];
    const { t } = useTranslation();
    const { t: location } = useTranslation('format');

    if (separatedProducts.data.length > 0) {
        separatedProducts.data.forEach((sp) => {
            const relatedProduct = sp.related ? getProducts(sp.related, location) : null;
            // Esta condición es para solo mostrar los productos que no se han eliminado del carro
            if (cart?.products.some((p) => p.id == sp.id))
                separatedProductsList.push({
                    sku: sp.sku,
                    name: sp.details.name,
                    tags: sp.details.attributes.map((attr) => `${attr.name} ${attr.value}`),
                    type: sp.type,
                    thumbnail: sp.details.thumbnail,
                    quantity: sp.quantity,
                    availability: 'na',
                    related: relatedProduct,
                });
        });
    }

    const isLoading = separatedProducts.status === 'pending';

    return (
        <DeliveryAlert
            onClose={props.onClose}
            title={t('unavailableProductsTitle', { count: separatedProductsList.length })}
            message={t('unavailableProductsText')}
            footer={
                <>
                    <Button
                        theme="primary"
                        size="large"
                        onClick={props.onClose}
                        disabled={isLoading}
                    >
                        {t('chooseAnotherDeliveryOption')}
                    </Button>

                    <Button size="large" onClick={props.onContinue} disabled={isLoading}>
                        {t('continueWithOutProduct')}
                    </Button>
                </>
            }
        >
            <Products list={separatedProductsList} />
        </DeliveryAlert>
    );
};
