import { combineReducers } from 'redux';

import app from './app';
import address, { createAddressSlice, modifyAddressSlice, deleteAddressSlice } from './address';
import area from './area';
import auth from './auth';
import card from './card';
import cardToken from './cardToken';
import cart from './cart';
import checkout from './checkout';
import company from './company';
import coupon from './coupon';
import deliveryMethod from './deliveryMethod';
import orderSummary from './orderSummary';
import paymentMethod from './paymentMethod';
import pickup from './pickup';
import rule from './rule';
import separatedProducts from './separatedProducts';
import shipping from './shipping';
import shippingCompatibility from './shippingCompatibility';
import store from './store';
import user from './user';
import events from './events';
import billing from './billing';
import logs from './logs';

const reducer = combineReducers({
    app,
    address,
    addressCreate: createAddressSlice.reducer,
    addressDelete: deleteAddressSlice.reducer,
    addressModify: modifyAddressSlice.reducer,
    area,
    auth,
    card,
    cardToken,
    cart,
    checkout,
    company,
    coupon,
    deliveryMethod,
    orderSummary,
    paymentMethod,
    pickup,
    rule,
    separatedProducts,
    shipping,
    shippingCompatibility,
    store,
    user,
    events,
    billing,
    logs,
});

export default reducer;
