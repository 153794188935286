import React, { FC, useState } from 'react';
import { country } from 'consts';

import Button from 'components/common/Button';
import Link from 'components/common/Link';
import { ProductThumb, ProductThumbProps } from '../../common/ProductThumb/ProductThumb';
import PickupStoreStockIcon from '../PickupStoreStockIcon';

import styles from './PickupStoreStock.module.scss';
import { productAvailability, productDisplay } from './PickupStoreStock.functions';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';

export type PickupStoreStockProps = {
    storeType: string;
    storeName: string;
    storeId: string;
    storeAddress: string;
    products: Array<ProductThumbProps>;
    isModal?: boolean;
    isLoading?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    deliveryDate?: string;
    extraMessage?: React.ReactNode;
};

export const PickupStoreStock: FC<PickupStoreStockProps> = (props: PickupStoreStockProps) => {
    const {
        storeType,
        storeName,
        storeId,
        storeAddress,
        deliveryDate,
        products,
        isLoading,
        onClick,
    } = props;
    const isEmpty = products && products.length === 0;
    const [isExpanded, setIsExpanded] = useState(false);
    const productThumbsClassNameTop = isExpanded
        ? `${styles['sg__product-thumbs']} ${styles['sg__product-thumbs--expanded-top']}`
        : styles['sg__product-thumbs'];
    const productThumbsClassNameBottom = `${styles['sg__product-thumbs']} ${styles['sg__product-thumbs--expanded-bottom']}`;
    const maxThumbs = 4;
    const containerClassName =
        styles['container'] + ' ' + styles[`is-${props.isModal ? '' : 'not-'}modal`];

    return (
        <div className={containerClassName}>
            <div className={styles['store']}>
                <div className={styles['store-header']}>
                    <PickupStoreStockIcon
                        country={country}
                        storeType={storeType}
                        storeId={storeId}
                        isEmpty={isEmpty}
                    />
                    <div className={styles['store-name']}>{storeName}</div>
                </div>

                {!isEmpty ? (
                    <>
                        <div className={styles['store-address']}>{storeAddress}</div>
                        {deliveryDate && (
                            <div className={styles['delivery-date']}>{deliveryDate}</div>
                        )}
                        {props.extraMessage}
                        {productAvailability(products)}
                        <section className={productThumbsClassNameTop}>
                            {productDisplay(products, isExpanded, setIsExpanded, maxThumbs)}
                        </section>
                        {isExpanded ? (
                            <>
                                <section className={productThumbsClassNameBottom}>
                                    {products.slice(4).map((props, index) => (
                                        <ProductThumb
                                            key={index}
                                            hasRightMargin={index !== products.length - 1}
                                            className={styles['sg__product-thumb']}
                                            {...props}
                                        />
                                    ))}
                                </section>
                                <section className={styles['sg__collapse']}>
                                    <Link
                                        className={styles['sg__collapse__link']}
                                        to={() => setIsExpanded(false)}
                                        title="Mostrar menos"
                                    >
                                        <span className={styles['sg__collapse__link__text']}>
                                            Mostrar menos
                                        </span>{' '}
                                        <ArrowUp />
                                    </Link>
                                </section>
                            </>
                        ) : null}
                    </>
                ) : (
                    <div className={styles['not-available']}>No disponible</div>
                )}
            </div>
            {!isEmpty && (
                <Button
                    theme="primary"
                    onClick={onClick}
                    loading={isLoading}
                    disabled={isLoading}
                    className={styles['choose-btn']}
                >
                    Elegir
                </Button>
            )}
        </div>
    );
};
