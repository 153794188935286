import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { RootState } from 'types/store';
import { RT, SERVICE_TYPE } from 'consts';
import { separateProducts } from 'store/separatedProducts/separatedProducts.slice';

export const selectSchedule = createAction<{ groupId: string; scheduleId: string }>(
    'pickup/selectSchedule',
);

export const fetchScheduleByCartId = createAsyncThunk<Delivery, undefined, { state: RootState }>(
    'pickup/fetchByCartId',
    async (_, thunkApi) => {
        const { area, cart, user, address, store }: RootState = thunkApi.getState();

        if (!area.data || !cart.data || !user.data || !address.data || !store.data) {
            // TODO: pending to handle the error well
            throw new Error('malformed payload');
        }

        const storeId = store.data.provisional ? store.data.provisional : store.data.selected;
        const selectedStore = store.data.byId[storeId];

        if (!selectedStore) {
            throw new Error('Tienda seleccionada no fue encontrada');
        }

        return await services.schedule.getSchedule({
            cartId: cart.data.id,
            store: selectedStore,
            areas: area.data,
        });
    },
);

export const setSchedule = createAsyncThunk<Delivery, undefined, { state: RootState }>(
    'pickup/setSchedule',
    async (_, thunkApi) => {
        const { cart, pickup, store, user } = thunkApi.getState();

        if (cart.data && pickup.data && user.data && store.data) {
            const storeId = store.data.provisional ? store.data.provisional : store.data.selected;
            const selectedStore = store.data.byId[storeId];

            if (!selectedStore) {
                throw new Error('Tienda seleccionada no fue encontrada');
            }

            return await services.schedule.setSchedule({
                cartId: cart.data.id,
                deliveryMethod: RT,
                delivery: pickup.data,
                user: user.data,
                store: {
                    ...store.pickupForm,
                    store: selectedStore.id,
                    businessHours:
                        selectedStore.attributes?.find((attr) => attr.key === 'businessHours')
                            ?.value || '',
                    region_code:
                        selectedStore.location.find((store) => store.key === 'region_code')
                            ?.value || '',
                    locality_code:
                        selectedStore.location.find((store) => store.key === 'city_code')?.value ||
                        '',
                },
            });
        }
        throw new Error('Datos incompletos para retiro en tienda');
    },
);

/**
 * Selector that gets the closest pickup schedule.
 * @param state Application root state.
 */
export const getPickupSchedule = (state: RootState): Schedule | null => {
    const scheduleData = state.pickup.data;
    const selectedStore = state.store.data?.provisional
        ? state.store.data?.provisional
        : state.store.data?.selected;
    const selectedSchedules = scheduleData?.selectedSchedules;

    if (
        scheduleData &&
        selectedSchedules &&
        Object.keys(selectedSchedules || {}).length &&
        selectedStore
    ) {
        const groups = Object.entries(selectedSchedules).map(
            ([gid]) => scheduleData.groups.byId[gid],
        );
        const schedules = groups.filter((group) => {
            // Discard Site-to-Store group if there's a RT group for the same products.
            if (group.schedules[0].typeOfService === SERVICE_TYPE.STS) {
                const alternativeServices = groups.filter(
                    (g) => g.products[0].id === group.products[0].id && g.id !== group.id,
                );
                if (alternativeServices.length > 0) {
                    return false;
                }
            }
            return true;
        });

        // For multiple products with separate groups show the farthest date.
        const farthest = schedules.reduce((prev, current) => {
            if (
                new Date(prev.schedules[0].closestDate) > new Date(current.schedules[0].closestDate)
            ) {
                return prev;
            }
            return current;
        }, schedules[0]);

        const selectedSchedule = farthest.schedules[0];
        // const warehouse = selectedSchedule?.customFields.warehouse;
        // const courier = selectedSchedule?.customFields.courier;
        // const typeOfService = selectedSchedule?.typeOfService;
        //
        // Check that selected pickup schedule warehouse or courier
        // matches the selected store id.
        // NOTE: Deleted since fulfillment. Logistic now sid to us: "Trust the
        // schedules you are receiving are from this store".
        // if (
        //     selectedSchedule &&
        //     (courier === selectedStore ||
        //         warehouse === selectedStore ||
        //         typeOfService?.includes(selectedStore))
        // ) {
        //     return selectedSchedule;
        // }
        return selectedSchedule;

        // TODO: El selector getPickupSchedule puede correr varias veces mientras cambia el store
        // de redux, dónde/cuándo deberíamos hacer este check y lanzar un error cuando ocurra?
        // throw new Error(
        //     `Agenda (${selectedSchedule?.customFields.warehouse}) no corresponde a tienda seleccionada (${selectedStore})`,
        // );
        // return null;
    }
    return null;
};

/**
 * Resets the shipping information saved in the cart
 */
export const resetSchedule = createAsyncThunk<Delivery, undefined, { state: RootState }>(
    'pickup/resetSchedule',
    async (_, thunkApi) => {
        const { cart } = thunkApi.getState();

        if (cart.data?.id) {
            return await services.schedule.resetSchedule(cart.data.id);
        }

        throw new Error('Identificador del carro no encontrado');
    },
);

const initialState: ScheduleState = {
    status: 'idle',
    data: null,
    requestId: '',
    error: null,
};

export type ScheduleState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: Delivery | null;
    requestId: string | null | undefined;
    error: SerializedError | null;
};

const slice = createSlice({
    name: 'schedule',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetSchedule.pending, (state: ScheduleState) => {
            state.status = 'pending';
        });
        builder.addCase(resetSchedule.fulfilled, (state: ScheduleState) => {
            state.status = 'ok';
            state.data = null;
        });
        builder.addCase(resetSchedule.rejected, (state: ScheduleState, { error }) => {
            state.status = 'error';
            state.data = null;
            state.error = error;
        });

        builder.addCase(fetchScheduleByCartId.pending, (state: ScheduleState, action) => {
            state.status = 'pending';
            state.requestId = action.meta.requestId;
            state.data = null;
            state.error = null;
        });
        builder.addCase(fetchScheduleByCartId.fulfilled, (state: ScheduleState, action) => {
            state.status = 'ok';
            state.data = action.payload;
            state.requestId = action.meta.requestId;
            state.error = null;
        });
        builder.addCase(fetchScheduleByCartId.rejected, (state: ScheduleState, { error }) => {
            state.status = 'error';
            state.error = error;
            state.requestId = undefined;
        });

        builder.addCase(selectSchedule, (state: ScheduleState, action) => {
            const { payload } = action;
            const { groupId, scheduleId } = payload;
            if (state.data?.selectedSchedules[groupId]) {
                state.data.selectedSchedules[groupId] = scheduleId;
            }
        });

        builder.addCase(setSchedule.pending, (state: ScheduleState) => {
            state.status = 'pending';
        });
        builder.addCase(setSchedule.fulfilled, (state: ScheduleState, action) => {
            state.status = 'ok';
            state.requestId = action.meta.requestId;
        });
        builder.addCase(setSchedule.rejected, (state: ScheduleState, { error }) => {
            state.status = 'error';
            state.data = null;
            state.error = error;
        });

        builder.addCase(separateProducts.fulfilled, (state: ScheduleState) => {
            if (state.data != null && state.data.unscheduled.length > 0) {
                state.data.unscheduled = [];
            }
        });
    },
});

export default slice;
