import api from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCardType } from 'components/payment/CreditCard/CreditCard.functions';
import { getMaskedPan } from 'lib/utils/payments';
import { RipleyCardData } from 'store/paymentMethod/ripleyCard.slice';
import { RootState } from 'types/store';
import { GetOptionsPayload } from './options';
import { getEnvironment } from 'config';

export type SetCardPayload = {
    card: Card;
    paymentMethod: PaymentMethodName;
};

export const setCard = createAsyncThunk<ExternalCard, Card, { state: RootState }>(
    'card/ripleyCard/setCard',
    async (card, { dispatch, getState }) => {
        const state = getState();

        const user = state.user.data;
        if (user == null) {
            throw new Error('La tarjeta no pudo ser asignada sin datos de usuario.');
        }

        const payment = state.paymentMethod.types.ripleyCard.data;
        if (payment == null) {
            throw new Error('La tarjeta no pudo ser asignada sin datos de pago.');
        }

        const century = new Date().getFullYear().toString().slice(0, -2);
        const tokenResponse = await window.paymenthub.tokenizeCybersourceV2Card(
            {
                cardNumber: card.pan,
                expiryMonth: card.month,
                expiryYear: century + card.year,
            },
            {
                name: user.firstname,
                lastName: user.lastname,
                email: user.email,
                phoneNumber: user.phoneNumber,
            },
            payment.jwk,
            payment.id,
            card.remember,
            payment.encryptedCard.encryptedCard,
        );
        if (tokenResponse.success === false) {
            throw new Error(tokenResponse.message);
        }

        const installments = 0;
        await window.paymenthub.updateInstallments(payment.id, installments);
        const maskedPan = getMaskedPan(card);
        dispatch(getOptions({ paymentId: payment.id }));

        let paymentMethod: PaymentMethodName = 'RipleyCard';

        if (state.cart.data?.paymentInfo) {
            paymentMethod = state.cart.data?.paymentInfo[0].paymentMethod;
        }

        return {
            id: payment.id,
            installments,
            deferred: 0,
            isRipley: true,
            maskedPan,
            paymentId: payment.id,
            paymentMethod: paymentMethod,
            type: getCardType(card.pan),
        };
    },
);

export const getOptions = createAsyncThunk(
    'card/ripleyCard/getOptions',
    async ({ paymentId }: GetOptionsPayload): Promise<CardOptions> => {
        const deferred: Deferred[] = [];
        let installments: Installment[] = [];
        let r: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any

        try {
            r = await window.paymenthub.getInstallments(paymentId, null);
        } catch {
            //
        }

        try {
            // Intenta primero obtener las cuotas usando el nuevo contrato
            if (r?.response?.minInstallments && r?.response?.maxInstallments) {
                const min = r.response.minInstallments;
                const max = r.response.maxInstallments;
                installments = [];
                for (let i = min; i <= max; i++) {
                    installments.push(i);
                }
            }
        } catch {
            //
        }

        try {
            // En caso de no funcionar el nuevo contrato seguir usando el esquema anterior
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (installments.length == 0 && r?.response?.CUOTAS.CUOTA) {
                r.response.CUOTAS.CUOTA.forEach((x: any) => {
                    installments.push(x['NRO-CUOTA']);
                });
                installments.unshift(1);
            }
        } catch {
            //
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            r.response.DIFERIDOS.DIFERIDO.forEach((x: any) => {
                /* NOTE: Bancoripley está devolviendo erróneamente el campo
                 * 'FECHA-DIFERIDO' por lo que basaremos todo en
                 * 'CANTIDAD-MESES-DIFERIDO' */
                // const dateRegexp = /(?<year>\d{4})(?<month>\d{2})(?<day>\d{2})/;
                // const match = dateRegexp.exec(`${x['FECHA-DIFERIDO']}`)?.groups;
                // if (match == null) { return; }
                // const { year, month, day } = match;
                // const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
                deferred.push({ months: x['CANTIDAD-MESES-DIFERIDO'] });
            });
        } catch {
            //
        }

        // Por último en caso de no tener las cuotas se debe usar el número de cuotas por defecto
        if (installments.length == 0) {
            installments = getDefaultInstallments();
        }

        return { installments, deferred };
    },
);

export const setCardByToken = createAsyncThunk(
    'ripleyCard/setCardByToken',
    async ({ card, payment }: SetCardByTokenArg, { dispatch }): Promise<ExternalCard> => {
        const installments = 0;
        await window.paymenthub.updateInstallments(payment.id, installments);

        dispatch(getOptions({ paymentId: payment.id, maskedPan: card.maskedPan }));

        return {
            ...card,
            installments,
            deferred: 0,
            paymentId: payment.id,
        };
    },
);

export type SetCardByTokenArg = {
    card: CardToken;
    payment: RipleyCardData;
};

export const getDefaultInstallments = (): Installment[] => {
    const { payments } = getEnvironment();
    const li: Installment[] = [];
    const max = parseInt(payments.maxInstallmentsRipley);
    li.push(0);
    for (let i = 2; i <= max; i++) li.push(i);
    return li;
};

export type GetSimulationArg = {
    cartId: string;
    paymentId: string;
    installments?: string;
    deferred?: string;
};

export const getSimulation = createAsyncThunk(
    'ripleyCard/getSimulation',
    async (payload: GetSimulationArg) => {
        const cartId = payload.cartId;
        const paymentId = payload.paymentId;
        const installments = payload.installments ? payload.installments : '0';
        const deferred = payload.deferred ? payload.deferred : '0';
        const simulation = await api.bff.getSimulation({
            cartId,
            paymentId,
            installments,
            deferred,
        });
        return simulation.data;
    },
);
