import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ValidationItem from 'components/common/ValidationItem';

export type PasswordValidatorProps = {
    /**
     * Value we want to validate
     */
    password: string;
    /**
     *
     */
    register?: boolean;
    /**
     * Triggers everytime `password` changes. Telling its parent whether
     * `password` is valid or not
     */
    onChange?: (isValid: boolean) => void;
};

const specialCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

const minTREPasswordNumberOfCharacters = 12;

export const PasswordValidator: React.FC<PasswordValidatorProps> = ({
    password,
    register,
    onChange,
}: PasswordValidatorProps) => {
    const { t } = useTranslation();
    const minNumberOfCharacters = register ? 6 : minTREPasswordNumberOfCharacters;
    const notEmpty = password != '';
    const nChars = password.length >= minNumberOfCharacters;
    const oneOfCase = /[a-zñ]/.test(password) && /[A-ZÑ]/.test(password);
    const oneNumber = /\d/.test(password);
    const oneSpecial = specialCharacters.test(password);

    const all = register
        ? nChars && oneOfCase && oneNumber && notEmpty
        : nChars && oneOfCase && oneNumber && oneSpecial && notEmpty;

    useEffect(() => {
        onChange?.(!all);
    });

    return (
        <>
            <ValidationItem
                valid={nChars}
                description={t('atLeastNCharacters', {
                    count: minNumberOfCharacters,
                })}
            />
            <ValidationItem valid={oneOfCase} description={t('atLeastOneLowerAndUppercase')} />
            <ValidationItem valid={oneNumber} description={t('atLeastOneNumber')} />
            {!register && (
                <ValidationItem valid={oneSpecial} description={t('atLeastOneSpecialCharacter')} />
            )}
        </>
    );
};
