import React, { useState } from 'react';
import styles from './PickupSummary.module.scss';

import { ReactComponent as ArrowUp } from './assets/ArrowUp.svg';

import Card from 'components/common/Card';
import ProductThumb from 'components/common/ProductThumb';
import Link from 'components/common/Link';
import Button from 'components/common/Button';
import { PickupStoreForm } from 'components/pickup/PickupStore/interface';
import { AlertBadge } from 'components/common/AlertBadge/AlertBadge';
import { CL, country } from 'consts';

export type Product = {
    image: string;
    qty: number;
};

export type Deliverables = {
    date: React.ReactNode;
    products: Product[];
};

export type PickupSummaryProps = {
    title: string;
    address: string;
    deliverables: Deliverables[];
    storeName: string;
    pickupForm: PickupStoreForm;
    storeBusinessHours?: string;
    changeStore: () => void;
    changeMethod: () => void;
    changeDelivery: () => void;
};

export const PickupSummary: React.FC<PickupSummaryProps> = (props: PickupSummaryProps) => {
    const {
        storeName,
        address,
        pickupForm,

        storeBusinessHours,
        deliverables,
        title,

        changeStore,
        changeMethod,
        changeDelivery,
    } = props;

    return (
        <Card title={title}>
            <div>
                <div className={styles['link-side']}>
                    {deliverables &&
                        deliverables.map((row, index) => (
                            <div key={`product-${index}`}>
                                {row.products && <Thumbnails products={row.products} />}
                                <div className={styles['delivery-sub-title']}>{row.date}</div>
                            </div>
                        ))}
                </div>

                {storeName && <div className={styles['store-name']}>{storeName}</div>}

                {address && <div className={styles['address']}>{address}</div>}

                {storeBusinessHours && (
                    <div className={styles['business-hours']}>{storeBusinessHours}</div>
                )}

                <Link className={styles['change-store']} to={changeStore} title="cambiar tienda">
                    Cambiar tienda
                </Link>

                {pickupForm.checkbox === 'me' ? (
                    <div className={styles['store-name']}>Yo retirare mi producto</div>
                ) : (
                    <div className={styles['store-name']}>
                        {pickupForm.firstName} {pickupForm.lastName} retirará el producto
                    </div>
                )}

                <Link
                    className={styles['change-store']}
                    to={changeDelivery}
                    title="cambiar quien retirará"
                >
                    Cambiar quien retirará
                </Link>

                {country === CL && (
                    <div style={{ marginBottom: '20px' }}>
                        <AlertBadge theme="success">
                            No olvides llevar tu bolsa reutilizable cuando retires tus productos en
                            tienda.
                        </AlertBadge>
                    </div>
                )}

                <Button size="large" theme="dark" onClick={changeMethod}>
                    Quiero despacho a domicilio
                </Button>
            </div>
        </Card>
    );
};

type ThumbnailsProps = { products: Product[] };

const Thumbnails: React.FC<ThumbnailsProps> = ({ products }: ThumbnailsProps) => {
    const [isHidden, setIsHidden] = useState(true);

    let index = 1;
    if (products.length > 4) {
        const displayedProducts = products.slice(0, 4).map((row) => {
            index += 1;
            return (
                <div key={`thumbnail-product-${index}`} className={styles['thumbnail-place']}>
                    {index == 5 ? (
                        <>
                            {isHidden && (
                                <ProductThumb
                                    showMore={products.length - 4}
                                    src={row.image}
                                    onClick={() => setIsHidden(false)}
                                />
                            )}
                            {!isHidden && <ProductThumb src={row.image} />}
                        </>
                    ) : (
                        <ProductThumb quantity={row.qty} src={row.image} />
                    )}
                </div>
            );
        });

        const moreProducts = products.slice(4, products.length).map((row) => {
            index += 1;
            return (
                <div key={`thumbnail-product-${index}`} className={styles['thumbnail-place']}>
                    <ProductThumb quantity={row.qty} src={row.image} />
                </div>
            );
        });

        return (
            <div>
                {displayedProducts}
                <div>{!isHidden && moreProducts}</div>
                {!isHidden && (
                    <div>
                        <Link title="Mostrar Menos" to={() => setIsHidden(true)}>
                            Mostrar Menos <ArrowUp />
                        </Link>
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            {products.map((row, index) => (
                <div key={`${row.image}-${index}`} className={styles['thumbnail-place']}>
                    <ProductThumb quantity={row.qty} src={row.image} />
                </div>
            ))}
        </>
    );
};
