import api from 'api';

export default class implements FulfillmentService {
    async setFulfillment(
        cartId: string,
        fulfillment: boolean,
        prods: Partial<Product>[],
    ): Promise<unknown> {
        const products = prods.map((p) => ({ id: p.id ?? '', sku: p.sku ?? '' }));
        return api.bff.setFulfillment(cartId, { fulfillment, products });
    }

    async setFulfillmentProducts(cartId: string, prods: Partial<Product>[]): Promise<unknown> {
        const products = prods.map((p) => ({ id: p.id ?? '', sku: p.sku ?? '' }));
        return api.bff.setFulfillmentProducts(cartId, products);
    }
}
