import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import services from '../../services';
import { getEnvironment } from 'config';

/**
 * `getDeliveryMethods' Allows to query the available delivery methods
 */
export const getDeliveryMethods = createAsyncThunk(
    'deliveryMethod/getDeliveryMethods',
    async () => {
        // TODO: el payload se debe rellenar con datos del store
        // por ahora se deja así ya que los delivery  methods
        // se obtienen de un mock.
        const payload = { cartId: '', region: '', locality: '' };
        return await services.deliveryMethod.getDeliveryMethods(payload);
    },
);

/**
 * `changeDeliveryMethod' allows to change the selected delivery method
 */
export const changeDeliveryMethod = createAsyncThunk(
    'deliveryMethod/changeDeliveryMethod',
    async (deliveryMethod: string) => {
        return deliveryMethod;
    },
);

export const changeProvisionalMethod = createAsyncThunk(
    'deliveryMethod/changeProvisionalMethod',
    async (provisionalMethod: string | null) => {
        return provisionalMethod;
    },
);

export type DeliveryMethodState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: DeliveryMethods | null;
    selected: string | null;
    provisional: string | null;
    error: SerializedError | null;
    isOpenPickupModal: boolean;
    isOpenStoreModal: boolean;
};

const initialState: DeliveryMethodState = {
    status: 'idle',
    data: null,
    selected: null,
    provisional: null,
    error: null,
    isOpenPickupModal: false,
    isOpenStoreModal: false,
};

const slice = createSlice({
    name: 'deliveryMethod',
    initialState: initialState,
    reducers: {
        togglePickupModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenPickupModal = action.payload;
        },
        toggleStoreModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenStoreModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDeliveryMethods.pending, (state: DeliveryMethodState) => {
            state.status = 'pending';
        });
        builder.addCase(getDeliveryMethods.fulfilled, (state: DeliveryMethodState, action) => {
            state.status = 'ok';
            state.data = action.payload;

            // Workaround for the deliveryMethod changing when going back from /pickup.
            // TODO: real solution might be for `getDeliveryMethods`
            //       to not be called again in the first place.
            if (!state.selected) {
                // TODO: validar si la pre selección es mejor mantenerla aquí
                // o gatillarla desde un componente.
                const { delivery } = getEnvironment();
                const allowedMethods = delivery.methods || 'RT,DD';

                state.selected = allowedMethods.split(',').includes('DD') ? 'DD' : 'RT';
            }
        });
        builder.addCase(getDeliveryMethods.rejected, (state: DeliveryMethodState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(changeDeliveryMethod.pending, (state: DeliveryMethodState) => {
            state.status = 'pending';
        });
        builder.addCase(changeDeliveryMethod.fulfilled, (state: DeliveryMethodState, action) => {
            state.status = 'ok';
            state.selected = action.payload;
        });
        builder.addCase(changeDeliveryMethod.rejected, (state: DeliveryMethodState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(changeProvisionalMethod.fulfilled, (state: DeliveryMethodState, action) => {
            state.provisional = action.payload;
        });
    },
});

export const { togglePickupModal, toggleStoreModal } = slice.actions;

export default slice;
