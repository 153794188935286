import Payment from 'payment';
import {
    ALIGNET_STATUS_MAX,
    ALIGNET_STATUS_RETRY,
    MASKED_PAN_SEPARATOR,
    OPEX_PAYMENT_METHODS,
    URL,
} from 'consts';
import { fetchCartById } from 'store/cart';
import { resetPayments } from 'store/paymentMethod/actions';
import { isTooManyTries, retryAsync } from 'ts-retry';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'types/store';
import { getEnvironment } from 'config';

const envs = getEnvironment();

export const getCardType = (cardNumber: string): CardIssuer => {
    const issuer = Payment.fns.cardType(cardNumber);
    // The posible return values of `Payment.fns.cardType` are:
    // amex dinersclub discover elo hipercard jcb laser maestro mastercard
    // unionpay visa
    switch (issuer) {
        case 'amex':
        case 'dinersclub':
        case 'mastercard':
        case 'visa':
            return issuer;
        default:
            return 'unsupported';
    }
};

/**
 * Separates year and month from a single string into a structure.
 *     getExpiration('1231') // { month: '12', year: '31', yearFull: '2031' }
 *     getExpiration('12/31') // { month: '12', year: '31', yearFull: '2031' }
 */
export const getExpiration = (exp: string): { month: string; year: string; yearFull: string } => {
    const expiration = exp.replace(/[^\d]/g, '');
    const century = new Date().getFullYear().toString().slice(0, -2);
    const month = expiration.substr(0, 2);
    const year = expiration.substr(2, 2);
    return { month, year, yearFull: century + year };
};

/**
 * Format credit card number
 * @param {*} value
 */
export const formatCreditCardNumber = (value: string): string => {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
                10,
                15,
            )}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
                10,
                14,
            )}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
                8,
                12,
            )} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
};

/**
 * Check if it's ripley card according the bank identifier number
 * @param {*} number
 */
export const isRipleyCard = (pan: string): boolean => {
    const bin = pan.slice(0, 6);
    const binList = envs.payments.ripleyBinList;
    return binList.split(',').includes(bin);
};

/**
 * Check if it's ripley credit card according the bank identifier number
 * @param {*} number
 */
export const isRipleyCreditCard = (pan: string): boolean => {
    const bin = pan.slice(0, 6);
    const binList = envs.payments.ripleyCreditCardBinList;
    return binList.split(',').includes(bin);
};

/**
 * Checks if PAN is a Ripley credit card.
 */
export const isRipleyCredit = (pan: string): boolean => {
    // Crédito Cerrada
    if (pan.startsWith('628156')) {
        return true;
    }
    // Crédito TAM
    if (pan.startsWith('549070')) {
        return true;
    }
    // Crédito Black
    if (pan.startsWith('522788')) {
        return true;
    }
    return false;
};

/**
 * Expected length of a credit-card validation code
 * @param {string} code
 * @param {string} cardNumber
 */
export const cvvLength = (cardNumber: string): number => {
    const issuer = Payment.fns.cardType(cardNumber);
    return issuer === 'amex' ? 4 : 3;
};

/**
 * Delete non-numerical digits
 * @param {string} value
 */
export const clearNumber = (value = ''): string => {
    return value.replace(/\D+/g, '');
};

/**
 * Transforms CardIssuer into IconType
 */
function getIssuerIcon(issuer?: CardIssuer): IconType {
    if (issuer == null || issuer === 'unsupported') {
        return 'credito';
    }
    if (issuer == 'dinersclub') {
        return 'diners';
    }
    if (issuer == 'ripley-open') {
        return 'ripleymastercard';
    }
    if (issuer == 'ripley-closed') {
        return 'ripleycredito';
    }
    return issuer;
}

/**
 * Returns a IconType from a payment and an optional credit card issuer
 */
export function getIconType(pm: PaymentMethodName, issuer?: CardIssuer, pan?: string): IconType {
    switch (pm) {
        case 'Alignet':
            return 'ripleycredito';
        case 'Niubiz3DS':
            return 'yape';
        case 'InStore':
            return 'instore';
        case 'Niubiz':
            if (issuer == null || issuer == 'unsupported') return 'niubiz';
            return getIssuerIcon(issuer);
        case 'RipleyNiubiz':
        case 'RipleyCard':
            if (pan?.startsWith('522788')) return 'ripleymastercardblack';
            if (issuer === 'mastercard' || issuer === 'ripley-open') {
                return 'ripleymastercard';
            }
            return 'ripleycredito';
        case 'RipleyChekCard':
            return 'chekcredito';
        case 'RipleyChek':
            return 'ripleychek';
        case 'RipleyGiftCard':
            return 'giftcard';
        case 'WebpayCredito':
            return 'webpayplus';
        case 'WebpayDebito':
            return 'webpaydebito';
        case 'Cybersource':
        default:
            return getIssuerIcon(issuer);
    }
}

const cardNames: { [issuer: string]: string } = {
    amex: 'Tarjeta American Express',
    dinersclub: 'Tarjeta Diners Club',
    mastercard: 'Tarjeta Mastercard',
    visa: 'Tarjeta Visa',
};

export const getCardName = (issuer: string): string => {
    if (typeof cardNames[issuer] === 'undefined') {
        return 'Tarjeta de Crédito';
    }
    return cardNames[issuer];
};

const paymentMethodNames: { [k: string]: string | ((i: string, pan?: string) => string) } = {
    Alignet: 'Tarjeta Ripley Crédito',
    WebpayDebito: 'Webpay Débito',
    WebpayCredito: 'Webpay Crédito',
    Cybersource: getCardName,
    InStore: 'Pago en tienda',
    RipleyChek: 'Chek',
    RipleyGiftCard: 'Gift Card',
    RipleyCard(i: string, pan?: string) {
        if (pan?.startsWith('522788')) return 'Tarjeta Ripley Black';

        let name = 'Tarjeta Ripley';
        if (i === 'mastercard' || i === 'ripley-open') {
            name += ' Mastercard';
        }
        return name;
    },
    RipleyChekCard: 'T. Crédito Chek',
};

export function getPaymentName(pm: PaymentMethodName, issuer?: CardIssuer, pan?: string): string {
    const name = paymentMethodNames[pm];
    switch (typeof name) {
        case 'string':
            return name;
        case 'function':
            return name(issuer ?? '', pan);
        default:
            return getCardName(issuer ?? '');
    }
}

/**
 * When Alignet is aborted we must wait till the cart
 * status is no longer "pending", when status is "active"
 * the user can retry the purchase.
 *
 * If the cart is still "pending" after retrying we redirect
 * the user to Simple.
 */
type History = ReturnType<typeof useHistory>;
export async function alignetRetryOrRedirect(
    history: History,
    dispatch: AppDispatch,
): Promise<void> {
    try {
        await retryAsync(
            async () => {
                return await (
                    await dispatch(fetchCartById()).unwrap()
                ).status;
            },
            {
                delay: ALIGNET_STATUS_RETRY,
                maxTry: ALIGNET_STATUS_MAX,
                until: (status) => status === 'active',
            },
        );
    } catch (err) {
        // Cart is stuck in "pending", as we can't work
        // with this we redirect the user to Simple.
        if (isTooManyTries(err)) {
            window.location.href = URL('REACT_APP_TIENDA_URL');
            return;
        }
    }
    await dispatch(resetPayments());
    history.push('/');
}

/**
 * getMaskedPan returns a card number with 6 digits (bank identification
 * number) visible, 6 hidden digits, and the 4 last digits visible.
 */
export function getMaskedPan(card?: Card | null): string {
    if (card == null) {
        return '';
    }
    const bin = card.pan.substr(0, 6);
    const hidden = MASKED_PAN_SEPARATOR.repeat(6);
    const tail = card.pan.substr(card.pan.length - 4, card.pan.length);
    return `${bin}${hidden}${tail}`;
}

/**
 * isRipleyPayment checks if the given array contains a ripley method
 * @param pm
 * @returns
 */
export function isRipleyPayment(pm: PaymentMethodName[]): boolean {
    const ripleyMethods: PaymentMethodName[] = OPEX_PAYMENT_METHODS;
    return ripleyMethods.some((method) => pm.includes(method));
}
