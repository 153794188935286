import forge from 'node-forge';
import { Buffer } from 'buffer';

const key = setPaddedEncryptionKey();

/** Codigo adaptado de Nicolas Davila equipo Analytics - 2022-12-27 */
export async function encrypt(data: string): Promise<string> {
    const decipher = forge.cipher.createCipher('AES-ECB', key);
    decipher.start();
    decipher.update(forge.util.createBuffer(data));

    if (decipher.finish()) {
        return Promise.resolve(Buffer.from(decipher.output.data, 'binary').toString('base64'));
    }
    return Promise.reject(new Error('decipher failed'));
}

/** Codigo adaptado de Nicolas Davila equipo Analytics - 2022-12-27 */
export async function decrypt(data: string): Promise<string> {
    const key = setPaddedEncryptionKey();
    const payload = forge.util.decode64(data);
    const decipher = forge.cipher.createDecipher('AES-ECB', key);
    decipher.start();
    decipher.update(forge.util.createBuffer(payload));
    if (decipher.finish()) {
        return Promise.resolve(Buffer.from(decipher.output.data, 'binary').toString('utf8'));
    }
    return Promise.reject(new Error('decipher failed'));
}

/** Codigo adaptado de Nicolas Davila equipo Analytics - 2022-12-27 */
function setPaddedEncryptionKey() {
    return forge.pkcs5.pbkdf2(
        'ripley-cl-user_id',
        'ripley-cl-user_id',
        65536,
        32,
        forge.md.sha256.create(),
    );
}
