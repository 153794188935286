import React from 'react';
import styles from './GiftCardConfirmation.module.scss';
import GiftCardPlastic from '../GiftCardPlastic';
import GiftCardResumePreview from '../GiftCardResumePreview';
import Button from 'components/common/Button';
import Link from 'components/common/Link';
import AlertBadge from 'components/common/AlertBadge';
import Spinner from 'components/common/Spinner';

export type GiftCardConfirmationProps = {
    cardNumber: string;
    cardExpireDate: string;
    cardBalance: string;
    balancePositive: string;
    balanceNegative: string;
    diffAmount: string;
    error?: string;
    pending: boolean;
    onCopayment?: () => void;
    onCancel: () => void;
    onContinue: () => void;
};

export const GiftCardConfirmation: React.FC<GiftCardConfirmationProps> = (
    props: GiftCardConfirmationProps,
) => {
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <GiftCardPlastic number={props.cardNumber} hideNumber={true} />
            </div>
            <div className={styles.expire}>Expira el {props.cardExpireDate}</div>
            <div className={styles.values}>
                <GiftCardResumePreview
                    giftCardBalance={props.cardBalance}
                    usedAmount={props.balancePositive}
                    purchaseAmount={props.balanceNegative}
                    differenceAmount={props.diffAmount}
                />
            </div>

            {props.error && (
                <div className={styles.error}>
                    <AlertBadge theme="danger" size="large">
                        {props.error}
                    </AlertBadge>
                </div>
            )}

            {props.balanceNegative &&
                (props.onCopayment ? (
                    <div className={styles.button}>
                        <Button
                            theme="primary"
                            size="large"
                            type="submit"
                            onClick={props.onCopayment}
                            disabled={props.pending}
                        >
                            {props.pending ? <Spinner size={20} theme="none" /> : 'Elegir copago'}
                        </Button>
                    </div>
                ) : (
                    <div className={styles.warning}>
                        <AlertBadge theme="warning">
                            Esta Gift Card no puede ser utilizada ya que el monto de tu compra es
                            mayor al saldo de tu Gift Card. (Por momento)
                        </AlertBadge>
                    </div>
                ))}

            {props.balancePositive && (
                <div className={styles.button}>
                    <Button theme="primary" size="large" type="submit" onClick={props.onContinue}>
                        Continuar
                    </Button>
                </div>
            )}

            <div className={styles.link}>
                <Link to={props.onCancel} title="cancelar">
                    Cancelar
                </Link>
            </div>
        </div>
    );
};
