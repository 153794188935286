import React from 'react';
import styles from './OrderStepItem.module.scss';
import mappings from './mappings.json';
import Icon from '../OrderStepIcon';

interface Step {
    [step: number]: {
        description: string;
        icon: 'check' | 'delivery' | 'bag' | 'ticket' | 'time';
    };
}

export type OrderStepItemProps = {
    /**
     * Step number
     */
    step: number;
    /**
     * Order shipping type
     */
    shippingType: 'shipping' | 'pickup';
    /**
     * Order country
     */
    country: 'cl' | 'pe';
    /**
     * Defines if the step will be shown disabled or not
     */
    disabled?: boolean;
};

export const OrderStepItem: React.FC<OrderStepItemProps> = ({
    shippingType,
    country,
    step,
    disabled,
}: OrderStepItemProps) => {
    const steps = mappings[shippingType][country] as Step;
    const info = steps[step];
    const classNames = `${styles['step']} ${disabled ? styles['step--disabled'] : ''}`;

    if (info) {
        return (
            <div className={classNames}>
                <div className={styles['step__icon']}>
                    {<Icon name={info.icon} country={country} disabled={disabled} />}
                </div>
                <div className={styles['step__number']}>Paso {step}</div>
                <div className={styles['step__description']}>{info.description}</div>
            </div>
        );
    }

    return null;
};
