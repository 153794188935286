import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';

export interface SetPaymentMethodArgs {
    cartId: string;
    paymentInfo: PaymentInfo[];
}

export const setPaymentMethod = createAsyncThunk('inStore/setPayment', async (_, { getState }) => {
    const { cart, billing } = getState() as RootState;
    const cartData = cart.data;
    const billingData = billing.data;

    if (cartData && billingData) {
        const resp = await services.cart.setPayment(cartData.id, [
            {
                documentType: billingData.documentType,
                paymentMethod: 'InStore',
                billingInfo: {
                    business: billingData.billingInfo.business,
                    customer: billingData.billingInfo.customer,
                    address: billingData.billingInfo.address,
                },
                amount: cartData.grandTotal['discount'] || cartData.grandTotal['master'],
                customFields: {},
            },
        ]);

        return resp.cart;
    } else {
        throw new Error('Datos incompletos para usar Pago en Tienda');
    }
});

interface InStoreState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    error: SerializedError | null;
}

const initialState: InStoreState = {
    status: 'idle',
    error: null,
};

export const slice = createSlice({
    name: 'inStore',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPaymentMethod.pending, (state: InStoreState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: InStoreState) => {
            state.status = 'ok';
            state.error = null;
        });
        builder.addCase(setPaymentMethod.rejected, (state: InStoreState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
    },
});
