import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AddressForm, { AddressFormData } from 'components/aio/AddressForm';
import Modal from 'components/common/Modal';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import {
    getCreateAddressPayload,
    getSelectOptions,
    guestUserValidationSchema,
    registeredUserValidationSchema,
} from 'lib/utils/addresses';
import { modifyAddress } from 'store/address';
import AlertBadge from 'components/common/AlertBadge';
import Button from 'components/common/Button';
import { country } from 'consts';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from 'types/store';

type EditAddressProps = {
    addressId: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export const EditAddress: React.FC<EditAddressProps> = ({
    addressId,
    isOpen,
    setIsOpen,
}: EditAddressProps) => {
    const dispatch = useAppDispatch();

    const address = useAppSelector((state: RootState) => state.address);
    const user = useAppSelector((state: RootState) => state.user.data) as NormalizedUser;
    const areas = useAppSelector((state: RootState) => state.area.data);
    const [error, setError] = useState<null | string>(null);

    const isLoading = address.status === 'pending';
    const addressToEdit = address.data?.byId[addressId];

    /**
     * Previous data of the address to modify
     */

    const streetName =
        addressToEdit?.addressComponents.find((c) => c.key === 'streetName')?.value ?? '';
    const streetNumber =
        addressToEdit?.addressComponents.find((c) => c.key === 'streetNumber')?.value ?? '';
    const homeNumber =
        addressToEdit?.addressComponents.find((c) => c.key === 'homeNumber')?.value ?? '';
    const phoneNumber =
        addressToEdit?.addressComponents.find((c) => c.key === 'phoneNumber')?.value ?? '';
    const ogRegionCode =
        addressToEdit?.addressComponents.find((c) => c.key === 'region_code')?.value ?? '';
    const ogDistrictCode =
        addressToEdit?.addressComponents.find((c) => c.key === 'district_code')?.value ?? '';
    const ogLocalityCode =
        addressToEdit?.addressComponents.find((c) => c.key === 'locality_code')?.value ?? '';
    const customMessage = addressToEdit?.addressComponents.find((c) => c.key === 'customMessage')
        ?.value;
    const nickname = addressToEdit?.addressComponents.find((c) => c.key === 'nickname')?.value;

    const defaultValues: AddressFormData = {
        streetName,
        homeNumber,
        phoneNumber,
        streetNumber,
        localityCode: ogLocalityCode,
        regionCode: ogRegionCode,
        districtCode: ogDistrictCode,
        customMessage,
        nickname,
    };
    const schema = user.registered ? registeredUserValidationSchema : guestUserValidationSchema;

    const form = useForm<AddressFormData>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const [selectOptions, setSelectOptions] = useState(
        getSelectOptions(areas, ogRegionCode, ogDistrictCode),
    );

    const regionCode = form.watch('regionCode');
    const districtCode = form.watch('districtCode');

    useEffect(() => {
        setSelectOptions(getSelectOptions(areas, regionCode, districtCode));
    }, [areas, regionCode, districtCode]);

    const saveForm = async (data: AddressFormData) => {
        const payload = getCreateAddressPayload(user, data, areas, addressId);
        try {
            await dispatch(modifyAddress(payload)).unwrap();
            setIsOpen(false);
        } catch (e) {
            setError('Hubo un problema modificando la dirección');
        }
    };

    const onSubmit = (event: React.FormEvent) => {
        (async (event) => {
            const handler = form.handleSubmit(saveForm);
            await handler(event);
        })(event);
    };

    return (
        <Modal
            isOpen={isOpen}
            onToggle={() => {
                setIsOpen(false);
                form.reset();
            }}
            header="Modifica tu dirección"
            footer={
                <>
                    {error && (
                        <AlertBadge theme="danger" timeout={5000} callback={() => setError(null)}>
                            {error}
                        </AlertBadge>
                    )}
                    <Button
                        theme="primary"
                        size="large"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Guardar
                    </Button>
                </>
            }
        >
            <AddressForm
                form={form}
                selectOptions={selectOptions}
                country={country}
                onSubmit={onSubmit}
                isRegisteredUser={user?.registered}
                isLoading={isLoading}
            />
        </Modal>
    );
};
