import { MP, SERVICE_TYPE } from 'consts';
import { ScheduleDescriptionProps } from 'components/shipping/ScheduleDescription';
import { formatDate, formatWeekDay } from 'lib/utils/date';
import { ProductThumbProps } from 'components/common/ProductThumb/ProductThumb';
import { differenceInHours } from 'date-fns';
import { getShippingCost } from 'containers/CartContainer/CartContainer.utils';

/**
 * Gets the datetime removing the timezone portions of the ISO string.
 * From Transactions is just the "Z" while Logistic Engine adds the
 * time offset (eg: "-03:00")
 */
export function getShippingDates(
    closestDate = '',
    latestDate = '',
    source: 'logistic' | 'transactions' = 'logistic',
): Date[] {
    const sliceLength = source === 'logistic' ? -6 : -1;
    return [
        new Date(closestDate.slice(0, sliceLength)),
        new Date(latestDate.slice(0, sliceLength)),
    ];
}

/**
 * Handles the different cases for schedule descriptions and returns
 * the <ScheduleDescription /> props as an object.
 */
export function getScheduleDescription(
    deliveryMethod: string,
    closestDate: Date,
    latestDate: Date,
    typeOfService: string,
    typeOfServiceDescription: string,
): ScheduleDescriptionProps {
    // Imprime: Retiro Tienda Proveedor en 48 hrs hábiles
    if (
        typeOfService === SERVICE_TYPE.RET_SELLER_CL ||
        typeOfService === SERVICE_TYPE.RET_SELLER_PE
    ) {
        return {
            message: typeOfServiceDescription,
        };
    }

    // Imprime: Recíbelo el 10 de julio de 9 a 21hrs
    if (typeOfService === SERVICE_TYPE.STANDARD) {
        return {
            message: 'Recíbelo el',
            closestDate: formatDate(latestDate),
            end: 'de ' + typeOfServiceDescription,
        };
    }

    // Imprime: Recíbelo hasta el 28 de julio
    if (deliveryMethod === MP) {
        return {
            message: 'Recíbelo hasta el',
            closestDate: formatDate(latestDate),
        };
    }

    // Imprime: Recíbelo en 24hrs
    if (differenceInHours(latestDate, new Date()) <= 24) {
        return {
            message: 'Recíbelo en 24 hrs',
        };
    }

    // Imprime: Recíbelo el 10 de julio
    return {
        message: 'Recíbelo el',
        closestDate: formatDate(closestDate),
    };
}

/**
 * Returns the options according to service groups
 * @param schedules
 * @param selected
 * @returns
 */
export function getShippingOptions(schedules: Schedule[], selected: string): Schedule[] {
    const services = schedules.reduce<Array<string>>((acc, current) => {
        if (!acc.includes(current.serviceGroup)) acc.push(current.serviceGroup);
        return acc;
    }, []);

    const options: Schedule[] = services.map((svc) => {
        const filter = schedules.filter((schedule) => schedule.serviceGroup == svc);
        const selectedSchedule = filter.find((schedule) => schedule.id === selected);

        if (selectedSchedule) {
            return selectedSchedule;
        }

        return filter[0];
    });

    return options;
}

/**
 *  Returns the thumbs of the products of the given shipping group.
 **/
export function getProducts(schedule: ScheduleGroup, cart: Cart): ProductThumbProps[] {
    const cartProducts = cart.products;

    const products: ProductThumbProps[] = schedule.products.map((product) => {
        const cartProduct = cartProducts.find(
            (p) => p.id == product.id && (p.sku == product.sku || p.skuPmm == product.sku),
        );
        return {
            quantity: cartProduct?.quantity,
            src: cartProduct?.details.thumbnail || 'https://ipsumimage.appspot.com/100',
        };
    });

    return products;
}

/**
 * Returns the description of the shipping options displayed in the pop-up window.
 */
export function getShippingDescription(
    deliveryMethod: string,
    closestDate: Date,
    latestDate: Date,
    typeOfService: string,
    typeOfServiceDescription: string,
): ScheduleDescriptionProps {
    // Marketplece product with pickup
    if (
        typeOfService === SERVICE_TYPE.RET_SELLER_CL ||
        typeOfService === SERVICE_TYPE.RET_SELLER_PE
    ) {
        return { message: typeOfServiceDescription || '' };
    }

    if (typeOfService === SERVICE_TYPE.STANDARD) {
        return {
            message: formatWeekDay(closestDate),
            closestDate: formatDate(closestDate),
            end: 'de ' + typeOfServiceDescription,
        };
    }

    if (deliveryMethod === MP) {
        return {
            message: formatWeekDay(latestDate),
            closestDate: formatDate(latestDate),
        };
    }

    return {
        message: formatWeekDay(closestDate),
        closestDate: formatDate(closestDate),
    };
}

export const getIsFree = (cart: Cart) => {
    const shippingCost = getShippingCost(cart);
    const shippingDiscount = cart.discounts.filter((d) => d.target == 'ShippingCost');
    const shippingDiscountValue = shippingDiscount.length ? Number(shippingDiscount[0].value) : 0;
    const shippingCostWithDiscount = Number(shippingCost) - shippingDiscountValue;
    // If shippingCostWithDiscount is 0 then shipping is free
    const isFree = !shippingCostWithDiscount;
    return isFree;
};
