import React, { FC } from 'react';
import { GiftCardPreviewTotalProps } from './GiftCardPreviewTotal.interface';
import styles from './GiftCardPreviewTotal.module.scss';
import { ReactComponent as DeleteIcon } from './assets/DeleteIcon.svg';
import { ReactComponent as Elipsis } from './assets/Elipsis.svg';

import Link from 'components/common/Link';
import PaymentMethodIcon from '../PaymentMethodIcon';

export const GiftCardPreviewTotal: FC<GiftCardPreviewTotalProps> = (
    props: GiftCardPreviewTotalProps,
) => {
    const {
        remainderAmount,
        giftCardNumber,
        deleteEnabled,
        modifyEnabled,
        appliedAmount,
        modifyAction,
        deleteAction,
    } = props;
    const shortCard =
        giftCardNumber &&
        giftCardNumber.length >= 4 &&
        giftCardNumber.slice(giftCardNumber.length - 4);
    return (
        <div className={styles['container']}>
            <div className={styles['wrapper']}>
                <div>
                    <PaymentMethodIcon type="giftcard" width="63px" />
                </div>
                <div className={styles['center']}>
                    <div className={styles['green-color']}>{appliedAmount} Aplicado</div>
                    <div className={styles['elipsis-center']}>
                        Gift Card Nº
                        <span className={styles['space-icon']}>
                            <Elipsis />
                        </span>
                        <span className={styles['space-icon']}>
                            <Elipsis />
                        </span>
                        <span className={styles['space-icon']}>
                            <Elipsis />
                        </span>
                        <span className={styles['place-short-card']}>{shortCard}</span>
                    </div>
                    {modifyEnabled && (
                        <Link
                            to={modifyAction}
                            title={'Modificar'}
                            className={styles['link-space']}
                        >
                            Modificar
                        </Link>
                    )}
                </div>
                {deleteEnabled && (
                    <div
                        className={styles['right']}
                        onKeyDown={deleteAction}
                        role={'button'}
                        tabIndex={0}
                        onClick={deleteAction}
                    >
                        <DeleteIcon />
                    </div>
                )}
            </div>
            {remainderAmount && (
                <div className={styles['total']}>
                    Diferencia a pagar: <span> {remainderAmount} </span>
                </div>
            )}
        </div>
    );
};
