/**
 * Documentacion Checkout:
 *  https://ripleycl.atlassian.net/wiki/spaces/CHO/pages/2688418012/Google+Analytics+4
 */
import { getEnvironment } from 'config';
import {
    OPEX_PAYMENT_METHODS,
    SELLER_RIPLEY,
    country,
    currency,
    vatPercentage,
    UTM_ENABLE,
} from 'consts';
import { getShippingCost } from 'containers/CartContainer/CartContainer.utils';
import { getAddressComponentValue } from 'lib/utils/addresses';
import { encrypt } from 'lib/utils/crypto';
import { AddressState } from 'store/address/address.slice';
import { DeliveryMethodState } from 'store/deliveryMethod/deliveryMethod.slice';
import { sendLog } from 'store/logs';
import { PaymentMethodState } from 'store/paymentMethod/paymentMethod.slice';

export type AnalyticsEventType =
    | 'begin_checkout'
    | 'add_shipping_info'
    | 'add_payment_info'
    | 'purchase'
    | 'checkout_error';

export type AnalyticsErrorType =
    | 'bank_problem'
    | 'card_block'
    | 'change_price'
    | 'checkout_timeout'
    | 'errorCreditInput'
    | 'factura'
    | 'giftcard_guest'
    | 'internalProblem'
    | 'noAgenda'
    | 'noProducts'
    | 'noStore'
    | 'outStockProducts'
    | 'restrictedBin'
    | 'stock_errors'
    | 'tarjetasincupo'
    | 'TREproblem'
    | 'TREretries'
    | 'validationRut'
    | 'validationTRE';

/** Returns encrypted user properties as needed by GA4 */
export const encryptUserProperties = async (up: UserProperties): Promise<UserProperties> => {
    up.email = await encrypt(up.email);
    up.login_id = await encrypt(up.login_id);
    up.streetName = await encrypt(up.streetName);
    up.streetNumber = await encrypt(up.streetNumber);
    return up;
};

export function getItems(cart: Cart, isOPEX: boolean, extraFields: ItemKey[] = []): Item[] {
    const items: Item[] =
        cart?.products.map((p) => {
            let paidPrice = p.prices.discount ? p.prices.discount.value : p.prices.master.value;
            if (isOPEX && p.prices.ripley_promo) {
                paidPrice = p.prices.ripley_promo.value;
            }
            const item: Item = {
                item_name: p.details.name,
                item_brand: p.details.brand,
                item_category: p.details.department,
                item_category4: p.details.sellerId === SELLER_RIPLEY ? 'RIPLEY' : 'MARKETPLACE',
                item_id: p.sku, // TODO: no tenemos acceso al SKU padre
                item_variant: p.sku,
                price: Math.floor(parseInt(paidPrice)),
                quantity: p.quantity,
                is_gift: p.isGift ? 'true' : 'false',
                seller: p.details.seller,
                sellerId: p.details.sellerId,
                affiliation: p.details.sellerId,
            };
            if (extraFields.length && extraFields.includes('item_revenue')) {
                item['item_revenue'] = parseInt(p.prices.ripley_promo?.value) || null; // Precio OPEX
            }
            return item;
        }) ?? [];
    return items;
}

export function addDataCtsToItems(items: Item[], ctsData: CtsResponse[]): Item[] {
    if (ctsData.length === 0) {
        return items;
    }

    const itemsCts = ctsData
        .map((cts: CtsResponse) => {
            return cts.cts_ripley_user_journey.events.items.map((item: ItemCts) => {
                return item;
            });
        })
        .flat();

    const itemsWithCts: Item[] = [];

    items.forEach((item: Item) => {
        const itemInCts = itemsCts.find(
            (itemCts) =>
                itemCts.item_id === item.item_id || itemCts.item_variant === item.item_variant,
        );

        if (itemInCts) {
            itemsWithCts.push({
                ...item,
                item_id: itemInCts.item_id,
                item_list_name: itemInCts.item_list_name,
                location_id: itemInCts.location_id,
                item_category2: itemInCts.item_category2,
                item_category5: itemInCts.item_category5,
                item_list_id: itemInCts.item_list_id,
                creative_name: itemInCts.creative_name,
                promotion_name: itemInCts.promotion_name,
                promotion_id: itemInCts.promotion_id,
                creative_slot: itemInCts.creative_slot || '',
            });
        } else {
            itemsWithCts.push(item);
        }
    });

    return itemsWithCts;
}

export async function getShippingProperties(
    address: AddressState,
    cart: Cart,
    deliveryMethod: string,
): Promise<ShippingProperties> {
    // TODO: unificar logica de formateo con AddressContainer y getUserProperties
    const userAddress = address.data?.selected ? address.data?.byId[address.data?.selected] : null;
    const streetName = userAddress ? getAddressComponentValue(userAddress, 'streetName') : '';
    const streetNumber = userAddress ? getAddressComponentValue(userAddress, 'streetNumber') : '';
    const homeNumber = userAddress ? getAddressComponentValue(userAddress, 'homeNumber') : '';
    const shippingAddress = await encrypt(
        `${streetName} ${streetNumber}${homeNumber ? `, ${homeNumber}` : ''}`,
    );

    const shippingProperties: ShippingProperties = {
        city: userAddress ? getAddressComponentValue(userAddress, 'locality_code') : '',
        region: userAddress ? getAddressComponentValue(userAddress, 'region_code') : '',
        shipping: cart ? getShippingCost(cart) ?? 0 : -1,
        shippingAddress: shippingAddress,
        shippingType: deliveryMethod,
        totalShipments: cart?.shippingInfo?.length ?? -1,
    };
    return shippingProperties;
}

export async function getUserProperties(
    address: AddressState,
    user: NormalizedUser,
): Promise<UserProperties> {
    const userAddress = address.data?.selected ? address.data?.byId[address.data?.selected] : null;

    const userProperties: UserProperties = {
        city: userAddress ? getAddressComponentValue(userAddress, 'locality_code') : '',
        cookieId: user?.id ?? '',
        country: country,
        email: user?.email ?? '',
        login_id: user?.nin ?? '',
        region: userAddress ? getAddressComponentValue(userAddress, 'region_code') : '',
        streetName: userAddress ? getAddressComponentValue(userAddress, 'streetName') : '',
        streetNumber: userAddress ? getAddressComponentValue(userAddress, 'streetNumber') : '',
        user_id: user?.uid ?? '',
        id_del_usuario: user?.id ?? '',
        uid_del_usuario: user.uid ?? '',
        user_type: user?.registered ? 'registered' : 'guest',
    };

    const encrypted = await encryptUserProperties(userProperties);
    return encrypted;
}

export function getUtmProperties(): UTMProperties {
    const source = localStorage.getItem('source') ?? '';
    const campaign = localStorage.getItem('campaign') ?? '';
    const medium = localStorage.getItem('medium') ?? '';

    return {
        source,
        campaign,
        medium,
    };
}

export function getPaymentWithoutCvvEnabled(
    cvvEnabled: boolean,
    paymentMethods: PaymentMethodName[],
): boolean {
    if (paymentMethods.length === 1) {
        return cvvEnabled && paymentMethods[0] === 'RipleyCard';
    }

    return false;
}

export async function getParams(
    address: AddressState,
    cart: Cart,
    deliveryMethod: DeliveryMethodState,
    user: NormalizedUser,
    paymentMethodData: PaymentMethodState,
    cvvEnabled: boolean,
    ctsData: CtsResponse[],
    eventName: AnalyticsEventType,
    eventError?: AnalyticsErrorType,
): Promise<AnalyticsEvent> {
    const { analytics } = getEnvironment();
    let areOpexPricesApplied =
        paymentMethodData.selected.some((pm: PaymentMethodName) =>
            OPEX_PAYMENT_METHODS.includes(pm),
        ) && cart.grandTotal.ripley_promo?.value
            ? true
            : false;

    // If for some reason the redux state isn't available, get this from the cart.
    if (paymentMethodData.selected.length === 0) {
        areOpexPricesApplied =
            cart.paymentInfo?.some((pi: PaymentInfo) =>
                OPEX_PAYMENT_METHODS.includes(pi.paymentMethod),
            ) && cart.grandTotal.ripley_promo?.value
                ? true
                : false;
    }

    const total = areOpexPricesApplied
        ? parseInt(cart.grandTotal.ripley_promo.value)
        : parseInt(cart.grandTotal.discount.value ?? '-1');

    const userProperties = await getUserProperties(address, user);
    let items =
        eventName === 'purchase'
            ? getItems(cart, areOpexPricesApplied, ['item_revenue'])
            : getItems(cart, areOpexPricesApplied);

    if (analytics.ctsEnabled) {
        items = addDataCtsToItems(items, ctsData);
    }

    // TODO: unificar logica todo-marketplace con DeliveryMethodContainer, mover a utils
    const allMarketplace = cart.products.every((p) => p.details.sellerId != SELLER_RIPLEY);
    const allRipley = cart.products.every((p) => p.details.sellerId === SELLER_RIPLEY);
    const type = allMarketplace ? 'Marketplace' : allRipley ? 'Ripley' : 'Mixto';

    let params: AnalyticsEvent = {
        action: 'Screenview',
        channel: cart.channel || '',
        coupon: cart.coupons.join(',') || '',
        currency: currency,
        destination_path: '',
        discount: cart.discounts.reduce((sum, discount) => sum + parseInt(discount.value), 0) || 0,
        pageType: 'Checkout',
        path: window.location.pathname,
        items: items,
        platform_used: cart.channel || '',
        priceType: areOpexPricesApplied && cart.grandTotal.ripley_promo ? 'OPEX' : 'Internet',
        status: eventError ? 'Error' : 'Ok',
        time: new Date().toISOString(),
        type: type,
        userId: user?.uid ?? '',
        userLoginType: user?.registered ? 'registered' : 'guest',
        user_properties: userProperties,
        value: total,
        invoiceType: paymentMethodData.docType,
        paymentType: paymentMethodData.selected.join(','),
        productsTotalCount: cart.products.reduce((sum, product) => product.quantity + sum, 0) || 0,
        tax:
            paymentMethodData.docType === 'factura'
                ? 0
                : Math.floor(total * vatPercentage[country]),
        cart_id: cart.id,
        transaction_id: cart.orderId !== '' ? cart.orderId : '',
        label: cart.type,
    };

    const shippingProperties = await getShippingProperties(
        address,
        cart,
        deliveryMethod.selected ?? '',
    );

    const utmData = getUtmProperties();
    const isWithoutCvv = getPaymentWithoutCvvEnabled(cvvEnabled, paymentMethodData.selected)
        ? 'Si'
        : 'No';

    switch (eventName) {
        case 'begin_checkout':
            params = { ...params };
            break;
        case 'add_shipping_info':
        case 'add_payment_info':
            params = { ...params, ...shippingProperties };
            break;
        case 'purchase':
            params =
                UTM_ENABLE && utmData
                    ? {
                          ...params,
                          ...shippingProperties,
                          ...utmData,
                          cvvEnable: isWithoutCvv,
                      }
                    : {
                          ...params,
                          ...shippingProperties,
                          cvvEnable: isWithoutCvv,
                      };
            validatePurchaseParams(params as AnalyticsEvent & ShippingProperties, cart.id, user.id);
            break;
        case 'checkout_error':
            params = {
                ...params,
                ...shippingProperties,
                label: eventError,
            };
            break;
        default:
            throw new Error('eventName invalido');
    }
    return params;
}

const validatePurchaseParams = (
    params: AnalyticsEvent & ShippingProperties,
    cartId: string,
    userId: string,
) => {
    const { app } = getEnvironment();
    const {
        transaction_id,
        value,
        cart_id,
        shippingType,
        productsTotalCount,
        channel,
        platform_used,
        paymentType,
        city,
        region,
        totalShipments,
        userLoginType,
        items,
    } = params;

    const paramsToValidate = {
        transaction_id,
        value,
        cart_id,
        shippingType,
        productsTotalCount,
        channel,
        platform_used,
        paymentType,
        city,
        region,
        totalShipments,
        userLoginType,
        items,
    };

    if (app.isActiveLogSending) {
        Object.entries(paramsToValidate).forEach(([key, value]) => {
            if (
                typeof value === 'string' ||
                typeof value === 'number' ||
                value === null ||
                value === undefined
            ) {
                validateParamAndLog(value, `${key} no found. cart.id:${cartId} user.id:${userId}`);
            }

            // Validate items array
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    Object.entries(item).forEach(([key, value]) => {
                        validateParamAndLog(
                            value,
                            `item.${key} no found. cart.id:${cartId} user.id:${userId} SKU:${item.item_variant} name:${item.item_name}`,
                        );
                    });
                });
            }
        });
    }
};

const validateParamAndLog = (
    param: string | number | null | boolean | undefined,
    description: string,
) => {
    const payload: LogPayload = {
        level: 40,
        message: 'errAnalyticsParams',
        description: description,
    };
    if (typeof param === 'string' && param === '') {
        sendLog(payload);
        return;
    }
    if (typeof param === 'number' && param === 0) {
        sendLog(payload);
        return;
    }
    if (typeof param === 'undefined') {
        sendLog(payload);
        return;
    }
    if (param === null) {
        sendLog(payload);
    }
};

/** Campo `action` */
export type ActionType =
    | 'Screenview'
    | 'Click Element'
    | 'Click Banner'
    | 'Click Slider'
    | 'Click Promo'
    | 'View Promotion'
    | 'Select Promotion'
    | 'User click';

/**
 * Campos del objeto evento de Google Analytics 4.
 */
export type AnalyticsEvent = {
    time: string; // formato ISO? "2022-10-26T21:59:31.583Z"
    user_properties: UserProperties;
    items: Item[];
    action: ActionType;
    channel: string;
    coupon: string;
    currency: string;
    /** URL de destino del evento. */
    destination_path: string;
    discount: number;
    /** Usado para el tipo de error en los eventos 'checkout_error' */
    label?: string;
    /**
     *  Tipo de página desde donde se levanta evento:
     *      'Home' | 'Catálogo' | 'Búsqueda' | 'Producto' | 'Bolsa' | 'Checkout'
     *  En nuestro caso siempre será 'Checkout'.
     */
    pageType: 'Checkout';
    /** URL donde se levanta el evento */
    path: string;
    /** APP, Internet, Kiosko, APP Tienda, otro */
    platform_used: string;
    /** Indica si el evento es de exito o error */
    status: 'Ok' | 'Error';
    /** Tipo de carro comprado ( Mixto, Mercado o Ripley ) */
    type: string;
    /** Igual al `user_id` de `user_properties` */
    userId: string;
    /** Logueado o invitado */
    userLoginType?: string;
    /** Monto total de la compra */
    value: number;
    /** Tipo de precio aplicado: Internet / OPEX */
    priceType: string;
    /** Identificador del carro */
    cart_id: string;
    /** Identificador de la compra ( OC ) */
    transaction_id: string;
    /** Monto de impuestos */
    tax: number;
    /** Tipo de pago (Webpay, Credito, Chek, Niubiz, etc) */
    paymentType: string;
    /** Cantidad total de productos comprados */
    productsTotalCount: number;
    /** Boleta o factura */
    invoiceType: string;
    /** acoustic utm source */
    source?: string | null;
    /** acoustic utm campaign */
    campaign?: string | null;
    /** acoustic utm medium */
    medium?: string | null;
    /** Indica si la compra fue con o sin cvv */
    cvvEnable?: string;
};

/**
 * Campos relacionados al despacho.
 */
export type ShippingProperties = {
    city: string;
    region: string;
    /** Monto total del despacho */
    shipping: number;
    /** Direccion despacho */
    shippingAddress: string;
    /** Tipo de despacho */
    shippingType: string;
    /** Cantidad de envios */
    totalShipments: number;
};

/**
 * Campos del objeto `user_properties`
 */
export type UserProperties = {
    /** Tipo de usuario. Encriptado. */
    user_type: string;
    /** Pais. Encriptado. */
    country: string;
    /** Region. Encriptado. */
    region: string;
    /** Ciudad. Encriptado. */
    city: string;
    /** Nombre de la calle. Encriptado. */
    streetName: string;
    /** Numero de la calle. Encriptado. */
    streetNumber: string;
    /** Cookie del usuario. */
    cookieId: string;
    /** RIPLEY_CUSTOMER_ID */
    id_del_usuario: string;
    user_id: string;
    uid_del_usuario: string;
    /** Email. Encriptado. */
    email: string;
    /** RUT/DNI. Encriptado */
    login_id: string;
};

/**
 * Campos del objeto `items`
 * Doc Confluence: https://ripleycl.atlassian.net/wiki/spaces/ED/pages/2668691629/Variables+GA4
 */
export type Item = {
    /** Nombre del producto */
    item_name: string;
    /** Marca del producto */
    item_brand: string;
    /** product[i].department */
    item_category: string;
    /** productType: RIPLEY / MARKETPLACE */
    item_category4: string;
    /** SKU del producto */
    item_id: string;
    /** SKU de variante */
    item_variant: string;
    /** Precio Master */
    price: number;
    /** Cantidad del producto en el carro. */
    quantity: number;
    /** Precio OPEX */
    item_revenue?: number | null;
    /** data cts */
    item_list_name?: string | null;
    location_id?: string | null;
    item_category2?: string | null;
    item_category5?: string | null;
    item_list_id?: string | null;
    creative_name?: string | null;
    promotion_name?: string | null;
    promotion_id?: string | null;
    creative_slot?: string | null;
    is_gift: string | null;
    seller: string;
    sellerId: string;
    affiliation: string;
};

export type ItemKey = keyof Item;
