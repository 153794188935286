export const copyElement = (node: Element | null): void => {
    if (node == null) return;

    const text = node.textContent;
    if (text && window.navigator?.clipboard?.writeText) {
        window.navigator.clipboard.writeText(text);
        return;
    }

    const range = new Range();
    range.setStart(node, 0);
    range.setEnd(node, node.childNodes.length);

    const selection = document.getSelection();
    if (selection == null) return;

    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand?.('copy');
    selection.removeAllRanges();
};
