import React from 'react';
import styles from './Cart.module.scss';
import Row from '../Skeleton/Skeleton';

import Collapsible from '../Collapsible';
import Button from '../Button';
import ProductThumb from '../ProductThumb';
import useWindowSize from 'lib/hooks/useWindowSize';

export function Skeleton(): JSX.Element {
    const [width] = useWindowSize();

    if (width <= 768) {
        return <div></div>;
    }

    return (
        <div className={styles['open']}>
            <div className={styles['content']}>
                <Collapsible
                    header={
                        <div className={styles['header']}>
                            <Row width={30} />
                        </div>
                    }
                    footer={
                        <div className={styles['desktop-detail']}>
                            <div className={styles['skeleton-row']}>
                                <Row width={40} />
                            </div>

                            <div className={styles['skeleton-row']}>
                                <Row width={40} />
                            </div>

                            <hr className={styles['skeleton-separator']}></hr>

                            <div className={styles['skeleton-row']}>
                                <Row width={50} />
                            </div>

                            <Button size="large" theme="primary" onClick={() => {}} disabled={true}>
                                Pagar
                            </Button>
                        </div>
                    }
                    open={true}
                >
                    <div className={styles['skeleton-product']}>
                        <ProductThumb src={''} />
                    </div>
                </Collapsible>
            </div>
        </div>
    );
}
