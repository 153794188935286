import services from 'services';
import Cookies from 'js-cookie';
import { country } from 'consts';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'types/store';
import { setSchedule } from 'store/shipping';
import { resetSchedule } from 'store/pickup';

import { setPaymentMethod as setPaymentChek } from '../paymentMethod/ripleyChek.slice';
import { setPaymentMethod as setPaymentCybersource } from '../paymentMethod/cybersource.slice';
import { setPaymentMethod as setPaymentInStore } from '../paymentMethod/inStore.slice';
import {
    setPaymentMethod as setPaymentNiubiz,
    setPaymentByToken as setTokenNiubiz,
} from '../paymentMethod/niubiz.slice';
import { setPaymentMethod as setPaymentWebpayCredito } from '../paymentMethod/webpayCredito.slice';
import { setPaymentMethod as setPaymentWebpayDebito } from '../paymentMethod/webpayDebito.slice';
import { setPaymentMethod as setPaymentAlignet } from '../paymentMethod/alignet.slice';
import { getEnvironment } from 'config';

export const fetchCartById = createAsyncThunk('cart/fetchById', async () => {
    const cartId = getCartId();
    const response = await services.cart.getById(cartId);
    return response;
});

export const fetchLightCartById = createAsyncThunk('cart/fetchLightById', async () => {
    const cartId = getCartId();
    const response = await services.cart.getById(cartId, true);
    return response;
});

export const setCustomer = createAsyncThunk('cart/setCustomer', async (arg, { getState }) => {
    const state = getState() as RootState;

    return await services.cart.setCustomer({
        cartId: state.cart.data?.id ?? '',
        user: state.user.data,
        addresses: state.address.data,
        areas: state.area.data,
    });
});

export const setBillingInfo = createAsyncThunk('cart/billingInfo', async (arg, { getState }) => {
    const state = getState() as RootState;

    const { billing, cart } = state;

    if (billing.data && cart.data) {
        return await services.cart.setBillingInfo({
            cartId: cart.data.id,
            paymentInfo: billing.data,
        });
    } else {
        new Error('can not set billing information due to incomplete data');
    }
});

export const setFingerprint = createAsyncThunk('cart/setFingerprint', async (arg, { getState }) => {
    const state = getState() as RootState;
    const cartId = state.cart.data?.id;
    window?.paymenthub?.setCYBFingerprint(); // to fix get fingerprint error on alignet flow, only dev env (by wallet's SDK)
    const fingerprint = await window.paymenthub.getFingerprintSessionId();

    if (fingerprint && cartId) {
        const resp = await window.paymenthub.updateDeviceInformation(
            country.toLowerCase(),
            { fingerprintSessionId: fingerprint },
            cartId,
        );

        if (resp.error && resp.error.message) {
            throw new Error('an error has occurred while configuring the fingerprint.');
        }
    } else {
        throw new Error('can not set fingerprint due to incomplete data');
    }

    return fingerprint;
});

export type CartState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: Cart | null;
    error: SerializedError | null;
};

const initialState: CartState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCartById.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchCartById.fulfilled, (state: CartState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(fetchCartById.rejected, (state: CartState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(fetchLightCartById.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchLightCartById.fulfilled, (state: CartState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(fetchLightCartById.rejected, (state: CartState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(setCustomer.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setCustomer.fulfilled, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(setCustomer.rejected, (state: CartState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(setBillingInfo.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setBillingInfo.fulfilled, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(setBillingInfo.rejected, (state: CartState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(setFingerprint.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setFingerprint.fulfilled, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(setFingerprint.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(setSchedule.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setSchedule.fulfilled, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(setSchedule.rejected, (state: CartState) => {
            state.status = 'error';
        });

        builder.addCase(resetSchedule.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(resetSchedule.fulfilled, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(resetSchedule.rejected, (state: CartState) => {
            state.status = 'error';
        });

        builder.addCase(setPaymentWebpayDebito.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentWebpayDebito.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload.cart;
        });
        builder.addCase(setPaymentWebpayDebito.rejected, (state: CartState) => {
            state.status = 'ok';
        });

        builder.addCase(setPaymentWebpayCredito.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentWebpayCredito.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload.cart;
        });
        builder.addCase(setPaymentWebpayCredito.rejected, (state: CartState) => {
            state.status = 'ok';
        });

        builder.addCase(setPaymentCybersource.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentCybersource.rejected, (state: CartState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(setPaymentCybersource.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload.cart;
        });

        builder.addCase(setPaymentChek.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentChek.fulfilled, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(setPaymentChek.rejected, (state: CartState) => {
            state.status = 'ok';
        });

        builder.addCase(setPaymentNiubiz.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentNiubiz.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload.cart;
        });
        builder.addCase(setPaymentNiubiz.rejected, (state: CartState) => {
            state.status = 'ok';
        });
        builder.addCase(setPaymentAlignet.pending, (state: CartState) => {
            state.status = 'pending';
        });

        builder.addCase(setPaymentAlignet.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });

        builder.addCase(setPaymentAlignet.rejected, (state: CartState) => {
            state.status = 'ok';
        });

        builder.addCase(setTokenNiubiz.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setTokenNiubiz.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload.cart;
        });
        builder.addCase(setTokenNiubiz.rejected, (state: CartState) => {
            state.status = 'ok';
        });

        builder.addCase(setPaymentInStore.pending, (state: CartState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentInStore.fulfilled, (state: CartState, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });
        builder.addCase(setPaymentInStore.rejected, (state: CartState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
    },
});

export function getCartId(): string {
    const { cart } = getEnvironment();
    const cookieName = cart.idCookie;
    const paramName = cart.queryParam;
    const query = new URLSearchParams(window.location.search);
    return query.get(paramName) || Cookies.get(cookieName) || '';
}

export default slice;
