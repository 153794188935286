import React from 'react';
import styles from './PickupStoreError.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from './icon.svg';

export const PickupStoreError: React.FC = () => {
    const { t } = useTranslation('error');
    return (
        <div className={styles['container']}>
            <div className={styles['content']}>
                <h3>No hay tiendas disponibles</h3>
                <p>{t('pickupStoreError.message')}</p>
                <ErrorIcon />
            </div>
        </div>
    );
};
export default PickupStoreError;
