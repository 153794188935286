import React, { FC, FormEvent, ReactElement, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import styles from './AddressForm.module.scss';
import TextField from '../../common/TextField';
import PhoneField from '../../common/PhoneField';
import SelectField from '../../common/SelectField';
import TextAreaField from '../../common/TextAreaField';
import FieldWrapper from '../../common/FieldWrapper';
import { Option } from 'components/common/Select';
import { CL, PE } from 'consts';

export type AddressFormData = {
    streetName: string;
    streetNumber: string;
    homeNumber?: string;
    phoneNumber: string;
    regionCode: string;
    districtCode?: string;
    localityCode: string;
    region?: string;
    district?: string;
    locality?: string;
    customMessage?: string;
    nickname?: string;
};

export type SelectOptions = {
    regions: Option[];
    districts: Option[];
    localities: Option[];
};

export type AddressFormProps = {
    form: UseFormReturn<AddressFormData>;
    defaultValues?: AddressFormData;
    selectOptions: SelectOptions;
    country: 'CL' | 'PE';
    // Indicates if the user is registered or not
    isRegisteredUser?: boolean;
    // indicates if the component is loading
    isLoading?: boolean;
    onSubmit: (event: FormEvent) => void;
};

/*
 * AddressForm is a component to be used exclusively
 * with the 'react-hook-form' library.
 */
export const AddressForm: FC<AddressFormProps> = ({
    form,
    /* defaultValues, */
    isRegisteredUser,
    isLoading,
    selectOptions,
    country,
    onSubmit,
}: AddressFormProps): ReactElement => {
    const errors = form.formState.errors;
    const defaultValues = form.formState.defaultValues;

    const regionCode = form.watch('regionCode');
    const districtCode = form.watch('districtCode');
    const localityCode = form.watch('localityCode');

    const regionName = selectOptions.regions.find((r) => r.value === regionCode)?.label;
    const districtName = selectOptions.districts.find((d) => d.value === districtCode)?.label;
    const localityName = selectOptions.localities.find((l) => l.value === localityCode)?.label;
    const patternRegex = /[^a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\d\s.,'#!-]/;
    const patternString = patternRegex.source;
    useEffect(() => {
        form.setValue('region', regionName);
        form.setValue('district', districtName);
        form.setValue('locality', localityName);
    }, [form, regionName, districtName, localityName]);

    return (
        <form onSubmit={onSubmit} data-testid="address-form">
            <FieldWrapper className={styles['input']} error={errors.streetName?.message}>
                <TextField
                    label={'Dirección*'}
                    disabled={isLoading}
                    defaultValue={defaultValues?.streetName}
                    isInvalid={!!errors.streetName?.message}
                    pattern={patternString}
                    {...form.register('streetName')}
                />
            </FieldWrapper>

            <FieldWrapper className={styles['input']} error={errors.streetNumber?.message}>
                <TextField
                    label={'Número*'}
                    disabled={isLoading}
                    defaultValue={defaultValues?.streetNumber}
                    isInvalid={!!errors.streetNumber?.message}
                    pattern={patternString}
                    {...form.register('streetNumber')}
                />
            </FieldWrapper>

            <FieldWrapper className={styles['input']} error={errors.homeNumber?.message}>
                <TextField
                    label={'Depto/Casa/Oficina'}
                    disabled={isLoading}
                    defaultValue={defaultValues?.homeNumber}
                    isInvalid={!!errors.homeNumber?.message}
                    pattern={patternString}
                    {...form.register('homeNumber')}
                />
            </FieldWrapper>

            <FieldWrapper className={styles['input']} error={errors.phoneNumber?.message}>
                <PhoneField
                    disabled={isLoading}
                    defaultValue={defaultValues?.phoneNumber}
                    country={country}
                    isInvalid={!!errors.phoneNumber?.message}
                    {...form.register('phoneNumber')}
                />
            </FieldWrapper>

            {country === CL && (
                <>
                    <FieldWrapper className={styles['input']} error={errors.regionCode?.message}>
                        <SelectField
                            label={'Región'}
                            options={selectOptions.regions}
                            disabled={isLoading}
                            defaultValue={defaultValues?.regionCode}
                            isInvalid={!!errors.regionCode?.message}
                            empty="Seleccione una región"
                            {...form.register('regionCode')}
                        />
                    </FieldWrapper>

                    <FieldWrapper className={styles['input']} error={errors.localityCode?.message}>
                        <SelectField
                            label={'Comuna'}
                            options={selectOptions.localities}
                            disabled={isLoading}
                            defaultValue={defaultValues?.localityCode}
                            isInvalid={!!errors.localityCode?.message}
                            empty="Seleccione una comuna"
                            {...form.register('localityCode')}
                        />
                    </FieldWrapper>
                </>
            )}

            {country === PE && (
                <>
                    <FieldWrapper className={styles['input']} error={errors.regionCode?.message}>
                        <SelectField
                            label={'Departamento'}
                            options={selectOptions.regions}
                            disabled={isLoading}
                            defaultValue={defaultValues?.regionCode}
                            isInvalid={!!errors.regionCode?.message}
                            empty="Seleccione un departamento"
                            {...form.register('regionCode')}
                        />
                    </FieldWrapper>

                    <FieldWrapper className={styles['input']} error={errors.districtCode?.message}>
                        <SelectField
                            label={'Provincia'}
                            options={selectOptions.districts}
                            disabled={isLoading}
                            defaultValue={defaultValues?.districtCode}
                            isInvalid={!!errors.districtCode?.message}
                            empty="Seleccione una provincia"
                            {...form.register('districtCode')}
                        />
                    </FieldWrapper>

                    <FieldWrapper className={styles['input']} error={errors.localityCode?.message}>
                        <SelectField
                            label={'Distrito'}
                            options={selectOptions.localities}
                            disabled={isLoading}
                            defaultValue={defaultValues?.localityCode}
                            isInvalid={!!errors.localityCode?.message}
                            empty="Seleccione un distrito"
                            {...form.register('localityCode')}
                        />
                    </FieldWrapper>
                </>
            )}

            <FieldWrapper
                className={styles['input']}
                error={form.formState.errors.customMessage?.message?.toString()}
            >
                <TextAreaField
                    rows={3}
                    label={'Instrucciones de entrega'}
                    placeholder={'Ej. Dejar en conserjería (Máximo 47 caracteres)'}
                    disabled={isLoading}
                    defaultValue={defaultValues?.customMessage}
                    maxLength={47}
                    {...form.register('customMessage')}
                />
            </FieldWrapper>

            {isRegisteredUser && (
                <FieldWrapper
                    className={styles['input']}
                    error={form.formState.errors.nickname?.message?.toString()}
                >
                    <TextField
                        label={'Nombre de la dirección*'}
                        placeholder={'Ej. Mi casa'}
                        disabled={isLoading}
                        defaultValue={defaultValues?.nickname}
                        {...form.register('nickname')}
                    />
                </FieldWrapper>
            )}
        </form>
    );
};
