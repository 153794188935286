import axios, { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestHeaders } from 'axios';
import { delay } from 'lib/utils/promises';

import { getEnvironment } from 'config';
import { getSlice } from '../../lib/utils/storage';
import { getCookie } from '../../lib/utils/cookie';
import { CustomConfig, HeadersType } from 'types/bff';
import { COOKIE_CART_ID, COOKIE_CUSTOMER_TOKEN, COOKIE_CUSTOMER_REFRESH_TOKEN } from '../../consts';
import { sendErrorToGA4, validateEndpoint } from 'lib/utils/checkoutErrors';

const envs = getEnvironment();

const baseURL = envs.bff.baseUrl;

export class BFFError extends Error implements SerializedError {
    code: string;
    name: string;

    constructor(code: string, op: string, msg?: string) {
        super(msg);
        this.name = op;
        this.code = code;
    }
}

async function errorHandler(axiosErr: AxiosError): Promise<BFFError | AxiosResponse> {
    const { response } = axiosErr as AxiosError<HttpResponse>;
    const config: CustomConfig = axiosErr.config as CustomConfig;
    const err = response?.data?.error;

    let message = err?.message;

    try {
        // Transforms the error object received from the BFF into a string
        // to have access to all fields in the components.
        message = JSON.stringify(err);
    } catch (_) {
        //
    }

    if (err?.extra) {
        try {
            message = JSON.stringify(err.extra);
        } catch (_) {
            //
        }
    }

    // When the error comes from Apigee, re-authenticate then
    // retry the request but decreasing the retry counter.
    // Requests to '/auth' are excluded since they would cause
    // an infinite loop as this calls the `authenticate()` method.
    if (
        envs?.oAuth?.enable &&
        !err &&
        response?.status == 401 &&
        config.retry > 0 &&
        config.url !== '/auth'
    ) {
        config.retry -= 1;

        await delay(config.retryDelay);
        await bff.authenticate();
        return await bff.http.request({
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${bff.currentAccessToken}`,
            },
        });
    }

    return Promise.reject(
        new BFFError(
            err?.code || 'errUnknown',
            err?.op || 'Unknown',
            message || 'Error desconocido.',
        ),
    );
}

const globalConfig: CustomConfig = {
    baseURL: baseURL,
    withCredentials: !envs.oAuth?.enable,
    retry: 3,
    retryDelay: 50,
};

/**
 * `BFFConnector' Handles the connection with the BFF API
 */
class BFFConnector implements BFFImpl {
    http: AxiosInstance;
    channel: null | string;
    userId: null | string;
    cartId: null | string;
    currentAccessToken: string | null = null;

    constructor() {
        this.channel = null;
        this.userId = null;
        this.cartId = null;
        this.http = axios.create(globalConfig);

        this.http.interceptors.request.use((config) => {
            if (this.getOauthFeatureFlag()) {
                const customHeaders = this.getCustomHeaders(config.url as string, config.headers);
                // set current headers
                config.headers = {
                    ...customHeaders,
                } as AxiosRequestHeaders;
                // set global headers
                this.setHeaders(customHeaders);
            }

            return config;
        });

        this.http.interceptors.response.use(
            (response) => {
                this.getOauthFeatureFlag() && this.setAuthorization(response);
                return response;
            },
            (err: AxiosError) => {
                const { response, config } = err as AxiosError<HttpResponse>;
                const endpoint = config?.url?.split('/')[0] ?? config?.url;
                const error = response?.data?.error;

                if (endpoint && error && validateEndpoint(endpoint)) {
                    sendErrorToGA4({
                        channel: this.channel,
                        error,
                        type: 'Checkout_network_error',
                        section: 'Backend',
                    });
                }

                return errorHandler(err);
            },
        );
    }

    /**
     * `getAreas` returns the geo info for a given country
     */
    async getAreas(country: string): Promise<HttpResponse<ExtArea>> {
        const response = await this.http.get(`/areas?country=${country}`);
        return response.data;
    }

    /**
     * `authenticate` returns the auth info
     */
    async authenticate(): Promise<HttpResponse<ExtAuth>> {
        const response = await this.http.get('/auth');
        return response.data;
    }

    /**
     * `getStores` returns all the stores for a given country
     */
    async getStores(country: string): Promise<HttpResponse<ExtStore[]>> {
        const response = await this.http.get(`/stores?country=${country}`);
        return response.data;
    }

    /**
     * `getStoresStock` returns the stock of products at stores near a given city
     */
    async getStoresStock(
        country: string,
        payload: StoresStockPayload,
    ): Promise<HttpResponse<ExtStoreStock[]>> {
        const response = await this.http.post(`/stores/stock?country=${country}`, payload);
        return response.data;
    }

    async createUser(createUserPayload: CreateUserPayload): Promise<HttpResponse> {
        const response = await this.http.post('/me', createUserPayload);
        return response.data;
    }

    /**
     * `getUser' gets the information of the logged user
     * @returns
     */
    async getUser(light?: boolean): Promise<HttpResponse<ExternalUser>> {
        const response = await this.http.get('/me', { params: { light: light } });
        const userData = response.data?.data;

        if (userData) {
            // Save the user id so that it is available in the error log
            this.userId = userData.id;
        }

        return response.data;
    }

    /**
     * `getCart' gets the cart with the given id
     * @param cartId
     * @returns
     */
    async getCart(cartId: string, light?: boolean): Promise<HttpResponse<ExtCart>> {
        const response = await this.http.get(`/carts/${cartId}`, { params: { light: light } });
        const cartData = response.data?.data;

        if (cartData) {
            // Save the channel and cart id so that they are available in the error log
            this.channel = cartData.channel;
            this.cartId = cartData.id;
            // We create this header to have the next requests differentiated per channel
            this.http.defaults.headers.common['x-cart-channel'] = cartData.channel;
        }

        return response.data;
    }
    /**
     * `getSchedule' gets the schedule of a cart
     * @param cartId
     * @returns
     */
    async getSchedule(payload: GetSchedulePayload): Promise<HttpResponse> {
        const response = await this.http.get(`/schedules`, { params: payload });
        return response.data;
    }

    /**
     * 'getDeliveryMethods´ queries the available delivery methods
     * based on the cart id, region and locality
     * @returns
     */
    async getDeliveryMethods(
        payload: GetDeliveryMethodsPayload,
    ): Promise<HttpResponse<BFFDeliveryMethods>> {
        const response = await this.http.get('/delivery-methods', { params: payload });
        return response.data;
    }

    /**
     * `getCards' gets the cards of the logged user
     * @returns
     */
    async getCards(deviceId: string): Promise<HttpResponse> {
        const params: Record<string, string> = {};
        if (deviceId !== '') {
            params['deviceId'] = deviceId;
        }
        const response = await this.http.get('/me/cards', { params });
        return response.data;
    }

    /**
     * `setCustomer' sets the customer of a cart with id equal to `cartId` :)
     * @param cartId string
     * @param payload NormalizedUser
     * @returns
     */
    async setCustomer(cartId: string, payload: ExternalCustomer): Promise<HttpResponse> {
        return await this.http.put(`/carts/${cartId}/customer`, payload);
    }

    /**
     * `setSchedule' sets a schedule in the card with the given id
     * @param cartId
     * @returns
     */
    async setSchedule(payload: SetSchedulePayload): Promise<HttpResponse> {
        const { cartId, schedule } = payload;
        const response = await this.http.put(`/carts/${cartId}/schedules`, schedule);
        return response.data;
    }

    async setBillingInfo(payload: SetBillingInfoPayload): Promise<HttpResponse> {
        const { cartId, paymentInfo } = payload;
        const response = await this.http.post(`/carts/${cartId}/paymentsx`, paymentInfo);
        return response.data;
    }

    /**
     * `setPayment' initializes a payment and sets the payment
     *  method in the cart with given id
     * @param cartId
     * @returns
     */
    async setPayment(
        cartId: string,
        payload: PaymentInfo[],
        isDummy: boolean,
        overwrite: boolean,
    ): Promise<HttpResponse<PaymentInfo[]>> {
        const method = overwrite ? 'put' : 'post';
        const response = await this.http[method](
            `/carts/${cartId}/payments?dummy=${isDummy}`,
            payload,
        );
        return response.data;
    }

    /**
     * `setCoupons' add a coupon to the coupon array
     * @param cartId
     * @param payload
     * @returns
     */
    async setCoupons(cartId: string, payload: string[]): Promise<HttpResponse<string[]>> {
        const response = await this.http.put(`/carts/${cartId}/coupons`, payload);
        return response.data;
    }

    /**
     * `getPayment' query the status of a payment
     * @param cartId
     * @param paymentId
     * @returns
     */
    async getPayment(cartId: string, paymentId: string): Promise<HttpResponse<PaymentInfo>> {
        const response = await this.http.get(`/carts/${cartId}/payments/${paymentId}`);
        return response.data;
    }

    /**
     * `getPaymentStatus' query the status of an Alignet payment
     * * @param cartId
     * @param paymentId
     * @returns
     */
    async getPaymentStatus(cartId: string, paymentId: string): Promise<HttpResponse> {
        const response = await this.http.post(`/payments/Alignet/verify`, {
            cartId: cartId,
            externalId: paymentId,
        });
        return response.data;
    }

    /**
     * `evaluate' an order
     * @param cartId
     * @param orderId
     * @returns
     */
    async evaluate(cartId: string, orderId: string): Promise<HttpResponse> {
        const response = await this.http.post(`/payments/Alignet/evaluate`, { cartId, orderId });
        return response.data;
    }

    /**
     * `doCheckout' triggers the checkout process of a cart
     * @param cartId
     * @returns
     */
    async doCheckout(cartId: string, payload: unknown): Promise<HttpResponse> {
        const response = await this.http.post(`/carts/${cartId}/checkout`, payload);
        return response.data;
    }

    /**
     * `createAddress' add new address for user
     * @param payload
     * @returns
     */
    async createAddress(payload: ExternalAddressComponents[]): Promise<HttpResponse> {
        const response = await this.http.post('/me/addresses', payload);
        return response.data;
    }

    /**
     * `deleteAddress' address for user
     * @param id address id
     * @returns
     */
    async deleteAddress(id: string): Promise<HttpResponse> {
        const response = await this.http.delete(`/me/addresses?id=${id}`);
        return response.data;
    }

    /**
     * `modifyAddress' address for user
     * @param payload key value of properties of address
     * @param id address id
     * @returns
     */
    async modifyAddress(payload: ExternalAddressComponents[], id: string): Promise<HttpResponse> {
        const response = await this.http.put(`/me/addresses?id=${id}`, payload);
        return response.data;
    }

    /**
     * Removes products from a cart
     */
    async deleteProducts(cartId: string, products: string[]): Promise<HttpResponse> {
        const response = await this.http.delete(`/carts/${cartId}/products`, {
            data: products,
        });
        return response.data;
    }

    /**
     * `fetchRules` returns all business rules
     */
    async fetchRules(): Promise<HttpResponse<Rules>> {
        const response = await this.http.get('/rules');
        return response.data;
    }

    /**
     * Allows change the password of a gift card
     * @returns
     */
    async giftCardChangePassword(payload: {
        cardNumber: string;
        password: string;
        newPassword: string;
    }): Promise<HttpResponse> {
        const response = await this.http.post('/payments/RipleyGiftCard/change-password', payload);
        return response.data;
    }

    /**
     * Allow to simulate installments with Ripley cards
     * @param payload
     * @returns
     */
    async getSimulation(payload: {
        cartId: string;
        paymentId: string;
        installments: string;
        deferred: string;
    }): Promise<HttpResponse<SimulationInfo>> {
        const response = await this.http.post('payments/RipleyCard/get-simulation', payload);
        return response.data;
    }

    async setFulfillment(cartId: string, body: unknown): Promise<HttpResponse> {
        const response = await this.http.put(`carts/${cartId}/fulfillment`, body);
        return response.data;
    }

    async setFulfillmentProducts(cartId: string, products: Product[]): Promise<HttpResponse> {
        const body = { products: products };
        const response = await this.http.put(`carts/${cartId}/fulfillment_products`, body);
        return response.data;
    }

    /**
     * `sendLog' send information to be logged in the bff
     * @param payload
     * @returns
     */
    async sendLog(payload: LogPayload): Promise<HttpResponse> {
        const response = await this.http.post('logs', payload);
        return response.data;
    }

    async getCtsRegistry(cartId: string): Promise<HttpResponse<CtsResponse[]>> {
        const response = await this.http.get(`cts/${cartId}`);
        return response.data;
    }

    async deleteCtsRegistry(cartId: string, documentId: string): Promise<HttpResponse> {
        const response = await this.http.delete(`cts/${cartId}/${documentId}`);
        return response.data;
    }

    /**
     * Retrieves custom headers from the authentication state stored in localStorage.
     *
     * @returns An object containing the custom headers: 'X-User-Id', 'X-Cart-Id', and 'X-Cart-Channel'  if they exist.
     */
    private getCustomHeaders(url: string, headers: AxiosRequestHeaders): AxiosRequestHeaders {
        // get session data
        const authState = getSlice<AuthState>('auth');
        const { userId, cartId, channel, accessToken: bearer } = authState?.data || {};
        // end

        // get cookies
        // main
        const cookieCartId = getCookie(COOKIE_CART_ID);
        const customerToken = getCookie(COOKIE_CUSTOMER_TOKEN);
        const customerRefreshToken = getCookie(COOKIE_CUSTOMER_REFRESH_TOKEN);
        /**
         * @deprecated These cookies will be deprecated when Atlas takes over the Mabaya flow.
         */
        const mabayaUserId = getCookie('mabayaUserId');
        const mabayaProducts = getCookie('mabayaProducts');
        // end get cookies

        // TODO: If these header names are implemented elsewhere, they should be moved to constant variables.
        // from storage
        if (userId) headers['X-User-Id'] = userId;
        if (cartId) headers['X-Cart-Id'] = cartId;
        if (channel) headers['X-Cart-Channel'] = channel;
        if (url !== '/auth')
            headers['Authorization'] = this.currentAccessToken
                ? `Bearer ${this.currentAccessToken}`
                : `Bearer ${bearer}`;
        else delete headers['Authorization'];
        // from cookies
        if (cookieCartId) headers['x-nmkr-tra'] = cookieCartId;
        if (customerToken) headers['x-ce-token'] = customerToken;
        // The refresh token is optional because it can be empty for guest users
        if (customerRefreshToken) headers['x-ce-refresh'] = customerRefreshToken;
        if (mabayaUserId) headers['x-mabaya-user-id'] = mabayaUserId;
        if (mabayaProducts) headers['x-mabaya-products'] = mabayaProducts;

        return headers;
    }

    /**
     * Retrieves the OAuth feature flag from the environment configuration.
     *
     * This function extracts the `enable` flag from the `oAuth` configuration
     * object within the environment settings, indicating whether the OAuth
     * feature is enabled or not.
     *
     * @returns {boolean} - Returns true if the OAuth feature is enabled, false otherwise.
     */
    private getOauthFeatureFlag(): boolean {
        // Destructure the `enable` property from the `oAuth` configuration object
        // within the environment settings.
        const {
            oAuth: { enable },
        } = getEnvironment();
        return enable;
    }

    /**
     * Sets the default headers for the Axios instance.
     *
     * @param headers - An object containing the headers to be set.
     */
    private setHeaders(headers: HeadersType): void {
        this.http.defaults.headers.common = {
            ...this.http.defaults.headers.common,
            ...headers,
        } as AxiosRequestHeaders;
    }
    /**
     * Updates the authorization header if the response is from a successful authentication request (`/auth`, status 200)
     * and includes an access token within the data.
     *
     * @param response - The Axios response object.
     */
    private setAuthorization(response: AxiosResponse): void {
        if (
            response.config.url === '/auth' &&
            response.status === 200 &&
            response.data?.data?.accessToken
        ) {
            this.setHeaders({ Authorization: `Bearer ${response.data.data.accessToken}` });
            this.currentAccessToken = response.data.data.accessToken;
        }
    }
}

export const bff = new BFFConnector();
