import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AnalyticsEventType } from 'lib/hooks/analytics.utils';
import { getCartId } from 'lib/utils/cart';
import services from 'services';
import { RootState } from 'types/store';

export const fetchCtsByCartId = createAsyncThunk('events/fetchCtsByCartId', async () => {
    const cartId = getCartId();
    const response = await services.events.getCtsRegistry(cartId);
    return response;
});

export const fetchDeleteCtsByDocumentId = createAsyncThunk(
    'events/fetchDeleteCtsByDocumentId',
    async (arg, { getState }) => {
        const cartId = getCartId();
        const state = getState() as RootState;

        const documentsIds = state.events.ctsData.map((cts: CtsResponse) => {
            return cts.document_id;
        });

        const promises = documentsIds.map((documentId: string) => {
            return services.events.deleteCtsRegistry(cartId, documentId);
        });

        const response = await Promise.all(promises);
        return response;
    },
);

export type eventState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: AnalyticsEventType[];
    ctsData: CtsResponse[];
    error: SerializedError | null;
};

const initialState: eventState = {
    status: 'idle',
    data: [],
    ctsData: [],
    error: null,
};

const slice = createSlice({
    name: 'events',
    initialState: initialState,
    reducers: {
        addEvent: (state, action: PayloadAction<AnalyticsEventType>) => {
            state.data.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCtsByCartId.pending, (state: eventState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchCtsByCartId.fulfilled, (state: eventState, action) => {
            state.status = 'ok';
            state.ctsData = action.payload as CtsResponse[];
        });
        builder.addCase(fetchCtsByCartId.rejected, (state: eventState) => {
            state.status = 'idle';
            state.ctsData = [];
        });
        builder.addCase(fetchDeleteCtsByDocumentId.pending, (state: eventState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchDeleteCtsByDocumentId.fulfilled, (state: eventState) => {
            state.status = 'ok';
            state.ctsData = [];
        });
        builder.addCase(fetchDeleteCtsByDocumentId.rejected, (state: eventState) => {
            state.status = 'idle';
            state.ctsData = [];
        });
    },
});

export const { addEvent } = slice.actions;

export default slice;
