import React from 'react';
import styles from './RegisterMessage.module.scss';
import Spinner from '../Spinner';

type RegisterMessageProps = {
    status: 'ok' | 'pending' | 'error' | 'idle';
};

export const RegisterMessage: React.FC<RegisterMessageProps> = ({
    status,
}: RegisterMessageProps) => {
    return (
        <div className={styles['register-message']}>
            {status === 'pending' ? (
                <div className={styles['title']}>
                    <Spinner size={24} />
                </div>
            ) : status === 'ok' ? (
                <div className={styles['title']}>Tu usuario fue creado correctamente</div>
            ) : (
                <div className={styles['title']}>Hubo un error, no pudimos crear tu usuario</div>
            )}
        </div>
    );
};
