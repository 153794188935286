import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'lib/hooks/redux';
import { useTranslation } from 'react-i18next';
import { RegisterForm } from 'components/common/RegisterForm/RegisterForm';
import { createUser } from 'store/user/user.slice';
import { RootState } from 'types/store';

export type Info = {
    email: string;
    dni: string;
    password: string;
};

type RegisterUserProps = {
    setIsFormOpen: (b: boolean) => void;
    setCreateUserClicked: (b: boolean) => void;
};

export const RegisterUser: React.FC<RegisterUserProps> = ({
    setIsFormOpen,
    setCreateUserClicked,
}: RegisterUserProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.user);
    const [info, setInfo] = useState<Info>({
        email: String(user.data?.email),
        dni: String(user.data?.nin),
        password: '',
    });
    const buttonText = t('createAccount');
    const dniLabel = t('createUserDniLabel');

    const handleNewUser = () => {
        const createUserPayload: CreateUserPayload = {
            nin: info.dni,
            email: info.email,
            password: info.password,
        };
        dispatch(createUser(createUserPayload));
        setIsFormOpen(false);
        setCreateUserClicked(true);
    };

    return (
        <RegisterForm
            handleNewUser={handleNewUser}
            info={info}
            setInfo={setInfo}
            user={user}
            buttonText={buttonText}
            dniLabel={dniLabel}
        />
    );
};
