import * as React from 'react';
import styles from './ProductItem.module.scss';
import Prices, { Price } from '../Prices';
import ProductThumb from '../ProductThumb';
import { ProductThumbAvailability } from '../ProductThumb/ProductThumb';

export type ProductItemProps = {
    /**
     * Product displayed as an item of a list
     */
    product: Product;
    /**
     * Sets the country
     */
    country?: 'cl' | 'pe';
};

export const ProductItem: React.FC<ProductItemProps> = ({ product, country }: ProductItemProps) => (
    <div className={styles['product']}>
        <>
            <div className={styles['item']}>
                <ProductThumb
                    src={product.thumbnail}
                    quantity={product.quantity}
                    alt={product.sku}
                    availability={product.availability}
                />
            </div>
            <div className={styles['item--center']}>
                <p className={styles['name'] + ' ' + styles['ellipsis']} title={product.name}>
                    {product.name}
                </p>
                {product.tags.length > 0 && (
                    <div className={styles['cart--tags']}>
                        {product.tags.map((tag) => (
                            <span className={styles['tag']} key={tag}>
                                {tag}
                            </span>
                        ))}
                    </div>
                )}
                {product.quantity <= 0 && <p className={styles['error']}>Agotado</p>}
            </div>
            {product.quantity > 0 && product.prices && (
                <div className={styles['item--right']}>
                    <Prices list={product.prices} isTotal={false} country={country} />
                </div>
            )}
        </>
    </div>
);

export const CartProductItem: React.FC<ProductItemProps> = ({
    product,
    country,
}: ProductItemProps) => (
    <div className={styles['product']}>
        <div className={styles['item']}>
            <ProductThumb
                src={product.thumbnail}
                quantity={product.quantity}
                alt={product.sku}
                availability={product.availability}
            />
        </div>
        <div className={styles['cart-product-item']}>
            <div>
                <p
                    className={`${styles['name']} ${styles['cart-product-name']}`}
                    title={product.name}
                >
                    {product.name}
                </p>
                {product.quantity <= 0 && <p className={styles['error']}>Agotado</p>}
            </div>

            <div className={`${styles['item--right']} ${styles['cart']}`}>
                {product.tags.length > 0 && (
                    <div className={styles['cart--tags']}>
                        {product.tags.map((tag) => {
                            return (
                                <span className={styles['tag']} key={tag}>
                                    {tag}
                                </span>
                            );
                        })}
                    </div>
                )}
                {product.prices && (
                    <Prices list={product.prices} isTotal={false} type="cart" country={country} />
                )}
            </div>
        </div>
    </div>
);

export interface Product {
    sku: string;
    name: string;
    tags: string[];
    thumbnail: string;
    quantity: number;
    prices?: Price[];
    availability?: ProductThumbAvailability;
}
