import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Link from 'components/common/Link';
import Spinner from 'components/common/Spinner';
import CreditCardTitle from 'components/payment/CreditCardTitle';
import InstallmentsForm from 'components/payment/InstallmentsForm';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import InstallmentsSimulation from 'components/payment/InstallmentsSimulation';
import NiubizOptions from 'components/payment/NiubizOptions';
import AlertBadge from 'components/common/AlertBadge';

// store
import { useRules } from 'lib/hooks/rules';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { getIconType, getPaymentName, getCardType } from 'lib/utils/payments';
import { deleteCard } from 'store/card/actions';
import { setOptions } from 'store/card/options';
import { getDeferredOptions, getInstallmentOptions } from './CardSelector.utils';
import { getSimulation } from 'store/card/ripleyCard.slice';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export type FullCardProps = {
    card: ExternalCard;
};

export const FullCard: React.FC<FullCardProps> = ({ card }: FullCardProps) => {
    const rules = useRules();
    const dispatch = useAppDispatch();
    const options = useAppSelector((state: RootState) => state.card.options[card.paymentId ?? '']);
    const simulation = useAppSelector(
        (state: RootState) => state.card.simulation[card.paymentId ?? ''],
    );
    const cardToken = useAppSelector(
        (state: RootState) => state.cardToken.data.byId[state.card.selected],
    );
    const cartId = useAppSelector((state: RootState) => state.cart.data?.id);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const { t } = useTranslation('format');
    const selectedPaymentMethod = useAppSelector(
        (state: RootState) => state.paymentMethod.data.selected[0],
    );

    useEffect(() => {
        if (simulation && simulation.status === 'error') {
            sendErrorToGA4({
                channel: channel,
                error: 'Error en la simulación de cuotas',
                section: 'PaymentSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [simulation]);

    const bin = card.maskedPan.slice(0, 6);
    if (
        cardToken?.maskedPan &&
        (selectedPaymentMethod === 'Niubiz' || selectedPaymentMethod === 'RipleyNiubiz')
    ) {
        card = { ...card, maskedPan: cardToken?.maskedPan };
    }

    if (!cartId || card.paymentId == null || card.paymentId === '') {
        return null;
    }
    const paymentId = card.paymentId;

    /* Set card after paymentMethod is created */
    const handleOnDelete = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        dispatch(deleteCard({ cardId: card.id, hard: true }));
    };

    const handleOptions = (key: 'deferred' | 'installments') => (n: string) => {
        dispatch(
            setOptions({
                paymentMethod: card.paymentMethod,
                paymentId,
                [key]: parseInt(n),
            }),
        );

        if (card.isRipley && rules.feature('simulation')) {
            dispatch(
                getSimulation({
                    cartId,
                    paymentId,
                    installments: card.installments.toString(),
                    deferred: card.deferred.toString(),
                    [key]: n,
                }),
            );
        }
    };

    const paymentName = getPaymentName(card.paymentMethod, card.type, bin);
    const iconType = getIconType(card.paymentMethod, getCardType(bin), bin);

    const isReady = options != null && options.status == 'ok';
    const filteredInstallments = card.isRipley
        ? options.installments.filter((n) => n !== 2)
        : options.installments; // Number of installments filtered to eliminate the option of 2 installments due to problems with Banco Ripley

    const defaultInstallmentForm = (
        <InstallmentsForm
            card={card}
            installments={getInstallmentOptions(filteredInstallments)}
            deferredPayments={rules.feature('deferred') ? getDeferredOptions(options.deferred) : []}
            changeInstallments={handleOptions('installments')}
            changeDeferredPayments={handleOptions('deferred')}
        />
    );
    const installmentForm = (() => {
        switch (selectedPaymentMethod) {
            case 'RipleyNiubiz':
                return <NiubizOptions isRipley />;
            case 'Niubiz':
                return <NiubizOptions isRipley={false} />;
            default:
                return defaultInstallmentForm;
        }
    })();

    return (
        <PaymentMethodItem
            paymentMethodName={paymentName}
            type={iconType}
            widthIcon="63px"
            onClick={() => {}}
            additionalInfo={<CreditCardTitle card={card} />}
            selected={true}
        >
            {isReady ? (
                installmentForm
            ) : (
                <div style={{ marginBottom: '1em' }}>
                    <Spinner />
                </div>
            )}

            {simulation && simulation.status == 'pending' && (
                <div style={{ marginBottom: '1em' }}>
                    <Spinner />
                </div>
            )}

            {simulation && simulation.status == 'error' && (
                <div style={{ maxWidth: '400px', margin: '0 auto 30px' }}>
                    <AlertBadge theme="warning" size="large">
                        En este momento no podemos calcular el valor de tus cuotas con Tarjeta
                        Ripley.
                    </AlertBadge>
                </div>
            )}

            {simulation && simulation.data && simulation.status == 'ok' && (
                <InstallmentsSimulation
                    monthly={t('amount', { value: simulation.data.monthly })}
                    cae={simulation.data.cae + '%'}
                    total={t('amount', { value: simulation.data.total })}
                />
            )}

            <div>
                <Link to={handleOnDelete} title="Eliminar">
                    Eliminar tarjeta
                </Link>
            </div>
        </PaymentMethodItem>
    );
};
