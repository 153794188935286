import React from 'react';

// Hooks
import { useAppSelector } from 'lib/hooks/redux';

// Containers
import OrderSummaryContainer from 'containers/OrderSummaryContainer';

// Components
import AppLayout from 'components/layout/AppLayout';
import Acknowledgement from 'components/order/Acknowledgement';
import PageLayout from 'components/layout/PageLayout';
import { RootState } from 'types/store';

export function OrderSummary(): JSX.Element {
    const cart = useAppSelector((state: RootState) => state.cart.data);

    // In src/App.tsx we are moving carts that are not complete to /confirmation

    return (
        <AppLayout>
            <PageLayout
                content={
                    <React.Fragment>
                        {cart?.orderId && cart?.channel && (
                            <Acknowledgement orderId={cart?.orderId} shopping={cart?.channel} />
                        )}
                        <OrderSummaryContainer />
                    </React.Fragment>
                }
            />
        </AppLayout>
    );
}
