import React, { FunctionComponent } from 'react';
import Spinner from 'components/common/Spinner';
import styles from './Button.module.scss';

export type ButtonProps = {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    size?: 'small' | 'medium' | 'large';
    theme?: 'primary' | 'secondary' | 'dark';
    type?: 'button' | 'submit' | 'reset';
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    let computedClassName = styles['button'];
    const { theme = 'secondary', type = 'button' } = props;

    /**
     * Handles the `onClick` event
     * @param event
     */
    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!(props.loading || props.disabled)) {
            props.onClick?.(event);
        }
    };

    /**
     * We must add a style according the `theme` property
     */
    if (theme) {
        computedClassName += ' ' + styles[`button--${theme}`];
    }

    /**
     * We must add a style according the `size` property
     */
    if (props.size) {
        computedClassName += ' ' + styles[`button--${props.size}`];
    }

    /**
     * We must add the disabled style if `props.disabled` is true
     */
    if (props.disabled) {
        computedClassName += ' ' + styles[`button--disabled`];
    }

    if (props.loading) {
        computedClassName += ' ' + styles[`button--loading`];
    }

    /**
     * Add className passed via props
     */
    if (props.className) {
        computedClassName += ' ' + props.className;
    }

    const spinnerTheme = props.disabled ? 'none' : theme == 'primary' ? 'primary' : 'secondary';

    const spinnerSize = props.size === 'large' ? 24 : 20;

    return (
        <button
            className={computedClassName}
            onClick={handleOnClick}
            disabled={props.disabled}
            type={type}
        >
            {props.loading ? <Spinner size={spinnerSize} theme={spinnerTheme} /> : props.children}
        </button>
    );
};
