import jsonata from 'jsonata';

export function normalizeStores(stores: ExtStore[]): NormalizedStores {
    /**
     * JSONata schema https://try.jsonata.org/807jZei2P
     */
    const schema = `{
        "byId": {
            id: {
                "id": id,
                "type": type,
                "name": name,
                "address": address,
                "location": location,
                "attributes": attributes
            }
        },
        "allIds": $count($[]) > 0 ? $[].id : [],
        "selectedStore": "",
        "selectedRegionId": "",
        "selectedProvinceId": "",
        "selectedCityId": ""
    }`;

    return jsonata(schema).evaluate(stores) as any;
}

export function normalizeStoresStock(stocks: ExtStoreStock[]): NormalizedStoresStock {
    /**
     * JSONata schema https://try.jsonata.org/yjfRrS6kE
     */
    const schema = `{
        "byId": {
            storeId: {
                "storeId": storeId,
                "products": products
            }
        },
        "allIds": [$.storeId]
    }`;

    return jsonata(schema).evaluate(stocks) as any;
}
