import React from 'react';
import styles from './OrderStepIcon.module.scss';

export type OrderStepIconProps = {
    /**
     * Icon name
     */
    name: 'check' | 'delivery' | 'bag' | 'ticket' | 'time';
    /**
     * Order country
     */
    country?: 'cl' | 'pe';
    /**
     * Defines if the OrderStepIcon will be shown disabled or not
     */
    disabled?: boolean;
};

export const OrderStepIcon: React.FC<OrderStepIconProps> = ({
    name,
    disabled,
    country,
}: OrderStepIconProps) => {
    const computedClassName = `${styles['image']} ${
        styles[`image_${name}_${country}--${!disabled ? 'actived' : 'disabled'}`]
    }`;
    return (
        <div className={styles['image_wrapper']} data-testid={name}>
            <div className={computedClassName} />
        </div>
    );
};

OrderStepIcon.defaultProps = {
    country: 'cl',
};
