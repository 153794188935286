import React from 'react';

// components
import Link from 'components/common/Link';
import CreditCardTitle from 'components/payment/CreditCardTitle';
import InstallmentsForm from 'components/payment/InstallmentsForm';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';

// store
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { getIconType, getPaymentName } from 'lib/utils/payments';
import { deleteCard } from 'store/card/actions';
import { setPaymentByToken as setTokenCyb } from 'store/paymentMethod/cybersource.slice';
import { setPaymentByToken as setTokenRipley } from 'store/paymentMethod/ripleyCard.slice';
import { useDeliveryMethod } from '../../../lib/hooks/deliveryMethod';
import { RootState } from 'types/store';

export type IncompleteCardProps = {
    card: CardToken;
};

export const IncompleteCard: React.FC<IncompleteCardProps> = ({ card }: IncompleteCardProps) => {
    const dispatch = useAppDispatch();
    const dm = useDeliveryMethod();
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );
    const paymentStatus = useAppSelector((state: RootState) => {
        switch (card.paymentMethod) {
            case 'Cybersource':
                return state.paymentMethod.types.cybersource.status;
            case 'RipleyCard':
            case 'RipleyChekCard':
                return state.paymentMethod.types.ripleyCard.status;
        }
        return 'ok';
    });

    /* The setToken function completes the Card */
    const handleChangeCvv = (cvv: string) => {
        switch (card.paymentMethod) {
            case 'Cybersource':
                dispatch(setTokenCyb({ card, cvv }));
                break;
            case 'RipleyCard':
            case 'RipleyChekCard':
                dispatch(setTokenRipley({ card, cvv, paymentMethod: card.paymentMethod }));
                break;
        }
    };

    const handleOnDelete = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        dispatch(deleteCard({ cardId: card.id, hard: true }));
    };

    const paymentName = getPaymentName(card.paymentMethod, card.type, card.maskedPan);
    const iconType = getIconType(card.paymentMethod, card.type, card.maskedPan);

    const isPending = couponLoading || paymentStatus === 'pending' || !dm.hasSchedules();

    return (
        <PaymentMethodItem
            paymentMethodName={paymentName}
            type={iconType}
            widthIcon="63px"
            onClick={() => {}}
            additionalInfo={<CreditCardTitle card={card} />}
            selected={true}
        >
            <InstallmentsForm changeCVV={handleChangeCvv} isPending={isPending} />
            <div>
                <Link to={handleOnDelete} title="Eliminar">
                    Eliminar tarjeta
                </Link>
            </div>
        </PaymentMethodItem>
    );
};
