import { differenceInCalendarDays, format } from 'date-fns';
import locale from 'date-fns/locale/es';

export function formatDate(d: Date): string {
    /* TODO: use a localization library */
    const date = d.getDate();
    let month;
    switch (d.getMonth()) {
        /* eslint-disable prettier/prettier */
        case 0: month = 'enero'; break;
        case 1: month = 'febrero'; break;
        case 2: month = 'marzo'; break;
        case 3: month = 'abril'; break;
        case 4: month = 'mayo'; break;
        case 5: month = 'junio'; break;
        case 6: month = 'julio'; break;
        case 7: month = 'agosto'; break;
        case 8: month = 'septiembre'; break;
        case 9: month = 'octubre'; break;
        case 10: month = 'noviembre'; break;
        case 11: month = 'diciembre'; break;
        default: break;
        /* eslint-enable prettier/prettier */
    }
    return `${date} de ${month}`;
}

/**
 * Returns the text of the day of week
 * @returns
 */
export function formatWeekDay(d: Date): string {
    if (differenceInCalendarDays(d, new Date()) == 0) {
        return 'Hoy';
    }

    if (differenceInCalendarDays(d, new Date()) == 1) {
        return 'Mañana';
    }

    const formated = format(d, 'iiii', { locale: locale });
    return formated.charAt(0).toUpperCase() + formated.slice(1);
}
