import { FormProps } from './CreditCard.interface';
import { getExpiration } from 'lib/utils/payments';
export {
    formatCreditCardNumber,
    getCardType,
    getExpiration,
    isRipleyCard,
} from 'lib/utils/payments';

/**
 * Add / after month
 * @param {string} value
 */
export const formatMonth = (value = ''): string => {
    return value.replace(/\D+/g, '/');
};

/**
 * Transform the expiration date to a date object
 * @param {string} expiry
 */
export const transformExpirationDate = (expiry: string): string => {
    const date = typeof expiry === 'number' ? expiry : expiry;

    let month = '';
    let year = '';

    if (date.includes('/')) {
        [month, year] = date.split('/');
    } else if (date.length) {
        month = date.substring(0, 2);
        year = date.substring(2, 6);
    }

    while (month.length < 2) {
        month += 'M';
    }

    if (year.length > 2) {
        year = year.substring(2, 6);
    }

    while (year.length < 2) {
        year += 'A';
    }

    return `${month}/${year}`;
};

export const standarizeCard = (form: FormProps): Card => {
    const { year, month } = getExpiration(form.expiry);
    return {
        pan: form.number,
        month,
        year,
        cvv: form.cvc,
        remember: form.remember,
    };
};
