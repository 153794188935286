import React, { useEffect, useState } from 'react';

import InvoiceForm, { InvoiceFormData, SelectOptions } from 'components/aio/InvoiceForm';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';

import { businessActivities } from 'lib/mocks/store/billing/activities';
import { ChileFormValidation, PeruFormValidation } from 'components/aio/InvoiceForm/schema';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CL, country } from 'consts';
import { getSelectOptions } from 'lib/utils/addresses';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';

import { getInvoiceFormData, getBillingInfo } from 'lib/utils/billing';
import { setBillingInfo } from 'store/billing';
import { RootState } from 'types/store';

const activities = businessActivities();

export type InvoiceFormContainerProps = {
    isOpen: boolean;
    onToggle: () => void;
    onContinue: () => void;
};

export const InvoiceFormContainer: React.FC<InvoiceFormContainerProps> = (
    props: InvoiceFormContainerProps,
) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state: RootState) => state.user.data);
    const addresses = useAppSelector((state: RootState) => state.address.data);
    const areas = useAppSelector((state: RootState) => state.area.data);
    const billing = useAppSelector((state: RootState) => state.billing.data);

    const form = useForm<InvoiceFormData>({
        resolver: yupResolver(country == CL ? ChileFormValidation : PeruFormValidation) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
        defaultValues: billing?.documentType == 'factura' ? getInvoiceFormData(billing) : {},
    });

    const regionCode = form.watch('regionCode');

    const [selectOptions, setSelectOptions] = useState<SelectOptions>({
        ...getSelectOptions(areas, regionCode, ''),
        activities,
    });

    useEffect(() => {
        setSelectOptions({
            ...getSelectOptions(areas, regionCode, ''),
            activities,
        });
    }, [areas, regionCode]);

    const saveForm = async (form: InvoiceFormData) => {
        if (user && addresses && areas) {
            const address = addresses.byId[addresses.selected];
            const payload = getBillingInfo('factura', user, address, areas, form);
            dispatch(setBillingInfo(payload));
            props.onContinue();
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        (async (event) => {
            const handler = form.handleSubmit(saveForm);
            await handler(event);
        })(event);
    };

    return (
        <Modal
            size="xs"
            header="Factura"
            isOpen={props.isOpen}
            onToggle={props.onToggle}
            footer={
                <Button
                    theme="primary"
                    size="large"
                    loading={false}
                    disabled={false}
                    onClick={handleSubmit}
                >
                    Guardar
                </Button>
            }
        >
            <InvoiceForm
                form={form}
                country={country}
                selectOptions={selectOptions}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
};
