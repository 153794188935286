import React from 'react';
import styles from './NiubizForm.module.scss';
import Checkbox from 'components/common/Checkbox';

export type NiubizFormProps = {
    /** True if niubiz form is being created */
    loading: boolean;
    /** True if submit should not be pressed */
    disabled: boolean;
    /** Submit button */
    submit: React.ReactNode;
    /** Allow remember card on file */
    allowRemember?: boolean;
    /** State of remember card */
    remember?: boolean;
    /** Change of remember card  */
    setRemember: (b: boolean) => void;
};

export function NiubizForm(props: NiubizFormProps): JSX.Element {
    const { disabled, loading, submit, remember, allowRemember, setRemember } = props;
    const onScroll = (ev: React.UIEvent<HTMLDivElement>) => {
        ev.stopPropagation();
        ev.preventDefault();
        ev.currentTarget.scrollLeft = 0;
    };

    const handleOnChange = () => {
        setRemember(!remember);
    };

    return (
        <div>
            <div className={styles['control']} onScroll={onScroll}>
                {/* paymenthub is appending error message to 2nd-level parent */}
                <div className={styles['pan']}>
                    <div className={loading ? '' : styles['field']}>
                        <div id="txtNumeroTarjeta"></div>
                    </div>
                </div>
            </div>
            <div className={styles['siblings']}>
                <div className={styles['control']} onScroll={onScroll}>
                    <div className={styles['exp']}>
                        <div className={loading ? '' : styles['field']}>
                            <div id="txtFechaVencimiento"></div>
                        </div>
                    </div>
                </div>
                <div className={styles['control']} onScroll={onScroll}>
                    <div className={styles['cvv']}>
                        <div className={loading ? '' : styles['field']}>
                            <div id="txtCvv"></div>
                        </div>
                    </div>
                </div>
            </div>
            {allowRemember && (
                <div className={styles['control']} onScroll={onScroll}>
                    {!loading && (
                        <div className={styles['remember']}>
                            <Checkbox
                                id="rememberCard"
                                checked={!!remember}
                                onChange={handleOnChange}
                            />
                            <label htmlFor="rememberCard">Guardar tarjeta</label>
                        </div>
                    )}
                </div>
            )}

            {/* The next <span> catches a TAB keypress preventing a jump from
            the cvv input to the next payment method that may provoke an
            unwanted change of payment method */}
            {disabled && <span role="button" tabIndex={0} aria-label="Botón sin funcionalidad" />}
            {!loading && <div className={styles['button']}>{submit}</div>}
            {disabled && !loading && (
                <p className={styles['warning-message']}>
                    Cobraremos un monto mínimo para validar tu tarjeta. Este será devuelto de
                    inmediato.
                </p>
            )}
        </div>
    );
}
