import services from '../../services';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IS_CHEK_OPEX } from 'consts';
import { fetchCartById } from 'store/cart';
import { RootState } from 'types/store';

/**
 * Creates a RipleyChek payment and updates the cart to get
 * the grand total recomputed
 */
export const setPaymentMethod = createAsyncThunk(
    'ripleyChek/setPayment',
    async (_, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { cart, billing } = state;
        if (cart.data && billing.data) {
            const payload = getPaymentInfoPayload(cart.data, billing.data, null);
            await services.cart.setPaymentOnce(cart.data.id, payload, true, true);
            await dispatch(fetchCartById());
        } else {
            throw new Error('Datos incompletos para usar Chek');
        }
    },
);

/**
 * Initializes a RipleyChek payment and returns the custom fields
 * to display the QR Code
 */
export const initializePayment = createAsyncThunk(
    'ripleyChek/initilizePayment',
    async (phoneNumber: string | null, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { cart, billing } = state;
        if (cart.data && billing.data) {
            const payload = getPaymentInfoPayload(cart.data, billing.data, phoneNumber);
            const resp = await services.cart.setPaymentOnce(cart.data.id, payload, false, true);
            const payment = resp.find((payment) => payment.paymentMethod == 'RipleyChek') ?? null;

            if (!payment?.customFields || !payment?.customFields.resource)
                throw new Error('Hubo un problema con el medio de pago');

            await dispatch(fetchCartById());

            return {
                phoneNumber: payment.customFields.phoneNumber,
                resource: payment.customFields.resource,
            };
        } else {
            throw new Error('Datos incompletos para usar Chek');
        }
    },
);

export interface GetPaymentPayload {
    cartId: string;
    paymentId: string;
}

/**
 * Queries the status of a payment
 */
export const getPaymentStatus = createAsyncThunk(
    'ripleyChek/getPaymentStatus',
    async (payload: GetPaymentPayload) => {
        const { cartId, paymentId } = payload;
        const response = await services.cart.getPayment(cartId, paymentId);

        if (!response.status) throw new Error('Error obteniendo el estado del pago');

        return response.status;
    },
);

/**
 * Returns the payload to create a RipleyChek payment
 * @returns
 */
export function getPaymentInfoPayload(
    cart: Cart,
    billing: PartialPaymentInfo,
    phoneNumber: string | null,
): PaymentInfo[] {
    let price: Price = cart.grandTotal[IS_CHEK_OPEX ? 'ripley_promo' : 'discount'];
    if (price == null) price = cart.grandTotal['discount'];
    if (price == null) price = cart.grandTotal['master'];

    const customFields: { [k: string]: string } = { message: 'Cobro generado por Ripley.com' };

    if (phoneNumber) {
        // Se corta el número para enviar los últimos 8 digitos
        phoneNumber = phoneNumber.slice(1, phoneNumber.length);
        customFields.phoneNumber = phoneNumber;
    }

    return [
        {
            documentType: billing.documentType,
            paymentMethod: 'RipleyChek',
            billingInfo: {
                business: billing.billingInfo.business,
                customer: billing.billingInfo.customer,
                address: billing.billingInfo.address,
            },
            amount: price,
            customFields: customFields,
        },
    ];
}

export interface RipleyChekData {
    phoneNumber?: string;
    resource: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

export interface RipleyChekState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: RipleyChekData | null;
    error: SerializedError | null;
}

const initialState: RipleyChekState = {
    status: 'idle',
    data: null,
    error: null,
};

export const slice = createSlice({
    name: 'ripleyChek',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPaymentMethod.pending, (state: RipleyChekState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: RipleyChekState) => {
            state.status = 'ok';
            state.data = null;
            state.error = null;
        });
        builder.addCase(setPaymentMethod.rejected, (state: RipleyChekState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
        builder.addCase(initializePayment.pending, (state: RipleyChekState) => {
            state.status = 'pending';
        });
        builder.addCase(initializePayment.fulfilled, (state: RipleyChekState, { payload }) => {
            state.status = 'ok';
            state.data = {
                status: 'initialized',
                phoneNumber: payload.phoneNumber,
                resource: payload.resource,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
            };
            state.error = null;
        });
        builder.addCase(initializePayment.rejected, (state: RipleyChekState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
        builder.addCase(getPaymentStatus.pending, (state: RipleyChekState) => {
            state.status = 'pending';
        });
        builder.addCase(getPaymentStatus.fulfilled, (state: RipleyChekState, { payload }) => {
            state.status = 'ok';
            state.error = null;

            if (state.data) {
                state.data.status = payload;
                state.data.updatedAt = new Date().toISOString();
            }
        });
        builder.addCase(getPaymentStatus.rejected, (state: RipleyChekState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
    },
});
