import React from 'react';
import styles from './ScheduleList.module.scss';
import RadioButton from 'components/common/RadioButton';
import NavList from 'components/common/NavList';
import NavItem from 'components/common/NavItem';
import ScheduleDescription, {
    ScheduleDescriptionProps,
} from 'components/shipping/ScheduleDescription';

export interface Schedule {
    description: ScheduleDescriptionProps;
    price: string;
    id: string;
    selected: boolean;
}

type ScheduleListProps = {
    schedules: Schedule[];
    onClick: (id: string) => void;
};

export const ScheduleList: React.FC<ScheduleListProps> = ({
    schedules,
    onClick,
}: ScheduleListProps) => {
    return (
        <>
            {schedules.length > 0 && (
                <NavList>
                    {schedules.map((schedule, index) => {
                        return (
                            <NavItem key={schedule.id} onClick={() => onClick(schedule.id)}>
                                <div className={styles['row']} data-testid="shipping-option">
                                    <div>
                                        <RadioButton
                                            id={`radio-id-${index}`}
                                            name={`radio-${index}`}
                                            checked={schedule.selected}
                                            value={schedule.id}
                                            disabled={false}
                                            className={styles['full-width']}
                                        />
                                    </div>
                                    <div>
                                        <ScheduleDescription {...schedule.description} />
                                        <div>{schedule.price}</div>
                                    </div>
                                </div>
                            </NavItem>
                        );
                    })}
                </NavList>
            )}
        </>
    );
};
