import React, { useContext } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { getIconType, getPaymentName } from 'lib/utils/payments';
import { setPaymentDummy } from 'store/paymentMethod/actions';
import { useTranslation } from 'react-i18next';
import { ErrorContext } from 'containers/aio/PaymentMethodsContainer/context';
import { setPaymentWithoutCvv } from 'store/paymentMethod/ripleyCard.slice';
import { RootState } from 'types/store';

import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import Title from 'components/payment/CreditCardTitle';

export type TokenItemProps = {
    token: CardToken;
    disabled?: boolean;
};

export const TokenItem: React.FC<TokenItemProps> = ({ token, disabled }: TokenItemProps) => {
    const { t } = useTranslation('error');
    const dispatch = useAppDispatch();
    const ctx = useContext(ErrorContext);
    const cards = useAppSelector((state: RootState) => state.card);
    const isOpen = useAppSelector((state: RootState) =>
        state.cardToken.data.selected.includes(token.id),
    );
    const isSelected = token.id === cards.selected;
    const pm = useAppSelector((state: RootState) => state.paymentMethod.data);
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    if (isSelected || isOpen) {
        return null;
    }

    const isLoading = cards.byId[token.id]?.status === 'pending';
    const isBlocked = (pm.status === 'pending' && !isLoading) || couponLoading || disabled;

    const handleOnClick = async () => {
        if (!isLoading) {
            const payload = { paymentMethod: token.paymentMethod, cardToken: token };
            try {
                if (token.paymentMethod == 'RipleyCard' && token.settings?.rules.cvvFreeAvailable) {
                    await dispatch(setPaymentWithoutCvv(token)).unwrap();
                } else {
                    await dispatch(setPaymentDummy(payload)).unwrap();
                }
            } catch (e) {
                ctx.setError(t('defaultPaymentError.message'));
            }
        }
    };

    const paymentName = getPaymentName(token.paymentMethod, token.type, token.maskedPan);
    const iconType = getIconType(token.paymentMethod, token.type, token.maskedPan);

    return (
        <PaymentMethodItem
            paymentMethodName={paymentName}
            type={iconType}
            widthIcon="63px"
            additionalInfo={<Title card={token} />}
            onClick={handleOnClick}
            selected={isSelected}
            isLoading={isLoading}
            blocked={isBlocked}
        />
    );
};
