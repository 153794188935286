import React from 'react';
import styles from './ChekQR.module.scss';
import QRCode from 'qrcode.react';
import Button from 'components/common/Button';

import chekImage from './chek.svg';

export type ChekQRProps = {
    url: string;
    amount: string;
    onClick: () => void;
};

export const ChekQR: React.FC<ChekQRProps> = (props: ChekQRProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div>
                    <img src={chekImage} alt="Logo chek" data-testid="image" />
                </div>
                <div>
                    <QRCode renderAs="svg" value={props.url} data-testid="qr" />
                </div>
                <div>
                    <h1>Escanea el Qr</h1>
                    <p>Abre la App Chek en tu dispositivo y escanea el código Qr.</p>
                    <b>Valor a pagar: {props.amount}</b>
                    <Button theme="secondary" size="medium" onClick={props.onClick}>
                        Elegir otro medio de pago
                    </Button>
                </div>
            </div>
        </div>
    );
};
