import jsonata from 'jsonata';

/**
 * Normalizes the delivery methods returned by BFF
 * @param dm
 * @returns
 */
export const normalizeDeliveryMethods = (dm: BFFDeliveryMethods): DeliveryMethods => {
    const schema = `{
        "byId": groups.{
            deliveryMethod:{
                "deliveryMethod":deliveryMethod,
                "products": products
            }
        },
        "allIds":[groups.deliveryMethod]
    }`;
    return jsonata(schema).evaluate(dm) as any;
};
