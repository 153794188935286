import React from 'react';
import ScheduleDescription, {
    ScheduleDescriptionProps,
} from 'components/shipping/ScheduleDescription';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { Deliverables } from 'components/shipping/DeliveryConfirmation';
import { formatDate } from 'lib/utils/date';
import { MP, RT, RC, SERVICE_TYPE } from 'consts';

export function getDeliveries(cart: Cart | null): Deliverables[] {
    return (
        cart?.shippingInfo?.map((shipping) => ({
            date: getShippingDate(shipping),
            products: shipping.productsList
                // match products identifiers with its data inside cart
                .map((p) => cart.products?.find((x) => p === x.id || p === x.sku))
                // remove not found items from list and assert that to typescript
                .filter((x): x is Product => !!x)
                // return desired data from products
                .map((p) => ({ image: p.details.thumbnail, qty: p.quantity })),
        })) || []
    );
}

export function getShippingDate(shipping: ShippingInfo): React.ReactNode {
    if (shipping.type === MP) {
        const [closestDate, latestDate] = getShippingNaiveDates(
            shipping.dateFrom,
            shipping.dateTo,
            'transactions',
        );
        return (
            <ScheduleDescription
                {...getScheduleDescription(
                    MP,
                    closestDate,
                    latestDate,
                    shipping.timing ?? '',
                    shipping.typeLabel,
                )}
            />
        );
    } else if (shipping.dateTo) {
        // TODO: Sort timezone stuff out (Logistic, Transactions, DST) so
        //  we don't have to resort to cutting out the timezone portion.
        //  Contrary to Logistic, here we only drop the "Z" at the end.
        const to = new Date(shipping.dateTo.slice(0, -1));
        const message = shipping.type === RT || shipping.type === RC ? 'Retira el' : 'Recíbelo el';
        return <ScheduleDescription message={message} closestDate={formatDate(to)} />;
    }
}

/**
 * Gets the datetime removing the timezone portions of the ISO string.
 * From Transactions is just the "Z" while Logistic Engine adds the
 * time offset (eg: "-03:00")
 *
 * TODO: Sort timezone stuff out (Logistic, Transactions, DST) so
 *   we don't have to resort to cutting out the timezone portion.
 */
export const getShippingNaiveDates = (
    closestDate = '',
    latestDate = '',
    source: 'logistic' | 'transactions' = 'logistic',
): Date[] => {
    const sliceLength = source === 'logistic' ? -6 : -1;
    return [
        new Date(closestDate.slice(0, sliceLength)),
        new Date(latestDate.slice(0, sliceLength)),
    ];
};

/**
 * Handles the different cases for schedule descriptions and returns
 * the <ScheduleDescription /> props as an object.
 */
export const getScheduleDescription = (
    deliveryMethod: string,
    closestDate: Date,
    latestDate: Date,
    typeOfService: string,
    hoursRange = '',
): ScheduleDescriptionProps => {
    // TODO: Quitar cuando este en produccion el retiro marketplace (RT) 2022-12-28
    // Esto permite mostrar el texto en vez de rango de fechas para los
    // retiros en tienda marketplace a traves del flujo de despacho a domicilio.
    if (
        typeOfService === SERVICE_TYPE.RET_SELLER_CL ||
        typeOfService === SERVICE_TYPE.RET_SELLER_PE
    ) {
        return { message: hoursRange };
    }

    if (deliveryMethod === MP) {
        // Marketplace product with same-day delivery.
        if (differenceInCalendarDays(closestDate, latestDate) === 0) {
            return {
                message: 'Recíbelo el ',
                closestDate: formatDate(closestDate),
                end: '',
            };
        }

        // Marketplace product with range-of-days delivery.
        return {
            message: 'Recíbelo entre el ',
            closestDate: formatDate(closestDate),
            end: 'y el ',
            latestDate: formatDate(latestDate),
        };
    }

    // Ripley product.
    return {
        message: 'Recíbelo el ',
        closestDate: formatDate(closestDate),
        end: 'de ' + hoursRange,
    };
};
