import React from 'react';
import styles from './LoadingCircle.module.scss';
import image from './loading.svg';

type LoadingCircleProps = {
    size: number;
};

const LoadingCircle = (props: LoadingCircleProps): JSX.Element => {
    const outer = props.size;
    const outerPx = `${outer}px`;
    const half = Math.round(outer / 2);
    const radius = half - 4; /* .loader.strokeWidth */
    const inner = outer - 20; /* .logo.top + .logo.left */
    const innerPx = `${inner}px`;

    return (
        <div className={styles.wrapper}>
            <svg width={outerPx} height={outerPx} viewBox={`0 0 ${outer} ${outer}`}>
                <circle className={styles.bg} cx={half} cy={half} r={radius} />
                <circle className={styles.loader} cx={half} cy={half} r={radius} />
            </svg>
            <img
                src={image}
                className={styles.logo}
                alt="Ripley"
                width={innerPx}
                height={innerPx}
            />
        </div>
    );
};

LoadingCircle.defaultProps = {
    size: 100,
};

export default LoadingCircle;
