import { CL, PE, country } from 'consts';
import { getAddressComponentsWithNames } from './addresses';
import { InvoiceFormData } from 'components/aio/InvoiceForm';
import { businessActivities } from 'lib/mocks/store/billing/activities';

export function getBillingInfo(
    type: DocType,
    user: NormalizedUser,
    address: ExternalAddress,
    areas: ExtArea,
    form?: InvoiceFormData,
): PartialPaymentInfo {
    if (type == 'factura' && form && country == CL) {
        return {
            documentType: 'factura' as DocType,
            billingInfo: {
                customer: {
                    nin: form.nin,
                    firstName: form.companyName,
                    lastName: '',
                    phoneNumber: form.phoneNumber,
                    email: user.email,
                },
                address: [
                    { key: 'nickname', value: form.companyName },
                    { key: 'streetName', value: form.streetName },
                    { key: 'streetNumber', value: form.streetNumber },
                    { key: 'homeNumber', value: form.homeNumber },
                    { key: 'region', value: form.region },
                    { key: 'region_code', value: form.regionCode },
                    { key: 'locality_code', value: form.localityCode },
                    { key: 'city', value: form.locality },
                    { key: 'country', value: 'CL' },
                ],
                business: form.activity,
            },
        };
    }

    if (type == 'factura' && form && country == PE) {
        return {
            documentType: 'factura' as DocType,
            billingInfo: {
                customer: {
                    nin: form.nin,
                    firstName: form.companyName,
                    lastName: '',
                    phoneNumber: user.phoneNumber,
                    email: user.email,
                },
                address: getAddressComponentsWithNames(address.addressComponents, areas),
                business: '',
            },
        };
    }

    return {
        documentType: 'boleta',
        billingInfo: {
            business: '',
            customer: {
                nin: user.nin,
                firstName: user.firstname,
                lastName: user.lastname,
                phoneNumber: user.phoneNumber,
                email: user.email,
            },
            address: getAddressComponentsWithNames(address.addressComponents, areas),
        },
    };
}

export function getInvoiceFormData(data: PartialPaymentInfo): InvoiceFormData {
    const getValue = (key: string) =>
        data.billingInfo?.address?.find(
            (addressComponents: ExternalAddressComponents) => addressComponents.key === key,
        )?.value ?? '';

    const activity = data.billingInfo.business ?? '';
    const activityCode =
        Object.values(businessActivities()).find((a) => a.label == activity)?.value ?? '';

    return {
        companyName: data.billingInfo.customer.firstName,
        nin: data.billingInfo.customer.nin,
        streetName: getValue('streetName'),
        streetNumber: getValue('streetNumber'),
        homeNumber: getValue('homeNumber'),
        phoneNumber: data.billingInfo.customer.phoneNumber,
        phoneNumberFormatted: data.billingInfo.customer.phoneNumber,
        regionCode: getValue('region_code'),
        localityCode: getValue('locality_code'),
        region: getValue('region'),
        locality: getValue('city'),
        activityCode: activityCode,
        activity: activity,
    };
}
