import React, { FC } from 'react';
import Image from 'components/common/Image';
import styles from './ProductThumb.module.scss';

export type ProductThumbAvailability = 'na' | 'available' | 'unavailable' | 'warning';

export type ProductThumbProps = {
    /**
     * Draws borders and icons indicating availability.
     * No border or icon is displayed if value is not passed.
     */
    availability?: ProductThumbAvailability;
    /**
     * For use with supermarket products.
     * Displays badge with number at the top left.
     */
    quantity?: number;
    /**
     * Displays a semi-transparent overlay with the number
     * of extra, not visible, products.
     */
    showMore?: number;
    /**
     * Handler for the `showMore` overlay, or other potential interactions.
     */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    /**
     * Keypress handler for the `showMore` overlay, or other potential interactions.
     * Required for static HTML elements with `onClick` handlers.
     */
    onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    /**
     * Product image URL. Required.
     */
    src: string;
    /**
     * Product description text for the img `alt` attribute.
     * Not mandatory but useful for accesibility.
     */
    alt?: string;
    /**
     * If true it will fit an horizontal image in a vertical aspect ratio
     */
    forceVertical?: boolean;
    /**
     * If true it will have a right margin.
     */
    hasRightMargin?: boolean;
    /**
     * Extra classNames.
     */
    className?: string;
};

export const ProductThumb: FC<ProductThumbProps> = (props: ProductThumbProps) => {
    const {
        src,
        alt,
        onClick,
        onKeyPress,
        className,
        quantity,
        showMore,
        availability = 'na',
        forceVertical,
        hasRightMargin,
    } = props;

    let computedClassName = styles['product-thumb'];
    let imgClassName = styles['product-thumb__image'];

    if (hasRightMargin) {
        computedClassName += ` ${styles['product-thumb--right-margin']}`;
        imgClassName += ` ${styles['product-thumb--right-margin']}`;
    }

    if (forceVertical) {
        computedClassName += ` ${styles['product-thumb--vertical']}`;
        imgClassName += ` ${styles['product-thumb--vertical-img']}`;
    }

    if (className) {
        computedClassName += ` ${className}`;
        imgClassName += ` ${className}`;
    }

    if (showMore) {
        return (
            <div
                className={computedClassName}
                onClick={onClick}
                onKeyPress={onKeyPress}
                role="button"
                tabIndex={0}
            >
                <span className={styles['product-thumb__show-more']}>+ {showMore}</span>
                <Image className={styles['product-thumb__image']} src={src} alt={alt} />
            </div>
        );
    } else if (quantity && quantity > 1) {
        return (
            <div className={computedClassName}>
                <span className={styles['product-thumb__quantity']}>{quantity}</span>
                <Image className={styles['product-thumb__image']} src={src} alt={alt} />
            </div>
        );
    } else if (availability !== 'na') {
        computedClassName += ` ${styles[`product-thumb__${availability}`]}`;

        return (
            <div className={computedClassName}>
                <Image className={styles['product-thumb__image']} src={src} alt={alt} />
            </div>
        );
    }
    return (
        <div className={computedClassName}>
            <Image className={imgClassName} src={src} alt={alt} />
        </div>
    );
};
