import React from 'react';
import Modal from '../../common/Modal';
import styles from './DeliveryAlert.module.scss';
import { useTranslation } from 'react-i18next';

export type DeliveryAlertProps = {
    title: string;
    message?: string;
    footer?: React.ReactNode;
    onClose?: () => void;
    children?: React.ReactNode;
};

export const DeliveryAlert: React.FC<DeliveryAlertProps> = (props: DeliveryAlertProps) => {
    const { children, title, footer, message, onClose } = props;

    const [isOpen, setIsOpen] = React.useState(true);
    const handleClose = () => {
        setIsOpen(false);
        onClose?.();
    };

    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} header={t('notAvailable')} onToggle={handleClose} footer={footer}>
            <div className={styles['box']}>
                <div className={styles['header']}>
                    <h2 className={styles['title']}>{title}</h2>
                    {message && <div className={styles['message']}>{message}</div>}
                </div>
                <div className={styles['body']}>{children}</div>
            </div>
        </Modal>
    );
};
