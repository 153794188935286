import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AddressSummary.module.scss';
import Card from 'components/common/Card';

export type AddressSummaryProps = {
    /** It indicates the address */
    address: string;

    /** It indicates the province and region */
    additionalAddress?: string;

    /** It indicates the "to" attribute for the edit link */
    to: string | (() => void);

    /** Slot for a a button that moves the user to a "Pickup" screen */
    children?: React.ReactNode;
};

function AddressSummary(props: AddressSummaryProps): JSX.Element {
    const { address, additionalAddress, to, children } = props;
    const { t } = useTranslation();
    return (
        <Card title={t('delivery')} link={to}>
            <p className={styles['container__address']}>{address}</p>
            {additionalAddress && (
                <p className={styles['container__address']}>{additionalAddress}</p>
            )}
            {children && <div className={styles['container__children']}>{children}</div>}
        </Card>
    );
}

export default AddressSummary;
