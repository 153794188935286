import { createAsyncThunk } from '@reduxjs/toolkit';
import services from 'services';
import { fetchCartById } from 'store/cart';
import { RootState } from 'types/store';
import { getAddressComponentsWithNames as getAddress } from 'lib/utils/addresses';
import { OPEX_PAYMENT_METHODS } from 'consts';

/* Removes payments data */
export const resetPayments = createAsyncThunk(
    'paymentMethod/resetPayments',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const cartId = state.cart.data?.id;
        if (cartId) {
            await services.cart.resetPayments(cartId);
        }
    },
);

/**
 * Sets a dummy payment into the cart and reloads the cart
 */
export const setPaymentDummy = createAsyncThunk<
    void,
    SetPaymentDummyArg,
    {
        state: RootState;
        fulfilledMeta: { isOverwrite: boolean };
    }
>(
    'paymentMethod/setPaymentDummy',
    async ({ paymentMethod }, { dispatch, fulfillWithValue, getState }) => {
        const state = getState();
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('cart, user or address store are empty');
        }

        const cartId = state.cart.data.id;
        const payments = getPayments({
            paymentMethod,
            cart: state.cart.data,
            user: state.user.data,
            addresses: state.address.data,
            areas: state.area.data,
        });

        // NOTE: Cuando es copago se mantiene lo viejo, cuando es normal se sobreescribe.
        const isOverwrite = state.app.data?.paymentMode !== 'copago';
        await services.cart.setPaymentOnce(cartId, payments, /*isDummy*/ true, isOverwrite);
        await dispatch(fetchCartById());
        return fulfillWithValue(void 0, { isOverwrite });
    },
);

export type SetPaymentDummyArg = {
    paymentMethod: PaymentMethodName;
    cardToken?: CardToken;
};

export const getPayments = ({
    paymentMethod,
    cart,
    user,
    addresses,
    areas,
    customFields,
    card,
}: GetPaymentsArg): PaymentInfo[] => {
    const address = addresses.byId[addresses.selected];
    let price = cart.grandTotal['discount'] || cart.grandTotal['master'];

    if (OPEX_PAYMENT_METHODS.includes(paymentMethod)) {
        price = cart.grandTotal['ripley_promo'] || price;
    }

    return [
        {
            documentType: 'boleta', // TODO: hardcoded
            paymentMethod,
            billingInfo: {
                customer: {
                    nin: user.nin,
                    firstName: user.firstname,
                    lastName: user.lastname,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                },
                address: getAddress(address.addressComponents, areas),
            },
            amount: price,
            customFields: customFields == null ? null : customFields,
            cardId: card?.id,
        },
    ];
};

export type GetPaymentsArg = {
    paymentMethod: PaymentMethodName;
    cart: Cart;
    user: NormalizedUser;
    addresses: NormalizedAddresses;
    areas: ExtArea;
    customFields?: CustomFields;
    card?: CardToken;
};

/*
 * Resets paymentmethods and other relevant data every time the systems enters
 * into the payment methods page.
 */
export const restartPaymentMethodsPage = createAsyncThunk(
    'paymentMethod/restartPaymentMethodsPage',
    async (_, { dispatch }) => {
        await dispatch(resetPayments());
        // We need to check if coupons still apply.
        await dispatch(fetchCartById());
    },
);

/*
 * Triggers an browser reload. Used to ensure `state.paymentMethod.clean` is
 * false after reload and `restartPaymentMethodsPage` gets called before any
 * other action that waits for it.
 */
export const reloadPaymentMethodsPage = createAsyncThunk(
    'paymentMethod/reloadPaymentMethodsPage',
    async () => {
        location.reload();
    },
);
