import api from '../../api';
import { normalizeAreas } from './normalizer';

class AreaService {
    async getAreas(country: string): Promise<ExtArea> {
        const provider = api.bff;
        const response = await provider.getAreas(country);
        return normalizeAreas(response.data);
    }
}

export default AreaService;
