import React from 'react';
import { MASKED_PAN_SEPARATOR } from 'consts';

export type CreditCardTitleProps = {
    card: CardToken;
};

export const CreditCardTitle: React.FC<CreditCardTitleProps> = ({ card }: CreditCardTitleProps) => {
    if (
        card.maskedPan.includes('*') &&
        (card.paymentMethod === 'Niubiz' || card.paymentMethod === 'RipleyNiubiz')
    ) {
        return <span style={{ fontWeight: 500 }}>Nº {card.maskedPan}</span>;
    }
    // NOTE: not used by NiubizSelector
    const sections = card.maskedPan.split(MASKED_PAN_SEPARATOR);
    const maskedPan = sections[sections.length - 1];
    if (!maskedPan) return null;
    return <span style={{ fontWeight: 500 }}>Nº ···{maskedPan}</span>;
};
