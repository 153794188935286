import React, { useEffect } from 'react';

// Redux
import { useAppDispatch, useAppSelector } from '../../../lib/hooks/redux';

// Components
import Clock, { DateLimit } from 'components/common/Clock';
import { setDateLimit } from 'store/orderSummary';
import { getEnvironment } from 'config';
import { RootState } from 'types/store';

export const InStoreClock: React.FC = () => {
    const { payments } = getEnvironment();
    const dispatch = useAppDispatch();
    const dateLimit = useAppSelector((state: RootState) => state.orderSummary.dateLimit);

    useEffect(() => {
        if (!dateLimit.minutes) {
            const payLimit = new Date();
            const hoursToPay = parseInt(payments.inStoreHoursToPay);
            payLimit.setHours(payLimit.getHours() + hoursToPay);
            const date: DateLimit = {
                minutes: payLimit.getMinutes().toString(),
                hours: payLimit.getHours().toString(),
                day: payLimit.getDate().toString(),
                month: (payLimit.getMonth() + 1).toString(),
                year: payLimit.getFullYear().toString(),
            };
            dispatch(setDateLimit(date));
        }
    }, [dispatch, dateLimit, payments.inStoreHoursToPay]);
    return <Clock date={dateLimit} message="Pagar hasta" />;
};
