import React from 'react';
import { RouteProps, Route } from 'react-router-dom';

import { useAppSelector } from 'lib/hooks/redux';
import { URL } from 'consts';
import { RootState } from 'types/store';

const AppRoute: React.FC<RouteProps> = ({ children, path, ...rest }: RouteProps) => {
    // When cart is 'pending' redirect, except when on /checkout,
    // /order-summary or /redirect.
    const cart = useAppSelector((s: RootState) => s.cart);

    if (
        (cart.data?.status === 'pending' || cart.data?.status === 'cancel') &&
        path !== '/checkout' &&
        path !== '/order-summary' &&
        path !== '/redirect'
    ) {
        window.location.href = URL('REACT_APP_TIENDA_URL');
        return null;
    }
    return (
        <Route path={path} {...rest}>
            {children}
        </Route>
    );
};

export default AppRoute;
