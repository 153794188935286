import React, { useEffect, useRef, useState } from 'react';

import { country, getTermsAndConditionByCountry } from 'consts';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { validateAddress } from 'lib/utils/addresses';
import { useAnalytics } from 'lib/hooks/analytics';
import { useNiubiz3ds, usePayments } from 'lib/hooks/payments';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { useTranslation } from 'react-i18next';

import { initialize, updateDeviceId } from 'store/app';
import {
    separateProducts as deleteProducts,
    updateSeparatedProducts,
} from 'store/separatedProducts';
import { fetchCartById, setBillingInfo, setCustomer, setFingerprint } from 'store/cart';
import { setFulfillmentProducts } from 'store/fulfillment';
import Layout from 'components/aio/Layout';
import Button from 'components/common/Button';

import CartContainer from 'containers/CartContainer';
import DeliverySection from 'sections/DeliverySection';
import CouponSection from 'sections/CouponSection';
import PaymentSection from 'sections/PaymentSection';
import TaxDocumentSection from 'sections/TaxDocumentSection';
import SummarySection from 'sections/SummarySection';
import SeparatedProductsContainer from 'containers/aio/SeparatedProductsContainer';
import Terms from 'components/common/Terms';
import AlertBadge from 'components/common/AlertBadge';
import { useFaceId } from 'lib/hooks/faceId';
import { isRipleyPayment } from 'lib/utils/payments';
import { getTotals } from 'containers/CartContainer/CartContainer.utils';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

// Terms feature flag
export const termsWithCheckbox = true;

export const Home: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const niubiz3ds = useNiubiz3ds();
    const faceId = useFaceId();
    const [isLoading, setLoading] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [showSeparatedProducts, toggleModal] = useState(false);
    const [isCustomerSet, toggleCustomerSetting] = useState(false);
    const app = useAppSelector((state) => state.app.data);
    const address = useAppSelector((state) => state.address.data);
    const cart = useAppSelector((state) => state.cart);
    const areas = useAppSelector((state) => state.area);
    const user = useAppSelector((state) => state.user);
    const pm = useAppSelector((state) => state.paymentMethod);

    const payments = usePayments();
    const deliveryMethod = useDeliveryMethod();
    const analytics = useAnalytics();
    const paymentSection = useRef<HTMLElement>(null);
    const [alertMessage, setAlertMessage] = useState<null | string>(null);

    let totalAmount = 0;
    if (payments.areReady() && cart.data && pm.data) {
        const totals = getTotals(cart.data);
        if (isRipleyPayment(pm.data.selected)) {
            totalAmount = totals.ripley;
        } else {
            totalAmount = totals.normal;
        }
    }

    /**
     * If address is not null but there is no
     * addresses, render /create-address page
     */
    if (address && !address?.allIds.length) {
        history.push('/create-address');
    }

    /**
     * If address is not null and have addresses
     * in it, check if the selected address is valid
     */
    if (address?.allIds.length) {
        const selectedAC = address.byId[address.selected].addressComponents as AddressComponent[];
        const isAddressValid = validateAddress(country, selectedAC);
        if (!isAddressValid) {
            history.push('/create-address');
        }
    }

    useEffect(() => {
        analytics.push('begin_checkout');
    });

    useEffect(() => {
        if (alertMessage) {
            sendErrorToGA4({
                channel: cart.data?.channel,
                error: alertMessage,
                section: 'Home',
                type: 'Checkout_presentational_error',
            });
        }
    }, [alertMessage]);

    useEffect(() => {
        // Set the customer info when the cart, user and areas are OK
        if (!isCustomerSet && cart?.status == 'ok' && user.status == 'ok' && areas.status == 'ok') {
            dispatch(setCustomer());
            toggleCustomerSetting(true);
        }
    }, [isCustomerSet, cart, user, areas, dispatch]);

    /**
     * Handles the click of the continue button
     */
    const onContinue = () => {
        (async () => {
            try {
                if (!termsChecked && termsWithCheckbox) {
                    return;
                }

                const unavailable = await dispatch(updateSeparatedProducts()).unwrap();
                if (unavailable.length) {
                    toggleModal(true);
                    return;
                }

                setLoading(true);
                await dispatch(setFulfillmentProducts());
                await dispatch(setCustomer()).unwrap();
                await dispatch(setBillingInfo()).unwrap();
                await dispatch(setFingerprint()).unwrap();
                await dispatch(fetchCartById()).unwrap();
                analytics.push('add_payment_info');

                if (pm.data.selected.includes('Alignet')) {
                    history.push({ pathname: '/redirect', state: { pm: 'alignet' } });
                    return;
                }

                if (
                    pm.data.selected.includes('WebpayDebito') ||
                    pm.data.selected.includes('WebpayCredito')
                ) {
                    history.push('/redirect');
                    return;
                }

                if (pm.data.selected.includes('RipleyChek')) {
                    history.push('/authorize');
                    return;
                }

                if (pm.data.selected.includes('Niubiz3DS')) {
                    setLoading(false);
                    await niubiz3ds.openModal();
                    return;
                }

                if (pm.data.selected.includes('RipleyCard') && app?.paymentWithoutCvvEnabled) {
                    await handleFaceIdPost();
                    return;
                }

                history.push('/checkout');
            } catch (err) {
                setLoading(false);
                throw err;
            }
        })();
    };

    /**
     * It removes products that are not available
     * and restarts the application.
     */
    const handleDeleteProducts = async () => {
        await dispatch(deleteProducts());
        await dispatch(initialize());
        toggleModal(false);
    };

    /**
     * Handles communication for Face ID validation
     */
    const handleFaceIdPost = async () => {
        try {
            const resp = await faceId.post();

            if (resp.id) {
                updateDeviceId(resp.id);
            }

            setLoading(false);
            history.push('/checkout');
        } catch (e) {
            const err = e as Error;
            setLoading(false);
            if (err.message) {
                setAlertMessage(err.message);
            }
        }
    };

    return (
        <Layout
            content={
                <>
                    <DeliverySection />
                    <CouponSection />
                    <PaymentSection ref={paymentSection} />
                    <TaxDocumentSection />
                    <SummarySection />
                    {showSeparatedProducts && (
                        <SeparatedProductsContainer
                            onContinue={handleDeleteProducts}
                            onClose={async () => {
                                toggleModal(false);
                            }}
                        />
                    )}
                </>
            }
            side={
                <CartContainer
                    alert={
                        alertMessage && (
                            <AlertBadge
                                theme="danger"
                                size="large"
                                timeout={5000}
                                callback={() => {
                                    setAlertMessage(null);
                                }}
                            >
                                {alertMessage}
                            </AlertBadge>
                        )
                    }
                    terms={
                        <Terms
                            id="confirmation-terms-checkbox"
                            onChange={setTermsChecked}
                            termsLocation={getTermsAndConditionByCountry(country)}
                            withCheckbox={termsWithCheckbox}
                        />
                    }
                >
                    <Button
                        size="large"
                        theme="primary"
                        onClick={onContinue}
                        disabled={
                            (termsWithCheckbox && !termsChecked) ||
                            !deliveryMethod.isReady() ||
                            !payments.areReady() ||
                            isLoading ||
                            totalAmount <= 0
                        }
                        loading={isLoading}
                    >
                        {pm.data?.selected[0] == 'InStore' ? t('reserveOrder') : t('pay')}
                    </Button>
                </CartContainer>
            }
        />
    );
};
