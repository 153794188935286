import React from 'react';
import Card from 'components/common/Card';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import Row from 'components/common/Skeleton';
import { country } from 'consts';
import useWindowSize from 'lib/hooks/useWindowSize';

function Skeleton(): JSX.Element {
    return (
        <Card title="Medio de Pago">
            <div style={{ paddingTop: '1.5em' }}>
                {country == 'CL' ? (
                    <>
                        <Item />
                        <Item />
                        <Item />
                        <Item />
                    </>
                ) : (
                    <>
                        <Item />
                        <Item />
                    </>
                )}
            </div>
        </Card>
    );
}

function Item(): JSX.Element {
    const [width] = useWindowSize();
    const isMobile = width <= 768;

    return (
        <PaymentMethodItem
            paymentMethodName=""
            type="credito"
            widthIcon="63px"
            onClick={() => {}}
            additionalInfo={<Row width={isMobile ? 90 : 50} />}
            disabled
            blocked
        />
    );
}

export default Skeleton;
