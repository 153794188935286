import slice, {
    fetchStores,
    getSelectedStore,
    getStocks,
    setStocksIdle,
    setRegionId,
    setProvinceId,
    setCityId,
    setPickupForm,
    setStore,
    setStoreForm,
    setProvisionalStore,
} from './store.slice';
export {
    fetchStores,
    getSelectedStore,
    getStocks,
    setStocksIdle,
    setRegionId,
    setProvinceId,
    setCityId,
    setPickupForm,
    setStore,
    setStoreForm,
    setProvisionalStore,
};
export default slice.reducer;
