import { CaseReducer, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { deleteCard } from 'store/card/actions';
import { setCardByToken as setTokenCyb } from 'store/card/cybersource';
import { setCardByToken as setTokenRipley } from 'store/card/ripleyCard.slice';
import { setPaymentDummy, resetPayments } from 'store/paymentMethod/actions';
import { setPaymentMethod as setCybersource } from 'store/paymentMethod/cybersource.slice';
import { setPaymentMethod as setInStore } from 'store/paymentMethod/inStore.slice';
import {
    setPaymentWithoutCvv,
    setPaymentMethod as setRipleyCard,
} from 'store/paymentMethod/ripleyCard.slice';
import { setPaymentMethod as setRipleyChek } from 'store/paymentMethod/ripleyChek.slice';
import { setPaymentMethod as setWebpayCredito } from 'store/paymentMethod/webpayCredito.slice';
import { setPaymentMethod as setWebpayDebito } from 'store/paymentMethod/webpayDebito.slice';
import { setPaymentMethod as setRipleyGiftCard } from 'store/paymentMethod/ripleyGiftCard.slice';

import { RootState } from 'types/store';
import { MOBILE } from 'consts';

export const disablePaymentWithoutCvv = createAction('app/disablePaymentWithoutCvv');

export const fetchCardsByUser = createAsyncThunk(
    'cardToken/fetchByUser',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const rules = state.rule.data;
        const appData = state.app.data;
        const isMobile = appData?.channel == MOBILE ? true : false;
        // Por seguridad usamos el deviceId solo en mobile
        const deviceId =
            isMobile && appData?.deviceId && rules.paymentWithoutCvv ? appData.deviceId : '';

        if (state.user.data?.registered) {
            return await services.card.fetchByUser(deviceId);
        }
        // TODO: handle guest as an error instead of returning empty data.
        return { byId: {}, allIds: [], selected: [], loading: [] };
    },
);

const initialState: CardTokenState = {
    status: 'idle',
    data: { byId: {}, allIds: [], selected: [], loading: [] },
    error: null,
};

const deselectFn: CaseReducer<CardTokenState> = (state: CardTokenState) => {
    state.data.selected = [];
};

export default createSlice({
    name: 'cardToken',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPayments.fulfilled, (state) => {
            state.data.selected = [];
        });
        builder.addCase(fetchCardsByUser.pending, (state: CardTokenState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchCardsByUser.rejected, (state, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(fetchCardsByUser.fulfilled, (state, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });

        builder.addCase(deleteCard.fulfilled, (state, { meta }) => {
            const { hard, cardId } = meta.arg;
            if (hard) {
                const index = state.data.allIds.indexOf(cardId);
                if (index >= 0) {
                    state.data.allIds.splice(index, 1);
                    delete state.data.byId[cardId];
                }
            }
            const selIndex = state.data.selected.indexOf(meta.arg.cardId);
            if (selIndex >= 0) {
                state.data.selected.splice(selIndex, 1);
            }
        });

        builder.addCase(setPaymentDummy.fulfilled, (state, { meta }) => {
            const cardId = meta.arg.cardToken?.id;
            if (cardId) {
                state.data.selected.push(cardId);
            }
        });

        builder.addCase(setTokenCyb.fulfilled, (state, { meta }) => {
            const i = state.data.selected.indexOf(meta.arg.card.id);
            if (i >= 0) {
                state.data.selected.splice(i, 1);
            }
        });

        builder.addCase(setTokenRipley.fulfilled, (state, { meta }) => {
            const i = state.data.selected.indexOf(meta.arg.card.id);
            if (i >= 0) {
                state.data.selected.splice(i, 1);
            }
        });

        /*
         * Deselect after other payment methods are set
         */
        builder.addCase(setCybersource.pending, deselectFn);
        builder.addCase(setPaymentDummy.pending, deselectFn);
        builder.addCase(setRipleyCard.pending, deselectFn);
        builder.addCase(setRipleyChek.pending, deselectFn);
        builder.addCase(setRipleyGiftCard.pending, deselectFn);
        builder.addCase(setInStore.pending, deselectFn);
        builder.addCase(setWebpayCredito.pending, deselectFn);
        builder.addCase(setWebpayDebito.pending, deselectFn);
        builder.addCase(setPaymentWithoutCvv.pending, deselectFn);

        builder.addCase(setPaymentWithoutCvv.rejected, (state, { error }) => {
            if (error.code == 'errCVVRequired') {
                state.data.allIds.forEach((id) => {
                    const card = state.data.byId[id];
                    if (card && card.settings) {
                        card.settings.rules.cvvFreeAvailable = false;
                    }
                });
            }
        });

        builder.addCase(disablePaymentWithoutCvv, (state) => {
            state.data.allIds.forEach((id) => {
                const card = state.data.byId[id];
                if (card && card.settings) {
                    card.settings.rules.cvvFreeAvailable = false;
                }
            });
        });
    },
});

export type CardTokenState = {
    status: 'idle' | 'pending' | 'error' | 'ok';
    data: NormalizedCardTokens;
    error: SerializedError | null;
};
