/**  Capitalizes first letter of string */
export function capitalize(text: string): string {
    return `${text[0].toUpperCase()}${text.slice(1)}`;
}

/** Converts to title case: "SOME text" to "Some Text" */
export function toTitleCase(text: string): string {
    return text.replace(/\w\S*/g, (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
}
