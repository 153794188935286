import React from 'react';

import PickupSummary from 'containers/aio/PickupSummaryContainer';
import AddressSummaryContainer from 'containers/aio/AddressSummaryContainer';
import ShippingListContainer from 'containers/aio/ShippingListContainer';
import { useAppSelector } from 'lib/hooks/redux';
import { RT } from 'consts';
import { RootState } from 'types/store';

export const DeliverySection: React.FC = () => {
    const method = useAppSelector((state: RootState) => state.deliveryMethod);

    if (method.selected === RT) {
        return (
            <section>
                <PickupSummary />
            </section>
        );
    }

    return (
        <section>
            <AddressSummaryContainer />
            <ShippingListContainer />
        </section>
    );
};
