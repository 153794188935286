import React, { useEffect, useMemo, useState } from 'react';

import { CL, KIOSKO, PE } from 'consts';

import { useAnalytics } from 'lib/hooks/analytics';
import { useAppSelector } from 'lib/hooks/redux';
import { useRules } from 'lib/hooks/rules';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';
import { useTranslation } from 'react-i18next';

import Card from 'components/common/Card';
import Title from 'components/common/Title/Title';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import AlertBadge from 'components/common/AlertBadge';

import GiftCardAdder from 'containers/payment/GiftCardAdder';
import CybersourceAdder from 'containers/payment/CybersourceAdder';
import CardAdder from 'containers/payment/CardAdder';
import NiubizSelector from 'containers/payment/NiubizSelector';

import CardSelector from 'containers/payment/CardSelector';
import ChekSelector from 'containers/payment/ChekSelector';
import InStoreSelector from 'containers/payment/InStoreSelector';
import TokenSelector from 'containers/payment/TokenSelector';
import WebpayCreditSelector from 'containers/payment/WebpayCreditSelector';
import WebpayDebitSelector from 'containers/payment/WebpayDebitSelector';
import AlignetSelector from 'containers/payment/AlignetSelector';
import Skeleton from './Skeleton';
import { ErrorContext } from './context';
import { getEnvironment } from 'config';
import Niubiz3dsSelector from 'containers/payment/Niubiz3dsSelector';
import { RootState } from 'types/store';

export const PaymentMethodsContainer: React.FC = () => {
    const { app } = getEnvironment();
    const { t: translate } = useTranslation('translation');
    const country = (app.country?.toUpperCase() as Country) || CL;
    const analytics = useAnalytics();
    const rules = useRules();
    const user = useAppSelector((state: RootState) => state.user.data);
    const cart = useAppSelector((state: RootState) => state.cart);
    const channel = cart?.data?.channel;
    const tokenData = useAppSelector((state: RootState) => state.cardToken.data);
    const tokens = Object.values(tokenData.byId);
    const niubizTokens = tokens.filter((card: CardToken) => card.paymentMethod === 'Niubiz');
    const ripleyTokens = tokens.filter((card: CardToken) => card.isRipley === true);
    const cybersourceTokens = tokens.filter(
        (card: CardToken) => card.paymentMethod === 'Cybersource',
    );
    const isCopago = useAppSelector((state: RootState) => state.app.data?.paymentMode === 'copago');
    const allowRemember = rules.feature('cardOnFile') && channel != KIOSKO && user?.registered;
    const businessRules = useAppSelector((state) => state.rule.data);

    const [error, setError] = useState<string | null>(null);
    const ctxValue = useMemo(() => ({ error, setError }), [error]);

    const allowCards =
        rules.feature('RipleyCard') ||
        rules.feature('Cybersource') ||
        rules.feature('Niubiz') ||
        rules.feature('RipleyNiubiz');

    useEffect(() => {
        if (country === CL && !rules.feature('RipleyGiftCard')) {
            analytics.push('checkout_error', 'giftcard_guest');
        }
    }, [rules, analytics, country]);

    useEffect(() => {
        if (error) {
            sendErrorToGA4({
                channel: cart.data?.channel,
                error: error,
                section: 'PaymentSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [error]);

    /**
     * Shows the payment methods for Chile
     */
    const CLMethods = (
        <>
            {allowCards && <CardSelector />}
            {allowRemember && <TokenSelector />}
            {rules.feature('RipleyCard') && ripleyTokens.length === 0 && (
                <CardAdder
                    iconType="ripleycredito"
                    paymentMethodTitle="T. Crédito Ripley"
                    header="Ripley Crédito"
                    paymentMethodName="RipleyCard"
                    name="Ripley"
                />
            )}
            {rules.feature('WebpayCredito') && <WebpayCreditSelector />}
            {rules.feature('WebpayDebito') && <WebpayDebitSelector />}
            {rules.feature('RipleyChekCard') && (
                <CardAdder
                    iconType="chekcredito"
                    paymentMethodTitle="T. Crédito Chek"
                    header="Tarjeta Crédito Chek"
                    paymentMethodName="RipleyChekCard"
                    name="Chek"
                />
            )}
            {rules.feature('RipleyChek') && <ChekSelector />}
            {rules.feature('RipleyGiftCard') ? (
                <GiftCardAdder />
            ) : (
                <PaymentMethodItem
                    paymentMethodName="Gift Card"
                    type="giftcard"
                    widthIcon="63px"
                    onClick={() => {}}
                    disabled={translate('giftCardDisabled')}
                />
            )}
            {rules.feature('Cybersource') && cybersourceTokens.length === 0 && <CybersourceAdder />}
            {rules.feature('InStore') && <InStoreSelector />}
        </>
    );

    /**
     * Shows the payment methods for Perú
     */
    const PEMethods = (
        <>
            {allowCards && <CardSelector />}
            {allowRemember && <TokenSelector />}
            {rules.feature('Alignet') && <AlignetSelector />}
            {rules.feature('Niubiz') && niubizTokens.length === 0 && (
                <NiubizSelector isRipley={false} />
            )}
            {rules.feature('Niubiz3DS') && <Niubiz3dsSelector />}
            {rules.feature('InStore') && <InStoreSelector />}
        </>
    );

    /**
     * Shows the payment methods available for Copago mode
     */
    const Copago = (
        <>
            <GiftCardAdder />

            <div style={{ marginTop: '.5em' }}>
                <Title size="medium">Elige un copago</Title>

                {rules.feature('RipleyCard') ? (
                    <>
                        {allowCards && <CardSelector />}
                        {allowRemember && <TokenSelector />}
                        <CardAdder
                            iconType="ripleycredito"
                            paymentMethodTitle="T. Crédito Ripley"
                            header="Ripley Crédito"
                            paymentMethodName="RipleyCard"
                            name="Ripley"
                        />
                    </>
                ) : (
                    <PaymentMethodItem
                        paymentMethodName="Ripley Card"
                        type="webpaydebito"
                        widthIcon="40px"
                        onClick={() => {}}
                        disabled={true}
                    />
                )}
            </div>
        </>
    );

    if (businessRules.payments.length == 0) {
        return <Skeleton />;
    }

    return (
        <Card title="Medio de Pago" isSection>
            <ErrorContext.Provider value={ctxValue}>
                <p style={{ marginBottom: '-0.5px' }}>
                    Selecciona un medio de pago para continuar con tu compra
                </p>
                {country === 'PE' && (
                    <div style={{ display: 'block', marginTop: '1em' }}>
                        <AlertBadge theme="info">
                            Recuerda activar tu tarjeta para compras por internet antes de realizar
                            el pago.
                        </AlertBadge>
                    </div>
                )}

                {!!error && (
                    <div style={{ display: 'block', marginTop: '1em' }}>
                        <AlertBadge theme="info" timeout={5000} callback={() => setError(null)}>
                            {error}
                        </AlertBadge>
                    </div>
                )}

                <div style={{ paddingTop: '1.5em' }}>
                    {isCopago && Copago}
                    {!isCopago && country === CL && CLMethods}
                    {!isCopago && country === PE && PEMethods}
                    {!isCopago && (
                        <>
                            {Object.keys(tokens).length > 0 && (
                                <Title size="small">Agregar medios de pago</Title>
                            )}
                            {country == CL &&
                                ripleyTokens.length > 0 &&
                                rules.feature('RipleyCard') && (
                                    <CardAdder
                                        iconType="ripleycredito"
                                        paymentMethodTitle="T. Crédito Ripley"
                                        header="Ripley Crédito"
                                        paymentMethodName="RipleyCard"
                                        name="Ripley"
                                    />
                                )}

                            {country == CL &&
                                cybersourceTokens.length > 0 &&
                                rules.feature('Cybersource') && <CybersourceAdder />}

                            {country == PE &&
                                niubizTokens.length > 0 &&
                                rules.feature('Niubiz') && <NiubizSelector isRipley={false} />}
                        </>
                    )}
                </div>
            </ErrorContext.Provider>
        </Card>
    );
};
