import React from 'react';
import styles from './EmptySection.module.scss';

import Card from '../Card';
import { ReactComponent as Icon } from './info.svg';

type EmptySectionProps = {
    title: string;
    text?: string;
};

export const EmptySection: React.FC<EmptySectionProps> = (props: EmptySectionProps) => {
    return (
        <Card title={props.title}>
            <div className={styles['content']}>
                <div className={styles['icon']}>
                    <Icon />
                </div>
                <p>
                    {!props.text
                        ? 'Debes completar los datos del paso anterior para habilitar esta opción.'
                        : props.text}
                </p>
            </div>
        </Card>
    );
};
