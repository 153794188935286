import data from './data';

/**
 * `BFFMock' simulates the connection with the BFF API
 */
class BFFMock implements BFFImpl {
    http = null;
    timeout = 50;
    meta = {
        country: 'cl',
        sessionId: '00009UR25_gBWQUm8T4-Q7Dmdqb:1atp2n8q7',
        requestId: '6daaf4ee-699c-4c9a-9599-1b7dd3ee9acc',
    };

    /**
     * `getAreas` returns the geo info for a given country
     */
    async getAreas(): Promise<HttpResponse<ExtArea>> {
        const response = data.area as HttpResponse<ExtArea>;
        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }

    /**
     * `authenticate` returns the auth info
     */
    async authenticate(): Promise<HttpResponse<ExtAuth>> {
        const response = data.auth as HttpResponse<ExtAuth>;
        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }

    /**
     * `getStores` returns all the stores for a given country
     */
    async getStores(): Promise<HttpResponse<ExtStore[]>> {
        const response = data.store as HttpResponse<ExtStore[]>;
        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }

    /**
     * `getStoresStock` returns the stock of products at stores near a given city
     */
    async getStoresStock(): Promise<HttpResponse<ExtStoreStock[]>> {
        const response = data.storeStock as HttpResponse<ExtStoreStock[]>;
        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }

    /**
     * `getUser' returns the information of a logged user
     * @returns
     */
    async getUser(): Promise<HttpResponse<ExternalUser>> {
        const response = data.user as HttpResponse<ExternalUser>;
        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }
    /**
     * `getCart' returns the information of a cart
     * @param cartId
     * @returns
     */
    async getCart(): Promise<HttpResponse<ExtCart>> {
        const response = data.cart as HttpResponse<ExtCart>;
        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }
    /**
     * `getSchedule' returns the information of the schedules of a cart
     * @param cartId
     * @returns
     */
    async getSchedule(payload: GetSchedulePayload): Promise<HttpResponse> {
        const schedule = payload.delivery_method === 'DP' ? data.schedule : data.schedulePickup;
        return await new Promise((resolve) => setTimeout(() => resolve(schedule), this.timeout));
    }

    /**
     * `getDeliveryMethods' returns the available delivery methods
     * based on the products in the cart
     * @returns
     */
    async getDeliveryMethods(): Promise<HttpResponse<BFFDeliveryMethods>> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.deliveryMethods), this.timeout),
        );
    }

    /**
     * `getCards' returns the information of the cards of a user
     * @returns
     */
    async getCards(): Promise<HttpResponse> {
        return await new Promise((resolve) => setTimeout(() => resolve(data.cards), this.timeout));
    }

    async setCustomer(): Promise<HttpResponse> {
        return await new Promise((r) => setTimeout(() => r(data.ok), this.timeout));
    }

    /**
     * `setSchedule' returns the information of a cart with the schedule setted
     * @param cartId
     * @returns
     */
    async setSchedule(): Promise<HttpResponse> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.scheduleSetted), this.timeout),
        );
    }

    async setBillingInfo(): Promise<HttpResponse> {
        return await new Promise((resolve) => {
            setTimeout(() => resolve(data.billingInfo), this.timeout);
        });
    }

    /**
     * `setPayment' returns the information of a cart with a payment setted
     * @param cartId
     * @returns
     */
    async setPayment(cartId: string, payload: PaymentInfo[]): Promise<HttpResponse<PaymentInfo[]>> {
        const paymentMethod = payload[0].paymentMethod;
        let response: HttpResponse;

        switch (paymentMethod) {
            case 'WebpayDebito':
                response = data.setPaymentWebpay;
                break;
            case 'RipleyChek':
                response = data.setPaymentChek;
                break;
            case 'InStore':
                response = data.setInStore;
                break;
            case 'RipleyGiftCard':
                response = data.setRipleyGiftCard;
                break;
            default:
                response = data.setPayment;
                break;
        }

        return await new Promise((resolve) => setTimeout(() => resolve(response), this.timeout));
    }

    /**
     * `addCoupon' add a coupon to the cart
     * @returns
     */
    async setCoupons(cartId: string, payload: string[]): Promise<HttpResponse<string[]>> {
        data.coupons.data = payload;
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.coupons as HttpResponse<string[]>), this.timeout),
        );
    }

    /**
     * `getPayment' query the status of a payment
     * @returns
     */
    async getPayment(): Promise<HttpResponse<PaymentInfo>> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.payment as HttpResponse<PaymentInfo>), this.timeout),
        );
    }

    /**
     * `getPaymentStatus' query the status of a Alignet payment
     * @returns
     */
    async getPaymentStatus(): Promise<HttpResponse<PaymentInfo>> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.payment as HttpResponse<PaymentInfo>), this.timeout),
        );
    }

    /**
     * `evaluate' cart order
     * @returns
     */
    async evaluate(): Promise<HttpResponse<PaymentInfo>> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.payment as HttpResponse<PaymentInfo>), this.timeout),
        );
    }

    /**
     * `doCheckout` returns the envelop of the checkout process
     * @returns
     */
    async doCheckout(): Promise<HttpResponse> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(data.checkout), this.timeout),
        );
    }

    async createUser(createUserPayload: CreateUserPayload): Promise<HttpResponse> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(createUserPayload as unknown as HttpResponse), this.timeout),
        );
    }

    async createAddress(payload: ExternalAddressComponents[]): Promise<HttpResponse> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(payload as unknown as HttpResponse), this.timeout),
        );
    }

    async deleteAddress(nickname: string): Promise<HttpResponse> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(nickname as unknown as HttpResponse), this.timeout),
        );
    }

    async modifyAddress(payload: ExternalAddressComponents[]): Promise<HttpResponse> {
        return await new Promise((resolve) =>
            setTimeout(() => resolve(payload as unknown as HttpResponse), this.timeout),
        );
    }

    /**
     * Removes products from a cart
     */
    async deleteProducts(): Promise<HttpResponse> {
        const response = {
            httpStatus: 200,
            data: null,
            error: null,
            meta: this.meta,
        };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async fetchRules(): Promise<HttpResponse<Rules>> {
        const response = {
            httpStatus: 200,
            data: data.rules,
            error: null,
            meta: this.meta,
        };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async giftCardChangePassword(): Promise<HttpResponse> {
        const response = {
            httpStatus: 200,
            data: null,
            error: null,
            meta: this.meta,
        };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async getSimulation(): Promise<HttpResponse> {
        const response = {
            httpStatus: 200,
            data: {
                cae: '10000',
                total: '1000',
                monthly: '1000',
            },
            error: null,
            meta: this.meta,
        };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async setFulfillment(): Promise<HttpResponse> {
        const response = { httpStatus: 200, data: null, error: null, meta: this.meta };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async setFulfillmentProducts(): Promise<HttpResponse> {
        const response = { httpStatus: 200, data: null, error: null, meta: this.meta };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async sendLog(payload: LogPayload): Promise<HttpResponse> {
        const response = { httpStatus: 200, data: payload, error: null, meta: this.meta };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async getCtsRegistry(): Promise<HttpResponse> {
        const response = { httpStatus: 200, data: data.cts, error: null, meta: this.meta };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }

    async deleteCtsRegistry(): Promise<HttpResponse> {
        const response = { httpStatus: 200, data: { message: 'OK' }, error: null, meta: this.meta };
        return await new Promise((r) => setTimeout(() => r(response), this.timeout));
    }
}

export default BFFMock;
