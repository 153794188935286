import Cookies from 'js-cookie';
import { differenceInHours } from 'date-fns';

import { ENDPOINTS_ERRORS } from 'consts';
import { getEnvironment } from 'config';

const KEY_ERROR = 'currentError';

export async function sendErrorToGA4({ channel, error, section, type }: SendErrorPayload) {
    const {
        app: { isActiveSendErrorToGA4 },
    } = getEnvironment();

    if (isActiveSendErrorToGA4 && window.gtag && typeof window.gtag === 'function' && error) {
        let params: PayloadSendError = {
            label: type,
            channel: channel ?? 'Tienda Virtual',
            date: new Date().toISOString(),
            id_del_usuario: Cookies.get('RIPLEY_CUSTOMER_ID'),
            cart_id: Cookies.get('NMKR_TRA'),
            section: section,
        };

        let msgError = '';

        if (typeof error === 'string') {
            msgError = error;
            params = { ...params, error };
        } else {
            msgError = error.message;
            params = {
                ...params,
                error: JSON.stringify(error),
            };
        }

        const errorStorage: ErrorStorage = {
            error: msgError,
            date: new Date(),
        };

        if (verifyLastError(errorStorage)) {
            window.gtag('event', 'checkout_error', params);
        }
    }
}

export function validateEndpoint(url: string): boolean {
    for (const endpoint of ENDPOINTS_ERRORS) {
        if (endpoint.toLocaleLowerCase().includes(url)) {
            return true;
        }
    }

    return false;
}

export function verifyLastError(error: ErrorStorage): boolean {
    const currentError = localStorage.getItem(KEY_ERROR);

    if (!currentError) {
        localStorage.setItem(KEY_ERROR, JSON.stringify(error));
        return true;
    }

    const errorToObject = JSON.parse(currentError);
    const errorDate = new Date(errorToObject.date);
    const currentDate = error.date;

    if (differenceInHours(currentDate, errorDate) >= 1 || error.error !== errorToObject.error) {
        localStorage.removeItem(KEY_ERROR);
        localStorage.setItem(KEY_ERROR, JSON.stringify(error));

        return true;
    }

    return false;
}
