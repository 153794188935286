import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { addCoupon, CouponPayload, deleteCoupon } from 'store/coupon';
import { fetchCartById } from 'store/cart';
import CouponForm from 'components/payment/CouponForm';
import ButtonField from 'components/common/ButtonField';
import { resetPayments } from 'store/paymentMethod/paymentMethod.slice';
import { useTranslation } from 'react-i18next';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export const CouponFormContainer: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const cartId = useAppSelector((state: RootState) => state.cart.data?.id);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const prevCoupons = useAppSelector((state: RootState) => state.cart.data?.coupons) || [];
    const couponsInfo = useAppSelector((state: RootState) => state.cart.data?.couponsInfo);

    // TODO: This won't allow us to display the coupons because we only know
    //       the code and not the conditions or possible error.
    // const currentCoupons = useAppSelector((state: RootState) => state.coupon.data) || prevCoupons; // If currentCoupons is null use prevCoupons
    const isLoading = useAppSelector((state: RootState) => state.coupon.status === 'pending');
    const [couponValue, setCouponValue] = useState<string>('');
    const [invalid, setInvalid] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const bottomMessage = (
        <>
            <p>
                *Asegúrate de ingresar el cupón antes de <b>seleccionar</b> el medio de pago.
            </p>
        </>
    );

    useEffect(() => {
        if (errorMsg) {
            sendErrorToGA4({
                channel: channel,
                error: errorMsg,
                section: 'CouponSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [errorMsg]);

    // Handles the add coupons errors
    const addCouponError = (errMsg: string) => {
        setCouponValue('');
        setInvalid(true);
        setErrorMsg(errMsg);
    };

    // Handles the click event on add coupon
    const handleAddCouponsOnSubmit = async () => {
        setErrorMsg('');
        setInvalid(false);
        const coupon = couponValue.toUpperCase();

        // Validate that the coupon field is not empty
        if (!coupon) {
            addCouponError(t('addCouponErr'));
            return;
        }

        // Validate that no more than 2 coupons are added
        if (prevCoupons.length >= 2) {
            addCouponError(t('maxOfTwoCouponsErr'));
            return;
        }

        // Validate that the same coupon is not added 2 times
        if (prevCoupons.includes(coupon)) {
            addCouponError(t('sameCouponErr'));
            return;
        }

        if (coupon.length > 30) {
            addCouponError(t('maxLengthCouponErr'));
            return;
        }

        if (coupon.trim() === '') {
            addCouponError(t('expiredOrInvalidCouponErr'));
            return;
        }

        // Add Coupon
        if (
            cartId != null // validate that the cart exist
        ) {
            // Payload construction
            const addCouponPayload: CouponPayload = { cartId, prevCoupons, coupon };
            // 1) Add coupon
            // 2) Reset the payments to recalculate the new prices
            // 3) Fetch the cart with the new prices
            try {
                await dispatch(addCoupon(addCouponPayload)).unwrap();
                await dispatch(resetPayments());
                await dispatch(fetchCartById());
                setCouponValue('');
            } catch {
                setInvalid(true);
                setErrorMsg(t('expiredOrInvalidCouponErr'));
            }
        }
    };

    const handleDeleteCoupon = async (coupon: string) => {
        if (cartId && prevCoupons) {
            const deleteCouponPayload: CouponPayload = { cartId, prevCoupons, coupon };
            // 1) Set loading to true
            // 2) Delete coupon
            // 3) Reset the payments to recalculate the new prices
            // 4) Fetch the cart with the new prices
            setInvalid(false);
            await dispatch(deleteCoupon(deleteCouponPayload));
            await dispatch(resetPayments());
            await dispatch(fetchCartById());
        }
    };

    return (
        <>
            <CouponForm
                theme="section"
                sectionTitle="Cupones"
                title="Agrega cupón de descuento"
                fields={[
                    {
                        // add coupon field
                        component: (
                            <ButtonField
                                value={couponValue}
                                placeholder="Cupón de descuento"
                                key="0"
                                onSubmit={handleAddCouponsOnSubmit}
                                onChange={setCouponValue}
                                invalid={invalid}
                                errorMsg={invalid ? errorMsg : ''}
                                isLoading={isLoading}
                            />
                        ),
                    },
                ]}
                bottomMessage={bottomMessage}
                currentCoupons={couponsInfo || []}
                handleDeleteCoupon={handleDeleteCoupon}
            />
        </>
    );
};
