import * as React from 'react';
import styles from './Collapsible.module.scss';

export type CollapsibleProps = {
    /**
     * Sets the content of a non-collapsible section at the top of the component
     */
    header?: React.ReactNode;
    /**
     * Sets the content of a non-collapsible section at the bottom of the component
     */
    footer?: React.ReactNode;
    /**
     * Semantic styles
     */
    theme?: 'none' | 'warning' | 'danger' | 'info' | 'success';
    /**
     * Sets if the list is collapsed or not
     */
    open?: boolean;
    /**
     * Callback triggered when the header bar or its arrow is clicked
     */
    onToggle?: () => void;
    /**
     * Sets the collapsible content of the component
     */
    children?: React.ReactNode;
};

export const Collapsible: React.FC<CollapsibleProps> = (props: CollapsibleProps) => {
    let wrapperClass = styles['wrapper'] + ' ' + (props.open ? styles['open'] : '');
    if (props.theme) {
        wrapperClass += ' ' + styles[props.theme];
    }
    return (
        <div className={wrapperClass}>
            <div
                className={styles['header']}
                onClick={props.onToggle}
                onKeyDown={() => {}}
                role="switch"
                aria-expanded={props.open}
                aria-checked={props.open}
                tabIndex={0}
            >
                <div className={styles['header__content']}>{props.header}</div>
                <div className={styles['collapse-icon']} />
            </div>
            <div className={styles['collapsible']}>{props.children}</div>
            {props.footer && <div className={styles['footer']}>{props.footer}</div>}
        </div>
    );
};

Collapsible.defaultProps = {
    open: false,
    theme: 'none',
};
