import React, { CSSProperties } from 'react';
import styles from './RadioButton.module.scss';

export type RadioButtonProps = {
    checked: boolean;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    id: string;
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    style?: CSSProperties;
    value: string;
    variant?: 'donut' | 'check' | 'filled';
};

export const RadioButton = React.forwardRef(function RadioButton(props: RadioButtonProps, ref) {
    const {
        className = '',
        children,
        name,
        id,
        value,
        style = {},
        checked = false,
        disabled = false,
        variant = 'filled',

        onChange = () => {},
    } = props;

    return (
        <label htmlFor={id} className={`${styles.radioButton} ${styles[variant]} ${className}`}>
            <input
                type="radio"
                name={name}
                id={id}
                value={value}
                style={style}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                data-testid={id}
                ref={ref as React.LegacyRef<HTMLInputElement>}
            />
            <span>{children}</span>
        </label>
    );
});

export default RadioButton;
