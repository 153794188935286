import React from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { getLevels } from 'lib/utils/addresses';

import { setCityId, setProvinceId, setRegionId, setStocksIdle, getStocks } from 'store/store';

import { LocationForm, AddressLevel } from 'components/pickup/LocationForm';
import { RootState } from 'types/store';

export const LocalitySelectorContainer: React.FC = () => {
    const dispatch = useAppDispatch();

    const area = useAppSelector((s: RootState) => s.area);
    const { regionId, provinceId, cityId } = useAppSelector((s: RootState) => s.store.storeForm);
    const levels = getLevels(area.data, regionId, provinceId, cityId);

    return (
        <LocationForm
            levels={levels}
            onChange={(level: AddressLevel) => (value) => {
                if (level.label === 'Region' || level.label === 'Departamento') {
                    dispatch(setRegionId(value));
                    dispatch(setProvinceId(''));
                    dispatch(setCityId(''));
                } else if (level.label === 'Provincia') {
                    dispatch(setProvinceId(value));
                    dispatch(setCityId(''));
                }
                dispatch(setStocksIdle());
            }}
            onSelect={() => (value) => {
                (async () => {
                    dispatch(setCityId(value));
                    await dispatch(getStocks());
                })();
            }}
        />
    );
};
