import React, { useContext } from 'react';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';

import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useChekAmountLimitExceeded } from 'lib/hooks/payments';
import { setPaymentMethod as setChek } from 'store/paymentMethod/ripleyChek.slice';
import { ErrorContext } from 'containers/aio/PaymentMethodsContainer/context';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import Skeleton from 'components/common/Skeleton/Skeleton';
import { RootState } from 'types/store';

/**
 * ChekSelector shows the option to select CHEK as payment method
 * @returns JSX.Element
 */
export default function ChekSelector(): JSX.Element {
    const { t } = useTranslation('error');
    const dm = useDeliveryMethod();
    const dispatch = useAppDispatch();
    const ctx = useContext(ErrorContext);
    const pm = useAppSelector((state: RootState) => state.paymentMethod);
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );
    const amountLimitExceeded = useChekAmountLimitExceeded();
    const isSelected = pm.data.selected.includes('RipleyChek');
    const isLoading = pm.data.loading.includes('RipleyChek');
    const isBlocked =
        (pm.data.status === 'pending' && !isLoading) || couponLoading || !dm.hasSchedules();

    async function setChekPayment() {
        try {
            await dispatch(setChek()).unwrap();
        } catch (e) {
            ctx.setError(t('defaultPaymentError.message'));
        }
    }

    return (
        <React.Fragment>
            <PaymentMethodItem
                paymentMethodName="Pago QR Chek"
                type="ripleychek"
                widthIcon="40px"
                onClick={setChekPayment}
                selected={isSelected}
                blocked={isBlocked}
                isLoading={isLoading}
                additionalInfo={isBlocked ?? <Skeleton />}
                disabled={amountLimitExceeded && <Trans i18nKey="chekAmountLimitExceeded" />}
            />
        </React.Fragment>
    );
}
