import { createAction, createSlice } from '@reduxjs/toolkit';

type Status = 'idle' | 'pending' | 'ok' | 'error';

export const setForm = createAction<TaxForm>('company/setForm');
export const setStatus = createAction<Status>('company/setStatus');

export type CompanyState = {
    status: Status;
    data: TaxForm | null;
    error: SerializedError | null;
};

const initialState: CompanyState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setForm, (state, action) => {
            const { payload } = action;
            state.data = payload;
        });
        builder.addCase(setStatus, (state, { payload }) => {
            state.status = payload;
        });
    },
});

export default slice;
