import * as React from 'react';
import styles from './Cart.module.scss';
import Addon from '../Addon';
import Collapsible from '../Collapsible';
import Products, { Product } from '../Products';
import bagImg from './bolsa.svg';
import PriceSummary, { PriceDetail, Total } from '../PriceSummary';

import Prices, { Price } from '../Prices';
import useWindowSize from 'lib/hooks/useWindowSize';

export type CartProps = {
    /**
     * List of products added to a cart
     */
    products: Product[];
    /**
     * Sets if the list is collapsed or not
     */
    open?: boolean;
    /**
     * Set the total amounts
     */
    totals: Totals;
    /**
     * List all price details
     */
    details?: PriceDetail[];
    /**
     * Sets the country to which the component must be adapted
     */
    country?: 'cl' | 'pe';
    /**
     * Callback triggered when the header bar or its arrow is clicked
     */
    onToggle?: () => void;
    /**
     * Button shown in the cart to continue or complete the purchase
     */
    button?: React.ReactNode;
    /**
     * Flag if is copago
     */
    isCopago?: boolean;
    /*
     * checkbox to accept the terms
     */
    terms?: React.ReactNode;
    /**
     * cart's alert
     */
    alert?: React.ReactNode;
    /**
     * Parameter to know if the channel is kiosk
     */
    isKiosko?: boolean;
};

export type Totals = Price[] | Total[];

export const Cart: React.FC<CartProps> = (props: CartProps) => {
    // Getting the width of the window
    const [width] = useWindowSize();
    const { products, onToggle, open, details, totals, country, button, isCopago } = props;

    const extraLetter = products.length > 1 ? 's' : '';
    const totalProducts = products.reduce((acc, product) => product.quantity + acc, 0);
    let productsLabel = `Mi${extraLetter} producto${extraLetter} (${totalProducts})`;

    // If the width of the window is less that 768px (tablet version)  we change than label displayed
    if (width <= 768) {
        productsLabel = `Revisar mi${extraLetter} producto${extraLetter} (${totalProducts})`;
    }
    return (
        <div className={open ? styles['open'] : styles['collapsed']}>
            <div className={styles['overlay']}></div>
            <div className={`${styles['content']} ${props.isKiosko ? styles['kiosko'] : ''}`}>
                <Collapsible
                    header={
                        <div className={styles['header']}>
                            <img alt="Bolsa" src={bagImg} className={styles['bag']} />
                            {productsLabel}
                        </div>
                    }
                    footer={
                        <>
                            {width >= 768 ? (
                                <div className={styles['desktop-detail']}>
                                    <PriceSummary
                                        totals={totals}
                                        details={details}
                                        country={country}
                                        className={styles['price-summary']}
                                    />

                                    {props.alert && (
                                        <div className={styles.alert}>{props.alert}</div>
                                    )}

                                    {props.terms && (
                                        <div className={styles.terms}>{props.terms}</div>
                                    )}

                                    {button}
                                </div>
                            ) : (
                                <div className={styles['mobile-detail']}>
                                    {props.alert && (
                                        <div className={styles.alert}>{props.alert}</div>
                                    )}

                                    {props.terms && (
                                        <div className={styles.terms}>{props.terms}</div>
                                    )}

                                    <Addon
                                        element={
                                            <Prices
                                                list={
                                                    isCopago
                                                        ? totals.filter(
                                                              (t) => t.type === 'difftopay',
                                                          )
                                                        : totals
                                                }
                                                isTotal={true}
                                                country={country}
                                            />
                                        }
                                    >
                                        {button}
                                    </Addon>
                                </div>
                            )}
                        </>
                    }
                    open={open}
                    onToggle={onToggle}
                >
                    <Products list={products} type="cart" country={country} />
                    {width <= 768 && (
                        <PriceSummary
                            totals={totals.filter((total) =>
                                ['difftopay', 'giftcard'].includes(total.type),
                            )}
                            details={details}
                            country={country}
                            className={styles['price-summary']}
                        />
                    )}
                </Collapsible>
            </div>
        </div>
    );
};
