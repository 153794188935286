import React, { FunctionComponent } from 'react';
import styles from './NavItem.module.scss';
import { ReactComponent as Arrow } from './arrow.svg';

export type NavItemProps = {
    title?: string;
    children?: React.ReactNode;
    showArrow?: boolean;
    loading?: boolean;
    skeletonRows?: number;
    onClick?: () => void;
};

export const NavItem: FunctionComponent<NavItemProps> = (props: NavItemProps) => {
    return (
        <li className={styles['item']}>
            {!props.loading ? (
                <div
                    className={styles['item__container']}
                    onClick={props.onClick}
                    onKeyDown={props.onClick}
                    role="button"
                    tabIndex={0}
                >
                    <div className={styles['item__content']}>
                        {props.title && <h2>{props.title}</h2>}
                        <div>{props.children}</div>
                    </div>
                    {props.showArrow && (
                        <div className={styles['item__arrow']}>
                            <Arrow />
                        </div>
                    )}
                </div>
            ) : (
                <div />
            )}
        </li>
    );
};
