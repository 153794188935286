import React from 'react';
import styles from './AddButton.module.scss';
import plus from './plus.svg';

export type AddButtonProps = {
    text: string;
    onClick: () => void;
};

export const AddButton: React.FC<AddButtonProps> = ({ text, onClick }: AddButtonProps) => {
    return (
        <button className={styles['button']} onClick={onClick}>
            <img src={plus} alt="plus" />
            {text}
        </button>
    );
};
