import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'lib/hooks/redux';
import { logoutUser } from 'store/user';
import ReactDOM from 'react-dom';
import Sidebar from 'components/kiosko/Sidebar';
import { RootState } from 'types/store';

export const SidebarKioskoContainer: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [container] = useState(document.createElement('div'));
    const cart = useAppSelector((state: RootState) => state.cart.data);

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    if (cart?.channel === 'Kiosko') {
        const rut = (cart.kiosko && cart.kiosko.vendorId) || '';
        const store = (cart.kiosko && cart.kiosko.storeName) || '';

        return ReactDOM.createPortal(
            <Sidebar
                rut={rut}
                store={store}
                isOpen={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
                onClick={logoutUser}
            />,
            container,
        );
    }

    return null;
};
