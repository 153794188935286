import React from 'react';
import styles from './FieldWrapper.module.scss';

export type FieldWrapperProps = {
    className?: string;
    children: React.ReactNode;
    error?: string;
};

/**
 * Enhance field components
 */
export const FieldWrapper: React.FC<FieldWrapperProps> = (props: FieldWrapperProps) => {
    return (
        <div className={props.className}>
            {props.children}
            {props.error && <span className={styles['error']}>{props.error}</span>}
        </div>
    );
};
