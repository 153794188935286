import React from 'react';
import { useAppSelector } from 'lib/hooks/redux';

import { AlignetRedirect } from './AlignetRedirect';
import { WebpayRedirect } from './WebpayRedirect';
import LoadingPayment from 'components/common/LoadingPayment/LoadingPayment';
import { RootState } from 'types/store';

export const Redirect: React.FC = () => {
    const pm = useAppSelector((state: RootState) => state.paymentMethod.data);

    if (pm.selected.includes('Alignet')) {
        return <AlignetRedirect />;
    }

    if (pm.selected.includes('WebpayCredito') || pm.selected.includes('WebpayDebito')) {
        return <WebpayRedirect />;
    }

    return <LoadingPayment content="Redireccionando..." />;
};
