import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAreas } from 'store/area';
import { authenticate } from 'store/auth';
import { fetchCartById } from 'store/cart';
import { fetchRules } from 'store/rule';
import { fetchUser } from 'store/user';
import { getDeliveryMethods } from 'store/deliveryMethod';
import { resetPayments } from 'store/paymentMethod/paymentMethod.slice';
import { fetchStores } from 'store/store';
import { setPaymentWithoutCvv } from 'store/paymentMethod/ripleyCard.slice';
import { disablePaymentWithoutCvv } from 'store/cardToken/cardToken.slice';
import { fetchCtsByCartId } from 'store/events';
import { getEnvironment } from 'config';
import { getCookie } from '../../lib/utils/cookie';
import { COOKIE_CART_ID, COOKIE_CUSTOMER_TOKEN } from '../../consts';

export type AppData = {
    channel: string;
    isMobile: boolean;
    paymentWithoutCvvEnabled: boolean;
    paymentMode: PaymentMode;
    deviceId: string;
    checkoutInit: string;
};

export type AppState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: AppData | null;
    error: SerializedError | null;
};

const defaultState: AppState = {
    status: 'idle',
    data: null,
    error: null,
};

export const updateDeviceId = createAction<string>('app/updateDeviceId');

export const changePaymentMode = createAsyncThunk(
    'app/changePaymentMode',
    (mode: PaymentMode) => mode,
);

export const initialize = createAsyncThunk('app/initialize', async (_, { dispatch }) => {
    const {
        analytics: { ctsEnabled },
    } = getEnvironment();
    dispatch(getDeliveryMethods());

    const promises: any = [];
    const cartPromise = dispatch(fetchCartById()).unwrap();
    const areasPromise = dispatch(fetchAreas()).unwrap();
    promises.push(cartPromise);
    promises.push(areasPromise);

    if (ctsEnabled) {
        const ctsPromise = dispatch(fetchCtsByCartId());
        promises.push(ctsPromise);
    }

    const [cart, areas] = await Promise.all(promises);
    await dispatch(fetchUser({ cart, areas, light: false })).unwrap();
    return { channel: cart.channel };
});

export const extraInitialize = createAsyncThunk('app/extraInitialize', async (_, { dispatch }) => {
    dispatch(fetchRules());
    await dispatch(fetchStores());
    initializeUtmProperties();
});

const slice = createSlice({
    name: 'app',
    reducers: {},
    initialState: defaultState,
    extraReducers: (builder) => {
        builder.addCase(initialize.pending, (state: AppState) => {
            state.status = 'pending';
            state.data = {
                channel: '',
                deviceId: getDeviceId(),
                isMobile: isMobile(),
                paymentWithoutCvvEnabled: false,
                paymentMode: 'normal',
                checkoutInit: new Date().toISOString(),
            };
        });
        builder.addCase(initialize.fulfilled, (state: AppState, action) => {
            state.status = 'ok';
            if (state.data) {
                state.data.channel = action.payload.channel;
            }
        });
        builder.addCase(initialize.rejected, (state: AppState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(extraInitialize.rejected, (state: AppState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(resetPayments.fulfilled, (state: AppState) => {
            if (state.data) {
                state.data.paymentMode = 'normal';
            }
        });

        builder.addCase(changePaymentMode.fulfilled, (state: AppState, { payload }) => {
            if (state.data) {
                state.data.paymentMode = payload;
            }
        });

        builder.addCase(setPaymentWithoutCvv.fulfilled, (state: AppState) => {
            if (state.data) {
                state.data.paymentWithoutCvvEnabled = true;
            }
        });
        builder.addCase(setPaymentWithoutCvv.rejected, (state: AppState) => {
            if (state.data) {
                state.data.paymentWithoutCvvEnabled = false;
            }
        });
        builder.addCase(disablePaymentWithoutCvv, (state: AppState) => {
            if (state.data) {
                state.data.paymentWithoutCvvEnabled = false;
            }
        });
        builder.addCase(updateDeviceId, (state, action) => {
            if (state.data) {
                state.data.deviceId = action.payload;
            }
        });
    },
});

export function isMobile(): boolean {
    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent,
        );
    return isMobile;
}

// Retorna el deviceId recibido como query param
export function getDeviceId(): string {
    const query = new URLSearchParams(window.location.search);
    let deviceId = query.get('deviceId');

    if (deviceId) {
        // Guarda el dato en local storage para no perderlo en
        // caso que ocurra una redirección sin el query param
        localStorage.setItem('deviceId', deviceId);
    } else {
        deviceId = localStorage.getItem('deviceId') || '';
    }

    return deviceId;
}

export const initializeOauth = createAsyncThunk('app/initializeOauth', async (_, { dispatch }) => {
    const cookieCardId = getCookie(COOKIE_CART_ID);
    const cookieCustomerToken = getCookie(COOKIE_CUSTOMER_TOKEN);

    const availableOauth = cookieCardId && cookieCustomerToken;
    if (!availableOauth) {
        const error: SerializedError = {
            code: 'errInvalidCookies',
            name: 'GetAuthToken',
        };
        throw error;
    }

    return await dispatch(authenticate()).unwrap();
});

export const initializeUtmProperties = () => {
    localStorage.removeItem('source');
    localStorage.removeItem('campaign');
    localStorage.removeItem('medium');

    const query = new URLSearchParams(window.location.search);
    const source = query.get('utmSource');
    const campaign = query.get('utmCampaign');
    const medium = query.get('utmMedium');

    if (source) {
        localStorage.setItem('source', source);
    }

    if (campaign) {
        localStorage.setItem('campaign', campaign);
    }

    if (medium) {
        localStorage.setItem('medium', medium);
    }
};

export default slice;
