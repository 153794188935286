import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';

/**
 * Creates a payment of type 'WebpayPlus' and configures it in the cart
 */
export const setPaymentMethod = createAsyncThunk(
    'webpayDebito/setPayment',
    async (_, { getState }) => {
        const state = getState() as RootState;
        if (state.cart.data == null || state.billing.data == null) {
            throw new Error('Datos incompletos para usar WebpayDebito');
        }
        let payments = getPaymentPayload(state.cart.data, state.billing.data);
        const cartId = state.cart.data.id;
        // NOTE: Cuando es copago se mantiene lo viejo, cuando es normal se sobreescribe.
        const isOverwrite = state.app.data?.paymentMode !== 'copago';
        await services.cart.setPaymentOnce(cartId, payments, /*isDummy*/ true, isOverwrite);

        const cartData = await services.cart.getById(cartId);

        const grandTotal = cartData.grandTotal['discount'] || cartData.grandTotal['master'];
        let grandTotalValue = Number(grandTotal.value);

        if (!isOverwrite) {
            const giftCardData = state.paymentMethod.types.ripleyGiftCard.data;
            if (giftCardData && grandTotal) {
                grandTotalValue = grandTotalValue - giftCardData.paymentAmount;
            }
        }
        payments = payments.map((payment) => {
            return {
                ...payment,
                amount: {
                    currency: 'CLP',
                    value: grandTotalValue.toString(),
                },
            };
        });

        const paymentData = await services.cart.setPaymentOnce(
            cartId,
            payments,
            /*isDummy*/ false,
            isOverwrite,
        );

        const payment =
            paymentData.find((payment) => payment.paymentMethod == 'WebpayDebito') || null;

        if (!payment) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        if (!payment.customFields) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        if (!payment.customFields.webpayToken || !payment.customFields.webpayUrl) {
            throw new Error('El medio de pago no pudo ser configurado');
        }
        return {
            webpayToken: payment?.customFields?.webpayToken,
            webpayUrl: payment?.customFields?.webpayUrl,
            cart: cartData,
            isOverwrite,
        };
    },
);

/**
 * Returns the payload to create a WebpayDebito payment
 * @returns
 */
function getPaymentPayload(cart: Cart, billing: PartialPaymentInfo): PaymentInfo[] {
    const returnUrl = window.location.origin + '/checkout';
    const price = cart.grandTotal['discount'] || cart.grandTotal['master'];

    return [
        {
            documentType: billing.documentType,
            paymentMethod: 'WebpayDebito',
            billingInfo: {
                business: billing.billingInfo.business,
                customer: billing.billingInfo.customer,
                address: billing.billingInfo.address,
            },
            amount: price,
            customFields: {
                returnUrl: returnUrl,
            },
        },
    ];
}

interface WebpayData {
    webpayToken: string;
    webpayUrl: string;
}

interface WebpayState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: WebpayData | null;
    error: SerializedError | null;
}

const initialState: WebpayState = {
    status: 'idle',
    data: null,
    error: null,
};

export const slice = createSlice({
    name: 'webpayDebito',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPaymentMethod.pending, (state: WebpayState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: WebpayState, { payload }) => {
            state.status = 'ok';
            state.error = null;
            state.data = {
                webpayToken: payload.webpayToken,
                webpayUrl: payload.webpayUrl,
            };
        });
        builder.addCase(setPaymentMethod.rejected, (state: WebpayState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
    },
});
