import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useRules } from 'lib/hooks/rules';

import {
    setPaymentMethod,
    setPaymentMethodDummy,
    changePassword,
    resetPayment,
    setPaymentWithCopago,
} from 'store/paymentMethod/ripleyGiftCard.slice';
import { changePaymentMode } from 'store/app/app.slice';

import Modal from 'components/common/Modal';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import GiftCardConfirmation from 'components/payment/GiftCardConfirmation';
import GiftCardPreviewTotal from 'components/payment/GiftCardPreviewTotal';
import GiftCardForm, { Form } from 'components/payment/GiftCardForm';
import GiftCardFormChangePassword, {
    Form as FormUpdatePass,
} from 'components/payment/GiftCardFormChangePassword';
import { getTotals } from 'containers/CartContainer/CartContainer.utils';
import { Total } from 'components/common/PriceSummary';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

/**
 * Componente encargado de agregar una Gift Card.
 * Este levanta el modal y según los datos de la tarjeta
 * muestra el formulario para cambiar clave o mostrar el balance de la tarjeta.
 * @returns
 */
export const GiftCardAdder: React.FC = () => {
    const dispatch = useAppDispatch();
    const dm = useDeliveryMethod();
    const { t } = useTranslation('format');
    const isCopago = useAppSelector((state: RootState) => state.app.data?.paymentMode === 'copago');
    const cart = useAppSelector((state: RootState) => state.cart);
    const pm = useAppSelector((state: RootState) => state.paymentMethod);
    const giftCard = pm.types.ripleyGiftCard;

    const [isOpen, setIsOpen] = useState(false);
    const [mustChangePassword, setMustChangePassword] = useState(false);
    const [isActivingCopago, setIsActivingCopago] = useState(false);
    const [card, setCard] = useState({ number: '', password: '' });
    const appliedAmountGiftCard = isCopago
        ? giftCard.data?.availableAmount
        : giftCard.data?.paymentAmount;

    const transformedTotalDiff: Total[] = [];
    if (cart.status == 'ok' && cart.data) {
        const totals = getTotals(cart.data);
        transformedTotalDiff.push({
            type: 'normal',
            amount: t('amount', { value: totals['normal'] - (appliedAmountGiftCard ?? 0) }),
        } as Total);
    }

    const haveBalance = !(giftCard?.data?.availableAmount === 0);

    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    useEffect(() => {
        if (giftCard.error && giftCard.error.code === 'errTREChangePassword')
            setMustChangePassword(true);
    }, [giftCard]);

    useEffect(() => {
        if (giftCard.status === 'error') {
            sendErrorToGA4({
                channel: cart.data?.channel,
                error: 'Error en el medio de pago giftcard',
                section: 'PaymentSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [giftCard.status, giftCard.error]);

    async function cancelGiftCard() {
        try {
            setIsActivingCopago(false);
            await dispatch(changePaymentMode('normal'));
            await dispatch(resetPayment()).unwrap();
            setIsOpen(false);
            setMustChangePassword(false);
        } catch {
            //
        }
    }

    function okGiftCard() {
        setIsOpen(false);
        setMustChangePassword(false);
    }

    async function activateCopayment() {
        setIsActivingCopago(true);

        try {
            await dispatch(
                setPaymentWithCopago({
                    number: card.number,
                    password: card.password,
                }),
            ).unwrap();
        } catch (_) {
            return;
        }

        dispatch(changePaymentMode('copago'));
        setIsOpen(false);
        setIsActivingCopago(false);
    }

    function selectGiftCard() {
        dispatch(setPaymentMethodDummy());
        setIsOpen(true);
        setMustChangePassword(false);
    }

    async function setGiftCard({ number, password }: Form) {
        setCard({ number, password });
        await dispatch(setPaymentMethod({ number, password }));
    }

    async function changeGiftCardPassword(p: FormUpdatePass) {
        try {
            await dispatch(changePassword(p)).unwrap();
        } catch (_) {
            return;
        }

        setGiftCard({ number: p.cardNumber, password: p.newPassword });
    }

    const pending = giftCard.status === 'pending';
    const isLoading = pm.data.status === 'pending' && pm.data.loading.includes('RipleyGiftCard');
    const isSelected = pm.data.selected.includes('RipleyGiftCard');
    const isBlocked =
        (pm.data.status === 'pending' && !isLoading) || couponLoading || !dm.hasSchedules();

    return (
        <React.Fragment>
            {!isSelected || giftCard.status !== 'ok' || giftCard.data == null || isOpen ? (
                <PaymentMethodItem
                    paymentMethodName="Gift Card"
                    type="giftcard"
                    widthIcon="63px"
                    onClick={selectGiftCard}
                    blocked={isBlocked}
                    selected={isSelected}
                />
            ) : (
                <GiftCardPreviewTotal
                    giftCardNumber={giftCard.data.cardNumber}
                    appliedAmount={t('amount', {
                        value: appliedAmountGiftCard,
                    })}
                    deleteEnabled={true}
                    modifyEnabled={false}
                    modifyAction={selectGiftCard}
                    deleteAction={cancelGiftCard}
                    remainderAmount={isCopago ? transformedTotalDiff[0]?.amount : ''}
                />
            )}

            <Modal onToggle={cancelGiftCard} isOpen={isOpen} header="Gift Card">
                {(giftCard.status === 'ok' || isActivingCopago) && haveBalance ? (
                    <GiftCardConfirmationContainer
                        onCancel={cancelGiftCard}
                        onContinue={okGiftCard}
                        onCopayment={activateCopayment}
                    />
                ) : mustChangePassword ? (
                    <GiftCardFormChangePasswordContainer
                        cardNumber={card.number}
                        cardPassword={card.password}
                        onCancel={cancelGiftCard}
                        onSubmit={changeGiftCardPassword}
                    />
                ) : (
                    <GiftCardForm
                        form={card}
                        setForm={setCard}
                        onSubmit={setGiftCard}
                        onCancel={cancelGiftCard}
                        pending={pending}
                        haveBalance={haveBalance}
                        error={giftCard.error}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
};

/**
 * Propiedades que debe recibir GiftCardConfirmationContainer
 */
type GiftCardConfirmationContainerProps = {
    onCopayment: () => void;
    onCancel: () => void;
    onContinue: () => void;
    error?: string;
};

/**
 *  Componente para mostrar el balance de la Gift Card
 * @param props
 * @returns
 */
const GiftCardConfirmationContainer: React.FC<GiftCardConfirmationContainerProps> = (
    props: GiftCardConfirmationContainerProps,
) => {
    const rules = useRules();
    const { t } = useTranslation('format');
    const { t: translateError } = useTranslation('error');

    const giftCard = useAppSelector((state: RootState) => state.paymentMethod.types.ripleyGiftCard);

    if (giftCard.data == null) {
        throw new Error('La información de la Gift Card está incompleta.');
    }

    const amount = giftCard.data.paymentAmount;
    const cardNumber = giftCard.data.cardNumber;
    const expiryDate = giftCard.data.expiryDate;
    const cardBalance = giftCard.data.availableAmount;

    let balancePositive = '';
    let balanceNegative = '';
    let diffAmount = '';

    if (cardBalance >= amount) {
        balancePositive = '-' + t('amount', { value: amount });
    }

    if (cardBalance < amount) {
        balanceNegative = '-' + t('amount', { value: amount });
    }

    if (balanceNegative) {
        diffAmount = t('amount', { value: amount - cardBalance });
    }

    const date = t('giftCardExpiryDate', {
        date: new Date(
            Number(expiryDate.toString().slice(0, 4)),
            Number(expiryDate.toString().slice(4, 6)) - 1,
            Number(expiryDate.toString().slice(6, 8)),
        ),
    });

    return (
        <GiftCardConfirmation
            pending={giftCard.status === 'pending'}
            cardNumber={cardNumber}
            cardExpireDate={date}
            cardBalance={t('amount', { value: cardBalance })}
            balancePositive={balancePositive}
            balanceNegative={balanceNegative}
            diffAmount={diffAmount}
            onCopayment={rules.feature('copago') ? props.onCopayment : void 0}
            onCancel={props.onCancel}
            onContinue={props.onContinue}
            error={
                giftCard.error?.code &&
                translateError([`${giftCard.error.code}.message`, 'errUnknown.message'])
            }
        />
    );
};

/**
 * Propiedades que recibe GiftCardFormChangePassword
 */
type GiftCardFormChangePasswordContainerProps = {
    cardNumber: string;
    cardPassword: string;
    onCancel: () => void;
    onSubmit: (f: FormUpdatePass) => void;
};

/**
 * Componente para mostrar formulario que permite
 * cambiar clave de una Gift Card
 * @param props
 * @returns
 */
const GiftCardFormChangePasswordContainer: React.FC<GiftCardFormChangePasswordContainerProps> = (
    props: GiftCardFormChangePasswordContainerProps,
) => {
    const { t: translate } = useTranslation();
    const { t: translateError } = useTranslation('error');
    const giftCard = useAppSelector((s: RootState) => s.paymentMethod.types.ripleyGiftCard);
    const pending = giftCard.status === 'pending';
    let error = '';
    switch (giftCard.error?.code) {
        case undefined:
            error = '';
            break;
        case 'errTREChangePassword':
            error = '';
            break;
        case 'errTREInvalidPassword':
            {
                const m = (giftCard.error?.message ?? '').match(/(\d+) intento/);
                if (m) {
                    const count = parseInt(m[1]);
                    error = translate('wrongPasswordAttemptsLeft', { count });
                } else {
                    error = translate('wrongPassword');
                }
            }
            break;
        default:
            error = translateError([`${giftCard.error?.code}.message`, 'errUnknown.message']);
            break;
    }

    return (
        <GiftCardFormChangePassword
            cardNumber={props.cardNumber}
            cardPassword={props.cardPassword}
            error={error}
            pending={pending}
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}
        />
    );
};
