import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';
import { DD, RT, SELLER_RIPLEY } from 'consts';
import { alignetCheckout, doCheckout } from 'store/checkout/checkout.slice';

/** Borra productos o redirecciona, lo que corresponda. */
export const separateProducts = createAsyncThunk(
    'separatedProducts/separate',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const separatedProducts =
            state.cart.data?.products.filter((p) =>
                state.separatedProducts.data.some((sp) => p.id == sp.id || p.sku == sp.sku),
            ) ?? [];
        const cartId = state.cart.data?.id ?? '';
        return await services.cart.deleteProducts(cartId, separatedProducts);
    },
);

/** Reevalua los productos a separar. */
export const updateSeparatedProducts = createAsyncThunk<
    SeparatedProduct[],
    undefined,
    { state: RootState }
>('separatedProducts/set', async (_, thunkApi) => {
    const { cart, deliveryMethod, pickup, shipping, store, rule } = thunkApi.getState();

    const selectedDeliveryMethod =
        deliveryMethod.provisional == null ? deliveryMethod.selected : deliveryMethod.provisional;

    const separatedProducts: SeparatedProduct[] = [];
    const ids = new Set<string>();

    // Retiro en tienda
    if (selectedDeliveryMethod === RT && store.data) {
        const selectedStore = store.data.provisional ? store.data.provisional : store.data.selected;

        // Si hay productos marketplace, los que deben ser quitados.
        const marketplaceProducts =
            cart.data?.products.filter((product) => product.details.sellerId !== SELLER_RIPLEY) ||
            [];

        // Solo debemos quitarlos si la regla marketplacePickup es `false`
        if (marketplaceProducts?.length > 0 && !rule.data.marketplacePickup) {
            marketplaceProducts.forEach((product) => ids.add(product.id));
        }

        // Cuando eligimos una tienda debemos revisar si hay algun producto no disponible.
        if (selectedDeliveryMethod === RT && selectedStore && store.stocks.data && cart.data) {
            const storeStock = store.stocks.data.byId[selectedStore];
            cart.data.products.forEach((product) => {
                const productStock = storeStock.products.find(
                    (stock) => stock.sku === (product.skuPmm || product.sku),
                );
                // TODO: deberiamos recalcular la separacion al obtener agenda?
                // TODO: debería el BFF devolver solo un boolean? nos interesa la distinción?
                // Si el producto del carro no tiene asociado un stock de tienda,
                // tambien tenemos que quitarlo.
                if (!productStock || (!productStock.pickup && !productStock.siteToStore)) {
                    ids.add(product.id);
                }
            });

            // En caso que haya desincronizacion entre stock en tienda y la respuesta
            // de agenda, verificamos que esta última traiga todos los productos.
            if (pickup.data && pickup.status === 'ok') {
                pickup.data.unscheduled.forEach((product) => {
                    const id = cart.data?.products?.find((p) => p.sku === product.id)?.id;
                    if (id) ids.add(id);
                });
            }
        }
    }

    // En despacho a domicilio, debemos separar los productos que esten "unscheduled".
    if (selectedDeliveryMethod === DD && shipping.data && shipping.status === 'ok') {
        // TODO: en el futuro Logistic disponibilizara el availableQuantity,
        //       que actualmente es siempre `null`. En ese caso los productos
        //       no vendran `unscheduled`, si no que tendremos que comparar
        //       la cantidad pedida con la cantidad disponible por producto.
        shipping.data.unscheduled.forEach((product) => {
            const id = cart.data?.products?.find((p) => p.sku === product.id)?.id;
            if (id) ids.add(id);
        });
    }

    // Por cada ID a separar, obtenemos el Product desde el carro.
    ids.forEach((id) => {
        const product = cart.data?.products.find((product) => product.id === id);
        if (product) separatedProducts.push(product);
    });

    return separatedProducts;
});

export const setSeparatedProductsOpen = createAction<boolean>('separatedProducts/setOpen');

export type SeparatedProductState = {
    status: 'idle' | 'pending' | 'error' | 'ok';
    data: SeparatedProduct[];
    error: SerializedError | null;
    isOpen: boolean;
};

const initialState: SeparatedProductState = {
    status: 'idle',
    data: [],
    error: null,
    isOpen: false,
};

const slice = createSlice({
    name: 'separatedProducts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateSeparatedProducts.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(separateProducts.pending, (state: SeparatedProductState) => {
            state.status = 'pending';
        });
        builder.addCase(separateProducts.rejected, (state: SeparatedProductState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(separateProducts.fulfilled, (state: SeparatedProductState) => {
            state.status = 'ok';
        });

        builder.addCase(doCheckout.rejected, (state: SeparatedProductState, { error }) => {
            const products = getProductWithoutStock(error);
            if (products.length > 0) state.data = products;
        });

        builder.addCase(alignetCheckout.rejected, (state: SeparatedProductState, { error }) => {
            const products = getProductWithoutStock(error);
            if (products.length > 0) state.data = products;
        });

        builder.addCase(setSeparatedProductsOpen, (state: SeparatedProductState, { payload }) => {
            state.isOpen = payload;
        });
    },
});

// Devuelve los productos sin stock recibidos en el error del order process
const getProductWithoutStock = (error: SerializedError): SeparatedProduct[] => {
    const list: SeparatedProduct[] = [];
    if (error.code == 'errProductStock' && error.message) {
        const products = JSON.parse(error.message);
        if (Array.isArray(products)) {
            products.forEach((p) => {
                list.push(p);
            });
        }
    }
    return list;
};

export default slice;
