import Cookies from 'js-cookie';

/**
 * Retrieves a specific key of the object stored in browser Cookies.
 *
 * @param key - The key of the slice to retrieve.
 * @returns The value of the key stored in browser Cookies or null if it doesn't exist.
 */
export function getCookie(key: string): string | null {
    return Cookies.get(key) ?? null;
}
