import React, { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { keyDownAsClick } from 'lib/utils/events';

import check from './check.svg';
import styles from './TextInput.module.scss';

export type TextInputProps = {
    /**
     * Specifies the type of the input, by default is 'text'
     */
    type?: 'text' | 'email' | 'search' | 'number' | 'tel';
    /**
     * Specifies the text or label that describes the text field
     */
    label?: string;
    /**
     * Specifies the identifier of the input
     */
    id?: string;
    /**
     * Specifies the default value that input must have
     */
    value?: string;
    /**
     * Specifies the name of the input
     */
    name?: string;
    /**
     * Specifies a regular expression that input's value is checked against
     */
    pattern?: string;
    /**
     * Specifies a short hint that describes the expected value of the input
     */
    placeholder?: string;
    /**
     * Specifies a help text under the input.
     */
    help?: string;
    /**
     * Specifies the maximum number of characters allowed in the input
     */
    maxlength?: number;
    /**
     * Mask character to put in each empty place.
     * eg: for mask="_" and format="##/##" it would display "__/__"
     */
    mask?: string;
    /**
     * Formatting to apply to a number with '#' as placeholders.
     * eg: '##/##' would format '1234' as '12/34'.
     */
    format?: string;
    /**
     * Specifies whether the field will be required or not
     */
    required?: boolean;
    /**
     * Specifies that input should be disabled
     */
    disabled?: boolean;
    /**
     * Specifies that input is invalid and is displayed in red
     */
    invalid?: boolean;
    /**
     * Specifies an additional class for the input's container
     */
    className?: string;
    /**
     * Determines whether the password is displayed or not. This parameter assumes that there is a useState outside the component
     */
    showPassword?: boolean;
    /**
     * Specifies if the show password label is inside or outside of the inputbox
     */
    showPasswordLabelInside?: boolean;
    /**
     * Flag to allow icons.
     */
    disabledIconEntry?: boolean;
    /**
     * display a check icon inside the inputbox
     */
    displayCheck?: boolean;
    /**
     * Change the state of showPassword. This parameter assumes that there is a useState outside the component
     */
    setShowPassword?: (value: boolean) => void;
    /**
     * Sets a callback that runs when the input's value changes
     */
    onChange?: (value: string) => void;
    /**
     * Sets a callback that returns the raw and formatted values for masked inputs
     */
    onChangeMasked?: (value: string, formatted: string) => void;
    /**
     * Sets a callback that runs when the input gets focus
     */
    onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
    /**
     * Sets a callback that runs when the input loses focus
     */
    onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
};

export const TextInput: FunctionComponent<TextInputProps> = (props: TextInputProps) => {
    const isTelephone = props.type == 'tel';
    const [value, setValue] = useState(props.value || '');
    const [shouldOpen, setShouldOpen] = useState(isTelephone || !!props.value);

    // If value is modified from the outside, update it locally.
    // eg: when formatting.
    useEffect(() => {
        if ((props.value || props.value === '') && props.value !== value) {
            setValue(props.value);
        }
    }, [props.value, value]);
    /**
     * Handles the input's change event.
     * @param event
     */
    function handleOnChange(event: React.FormEvent<HTMLInputElement>) {
        const currentValue = event.currentTarget.value;

        /**
         * In case that a `pattern` is received, we must validate that
         * input's value complies with the regular expression.
         */
        if (props.disabledIconEntry) {
            const er =
                /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
            if (er.test(currentValue)) return;
        }

        if (props.pattern) {
            const rule = new RegExp(props.pattern, 'gi');

            if (!rule.test(currentValue)) {
                return false;
            }
        }

        /**
         * In case a `maxlength` is received, we must validate that
         * input's value doesn't exceded the maximum number of characters.
         */
        if (props.maxlength) {
            if (currentValue.length > props.maxlength) {
                return false;
            }
        }

        setValue(currentValue);

        if (props.onChange) {
            props.onChange(currentValue);
        }
    }

    /**
     * Handles the input's focus event.
     * @param event
     */
    function handleOnFocus(event: React.FormEvent<HTMLInputElement>) {
        setShouldOpen(true);

        if (typeof props.onFocus === 'function') {
            props.onFocus(event);
        }
    }

    /**
     * Handles the input's blur event.
     * @param event
     */
    function handleOnBlur(event: React.FormEvent<HTMLInputElement>) {
        if (!value && !isTelephone) {
            setShouldOpen(false);
        }

        if (typeof props.onBlur === 'function') {
            props.onBlur(event);
        }
    }

    let computedClassName = styles['textfield'];
    let showPasswordClassName = styles['textfield__show_password'];

    if (props.invalid) {
        computedClassName += ' ' + styles['textfield--invalid'];
    }

    if (props.disabled) {
        computedClassName += ' ' + styles['textfield--disabled'];
    }

    if (shouldOpen) {
        computedClassName += ' ' + styles['textfield--open'];
    }

    if (props.className) {
        computedClassName += ' ' + props.className;
    }

    props.showPassword
        ? (showPasswordClassName += ' ' + styles['textfield__show_password--true'])
        : (showPasswordClassName += ' ' + styles['textfield__show_password--false']);

    const handleShowPassword = () => {
        props.setShowPassword?.(!props.showPassword);
    };

    const inputProps = {
        id: props.id,
        name: props.name,
        value: value,
        type: props.showPassword ? props.type : 'password',
        className: styles['textfield__control'],
        placeholder: shouldOpen ? props.placeholder : undefined,
        maxLength: props.maxlength,
        required: props.required,
        onChange: (e: ChangeEvent<HTMLInputElement>) => handleOnChange(e),
        onFocus: (e: ChangeEvent<HTMLInputElement>) => handleOnFocus(e),
        onBlur: (e: ChangeEvent<HTMLInputElement>) => handleOnBlur(e),
    };
    useEffect(() => {
        value && setShouldOpen(true);
    }, [value]);

    return (
        <div className={styles['textfield__container']}>
            <div className={computedClassName}>
                <span className={styles['textfield__label']}>{props.label}</span>

                {props.format ? (
                    <NumberFormat
                        {...inputProps}
                        type={
                            props.showPassword
                                ? props.type === 'tel'
                                    ? 'tel'
                                    : 'text'
                                : 'password'
                        }
                        mask={props.showPassword ? props.mask : ''}
                        format={props.showPassword ? props.format : props.format?.replace(/ /g, '')} // remove white space from format when password is activated
                        onValueChange={(values) => {
                            props.onChangeMasked?.(values.value, values.formattedValue);
                        }}
                        allowEmptyFormatting={props.type === 'tel'}
                    />
                ) : (
                    <>
                        <input {...inputProps} />
                        {props.displayCheck && (
                            <div className={styles['textfield_check_container']}>
                                <img
                                    src={check}
                                    alt="check"
                                    className={styles['textfield_check']}
                                />
                            </div>
                        )}
                        {props.showPasswordLabelInside && (
                            <span
                                className={showPasswordClassName}
                                onClick={handleShowPassword}
                                onKeyDown={keyDownAsClick(handleShowPassword)}
                                role="button"
                                tabIndex={0}
                            ></span>
                        )}
                    </>
                )}
                <fieldset className={styles['textfield__fill']}>
                    <legend className={styles['textfield__legend']}>
                        <span>{props.label}</span>
                    </legend>
                </fieldset>
            </div>
            {props.help && <span className={styles['textfield__help']}>{props.help}</span>}
            {props.setShowPassword != null && !props.showPasswordLabelInside && (
                <span
                    className={showPasswordClassName}
                    onClick={handleShowPassword}
                    onKeyDown={keyDownAsClick(handleShowPassword)}
                    role="button"
                    tabIndex={0}
                ></span>
            )}
        </div>
    );
};

const defaultProps: TextInputProps = {
    type: 'text',
    showPassword: true,
    disabledIconEntry: true,
};

TextInput.defaultProps = defaultProps;

export default TextInput;
