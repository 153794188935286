import { slice } from './shippingCompatibility.slice';
export {
    checkShippingCompatibility,
    checkPickupCompatibility,
    separateByCompatibleGroup,
    largestPriceGroup,
} from './shippingCompatibility.slice';

export const { toggleShow } = slice.actions;
export default slice.reducer;
