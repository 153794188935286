import { createAsyncThunk } from '@reduxjs/toolkit';
import services from 'services';

export const deleteCard = createAsyncThunk(
    'card/deleteById',
    async ({ cardId, hard = false }: DeleteCardPayload) => {
        if (hard) {
            return await services.card.deleteById(cardId);
        }
        return null;
    },
);

export type DeleteCardPayload = {
    cardId: string;
    hard?: boolean;
};
