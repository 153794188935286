import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { RootState } from 'types/store';
import { DD } from 'consts';
import { separateProducts } from 'store/separatedProducts/separatedProducts.slice';
import { getCartId } from 'store/cart/cart.slice';

export const selectSchedule = createAction<{ groupId: string; scheduleId: string }>(
    'shipping/selectSchedule',
);

export const fetchScheduleByCartId = createAsyncThunk<Delivery, undefined, { state: RootState }>(
    'shipping/fetchByCartId',
    async (_, thunkApi) => {
        const { area, user, address } = thunkApi.getState();
        const cartId = getCartId();

        // Validate and create the payload required by the getSchedule method
        if (!area.data || !cartId || !user.data || !address.data) {
            throw new Error('Datos insuficientes para obtener agendas');
        }
        return await services.schedule.getSchedule({
            cartId,
            address: address.data?.byId[address.data?.selected ?? ''],
            areas: area.data,
        });
    },
);

export const setSchedule = createAsyncThunk<Delivery, undefined, { state: RootState }>(
    'shipping/setSchedule',
    async (_, thunkApi) => {
        const { address, shipping, user } = thunkApi.getState();
        const cartId = getCartId();

        if (!shipping.data || !user.data || !address.data) {
            throw new Error('Datos incompletos para despacho a domicilio');
        }

        const selectedAddress = address.data.byId[address.data.selected ?? ''];
        if (selectedAddress == null) {
            throw new Error('Direccion seleccionada no fue encontrada');
        }

        return await services.schedule.setSchedule({
            cartId: cartId,
            deliveryMethod: DD,
            delivery: shipping.data,
            user: user.data,
            address: selectedAddress,
        });
    },
);

const initialState: ScheduleState = {
    status: 'idle',
    data: null,
    requestId: '',
    error: null,
};

export type ScheduleState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: Delivery | null;
    requestId: string | null | undefined;
    error: SerializedError | null;
};

const slice = createSlice({
    name: 'schedule',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchScheduleByCartId.pending, (state: ScheduleState, action) => {
            state.status = 'pending';
            state.requestId = action.meta.requestId;
            state.data = null;
            state.error = null;
        });
        builder.addCase(fetchScheduleByCartId.fulfilled, (state: ScheduleState, action) => {
            state.status = 'ok';
            state.data = action.payload;
            state.requestId = action.meta.requestId;
            state.error = null;
        });
        builder.addCase(fetchScheduleByCartId.rejected, (state: ScheduleState, { error }) => {
            state.status = 'error';
            state.error = error;
            state.requestId = undefined;
        });

        builder.addCase(selectSchedule, (state: ScheduleState, action) => {
            const { payload } = action;
            const { groupId, scheduleId } = payload;
            if (state.data?.selectedSchedules[groupId]) {
                state.data.selectedSchedules[groupId] = scheduleId;
            }
        });

        builder.addCase(setSchedule.pending, (state: ScheduleState) => {
            state.status = 'pending';
        });
        builder.addCase(setSchedule.fulfilled, (state: ScheduleState, action) => {
            state.status = 'ok';
            state.requestId = action.meta.requestId;
        });
        builder.addCase(setSchedule.rejected, (state: ScheduleState, { error }) => {
            state.status = 'error';
            state.error = error;
        });

        builder.addCase(separateProducts.fulfilled, (state: ScheduleState) => {
            if (state.data != null && state.data.unscheduled.length > 0) {
                state.data.unscheduled = [];
            }
        });
    },
});

export default slice;
