import React from 'react';

interface UseCountdown {
    timeRemaining: null | number; // Indica el tiempo restante
    startCountdown: (endTime: number) => void; // Funcion para iniciar el temporizador
}

export function useCountdown(): UseCountdown {
    // Inicializa el estado `count` con null, lo cual permite diferenciar un temporizador no iniciado.
    const [count, setCount] = React.useState<null | number>(null);
    // Estado `endTime` para almacenar el tiempo de finalización del temporizador.
    const [endTime, setEndTime] = React.useState(0);

    // Referencia para almacenar el ID del intervalo y poder limpiarlo más tarde.
    const intervalIdRef = React.useRef(0);

    // Función para limpiar el intervalo, evitando ejecuciones innecesarias o fugas de memoria.
    const handleClearInterval = () => {
        window.clearInterval(intervalIdRef.current);
    };

    // Efecto para manejar el decremento de `count` cada segundo y detenerse en cero.
    React.useEffect(() => {
        // Si `count` es null, el temporizador no está activo, por lo que se sale temprano.
        if (count == null) return;

        // Establece un intervalo que decrementa `count` cada segundo hasta llegar a cero.
        intervalIdRef.current = window.setInterval(() => {
            if (count === 0) {
                handleClearInterval();
            } else {
                setCount(count - 1);
            }
        }, 1000);

        // Limpieza del intervalo al desmontar o actualizar el componente.
        return () => handleClearInterval();
    }, [count]);

    // Efecto para inicializar `count` basado en `endTime` y la hora actual.
    React.useEffect(() => {
        // Si `endTime` es 0, no hay temporizador establecido, se retorna temprano.
        if (endTime == 0) return;

        // Calcula el `count` inicial como la diferencia entre `endTime` y la hora actual.
        setCount(Math.round((endTime - Date.now()) / 1000));
    }, [endTime]);

    // Expone `count` y la función `startCountdown` para iniciar el temporizador.
    return {
        timeRemaining: count,
        startCountdown: (time) => setEndTime(time),
    };
}

interface UseIdle {
    isIdle: boolean; // Expone si el usuario esta inactivo o no
    startIdleTimer: (ms: number) => void; // Inicia el temporizador para dar seguimiento de inactividad
}

export function useIdle(): UseIdle {
    // Estado `isIdle` para rastrear si el usuario está inactivo.
    const [isIdle, setIsIdle] = React.useState(false);
    // Estado `timer` para definir el tiempo de espera antes de considerar inactividad.
    const [timer, setTimer] = React.useState(0);

    React.useEffect(() => {
        let timeoutId;

        // Función que se llama cuando se cumple el tiempo de inactividad.
        const handleTimeout = () => {
            setIsIdle(true);
        };

        // Función envuelta en `throttle` para limitar la frecuencia de reinicio del temporizador.
        const handleEvent = throttle(() => {
            setIsIdle(false);
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(handleTimeout, timer);
        }, 1000);

        // Manejador para reiniciar el temporizador cuando la pestaña vuelve a estar activa.
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                handleEvent();
            }
        };

        // Si `timer` es 0, el efecto no establece ningún temporizador de inactividad.
        if (timer == 0) {
            return;
        }

        timeoutId = window.setTimeout(handleTimeout, timer);

        // Registra los manejadores de eventos para detectar actividad del usuario.
        window.addEventListener('mousemove', handleEvent);
        window.addEventListener('mousedown', handleEvent);
        window.addEventListener('resize', handleEvent);
        window.addEventListener('keydown', handleEvent);
        window.addEventListener('touchstart', handleEvent);
        window.addEventListener('wheel', handleEvent);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Limpieza de efectos y manejadores de eventos al desmontar o actualizar el componente.
        return () => {
            window.removeEventListener('mousemove', handleEvent);
            window.removeEventListener('mousedown', handleEvent);
            window.removeEventListener('resize', handleEvent);
            window.removeEventListener('keydown', handleEvent);
            window.removeEventListener('touchstart', handleEvent);
            window.removeEventListener('wheel', handleEvent);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.clearTimeout(timeoutId);
        };
    }, [timer]);

    // Expone el estado `isIdle` y la función `startIdleTimer` para iniciar el seguimiento de inactividad.
    return {
        isIdle,
        startIdleTimer: (ms) => setTimer(ms),
    };
}

// `throttle` limita la frecuencia con la que se puede llamar a una función.
function throttle(cb, ms) {
    let lastTime = 0;
    return () => {
        const now = Date.now();
        if (now - lastTime >= ms) {
            cb();
            lastTime = now;
        }
    };
}
