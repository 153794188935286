import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'lib/hooks/analytics';

// components
import AlertBadge from 'components/common/AlertBadge';
import CreditCard from 'components/payment/CreditCard';
import Modal from 'components/common/Modal';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';

// store
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { isRipleyCredit } from 'lib/utils/payments';
import { setPaymentDummy } from 'store/paymentMethod/actions';
import { setPaymentMethod } from 'store/paymentMethod/ripleyCard.slice';
import { setCard } from 'store/card/ripleyCard.slice';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { useRules } from 'lib/hooks/rules';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';
import { KIOSKO } from 'consts';
import { RootState } from 'types/store';

export type cardAdderProps = {
    /** This parameter will set type of the icon */
    iconType: IconType;

    /** It allow to determine the method to process the credit card  */
    paymentMethodName: PaymentMethodName;

    /** This field determine the name for the payment method */
    paymentMethodTitle: string;

    /** This field determine the name to process */
    name: string;

    /** This field determine the title for the modal */
    header: string;
};

export function CardAdder(props: cardAdderProps): JSX.Element {
    const { paymentMethodName, paymentMethodTitle, name, iconType, header } = props;
    const dm = useDeliveryMethod();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('error');
    const analytics = useAnalytics();
    const cart = useAppSelector((state: RootState) => state.cart);
    const user = useAppSelector((state: RootState) => state.user);
    const addresses = useAppSelector((state: RootState) => state.address);
    const paymentMethod = useAppSelector((state: RootState) => state.paymentMethod);
    const isKiosko = useAppSelector((state: RootState) => state.cart.data?.channel == KIOSKO);
    const ripleyCard = paymentMethod.types.ripleyCard;
    const [isOpen, setIsOpen] = useState(false);
    const [isInvalidBin, setIsInvalidBin] = useState(false);
    const rules = useRules();
    const isRegisteredUser = user.data?.registered;
    const allowRemember = rules.feature('cardOnFile') && !isKiosko && isRegisteredUser;

    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    useEffect(() => {
        if (isInvalidBin) {
            analytics.push('checkout_error', 'restrictedBin');

            sendErrorToGA4({
                channel: cart.data?.channel,
                error: 'Bin invalido',
                section: 'PaymentSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [isInvalidBin, analytics]);

    useEffect(() => {
        if (ripleyCard.error?.code && ripleyCard.status === 'error') {
            sendErrorToGA4({
                channel: cart.data?.channel,
                error: t(`${ripleyCard.error.code}.message`),
                section: 'PaymentSection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [ripleyCard.error?.code, ripleyCard.status]);

    const handleOnClick = async () => {
        if (cart.data == null || user.data == null || addresses.data == null) {
            // TODO: better error handling
            return;
        }
        setIsOpen(!isOpen);
        dispatch(setPaymentDummy({ paymentMethod: paymentMethodName })); // se debe cambiar por check
    };

    const handleOnChange = async (v: string) => {
        setIsInvalidBin(v.length >= 6 && !isRipleyCredit(v));
    };

    const handleOnSubmit = async (card: Card) => {
        if (cart.data == null) {
            // TODO: better error handling
            return;
        }
        try {
            await dispatch(
                setPaymentMethod({ cartId: cart.data.id, card, paymentMethod: paymentMethodName }),
            ).unwrap();
            await dispatch(setCard(card)).unwrap();
            setIsOpen(false);
        } catch (e) {
            // console.error(e);
        }
    };

    const isLoading =
        paymentMethod.data.status === 'pending' &&
        paymentMethod.data.loading.includes(paymentMethodName);

    const isBlocked =
        (paymentMethod.data.status === 'pending' && !isLoading) ||
        couponLoading ||
        !dm.hasSchedules();

    return (
        <>
            <PaymentMethodItem
                paymentMethodName={paymentMethodTitle}
                type={iconType}
                widthIcon="63px"
                onClick={handleOnClick}
                blocked={isBlocked}
                selected={isOpen}
            />
            <Modal
                onToggle={() =>
                    // TODO: Reset payment
                    setIsOpen(false)
                }
                isOpen={isOpen}
                header={header}
            >
                <CreditCard
                    disableRemember={!allowRemember}
                    disabled={isInvalidBin || paymentMethod.data.status === 'pending'}
                    method={name}
                    onChange={handleOnChange}
                    onSubmit={handleOnSubmit}
                    pending={ripleyCard.status === 'pending'}
                >
                    {isInvalidBin && (
                        <AlertBadge theme="warning" size="large">
                            Solo puedes ingresar <b>tarjetas {name} Crédito</b>. Para tarjetas de
                            débito utiliza Webpay.
                        </AlertBadge>
                    )}
                    {paymentMethod.data.status === 'error' && (
                        <AlertBadge theme="warning" size="large">
                            Por favor vuelve a intentar.
                        </AlertBadge>
                    )}
                    {ripleyCard.status === 'error' &&
                        (() => {
                            switch (ripleyCard.error?.code) {
                                case 'errInternalTimeout':
                                    analytics.push('checkout_error', 'checkout_timeout');
                                    return (
                                        <AlertBadge theme="warning" size="large">
                                            {t('errInternalTimeout.title')}
                                            <br />
                                            {t('errInternalTimeout.message')}
                                        </AlertBadge>
                                    );
                                case 'errITFOwnership':
                                    return (
                                        <AlertBadge theme="warning" size="large">
                                            {t('errITFOwnership.message')}
                                        </AlertBadge>
                                    );
                                default:
                                    analytics.push('checkout_error', 'errorCreditInput');
                                    return (
                                        <AlertBadge theme="danger" size="large">
                                            Revisa que los datos sean correctos y vuelve a intentar
                                        </AlertBadge>
                                    );
                            }
                        })()}
                </CreditCard>
            </Modal>
        </>
    );
}
