import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';
import { setOptions } from 'store/card/options';
import { NiubizCard, setCard, getOptions, setCardByToken } from 'store/card/niubiz';
import { resetPayments, getPayments } from './actions';
import { getAddressComponentsWithNames } from 'lib/utils/addresses';

export const setPaymentMethod = createAsyncThunk(
    'niubiz/setPaymentMethod',
    async ({ isRipley, onChange }: NiubizSetPaymentMethodArg, { getState }) => {
        const state = getState() as RootState;
        const cartId = state.cart.data?.id ?? '';
        const pm: PaymentMethodName = isRipley ? 'RipleyNiubiz' : 'Niubiz';
        const setPaymentPayload = getPaymentPayload(state, isRipley);
        const { paymentInfo } = await services.cart.setPayment(cartId, setPaymentPayload);
        // It updates the cart for getting the new order id
        const cart = await services.cart.getById(cartId);
        const payment = paymentInfo.find((p) => p.paymentMethod === pm);
        if (payment?.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        await window.paymenthub.loadScript(window.paymenthub.NIUBIZ_JS);
        await window.paymenthub.reloadNiubizSDK();

        const form = await window.paymenthub.createForm(
            cart.orderId,
            payment.amount.value,
            payment.customFields.sessionToken.sessionKey,
            /* add input style */ false,
            onChange,
        );
        if (form.success === false) {
            throw { op: 'AddCartPayment', code: 'errExternalService' };
        }

        return { id: payment.id ?? '', cart };
    },
);

export const setPaymentByToken = createAsyncThunk(
    'niubiz/setPaymentByToken',
    async ({ isRipley, token }: SetPaymentByTokenArg, { getState, dispatch }) => {
        const paymentMethod = isRipley ? 'RipleyNiubiz' : 'Niubiz';
        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null ||
            token.id == null
        ) {
            throw new Error('cart, user, address, area store or token id are empty');
        }

        /* Create the paymentInfo */
        const payments = getPayments({
            paymentMethod,
            cart: state.cart.data,
            user: state.user.data,
            addresses: state.address.data,
            areas: state.area.data,
            card: token,
        });

        const cartId = state.cart.data.id;

        const { cart } = await services.cart.setPayment(cartId, payments);
        if (cart == null) {
            throw new Error('cart setPayment has failed');
        }

        const bin = token.maskedPan.slice(0, 6);
        if (bin == null) {
            throw new Error('missing bin');
        }

        const paymentId = cart.paymentInfo?.find((p) => p.paymentMethod == paymentMethod)?.id;
        if (paymentId == null) {
            throw new Error('missing paymentId');
        }

        const cardId = token.id;
        dispatch(setCardByToken({ isRipley, paymentId, cardId, bin }));
        return { paymentId, cart };
    },
);

const initialState: NiubizState = {
    status: 'idle',
    error: null,
    data: null,
    card: null,
    options: {
        status: 'idle',
        error: null,
        data: { installments: [], deferred: [] },
    },
    selectedByToken: false,
};

export const slice = createSlice({
    name: 'niubiz',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPayments.fulfilled, (state: NiubizState) => {
            state.status = 'idle';
            state.card = null;
            state.data = null;
            state.error = null;
            state.selectedByToken = false;
        });

        builder.addCase(setPaymentMethod.pending, (state: NiubizState, { meta }) => {
            if (meta.arg.isRipley) {
                return; // ignore RipleyNiubiz
            }
            state.status = 'pending';
            state.data = null;
            state.error = null;
        });
        builder.addCase(setPaymentMethod.rejected, (state: NiubizState, { error, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'error';
            state.data = null;
            state.error = error;
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: NiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'ok';
            const { id } = payload;
            state.data = { id };
            state.error = null;
        });

        builder.addCase(setPaymentByToken.pending, (state: NiubizState, { meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'pending';
            state.selectedByToken = true;
        });
        builder.addCase(setPaymentByToken.rejected, (state: NiubizState, { error, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'error';
            state.error = error;
            state.selectedByToken = false;
        });
        builder.addCase(setPaymentByToken.fulfilled, (state: NiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'ok';
            state.data = payload.cart;
            state.selectedByToken = true;
        });

        builder.addCase(setCard.pending, (state: NiubizState, { meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'pending';
        });
        builder.addCase(setCard.rejected, (state: NiubizState, { error, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'error';
            state.card = null;
            state.error = error;
        });

        builder.addCase(setCard.fulfilled, (state: NiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'ok';
            state.card = payload;
            state.error = null;
        });

        builder.addCase(setCardByToken.pending, (state: NiubizState, { meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'pending';
        });

        builder.addCase(setCardByToken.rejected, (state: NiubizState, { error, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'error';
            state.card = null;
            state.error = error;
        });

        builder.addCase(setCardByToken.fulfilled, (state: NiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) return;
            state.status = 'ok';
            state.card = payload;
            state.error = null;
        });

        builder.addCase(getOptions.pending, (state: NiubizState, { meta }) => {
            if (meta.arg.isRipley) return;
            state.options.status = 'pending';
        });
        builder.addCase(getOptions.rejected, (state: NiubizState, { error, meta }) => {
            if (meta.arg.isRipley) return;
            state.options.status = 'error';
            state.options.error = error;
        });
        builder.addCase(getOptions.fulfilled, (state: NiubizState, { payload, meta }) => {
            if (meta.arg.isRipley) return;
            state.options.status = 'ok';
            state.options.data = payload;
        });

        builder.addCase(setOptions.pending, (state: NiubizState, { meta }) => {
            if (meta.arg.paymentMethod === 'Niubiz') {
                state.options.status = 'pending';
            }
        });
        builder.addCase(setOptions.fulfilled, (state: NiubizState, { meta }) => {
            if (meta.arg.paymentMethod === 'Niubiz') {
                state.options.status = 'ok';
                if (state.card) {
                    // state.card.deferred = meta.arg.deferred;
                    state.card.installments = meta.arg.installments ?? 1;
                }
            }
        });
    },
});

export type NiubizData = {
    id: string;
};

export type NiubizState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    error: SerializedError | null;
    data: NiubizData | null;
    card: NiubizCard | null;
    options: {
        status: 'idle' | 'pending' | 'ok' | 'error';
        error: SerializedError | null;
        data: CardOptions;
    };
    selectedByToken: boolean;
};

function getPaymentPayload(state: RootState, isRipley: boolean) {
    const pm: PaymentMethodName = isRipley ? 'RipleyNiubiz' : 'Niubiz';
    if (state.cart.data == null || state.user.data == null) {
        throw { code: 'errBadRequest', message: 'Datos insuficientes en carro y usuario' };
    }

    const amount =
        (isRipley ? state.cart.data.grandTotal['ripley_promo'] : null) ??
        state.cart.data.grandTotal['discount'] ??
        state.cart.data.grandTotal['master'];

    const address = getAddressComponentsWithNames(
        state.address.data?.byId[state.address.data?.selected ?? '']?.addressComponents ?? [],
        state.area.data,
    );

    const customer = {
        nin: state.user.data.nin ?? '',
        firstName: state.user.data.firstname ?? '',
        lastName: state.user.data.lastname ?? '',
        email: state.user.data.email ?? '',
        phoneNumber: state.user.data.phoneNumber ?? '',
    };

    return [
        {
            amount,
            documentType: 'boleta' as const,
            paymentMethod: pm,
            billingInfo: { customer, address },
        },
    ];
}

export type SetPaymentByTokenArg = {
    isRipley: boolean;
    token: CardToken;
};

export type NiubizSetPaymentMethodArg = {
    isRipley: boolean;
    onChange?: (state: NiubizFormState) => void;
};

export type NiubizFormState = {
    isValid: boolean;
    // inputs: {
    //     id: string;
    //     errors: unknown[];
    //     isValid: boolean;
    //     code: string;
    // }[];
};
