import React from 'react';
import styles from './DeliveryAlert.module.scss';

export type DeliveryAlertProps = {
    title: string;
    message?: string;
    children?: React.ReactNode;
};

export const DeliveryAlert: React.FC<DeliveryAlertProps> = (props: DeliveryAlertProps) => {
    return (
        <div className={styles['box']}>
            <div className={styles['header']}>
                <h2 className={styles['title']}>{props.title}</h2>
                {props.message && <div className={styles['message']}>{props.message}</div>}
            </div>
            <div className={styles['body']}>{props.children}</div>
        </div>
    );
};
