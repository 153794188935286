import { useState, useLayoutEffect } from 'react';

/**
 * Return the and width y height of the window each time that it is resized
 * @returns
 */
function useWindowSize(): number[] {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default useWindowSize;
