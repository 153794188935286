import React from 'react';
import styles from './InstallmentsSimulation.module.scss';

export type InstallmentsSimulationProps = {
    monthly: string;
    cae: string;
    total: string;
};

export const InstallmentsSimulation: React.FC<InstallmentsSimulationProps> = ({
    monthly,
    cae,
    total,
}: InstallmentsSimulationProps) => {
    return (
        <div className={styles.container}>
            <div>
                <div>Pagos Mensuales</div>
                <div>{monthly}</div>
            </div>
            <div>
                <div>
                    <b>CAE</b>
                </div>
                <div>{cae}</div>
            </div>
            <div>
                <div>
                    <b>Costo Total</b>
                </div>
                <div>
                    <b>{total}</b>
                </div>
            </div>
        </div>
    );
};
