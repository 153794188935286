import React, { FC } from 'react';
import styles from '../CreditCard/CreditCard.module.scss';
import { CodeProps, ExpirationDateProps, NumberProps, TypeProps } from './CreditCard.interface';

/** Component that shows the cvc on the CreditCard component */
export const Code: FC<CodeProps> = (props: CodeProps) => {
    const { cvc, focused } = props;

    let className = styles['card-cvc-front'] + ' tlPrivate';

    if (focused === 'cvc') {
        className += ' ' + styles.focused;
    }

    return <div className={className}>{cvc}</div>;
};

/** Component that shows the expiration date on the CreditCard component */
export const ExpirationDate: FC<ExpirationDateProps> = (props: ExpirationDateProps) => {
    const { expiry, focused } = props;

    let className = styles['card-expiry'] + ' tlPrivate';

    if (focused === 'expiry') {
        className += ' ' + styles.focused;
    }

    if (expiry.substring(0, 1) !== '') {
        className += ' ' + styles.filled;
    }

    return <div className={className}>{expiry}</div>;
};

/** Component that shows the card number on the CreditCard component */
export const Number: FC<NumberProps> = (props: NumberProps) => {
    const { number, focused } = props;

    let className = styles['card-number'] + ' tlPrivate';

    if (number.replace(/ /g, '').length > 16) {
        className += ' ' + styles['card-number-large'];
    }

    if (focused === 'number') {
        className += ' ' + styles.focused;
    }
    if (number.substring(0, 1) !== '*') {
        className += ' ' + styles.filled;
    }

    return <div className={className}>{number}</div>;
};

/** Component that shows credit card type on the CreditCard component */
export const Type: FC<TypeProps> = ({ method }: TypeProps) => (
    <div className={styles['card-name']}>
        {method === 'RipleyCard' ? 'Tarjeta Ripley Crédito' : 'Tarjeta de Crédito'}
    </div>
);
