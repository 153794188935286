import React from 'react';
import PaymentMethodsContainer from 'containers/aio/PaymentMethodsContainer';

export const PaymentSection = React.forwardRef<HTMLElement>(function PaymentSection(_, ref) {
    return (
        <section ref={ref}>
            <PaymentMethodsContainer />
        </section>
    );
});
