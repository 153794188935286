import React, { useState } from 'react';
import styles from './SelectField.module.scss';
import arrowDownImg from './chevron.svg';

export interface Option {
    value: string;
    label: string;
}

export interface SelectFieldProps {
    /**
     * Sets the name of the field
     */
    name?: string;
    /**
     * Sets whether the field is invalid
     */
    isInvalid?: boolean;
    /**
     * Specifies the text or label that describes the text field
     */
    label?: string;
    /**
     * Array with the information needed to build
     * the options of the select component
     */
    options: Option[];
    /**
     * Specifies whether the field should be disabled
     */
    disabled?: boolean;
    /**
     * used for the first option of the select component
     */
    empty?: boolean | string;
    /**
     * Specifies the default Value
     */
    defaultValue?: string;

    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectField = React.forwardRef(function SelectField(props: SelectFieldProps, ref) {
    const {
        name,
        label,
        options = [],
        disabled,
        empty = true,
        isInvalid,
        defaultValue,
        onChange,
        onFocus,
        onBlur,
        ...args
    } = props;

    const [open, setOpen] = useState(false);
    /**
     * Handles the select's change event.
     * @param event
     */
    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(event);
    };

    /**
     * Handles the select's focus event.
     * @param event
     */
    const handleOnFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
        // open the select tab
        setOpen(true);

        // Custom onFocus passed in from outside
        onFocus?.(event);
    };

    /**
     * Handles the select's blur event.
     * @param event
     */
    const handleOnBlur = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // close the select tab
        setOpen(false);

        // Custom onFocus passed in from outside
        onBlur?.(event);
    };

    /**
     * Builds the classNames to be passed to the component
     */
    const controlClassName = getControlClassName(options);
    const fieldsetClassName = getFieldsetClassName(open, !!isInvalid);
    const legendClassName = getLegendClassName(!!isInvalid);

    /**
     * Builds the attributes passed to the HTML input
     */
    const inputAttributes = {
        'data-testid': name,
        name: name,
        className: controlClassName,
        disabled,
        defaultValue,
        ref: ref as React.RefObject<HTMLSelectElement>,
        style: { backgroundImage: `url(${arrowDownImg})` },
        onChange: (e: React.ChangeEvent<HTMLSelectElement>) => handleOnChange(e),
        onFocus: (e: React.FocusEvent<HTMLSelectElement>) => handleOnFocus(e),
        onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => handleOnBlur(e),
        ...args,
    };

    return (
        <fieldset className={fieldsetClassName}>
            <select {...inputAttributes}>
                {empty && <option value="">{empty}</option>}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <legend className={legendClassName}>
                <span>{label}</span>
            </legend>
        </fieldset>
    );
});

/**
 * Builds the className to be passed to the component
 * @param options
 */
function getControlClassName(options: Option[]) {
    let controlClassName = styles['control'];
    if (options[0]?.label === '') {
        controlClassName += ' ' + styles['control--empty'];
        return controlClassName;
    }
    controlClassName += ' ' + styles['control--selected'];
    return controlClassName;
}

/**
 * Builds the fieldset ClassName
 * @param open
 * @param invalid
 */
function getFieldsetClassName(open: boolean, invalid: boolean) {
    let fieldsetClassName = styles['group'];
    if (open) {
        fieldsetClassName += ' ' + styles['group--open'];
    }
    if (invalid) {
        fieldsetClassName += ' ' + styles['invalid'];
    }
    return fieldsetClassName;
}

/**
 * Builds the legend className
 * @param invalid
 */
function getLegendClassName(invalid: boolean) {
    let legendClassName = styles['legend'];
    if (invalid) {
        legendClassName += ' ' + styles['invalid'];
    }
    return legendClassName;
}
