import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from '../../services';
import { getEnvironment } from 'config';

type fetchUserProps = {
    cart: Cart;
    areas: ExtArea;
    light?: boolean;
};

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (fetchUserProps: fetchUserProps) => {
        const { cart, areas, light } = fetchUserProps;
        const response = await services.user.getInfo(cart, areas, light);
        return response;
    },
);

export const logoutUser = (): void => {
    const { kiosko } = getEnvironment();
    const URL = kiosko.logoutUrl || '';
    window.location.href = URL;
    return;
};

export const createUser = createAsyncThunk(
    'user/createUser',
    async (createUserPayload: CreateUserPayload) => {
        const response = await services.user.createUser(createUserPayload);
        return response;
    },
);

export type UserState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: NormalizedUser | null;
    error: SerializedError | null;
};

const initialState: UserState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state: UserState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchUser.fulfilled, (state: UserState, action) => {
            state.status = 'ok';
            state.data = action.payload.user;
            // NOTE: In the 'store' slice a region and a city will be selected
            // using the selectedAddress of this fetch.
        });
        builder.addCase(fetchUser.rejected, (state: UserState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(createUser.pending, (state: UserState) => {
            state.status = 'pending';
        });
        builder.addCase(createUser.fulfilled, (state: UserState) => {
            state.status = 'ok';
        });
        builder.addCase(createUser.rejected, (state: UserState) => {
            state.status = 'error';
        });
    },
});

export default slice;
