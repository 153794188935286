import React, { ReactNode } from 'react';
import ProductThumb from '../../common/ProductThumb';
import { ProductThumbProps } from '../../common/ProductThumb/ProductThumb';
import styles from './PickupStoreStock.module.scss';

export const productDisplay = (
    productArray: Array<ProductThumbProps>,
    isExpanded: boolean,
    setIsExpanded: (value: boolean) => void,
    maxThumbs: number,
): ReactNode => {
    if (productArray && productArray.length == 0) return;
    const hasShowMore = (index: number) =>
        productArray.length > maxThumbs && index === maxThumbs - 1 && !isExpanded;
    return productArray
        .slice(0, maxThumbs)
        .map((props, index) => (
            <ProductThumb
                key={index}
                hasRightMargin={index !== productArray.length - 1}
                showMore={hasShowMore(index) ? productArray.length - maxThumbs : undefined}
                onClick={hasShowMore(index) ? () => setIsExpanded(true) : undefined}
                onKeyPress={hasShowMore(index) ? () => setIsExpanded(true) : undefined}
                {...props}
            />
        ));
};

export const productAvailability = (productArray: Array<ProductThumbProps>): ReactNode => {
    if (!productArray) return;
    const availableProducts = productArray.filter((row) => row.availability == 'available').length;
    const isPluralUnavailable = productArray.length > 1;

    if (availableProducts === productArray.length) {
        return <div className={styles['availability'] + ' ' + styles['available']}>Disponible</div>;
    }
    return (
        <div className={styles['availability'] + ' ' + styles['unavailable']}>
            {`${availableProducts} de ${productArray.length} 
            producto${isPluralUnavailable ? 's disponibles' : ' disponible'}`}
        </div>
    );
};
