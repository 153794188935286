import React, { Fragment } from 'react';
import styles from './CouponForm.module.scss';
import Card from '../../common/Card';
import couponSVG from './images/coupon.svg';
import couponBlackSVG from './images/coupon-black.svg';
import CouponTag from '../../payment/CouponTag';

export type Field = {
    component: React.ReactNode;
    note?: React.ReactNode;
};

export type CouponFormProps = {
    title?: string;
    fields?: Field[];
    bottomMessage?: React.ReactNode;
    currentCoupons: Coupon[];
    handleDeleteCoupon: (coupon: string) => void;
    theme?: 'primary' | 'section';
    sectionTitle?: string;
};

export const CouponForm: React.FC<CouponFormProps> = (props: CouponFormProps) => {
    const {
        bottomMessage,
        currentCoupons,
        fields,
        handleDeleteCoupon,
        sectionTitle,
        theme = 'primary',
        title,
    } = props;

    const icon = (
        <img
            src={theme == 'primary' ? couponSVG : couponBlackSVG}
            alt="Cupon"
            className={styles['card_coupon_svg'] + ' ' + styles[theme]}
        />
    );

    const cardProps = {
        variant: theme == 'primary' ? ('gray' as const) : ('white' as const),
        title: theme == 'primary' ? '' : sectionTitle,
        isSection: theme == 'section',
    };

    return (
        <Card {...cardProps}>
            <div className={styles['card__header'] + ' ' + styles[theme]}>
                {icon}
                <h2 className={styles['card__title']}>{title}</h2>
            </div>
            {fields &&
                fields.map((field, index) => (
                    <div className={styles['card__field']} key={index}>
                        {field.component}
                        {field.note && <div className={styles['card__note']}>{field.note}</div>}
                    </div>
                ))}
            {currentCoupons.length ? (
                <div className={styles['card__coupons']}>
                    {currentCoupons.map((coupon, key) => {
                        return (
                            <Fragment key={`${coupon.coupon}${key}`}>
                                <CouponTag
                                    coupon={coupon}
                                    handleDeleteCoupon={() => handleDeleteCoupon(coupon.coupon)}
                                />
                            </Fragment>
                        );
                    })}
                </div>
            ) : (
                <div className={styles['card__bottom_message']}>{bottomMessage}</div>
            )}
        </Card>
    );
};

CouponForm.defaultProps = {
    title: 'Cupón de descuento/código club',
    fields: [],
    bottomMessage: <></>,
};
