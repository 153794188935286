import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from '../../services';

export const fetchRules = createAsyncThunk('rule/fetchRules', () => {
    return services.rule.fetchAll();
});

export type RulesState = {
    status: 'idle' | 'pending' | 'error' | 'ok';
    error: SerializedError | null;
    data: Rules;
};

const initialState: RulesState = {
    status: 'idle',
    error: null,
    data: {
        payments: [],
        paymentsInternet: [],
        paymentsKiosko: [],
        paymentsMobile: [],
        paymentsRegistered: [],
        paymentsGuest: [],
        paymentsRipley: [],
        paymentsMarketplace: [],
        paymentsShipping: [],
        paymentsPickup: [],
        paymentsGiftCard: [],
        cardOnFile: true,
        coupons: true,
        factura: false,
        deferred: false,
        simulation: false,
        copago: false,
        marketplacePickup: false,
        separateFulfillment: false,
        separateFulfillmentPromises: false,
        paymentWithoutCvv: false,
        parameters: {
            chekStatusPollingInterval: 1000,
            chekAuthorizationTimeout: 300000,
            chekAmountLimit: '500000',
        },
        paymentAmountRange: [],
        checkoutTimer: [],
    },
};

export const slice = createSlice({
    name: 'rule',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchRules.pending, (state: RulesState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchRules.rejected, (state: RulesState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(fetchRules.fulfilled, (state: RulesState, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });
    },
});
