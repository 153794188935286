import React from 'react';
import PaymentMethodList from 'components/payment/PaymentMethodList';
import CreditCardTitle from 'components/payment/CreditCardTitle';

// Types
import { TFunction } from 'i18next';
import {
    Deliveries,
    DeliveryAddress,
} from 'components/shipping/DeliveryResume/DeliveryResume.interface';
import { PaymentDetail } from 'components/payment/PaymentSummary';
import { Product } from 'components/common/ProductWithWarranty';
import { Price } from 'components/common/Prices';
import { PaymentMethod } from 'components/payment/Confirmation/Confirmation';
import { getShippingDate } from 'containers/aio/PickupSummaryContainer/PickupSummaryContainer.utils';
import { getProducts } from 'containers/CartContainer/CartContainer.utils';
import { PaymentMethodStore } from 'types/store';
import { NiubizCardNumber } from 'containers/payment/NiubizSelector/NiubizSelector.utils';
import { getCardType, getIconType, getPaymentName } from 'lib/utils/payments';

export const formatDeliveries = (cart: Cart, t: TFunction): Deliveries[] => {
    const shippingInfo: ShippingInfo[] = cart.shippingInfo || [{} as ShippingInfo];

    // Aisla y formatea todos los productos junto con su id
    const products = cart.products.map((cartProduct) => {
        const related = cartProduct.related ? getProducts(cartProduct.related, t) : null;
        const value: Product = {
            sku: cartProduct.sku,
            name: cartProduct.details.name,
            prices: Array<Price>({
                type: '',
                amount: '',
            }),
            tags: [],
            type: cartProduct.type,
            thumbnail: cartProduct.details.thumbnail,
            quantity: cartProduct.quantity,
            related,
        };
        return {
            id: cartProduct.id,
            value: value,
        };
    });

    // relaciona objetos dentro de shippingInfo con productos, creacion del Array de Deliveries
    const deliveries: Deliveries[] = shippingInfo.map((schedule) => {
        return {
            estimatedDate: getShippingDate(schedule),
            products: schedule.productsList.map(
                (productId) => products.find((product) => product.id == productId)?.value,
            ),
        } as Deliveries;
    });
    return deliveries;
};

// Builds the details prop for the PaymentSummary component
export const formatDetails = (
    payments: PaymentMethod[],
    card: ExternalCard | null,
): PaymentDetail[] => {
    // Add object {label, value} to the array to show more info
    const details = Array<PaymentDetail>({
        label: 'Medio de pago',
        value: <PaymentMethodList payments={payments} card={card} />,
    });
    return details;
};

// calculates the points based on the total price and the payment method
export const goPointsCalculator = (totalPrice: string, paymentMethod: string): number => {
    let goPointsPercentage: number;
    let intTotalPrice: number;
    // totalPrice parse to int
    totalPrice == '' ? (intTotalPrice = 0) : (intTotalPrice = parseInt(totalPrice));

    switch (paymentMethod) {
        case 'RipleyCardBlack':
            goPointsPercentage = 0.01;
            break;
        case 'RipleyCard':
            goPointsPercentage = 0.0067;
            break;
        case 'RipleyChek':
            goPointsPercentage = 0.002;
            break;
        case 'WebpayCredito':
            goPointsPercentage = 0.0015;
            break;
        case 'WebpayDebito':
            goPointsPercentage = 0.0015;
            break;
        case 'Cybersource':
            goPointsPercentage = 0.0015;
            break;
        case 'Giftcard':
            goPointsPercentage = 0;
            break;
        case 'Niubiz':
        case 'RipleyNiubiz':
            goPointsPercentage = 0;
            break;
        default:
            goPointsPercentage = 0;
    }
    return Math.ceil(intTotalPrice * goPointsPercentage);
};

// Format the information the store address depends of option delivery selected for user/client
export const formatCustomerAddress = (
    typeDelivery: string,
    customerAddress: { [field: string]: string },
): DeliveryAddress => {
    const fields = ['streetName', 'city'];

    if (typeDelivery === 'RT') {
        fields.push('nickname', 'homeNumber');
    }

    const format = fields
        .map((v: string) => customerAddress[v].trim())
        .filter((v: string) => v.length > 0);

    if (format[2]) {
        format[2] =
            'Ripley ' +
            format[2]
                .toLowerCase()
                .split(' ')
                .map((f: string) => f.charAt(0).toUpperCase() + f.substring(1))
                .join(' ');
    }

    return {
        type: typeDelivery,
        storeName: format[2] || '',
        storeDetails: format[3] || '',
        street: format[0],
        city: format[1],
        homeNumber: customerAddress.homeNumber || '',
    };
};

export function getPayments(
    paymentMethods: PaymentMethodStore,
    card: ExternalCard | null,
    cardToken?: CardToken,
): PaymentMethod[] {
    const pm = paymentMethods.data?.selected ?? [];
    return pm.map((m: PaymentMethodName) => {
        if (m === 'Niubiz' || m === 'RipleyNiubiz') {
            const niubizCard = paymentMethods.types.niubiz.card;
            const isGiftCard = niubizCard?.bin.startsWith('212121');
            const cardType = getCardType(niubizCard?.bin ?? '');
            const maskedPan = cardToken?.maskedPan ?? niubizCard?.bin;

            return {
                label: isGiftCard ? 'Gift Card' : getPaymentName(m, cardType),
                icon: isGiftCard ? 'giftcard' : getIconType(m, cardType),
                name: m,
                cardNumber: <NiubizCardNumber bin={maskedPan} />,
                installments: niubizCard?.installments,
                deferredPeriod: niubizCard?.deferred,
            };
        }

        if (m === 'Niubiz3DS') {
            return {
                label: 'Yape',
                icon: getIconType(m),
                name: m,
            };
        }

        let payment: PaymentMethod = {
            label: getPaymentName(m, card?.type, card?.maskedPan),
            icon: getIconType(m, card?.type, card?.maskedPan),
            name: m,
        };
        if (m == 'RipleyCard' || m == 'Cybersource' || m == 'RipleyChekCard') {
            payment = {
                ...payment,
                cardNumber: card && <CreditCardTitle card={card} />,
                installments: card?.installments,
                deferredPeriod: card?.deferred,
            };
        }

        return payment;
    });
}
