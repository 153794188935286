import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { format as formatDate } from 'date-fns';
import numeral from 'numeral';

import es_cl_translation from './es/cl/translation.json';
import es_cl_error from './es/cl/error.json';
import es_cl_format from './es/cl/format.json';
import es_cl_validations from './es/cl/validations.json';
import es_pe_translation from './es/pe/translation.json';
import es_pe_error from './es/pe/error.json';
import es_pe_format from './es/pe/format.json';
import es_pe_validations from './es/pe/validations.json';
import { getEnvironment } from 'config';

const envs = getEnvironment();

const lang = envs.app.locale || 'es_CL';

const resources = {
    es_CL: {
        translation: es_cl_translation,
        error: es_cl_error,
        format: es_cl_format,
        validations: es_cl_validations,
    },
    es_PE: {
        translation: es_pe_translation,
        error: es_pe_error,
        format: es_pe_format,
        validations: es_pe_validations,
    },
};

/**
 * We create the i18next instance.
 *
 * We use initReactI18next for creating automatically a
 * context that allow the use of this instance.
 */
i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    interpolation: {
        escapeValue: false,
        format: function (value, format) {
            if (format === 'CLP') {
                return numeral(value).format('$0,0');
            }
            if (format === 'PEN') {
                return numeral(value).format('$0,0.00');
            }
            if (value instanceof Date) {
                return formatDate(value, format || 'MM/dd/yyyy');
            }
            return value;
        },
    },
});

i18n.services.pluralResolver.addRule('es_CL', {
    numbers: [1, 2],
    plurals: (n: number | string) => Number(n != 1),
});

i18n.services.pluralResolver.addRule('es_PE', {
    numbers: [1, 2],
    plurals: (n: number | string) => Number(n != 1),
});

// Definimos los formatos para la libreria `numeral`.
// Copiado de node_modules/numeral/locales/es.js
const numeral_CL = {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'mm',
        billion: 'b',
        trillion: 't',
    },
    ordinal(n: number) {
        const b = n % 10;
        return b === 1 || b === 3
            ? 'er'
            : b === 2
            ? 'do'
            : b === 7 || b === 0
            ? 'mo'
            : b === 8
            ? 'vo'
            : b === 9
            ? 'no'
            : 'to';
    },
    currency: {
        symbol: '$',
    },
};

const numeral_PE = {
    // NOTE: esta mezcla es superficial asi que si quieres cambiar una sola cosa
    // por ejemplo solo `delimiters.decimal` tienes que enviar de todas formas
    // todos los campos de delimiters (en el ejemplo agregar tambien thousands.
    ...numeral_CL,
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    currency: {
        symbol: 'S/',
    },
};

// Disponibilizar formatos
numeral.register('locale', 'es_CL', numeral_CL);
numeral.register('locale', 'es_PE', numeral_PE);
// Asignar locale de acuerdo a las variables de entorno
numeral.locale(lang);

// Create a translation function for the given namespace
// #doc: https://www.i18next.com/overview/api#getfixedt
export const trans = (namespace: string): TFunction => i18n.getFixedT(null, namespace);

export default i18n;
