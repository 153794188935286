import { createAsyncThunk } from '@reduxjs/toolkit';

export const setOptions = createAsyncThunk(
    'card/setOptions',
    async ({ paymentId, installments, deferred }: SetOptionsArg) => {
        return await window.paymenthub.updateInstallments(paymentId, installments, deferred);
    },
);

export type SetOptionsArg = {
    paymentMethod: PaymentMethodName;
    paymentId: string;
    installments?: number;
    deferred?: number;
};

export type GetOptionsPayload = {
    paymentId: string;
    maskedPan?: string;
    bin?: string;
};
