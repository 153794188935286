import React, { useState, useEffect } from 'react';
import styles from './AddressSelectorContainer.module.scss';

import AlertBadge from 'components/common/AlertBadge';
import AddressList, { Item } from 'components/aio/AddressList';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import AddButton from 'components/common/AddButton';
import AddNewAddress from '../AddNewAddress';
import EditAddress from '../EditAddress';

import { deleteAddress } from 'store/address';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { selectAddress } from 'store/address/address.slice';
import { RootState } from 'types/store';
import { useTranslation } from 'react-i18next';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export type AddressSelectorContainerProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const AddressSelectorContainer: React.FC<AddressSelectorContainerProps> = (
    props: AddressSelectorContainerProps,
) => {
    const { t } = useTranslation('error');
    const dispatch = useAppDispatch();
    const addressState = useAppSelector((state: RootState) => state.address);
    const { registered } = useAppSelector((state: RootState) => state.user.data) as NormalizedUser;
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const address = addressState.data as NormalizedAddresses;
    const selectedAddress = address?.selected || '';
    const [addressList, setAddressList] = useState<Item[]>([]);
    const [temporalAddress, setTemporalAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [openAddNewAdress, setOpenAddNewAdress] = useState(false);
    const [editAddressId, setEditAddressId] = useState<string>('');
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
        setTemporalAddress(selectedAddress);
    }, [selectedAddress]);

    useEffect(() => {
        if (error) {
            sendErrorToGA4({
                channel: channel,
                error: error,
                section: 'DeliverySection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [error]);

    /**
     * Generates the items passed to the component
     */
    useEffect(() => {
        if (address)
            setAddressList(
                address.allIds.map((addressId: string) => {
                    const selected = address.byId[addressId];
                    return {
                        id: addressId,
                        fullAddress: selected.formattedAddress,
                        name: registered ? selected.nickname : '',
                        loading: false,
                    };
                }),
            );
    }, [address, registered]);

    /**
     * Handles the deletion of addresses
     * @param id
     */
    function handleDeleteAddress(id: string) {
        (async () => {
            // It prevents to delete the primary address
            if (id === selectedAddress) {
                setError(t('errDeletePrimaryAddress.message'));
                return;
            }

            try {
                setAddressList(toggleAddress(addressList, id, true));
                await dispatch(deleteAddress(id)).unwrap();

                if (id === temporalAddress) {
                    setTemporalAddress(selectedAddress);
                }

                setAddressList(addressList.filter((a) => a.id !== id));
            } catch (e) {
                setError(t('errDeleteAddress.message'));
                setAddressList(toggleAddress(addressList, id, false));
            }
        })();
    }

    /**
     * Handles the change of address
     */
    function handleChangeAddress() {
        (async () => {
            if (selectedAddress !== temporalAddress) {
                setLoading(true);

                try {
                    await dispatch(selectAddress(temporalAddress)).unwrap();
                    props.onClose();
                } catch (e) {
                    await dispatch(selectAddress(selectedAddress)).unwrap();
                }

                setLoading(false);
            } else {
                props.onClose();
            }
        })();
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onToggle={() => {
                !loading && props.onClose();
            }}
            header="Elige una dirección"
            footer={
                <React.Fragment>
                    {error && (
                        <AlertBadge theme="danger" timeout={5000} callback={() => setError(null)}>
                            {error}
                        </AlertBadge>
                    )}
                    <Button
                        theme="primary"
                        size="large"
                        disabled={loading}
                        onClick={handleChangeAddress}
                        loading={loading}
                    >
                        Guardar
                    </Button>
                </React.Fragment>
            }
        >
            <AddressList
                selectedAddress={temporalAddress}
                list={addressList}
                onChange={(id) => setTemporalAddress(id)}
                onDelete={handleDeleteAddress}
                onEdit={(addressId) => {
                    setEditAddressId(addressId);
                    setIsEditOpen(true);
                }}
            />
            {isEditOpen && (
                <EditAddress
                    addressId={editAddressId}
                    isOpen={isEditOpen}
                    setIsOpen={setIsEditOpen}
                />
            )}
            {openAddNewAdress && (
                <AddNewAddress isOpen={openAddNewAdress} setIsOpen={setOpenAddNewAdress} />
            )}
            {registered && (
                <div className={styles['add-button']}>
                    <AddButton
                        text={'Agregar nueva dirección'}
                        onClick={() => setOpenAddNewAdress(true)}
                    />
                </div>
            )}
        </Modal>
    );
};

/**
 * Toggles the `loading` field of an address
 * @param addresses
 * @param id
 * @returns
 */
function toggleAddress(addresses: Item[], id: string, isLoading: boolean): Item[] {
    return addresses.map((a) => {
        if (a.id === id) {
            return {
                ...a,
                loading: isLoading,
            };
        }

        return a;
    });
}
