import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { separateProducts } from 'store/separatedProducts';

import Button from 'components/common/Button';
import Products from 'components/common/Products';
import { ErrorTemplate, ErrorButton, ErrorLink } from 'components/common/RipleyError/ErrorTemplate';

import { getProducts } from 'containers/CartContainer/CartContainer.utils';
import { getEnvironment } from 'config';
import { RootState } from 'types/store';

const envs = getEnvironment();

export function SoldOutProductsContainer(): JSX.Element {
    const { t } = useTranslation();
    const { t: localize } = useTranslation('format');
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const badProducts = useAppSelector((state: RootState) => state.separatedProducts.data);
    const dispatch = useAppDispatch();

    if (cart == null) {
        return <></>;
    }

    const productsQty = cart.products.map((p) => p.quantity).reduce((a, b) => a + b, 0);
    let separatedProductsQty = 0;

    const separatedProducts: SeparatedProduct[] = [];
    badProducts.forEach((sp) => {
        const product = cart.products.find((p) => p.id == sp.id || p.sku == sp.sku);
        if (product == null) return null;
        // TODO: Descomentar lo de abajo cuando el BFF soporte borrar o cambiar
        // cantidad en el endpoint de separacion de productos.

        // let availableQty = Number(sp.availableQuantity);
        // if (isNaN(availableQty)) {
        //     availableQty = 0;
        // }
        // const count = product.quantity - availableQty;
        const count = product.quantity;
        separatedProductsQty += count;
        for (let i = 0; i < count; i++) {
            separatedProducts.push({ ...product, quantity: 0 });
        }
    });

    const products = (
        <Products
            list={getProducts(
                separatedProducts.map((p) => ({ ...p, quantity: 0 })),
                localize,
            )}
        />
    );
    const Space = (props: { children?: React.ReactNode }) => (
        <div style={{ marginBottom: '60px' }}>{props.children}</div>
    );

    // Check if after separation there will be at least one product unit left.
    if (separatedProductsQty == productsQty) {
        const tiendaUrl = envs.app.tiendaUrl;
        return (
            <ErrorTemplate
                figure={<Space />}
                image="unavailableproduct"
                accept={<ErrorButton url={tiendaUrl}>{t('backToRipley')}</ErrorButton>}
                title={t('someoneBoughtThat', { count: separatedProducts.length })}
            >
                <Space>{t('inviteToNavigation')}</Space>
                {products}
            </ErrorTemplate>
        );
    }

    const handleContinue = async () => {
        await dispatch(separateProducts());
        window.location.href = '/';
    };
    const bolsaUrl = envs.app.bolsaUrl;
    return (
        <ErrorTemplate
            figure={<Space />}
            title={t('soldOutProducts')}
            accept={
                <Button theme="primary" size="medium" onClick={handleContinue}>
                    {t('continuePurchase')}
                </Button>
            }
            reject={<ErrorLink url={bolsaUrl}> {t('backToBag')} </ErrorLink>}
        >
            <Space>{t('continueWithRest')}</Space>
            {products}
        </ErrorTemplate>
    );
}
