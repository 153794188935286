import React from 'react';
import styles from './PaymentMethodIcon.module.scss';

export type PaymentMethodIconProps = {
    /**
     * Payment method name
     */
    type: IconType;

    /**
     * Dynamically defines the width of the icon
     */
    width?: string;

    /**
     * Defines the style if the icon is to be displayed enabled or disabled
     */
    disabled?: boolean;
};

export function PaymentMethodIcon(props: PaymentMethodIconProps): JSX.Element {
    const { type: iconType, disabled = false, width = '50px' } = props;
    let computedClassName = styles['icon'];

    const iconStatus = !disabled ? 'activate' : 'hover';

    /**
     * We must add a style according the `type` and `disabled` property
     */
    computedClassName += ' ' + styles[`icon--${iconType}--${iconStatus}`];

    return <img style={{ width }} className={computedClassName} alt={iconType} />;
}

export default PaymentMethodIcon;
