import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEnvironment } from 'config';
import { CL } from 'consts';
import services from 'services';

export const fetchAreas = createAsyncThunk('area/getAreas', async () => {
    const { app } = getEnvironment();
    const country = (app.country?.toUpperCase() as Country) || CL;
    const response = await services.area.getAreas(country);
    return response;
});

export type AreaState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: ExtArea | null;
    error: SerializedError | null;
};

const initialState: AreaState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'area',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAreas.pending, (state: AreaState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchAreas.fulfilled, (state: AreaState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(fetchAreas.rejected, (state: AreaState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
    },
});

export default slice;
