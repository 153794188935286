import React from 'react';
import Card from 'components/common/Card';
import Row from 'components/common/Skeleton/Skeleton';
import useWindowSize from 'lib/hooks/useWindowSize';

export function Skeleton(): JSX.Element {
    const [width] = useWindowSize();
    const isMobile = width <= 768;

    return (
        <Card title="Boleta">
            <div style={{ marginTop: 15 }}>
                <Row width={isMobile ? 100 : 60} />
            </div>
        </Card>
    );
}
