import api from '../../api';
import { normalizeDeliveryMethods } from './normalizer';

class DeliveryMethodService {
    async getDeliveryMethods(payload: GetDeliveryMethodsPayload): Promise<DeliveryMethods> {
        const provider = api.mock;
        const response = await provider.getDeliveryMethods(payload);
        return normalizeDeliveryMethods(response.data);
    }
}

export default DeliveryMethodService;
