import { AnalyticsErrorType, AnalyticsEventType, getParams } from './analytics.utils';
import { addEvent } from '../../store/events';
import { useAppDispatch, useAppSelector } from './redux';
import { getEnvironment } from 'config';
import { RootState } from 'types/store';

type AnalyticsHook = {
    push: (event: AnalyticsEventType, error?: AnalyticsErrorType) => void;
};

/**
 * Docs:
 *      https://ripleycl.atlassian.net/wiki/spaces/ED/pages/2667053477/GA4
 *
 * Docs Checkout:
 *      https://ripleycl.atlassian.net/wiki/spaces/CHO/pages/2688418012/Google+Analytics+4
 *
 * Centraliza la recoleccion de parametros y envio de eventos de Google Analytics 4.
 *
 * La idea es no tener que pasar decenas de parametros desde los respectivos containers,
 * la desventaja es que tenemos logica duplicada para obtener cierta informacion, la
 * solucion mejor creo que implicaria hacer un hook.
 *
 * Por ejemplo el OrderSummary obtiene los metodos de pagos, el main shipping, tarjeta,
 * region, direccion, email, pagos  y totales. Todo eso podria ser abstraido en un hook
 * y que tanto el hook de analytics, el OrderSummary y otros containers puedan acceder
 * a estos mismos datos.
 *
 * Otro ejemplo el CartContainer.utils tiene varios metodos utiles, tambien podrian
 * moverse a un utils global.
 */
export function useAnalytics(): AnalyticsHook {
    const { analytics } = getEnvironment();
    const dispatch = useAppDispatch();

    const address = useAppSelector((state: RootState) => state.address);
    const deliveryMethod = useAppSelector((state: RootState) => state.deliveryMethod);
    const cart = useAppSelector((state: RootState) => state.cart);
    const paymentMethod = useAppSelector((state: RootState) => state.paymentMethod);
    const user = useAppSelector((state: RootState) => state.user);
    const events = useAppSelector((state: RootState) => state.events);
    const { data } = useAppSelector((state: RootState) => state.app);
    const { ctsData } = useAppSelector((state: RootState) => state.events);
    const cvvEnabled = data?.paymentWithoutCvvEnabled ?? false;

    /**
     * Pushes a Google Analytics 4 event. An event can only be pushed once.
     */
    async function push(
        eventName: AnalyticsEventType,
        eventError?: AnalyticsErrorType,
    ): Promise<void> {
        if (
            analytics.ga4Id &&
            window.gtag &&
            typeof window.gtag === 'function' &&
            cart.status === 'ok' &&
            cart.data &&
            user.status === 'ok' &&
            user.data &&
            !events.data.includes(eventName)
        ) {
            dispatch(addEvent(eventName));
            const params = await getParams(
                address,
                cart.data,
                deliveryMethod,
                user.data,
                paymentMethod.data,
                cvvEnabled,
                ctsData,
                eventName,
                eventError,
            );

            window.gtag('event', eventName, params);
        }
    }

    return { push };
}
