import React from 'react';
import styles from './Sidebar.module.scss';
import { ReactComponent as Kiosko } from './kiosko.svg';
import { ReactComponent as Avatar } from './avatar.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as K } from './k.svg';

import Button from 'components/common/Button';

export type SidebarProps = {
    // RUT of the vendor
    rut: string;
    // Store of the vendor
    store: string;
    // Sets if the sidebar is opened or not
    isOpen?: boolean;
    // Callback to logout
    onClick?: () => void;
    // Callback to close or open the sidebar
    onToggle?: () => void;
};

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    const { rut, store, isOpen, onToggle, onClick } = props;

    let classNames = styles.sidebar;

    if (isOpen) {
        classNames += ' ' + styles.open;
    }

    return (
        <div className={classNames}>
            <div className={styles.sidebar__header}>
                <div>
                    <Kiosko />
                </div>
                <button onClick={() => onToggle && onToggle()}>
                    <Arrow />
                </button>
            </div>
            <div className={styles.sidebar__avatar}>
                <K />
                <Avatar />
            </div>
            <div className={styles.sidebar__body}>
                <div>Vendedor Ripley</div>
                {rut && (
                    <div>
                        RUT: <b>{rut}</b>
                    </div>
                )}
                {store && (
                    <div>
                        Tienda: <b>{store}</b>
                    </div>
                )}
                <Button theme="secondary" size="large" onClick={() => onClick && onClick()}>
                    Cerrar Sesión
                </Button>
            </div>
        </div>
    );
};
