export function removeFrom<T>(array: T[], v: T): void {
    const i = array.indexOf(v);
    if (i >= 0) {
        array.splice(i, 1);
    }
}

export function addItem<T>(array: T[], v: T): T[] {
    const s = new Set(array);
    s.add(v);
    return Array.from(s);
}

export function deleteItem<T>(array: T[], v: T): T[] {
    const s = new Set(array);
    s.delete(v);
    return Array.from(s);
}
