import { createSlice, CaseReducer } from '@reduxjs/toolkit';

import { resetPayments, setPaymentDummy } from 'store/paymentMethod/paymentMethod.slice';
import { setPaymentMethod as setCybersource } from 'store/paymentMethod/cybersource.slice';
import { setPaymentMethod as setInStore } from 'store/paymentMethod/inStore.slice';
import {
    setPaymentWithoutCvv,
    setPaymentMethod as setRipleyCard,
} from 'store/paymentMethod/ripleyCard.slice';
import { setPaymentMethod as setRipleyChek } from 'store/paymentMethod/ripleyChek.slice';
import { setPaymentMethod as setWebpayCredito } from 'store/paymentMethod/webpayCredito.slice';
import { setPaymentMethod as setWebpayDebito } from 'store/paymentMethod/webpayDebito.slice';
import { setPaymentMethod as setRipleyGiftCard } from 'store/paymentMethod/ripleyGiftCard.slice';
import { setPaymentMethod as setAlignet } from 'store/paymentMethod/alignet.slice';
import { setPaymentMethod as setNiubiz } from 'store/paymentMethod/niubiz.slice';

import { setCardByToken as setTokenNiubiz, getOptions as getOptsNiubiz } from 'store/card/niubiz';

import { deleteCard } from './actions';
import { setOptions } from './options';
import {
    setCard as setCardCyb,
    setCardByToken as setTokenCyb,
    getOptions as getOptsCyb,
} from './cybersource';
import {
    setCard as setCardRipley,
    setCardByToken as setTokenRipley,
    getOptions as getOptsRipley,
    getSimulation as getSimulationRipley,
} from './ripleyCard.slice';

const initialState: CardState = {
    byId: {},
    allIds: [],
    selected: '',
    fingerprint: null,
    options: {},
    simulation: {},
};

const deselectFn: CaseReducer<CardState> = (state: CardState) => {
    state.selected = '';
};

export default createSlice({
    name: 'card',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPayments.fulfilled, (state) => {
            state.selected = '';
        });

        builder.addCase(deleteCard.pending, (state, { meta }) => {
            if (typeof state.byId[meta.arg.cardId] === 'undefined') {
                state.byId[meta.arg.cardId] = { status: 'idle', error: null, data: null };
            }
            state.byId[meta.arg.cardId].status = 'pending';
        });
        builder.addCase(deleteCard.rejected, (state, { error, meta }) => {
            state.byId[meta.arg.cardId].status = 'error';
            state.byId[meta.arg.cardId].error = error;
        });
        builder.addCase(deleteCard.fulfilled, (state, { meta }) => {
            state.byId[meta.arg.cardId].status = 'ok';
            const index = state.allIds.indexOf(meta.arg.cardId);
            if (index >= 0) {
                state.allIds.splice(index, 1);
                delete state.byId[meta.arg.cardId];
            }
            state.selected = '';
        });

        builder.addCase(setOptions.pending, () => {
            // TODO
        });
        builder.addCase(setOptions.rejected, () => {
            // TODO
        });
        builder.addCase(setOptions.fulfilled, (state, { payload }) => {
            const data = state.byId[state.selected]?.data;
            if (data == null) {
                return;
            }
            data.installments = payload.installments;
            data.deferred = payload.deferredPeriod;
        });

        builder.addCase(getOptsNiubiz.pending, (state, { meta }) => {
            if (state.options[meta.arg.paymentId] == null) {
                state.options[meta.arg.paymentId] = {
                    status: 'idle',
                    installments: [],
                    deferred: [],
                    error: null,
                };
            }
            state.options[meta.arg.paymentId].status = 'pending';
        });

        builder.addCase(getOptsNiubiz.rejected, (state, { error, meta }) => {
            const opts = state.options[meta.arg.paymentId];
            opts.status = 'error';
            opts.error = error;
        });

        builder.addCase(getOptsNiubiz.fulfilled, (state, { payload, meta }) => {
            const opts = state.options[meta.arg.paymentId];
            opts.status = 'ok';
            opts.installments = payload.installments;
            opts.deferred = payload.deferred;
        });

        builder.addCase(setTokenNiubiz.fulfilled, (state, { payload, meta }) => {
            if (typeof state.byId[payload.id] === 'undefined') {
                state.byId[payload.id] = { status: 'idle', error: null, data: null };
                state.allIds.push(payload.id);
            }
            state.byId[payload.id].status = 'ok';
            const { id, deferred, installments, paymentId, bin } = payload;
            state.byId[payload.id].data = {
                id,
                deferred,
                installments,
                isRipley: false,
                maskedPan: bin,
                paymentId,
                paymentMethod: meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz',
                type: 'credito',
            };
            state.selected = id;
        });

        builder.addCase(setCardCyb.fulfilled, (state, { payload }) => {
            if (typeof state.byId[payload.id] === 'undefined') {
                state.byId[payload.id] = { status: 'idle', error: null, data: null };
                state.allIds.push(payload.id);
            }
            state.byId[payload.id].status = 'ok';
            state.byId[payload.id].data = payload;
            state.selected = payload.id;
        });

        builder.addCase(setTokenCyb.fulfilled, (state, { payload }) => {
            if (typeof state.byId[payload.id] === 'undefined') {
                state.byId[payload.id] = { status: 'idle', error: null, data: null };
                state.allIds.push(payload.id);
            }
            state.byId[payload.id].status = 'ok';
            state.byId[payload.id].data = payload;
            state.selected = payload.id;
        });

        builder.addCase(getOptsCyb.pending, (state, { meta }) => {
            if (state.options[meta.arg.paymentId] == null) {
                state.options[meta.arg.paymentId] = {
                    status: 'idle',
                    installments: [],
                    deferred: [],
                    error: null,
                };
            }
            state.options[meta.arg.paymentId].status = 'pending';
        });
        builder.addCase(getOptsCyb.rejected, (state, { error, meta }) => {
            const opts = state.options[meta.arg.paymentId];
            opts.status = 'error';
            opts.error = error;
        });
        builder.addCase(getOptsCyb.fulfilled, (state, { payload, meta }) => {
            const opts = state.options[meta.arg.paymentId];
            opts.status = 'ok';
            opts.installments = payload.installments;
            opts.deferred = payload.deferred;
        });

        builder.addCase(setCardRipley.fulfilled, (state, { payload }) => {
            if (typeof state.byId[payload.id] === 'undefined') {
                state.byId[payload.id] = { status: 'idle', error: null, data: null };
                state.allIds.push(payload.id);
            }
            state.byId[payload.id].status = 'ok';
            state.byId[payload.id].data = payload;
            state.selected = payload.id;
        });

        builder.addCase(setTokenRipley.fulfilled, (state, { payload }) => {
            if (typeof state.byId[payload.id] === 'undefined') {
                state.byId[payload.id] = { status: 'idle', error: null, data: null };
                state.allIds.push(payload.id);
            }
            state.byId[payload.id].status = 'ok';
            state.byId[payload.id].data = payload;
            state.selected = payload.id;
        });

        builder.addCase(getOptsRipley.pending, (state, { meta }) => {
            if (state.options[meta.arg.paymentId] == null) {
                state.options[meta.arg.paymentId] = {
                    status: 'idle',
                    installments: [],
                    deferred: [],
                    error: null,
                };
            }
            state.options[meta.arg.paymentId].status = 'pending';
        });
        builder.addCase(getOptsRipley.rejected, (state, { error, meta }) => {
            const opts = state.options[meta.arg.paymentId];
            opts.status = 'error';
            opts.error = error;
        });
        builder.addCase(getOptsRipley.fulfilled, (state, { payload, meta }) => {
            const opts = state.options[meta.arg.paymentId];
            opts.status = 'ok';
            opts.installments = payload.installments;
            opts.deferred = payload.deferred;
        });

        builder.addCase(getSimulationRipley.pending, (state, { meta }) => {
            if (state.simulation[meta.arg.paymentId] == null) {
                state.simulation[meta.arg.paymentId] = {
                    status: 'idle',
                    data: null,
                    error: null,
                };
            }
            state.simulation[meta.arg.paymentId].status = 'pending';
        });
        builder.addCase(getSimulationRipley.rejected, (state, { error, meta }) => {
            const sim = state.simulation[meta.arg.paymentId];
            sim.status = 'error';
            sim.error = error;
        });
        builder.addCase(getSimulationRipley.fulfilled, (state, { payload, meta }) => {
            const sim = state.simulation[meta.arg.paymentId];
            sim.status = 'ok';
            sim.data = payload;
        });

        /*
         * Deselect after other payment methods are set
         */
        builder.addCase(setCybersource.pending, deselectFn);
        builder.addCase(setPaymentDummy.pending, deselectFn);
        builder.addCase(setRipleyCard.pending, deselectFn);
        builder.addCase(setRipleyChek.pending, deselectFn);
        builder.addCase(setRipleyGiftCard.pending, deselectFn);
        builder.addCase(setInStore.pending, deselectFn);
        builder.addCase(setWebpayCredito.pending, deselectFn);
        builder.addCase(setWebpayDebito.pending, deselectFn);
        builder.addCase(setAlignet.pending, deselectFn);
        builder.addCase(setNiubiz.pending, deselectFn);
        builder.addCase(setPaymentWithoutCvv.pending, deselectFn);
    },
});

export type CardState = {
    byId: {
        [cardId: string]: {
            status: 'idle' | 'pending' | 'error' | 'ok';
            error: SerializedError | null;
            data: ExternalCard | null;
        };
    };
    allIds: string[];
    selected: string;
    fingerprint: string | null;
    options: {
        [paymentId: string]: {
            status: 'idle' | 'pending' | 'error' | 'ok';
            error: SerializedError | null;
            installments: Installment[];
            deferred: Deferred[];
        };
    };
    simulation: {
        [paymentId: string]: {
            status: 'idle' | 'pending' | 'error' | 'ok';
            error: SerializedError | null;
            data: {
                cae: string;
                monthly: string;
                total: string;
            } | null;
        };
    };
};
