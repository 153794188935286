import api from '../../api';
import { normalizeStores, normalizeStoresStock } from './normalizers';

class StoreService {
    async getStores(country: string): Promise<NormalizedStores> {
        const provider = api.bff;
        const response = await provider.getStores(country);
        return normalizeStores(response.data);
    }

    async getStoresStock(
        country: string,
        payload: StoresStockPayload,
    ): Promise<NormalizedStoresStock> {
        const provider = api.bff;
        const response = await provider.getStoresStock(country, payload);
        return normalizeStoresStock(response.data);
    }
}

export default StoreService;
