import React, { RefObject, useState } from 'react';

import styles from './TextField.module.scss';

import { getComputedClassName } from 'lib/utils/inputs';

export interface TextFieldProps {
    /**
     * Sets the name of the text field
     */
    name?: string;
    /**
     * Sets the label that describes the text field
     */
    label?: string;
    /**
     * Sets the identifier of the text field
     */
    id?: string;
    /**
     * Sets whether the text field should be shown disabled
     */
    disabled?: boolean;
    /**
     * Sets a class name on the text field
     */
    className?: string;
    /**
     * Sets whether the text field is invalid
     */
    isInvalid?: boolean;
    /**
     * Sets a placeholder on the text field
     */
    placeholder?: string;
    /**
     * Sets a default value for the text field
     */
    defaultValue?: string;
    /**
     * Sets a default value for the text field
     */
    pattern?: string;
    /**
     * Sets the maximum length of characters in the field
     */
    maxLength?: number;
    /**
     * Sets a callback for the change event
     */
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    /**
     * Sets a callback for the focus event
     */
    onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
    /**
     * Sets a callback for the blur event
     */
    onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const TextField = React.forwardRef(function TextField(props: TextFieldProps, ref) {
    const {
        name,
        id,
        defaultValue,
        disabled,
        className,
        label,
        placeholder,
        isInvalid,
        pattern,
        maxLength,
        onChange,
        onFocus,
        onBlur,
    } = props;

    const [shouldOpen, setShouldOpen] = useState(defaultValue?.length ? true : false);

    /**
     * Handles the input's change event.
     * @param event
     */
    const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
        const inputValue = event.currentTarget.value;
        let sanitizedValue = inputValue;
        if (pattern) {
            const patternRegex = new RegExp(pattern, 'g');
            sanitizedValue = inputValue.replace(patternRegex, '');
        }
        const sanitizedValueWithoutDoubleSpaces = sanitizedValue.replace(/\s{2,}/g, ' ');
        onChange?.({
            ...event,
            currentTarget: { ...event.currentTarget, value: sanitizedValueWithoutDoubleSpaces },
        });
        event.currentTarget.value = sanitizedValueWithoutDoubleSpaces;
    };

    /**
     * Handles the input's focus event.
     * @param event
     */
    const handleOnFocus = (event: React.FormEvent<HTMLInputElement>) => {
        setShouldOpen(true);

        // Custom onFocus passed in from outside
        onFocus?.(event);
    };

    /**
     * Handles the input's blur event.
     * @param event
     */
    const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
        // If the field is not empty it should remain open
        if (!event.currentTarget.value) setShouldOpen(false);

        // Custom onFocus passed in from outside
        onBlur?.(event);
    };

    /**
     * Builds the className to be passed to the component
     */
    const computedClassName = getComputedClassName(
        !!isInvalid,
        !!disabled,
        shouldOpen,
        className ?? '',
        styles,
    );

    /**
     * Builds the attributes passed to the HTML input
     */
    const inputAttributes = {
        'data-testid': name,
        name: name,
        id: id,
        defaultValue: defaultValue,
        ref: ref as RefObject<HTMLInputElement>,
        placeholder: shouldOpen ? placeholder : undefined,
        className: styles['textfield__control'],
        maxLength: maxLength,
        onChange: (e: React.FormEvent<HTMLInputElement>) => handleOnChange(e),
        onFocus: (e: React.FormEvent<HTMLInputElement>) => handleOnFocus(e),
        onBlur: (e: React.FormEvent<HTMLInputElement>) => handleOnBlur(e),
    };

    return (
        <div className={styles['textfield__container']}>
            <div className={computedClassName}>
                <span className={styles['textfield__label']}>{label}</span>
                <input {...inputAttributes} />
                <fieldset className={styles['textfield__fill']}>
                    <legend className={styles['textfield__legend']}>
                        <span>{label}</span>
                    </legend>
                </fieldset>
            </div>
        </div>
    );
});
