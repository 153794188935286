import React from 'react';
import styles from './TaxDocumentSummary.module.scss';
import Card from 'components/common/Card';

import { CL } from 'consts';
import { Link } from 'components/common/Link/Link';
import { useTranslation } from 'react-i18next';

export type TaxDocumentSummaryProps = {
    docType: 'boleta' | 'factura';
    email: string;
    country: string;
    companyName?: string;
    companyDNI?: string;
    companyActivity?: string;
    onToggle?: () => void;
    onEdit?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    helpText?: string;
};

function TaxDocumentSummary(props: TaxDocumentSummaryProps): JSX.Element {
    const { t } = useTranslation();

    if (props.docType == 'factura') {
        return (
            <Card
                title={t('invoice')}
                link={!props.onToggle ? '#' : props.onToggle}
                linkText="¿Necesitas boleta?"
                linkDisabled={!props.onToggle}
                helpText={props.helpText}
            >
                <div className={styles['content']}>
                    <p>
                        <span>{t('companyDNI')}</span>
                        <strong data-testid="companyDNI">{props.companyDNI}</strong>
                    </p>

                    <p>
                        <span>{t('companyName')}</span>
                        <strong data-testid="companyName">{props.companyName}</strong>
                    </p>

                    {props.country == CL && (
                        <p>
                            <span>{t('companyActivity')}</span>
                            <strong data-testid="companyActivity">{props.companyActivity}</strong>
                        </p>
                    )}

                    <p>
                        La factura se enviará al siguiente correo{' '}
                        <strong data-testid="email">{props.email}</strong>.
                    </p>
                    {props.onEdit && (
                        <Link title="Modificar" to={props.onEdit}>
                            Modificar
                        </Link>
                    )}
                </div>
            </Card>
        );
    }

    return (
        <Card
            className={styles['container']}
            title={t('receipt')}
            link={!props.onToggle ? '#' : props.onToggle}
            linkText="¿Necesitas factura?"
            linkDisabled={!props.onToggle}
            helpText={props.helpText}
        >
            <div className={styles['content']}>
                <p>
                    La boleta se enviará al siguiente correo <strong>{props.email}</strong> .
                </p>
            </div>
        </Card>
    );
}

export default TaxDocumentSummary;
