import api from '../../api';

import {
    normalizeDeliverySchedule,
    getPayloadSetSchedule,
    getPayloadGetScheduleRT,
    getPayloadGetScheduleDD,
} from './ScheduleService.utils';

/**
 * `ScheduleService' handles the business logic of the schedule domain
 */
class ScheduleService implements ScheduleService {
    /**
     * `getSchedule' Gets a schedule with the given id
     * @param cartId
     * @returns
     */
    async getSchedule(arg: GetScheduleArgs): Promise<Delivery> {
        const { cartId, store, areas, address } = arg;
        const provider = api.bff;

        const payload = store
            ? getPayloadGetScheduleRT(cartId, store)
            : getPayloadGetScheduleDD(cartId, areas, address);

        const response = await provider.getSchedule(payload);
        return normalizeDeliverySchedule(response);
    }

    /**
     * `setSchedule' Set a schedules in the cart
     * @param cartId
     * @returns
     */
    async setSchedule(args: SetScheduleArgs): Promise<null> {
        const provider = api.bff;
        const payload = getPayloadSetSchedule(args);
        const response = await provider.setSchedule(payload);
        return response.data;
    }

    /**
     * `resetSchedule' Reset the shipping information saved in the cart
     * @param cartId
     * @returns
     */
    async resetSchedule(cartId: string): Promise<null> {
        const provider = api.bff;

        const response = await provider.setSchedule({ cartId, schedule: [] });
        return response.data;
    }
}

export default ScheduleService;
