import jsonata from 'jsonata';

export function normalizeAreas(areas: ExtArea): ExtArea {
    /**
     * JSONata schema https://try.jsonata.org/THsu_Cn0t
     */
    const schema = `{
        "id": id,
        "type": type,
        "name": name,
        "parentAreaId": parentAreaId,
        "parentAreaType": parentAreaType,
        "subAreas": subAreas^($number(id))
    }`;

    return jsonata(schema).evaluate(areas) as any;
}
