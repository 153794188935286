import * as React from 'react';
import PaymentMethodIcon from 'components/payment/PaymentMethodIcon';
import styles from './PriceSummary.module.scss';

export type PriceSummaryProps = {
    totals?: Total[];
    details?: PriceDetail[];
    country?: 'cl' | 'pe';
    className?: string;
};

export const PriceSummary: React.FC<PriceSummaryProps> = ({
    totals,
    details,
    country,
    className,
}: PriceSummaryProps) => (
    <div className={`${styles['price-summary']} ${className ? className : ''}`}>
        <table className={styles['table']}>
            <tbody>
                {details?.map((detail) => (
                    <React.Fragment key={detail.label}>
                        <tr>
                            <th className={styles['label']}>{detail.label}</th>
                            <td className={styles['value']}>
                                {detail.children ? '' : detail.value}
                            </td>
                        </tr>
                        {detail.children?.map((child) => (
                            <tr key={child.label} className={styles['subdetail']}>
                                <th className={styles['label']}>
                                    <ul className={styles['label-list']}>
                                        <li className={styles['label-item']}>{child.label}</li>
                                    </ul>
                                </th>
                                <td
                                    className={styles['value']}
                                    style={{ color: detail.label === 'Descuentos' ? 'green' : '' }}
                                >
                                    {child.value}
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                {details && (
                    <tr>
                        <td colSpan={2}>
                            <hr className={styles['separator']} />
                        </td>
                    </tr>
                )}
                {totals &&
                    totals.map((total, index) => (
                        <tr
                            key={index}
                            className={`${
                                styles[total.type == 'giftcard' ? 'label-gift-card' : 'total']
                            } ${isHighlight(total) ? styles['total--highlight'] : ''}`}
                        >
                            <th>{getLabel(total, country)}</th>
                            <td className={styles['value']}>
                                {getIcon(total, country)}
                                {total.amount}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    </div>
);

export type Total = {
    type: '' | 'normal' | 'ripley' | 'giftcard' | 'difftopay';
    amount: string;
    amountUnformatted?: number;
};

export type Country = 'cl' | 'pe';
export interface PriceDetail {
    label: string;
    value: string;
    children?: PriceDetail[];
}

const isHighlight = (total: Total): boolean => {
    return total.type === 'ripley';
};

export const getLabel = (total: Total, country?: Country): React.ReactNode => {
    if (total.type == 'normal') {
        return 'Total a pagar';
    } else if (total.type == 'ripley' && (!country || country == 'cl')) {
        return 'T. Crédito (Ripley / Chek)';
    } else if (total.type == 'ripley' && country == 'pe') {
        return 'T. Crédito Ripley';
    } else if (total.type == 'giftcard') {
        return 'Gift Card';
    } else if (total.type == 'difftopay') {
        return 'Diferencia a pagar';
    }
    return 'Total a Pagar';
};

export const getIcon = (total: Total, country?: Country): React.ReactNode => {
    if (total.type == 'ripley' && (!country || country == 'cl')) {
        return (
            <>
                <PaymentMethodIcon type="ripleycredito" width="25px" />
                <PaymentMethodIcon type="chekcredito" width="25px" />
            </>
        );
    } else if (total.type == 'ripley' && country == 'pe') {
        return <PaymentMethodIcon type="ripleycredito" width="25px" />;
    }
};
