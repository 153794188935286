import React, { useState } from 'react';
import styles from './GiftCardFormChangePassword.module.scss';

import Button from 'components/common/Button';
import Link from 'components/common/Link';
import TextInput from 'components/common/TextInput';
import AlertBadge from 'components/common/AlertBadge';
import Spinner from 'components/common/Spinner';
import PasswordValidator from 'components/common/PasswordValidator';

export type Form = {
    cardNumber: string;
    password: string;
    newPassword: string;
};

export type GiftCardFormChangePasswordProps = {
    cardNumber: string;
    cardPassword: string;
    error?: string;
    pending?: boolean;
    onSubmit: (f: Form) => void;
    onCancel: () => void;
};

export const GiftCardFormChangePassword: React.FC<GiftCardFormChangePasswordProps> = (
    props: GiftCardFormChangePasswordProps,
) => {
    const [form, setForm] = useState<Form>({
        cardNumber: props.cardNumber,
        password: props.cardPassword,
        newPassword: '',
    });
    const [repeatPassword, setRepeatPassword] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(true);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const disabledButton =
        props.pending || invalidPassword || !(form.newPassword == repeatPassword);
    const displayCheck = !invalidPassword && form.newPassword == repeatPassword;

    function handleOnSubmit(event: React.FormEvent) {
        props.onSubmit(form);

        event.preventDefault();
    }

    return (
        <div className={styles.container}>
            <div>
                Por seguridad debes crear una nueva clave de tu{' '}
                <b>Gift Card Nº {props.cardNumber}</b>.
            </div>
            <form className={styles.form} onSubmit={handleOnSubmit}>
                <div className={styles.field}>
                    <TextInput
                        type="text"
                        className={styles.input}
                        label="Clave nueva"
                        placeholder="Clave nueva"
                        required
                        maxlength={20}
                        onChange={(v) => setForm({ ...form, newPassword: v })}
                        showPassword={showCurrentPassword}
                        setShowPassword={setShowCurrentPassword}
                        showPasswordLabelInside
                        displayCheck={displayCheck}
                    />
                </div>
                <div className={styles.field}>
                    <TextInput
                        type="text"
                        className={styles.input}
                        label="Repetir clave"
                        placeholder="Repetir clave"
                        required
                        maxlength={20}
                        onChange={(v) => setRepeatPassword(v)}
                        showPassword={showNewPassword}
                        setShowPassword={setShowNewPassword}
                        showPasswordLabelInside
                        displayCheck={displayCheck}
                    />
                </div>
                {form.newPassword && !displayCheck && (
                    <div>
                        <PasswordValidator
                            password={form.newPassword}
                            onChange={setInvalidPassword}
                        />
                    </div>
                )}

                {props.error && (
                    <div className={styles.error}>
                        <AlertBadge theme="danger" size="large">
                            {props.error}
                        </AlertBadge>
                    </div>
                )}

                <div className={styles.button}>
                    <Button
                        theme="primary"
                        size="large"
                        type="submit"
                        onClick={handleOnSubmit}
                        disabled={disabledButton}
                    >
                        {props.pending ? <Spinner size={20} theme="none" /> : 'Crear nueva clave'}
                    </Button>
                </div>
                <div className={styles.link}>
                    <Link to={props.onCancel} title="cancelar">
                        Cancelar
                    </Link>
                </div>
            </form>
        </div>
    );
};
