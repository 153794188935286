import * as React from 'react';
import styles from './CollapsibleSelector.module.scss';

export type CollapsibleProps = {
    /**
     * Sets if the list is collapsed or not
     */
    open?: boolean;
    /**
     * Sets the collapsible content of the component
     */
    children?: React.ReactNode;
};

export const CollapsibleSelector: React.FC<CollapsibleProps> = (props: CollapsibleProps) => {
    const wrapperClass = styles['wrapper'] + ' ' + (props.open ? styles['open'] : '');
    return (
        <div className={wrapperClass}>
            <div className={styles['collapsible']}>{props.children}</div>
        </div>
    );
};
