import React, { useContext } from 'react';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { setPaymentMethod as setInStore } from 'store/paymentMethod/inStore.slice';
import { useTranslation } from 'react-i18next';
import { ErrorContext } from 'containers/aio/PaymentMethodsContainer/context';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';
import { RootState } from 'types/store';

export const InStoreSelector: React.FC = () => {
    const { t } = useTranslation('error');
    const dispatch = useAppDispatch();
    const dm = useDeliveryMethod();
    const pm = useAppSelector((state) => state.paymentMethod);
    const ctx = useContext(ErrorContext);

    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    const isSelected = pm.data.selected.includes('InStore');
    const isLoading = pm.data.loading.includes('InStore');
    const isBlocked =
        (pm.data.status === 'pending' && !isLoading) || couponLoading || !dm.hasSchedules();

    async function setInStorePayment() {
        try {
            await dispatch(setInStore()).unwrap();
        } catch (e) {
            ctx.setError(t('defaultPaymentError.message'));
        }
    }

    return (
        <React.Fragment>
            <PaymentMethodItem
                paymentMethodName="Pago en Tienda"
                type="instore"
                widthIcon="30px"
                onClick={setInStorePayment}
                selected={isSelected}
                blocked={isBlocked}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};
