import React, { useEffect, useState } from 'react';

import TaxDocumentSummary, { Skeleton } from 'components/aio/TaxDocumentSummary';
import InvoiceFormContainer from '../InvoiceFormContainer';

import { CL, country } from 'consts';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { getBillingInfo } from 'lib/utils/billing';
import { setBillingInfo } from 'store/billing';
import { useRules } from 'lib/hooks/rules';
import { hasMarketplaceProducts } from 'lib/utils/cart';
import { format } from 'rut.js';
import { RootState } from 'types/store';

export const TaxDocumentSummaryContainer: React.FC = () => {
    const [showModal, toggleModal] = useState(false);
    const dispatch = useAppDispatch();

    const rules = useRules();
    const isInvoiceAvailable = rules.feature('factura');
    const billing = useAppSelector((state: RootState) => state.billing);
    const user = useAppSelector((state: RootState) => state.user.data);
    const addresses = useAppSelector((state: RootState) => state.address.data);
    const areas = useAppSelector((state: RootState) => state.area.data);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const payments = useAppSelector((state: RootState) => state.paymentMethod.data.selected);
    const giftCard = useAppSelector((s: RootState) => s.paymentMethod.types.ripleyGiftCard.data);

    const billingData = billing.data;

    useEffect(() => {
        if (billing.status == 'idle' && addresses?.selected) setBoleta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const setBoleta = () => {
        if (user && addresses && areas) {
            const address = addresses.byId[addresses.selected];
            const payload = getBillingInfo('boleta', user, address, areas);
            dispatch(setBillingInfo(payload));
        }
    };

    if (!cart || !billingData) {
        return <Skeleton />;
    }

    let helpText = 'Temporalmente no disponible';

    // Not marketplace products
    if (hasMarketplaceProducts(cart.products)) {
        helpText = 'No disponible para productos Marketplace.';
    }

    // Not selected payments.
    if (payments.length == 0) {
        helpText = 'Debes seleccionar un medio de pago.';
    }

    // Not "TRE factura".
    if (payments.includes('RipleyGiftCard') && giftCard?.billingType === 1) {
        helpText = 'No disponible para el medio de pago GiftCard.';
    }

    let dni = billingData.billingInfo.customer.nin;
    if (country == CL) dni = format(billingData.billingInfo.customer.nin);

    return (
        <>
            {billingData?.documentType == 'boleta' ? (
                <TaxDocumentSummary
                    docType="boleta"
                    email={billingData.billingInfo.customer.email}
                    country={country}
                    onToggle={isInvoiceAvailable ? () => toggleModal(true) : undefined}
                    helpText={helpText}
                />
            ) : (
                <TaxDocumentSummary
                    docType="factura"
                    email={billingData.billingInfo.customer.email}
                    country={country}
                    companyName={billingData.billingInfo.customer.firstName}
                    companyDNI={dni}
                    companyActivity={billingData.billingInfo.business}
                    onToggle={setBoleta}
                    onEdit={() => toggleModal(true)}
                />
            )}

            {showModal && (
                <InvoiceFormContainer
                    isOpen={showModal}
                    onContinue={() => toggleModal(false)}
                    onToggle={() => toggleModal(false)}
                />
            )}
        </>
    );
};
