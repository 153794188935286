import slice, {
    modifyAddress,
    deleteAddress,
    createAddress,
    modifyAddressSlice,
    deleteAddressSlice,
    createAddressSlice,
} from './address.slice';
export {
    modifyAddress,
    deleteAddress,
    createAddress,
    modifyAddressSlice,
    deleteAddressSlice,
    createAddressSlice,
};
export default slice.reducer;
