import React from 'react';
import { CL, PE, sellers_ids } from 'consts';

import styles from './PickupStoreStockIcon.module.scss';
import { ReactComponent as RipleyIcon } from './pickup-ripley.svg';
import { ReactComponent as TamboIcon } from './pickup-tambo.svg';
import { ReactComponent as MarketplaceIcon } from './emblema-seller.svg';

export interface PickupStoreStockIconProps {
    country: Country;
    storeType: string;
    storeId: string;
    isEmpty?: boolean;
}

export const getIconByCountry = (
    country: Country,
    storeId: string,
    storeType: string,
): React.FC<React.SVGProps<SVGSVGElement>> => {
    if (country == PE && sellers_ids.includes(storeId)) {
        return MarketplaceIcon;
    }

    if (storeType === 'pickupPoint') {
        return RC_ICON[country];
    }

    return RipleyIcon;
};

export const PickupStoreStockIcon: React.FC<PickupStoreStockIconProps> = ({
    country,
    storeType,
    storeId,
    isEmpty = false,
}: PickupStoreStockIconProps) => {
    const Icon = getIconByCountry(country, storeId, storeType);

    return (
        <Icon
            data-testid="pickup-store-stock-icon"
            className={styles['icon' + (isEmpty ? '--disabled' : '')]}
        />
    );
};

export const RC_ICON: { [key in Country]: React.FC } = {
    [CL]: MarketplaceIcon,
    [PE]: TamboIcon,
};
