import React from 'react';
import styles from './AddressSummary.module.scss';
import Card from 'components/common/Card';
import Row from 'components/common/Skeleton';
import Button from 'components/common/Button';

import { useTranslation } from 'react-i18next';
import useWindowSize from 'lib/hooks/useWindowSize';

export function Skeleton(): JSX.Element {
    const { t } = useTranslation();
    const [width] = useWindowSize();
    const isMobile = width <= 768;

    return (
        <Card title={t('delivery')} link={() => {}}>
            <div className={styles['container__address']}>
                <Row width={isMobile ? 60 : 30} />
            </div>

            <div className={styles['container__children']}>
                <Button size="large" theme="dark" onClick={() => {}} disabled={true}>
                    {t('iWantPickup')}
                </Button>
            </div>
        </Card>
    );
}
