import { useAppSelector } from './redux';
import { KIOSKO, MOBILE, RT, SELLER_RIPLEY } from 'consts';
import { RootState } from 'types/store';
import { getTotals, Totals } from 'containers/CartContainer/CartContainer.utils';

export const useRules = (): RulesHelper => {
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const registered = useAppSelector((state: RootState) => state.user.data?.registered ?? false);
    const delivery = useAppSelector((state: RootState) => state.deliveryMethod.selected);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const products = cart?.products ?? [];
    const marketplace = products.some((p) => p.details.sellerId !== SELLER_RIPLEY);
    const ripley = products.some((p) => p.details.sellerId === SELLER_RIPLEY);
    const totals = cart != null ? getTotals(cart) : ({} as Totals);

    const payments = useAppSelector((state: RootState) => state.paymentMethod.data.selected);
    const ripleyGiftCardType = useAppSelector(
        (state: RootState) => state.paymentMethod.types.ripleyGiftCard.data?.billingType,
    );
    const copago = useAppSelector((state: RootState) => state.app.data?.paymentMode === 'copago');

    const rules = useAppSelector((state: RootState) => state.rule.data);

    function feature(key: RulesFeature): boolean;
    function feature(key: PaymentMethodName): boolean;
    function feature(key: RulesFeature | PaymentMethodName): boolean {
        if (isRulesFeature(key)) {
            // 'factura' is only available when:
            // 1. all products are "ripley",
            // 2. there are selected payment methods,
            // 3. in the case of RipleyGiftCard, it is of 'factura' type.
            if (key == 'factura') {
                // Check if disabled by rules.
                if (rules[key] === false) {
                    return false;
                }

                // Conditions for 'factura'.
                const hasSelectedPayments = payments.length <= 0;
                const isTREFactura =
                    payments.includes('RipleyGiftCard') && ripleyGiftCardType === 1;

                if (marketplace || hasSelectedPayments || isTREFactura) {
                    return false;
                }
            }

            return rules[key];
        }
        return isPaymentActive(key);
    }

    function isPaymentActive(key: PaymentMethodName): boolean {
        if (!rules.payments.includes(key)) {
            return false;
        }

        if (copago && !rules.paymentsGiftCard.includes(key)) {
            return false;
        }

        /* NOTE: Rule is created to not have more than two payment methods
         * in the cart*/
        if (copago && !payments.includes(key) && payments.length === 2) {
            return false;
        }

        /* NOTE: We want to have an abbreviated flow for KIOSKO which ends with
         * this rule independent of the kind of user, products or shipping set
         * in the cart. */
        if (channel === KIOSKO) {
            if (!rules.paymentsKiosko.includes(key)) {
                return false;
            }
        } else if (channel === MOBILE) {
            if (!rules.paymentsMobile.includes(key)) {
                return false;
            }
        } else {
            if (!rules.paymentsInternet.includes(key)) {
                return false;
            }
        }

        // Para Kiosko no se consideran las reglas por tipo de usuario
        // ya que tienen un comportamiento distinto.
        if (
            channel !== KIOSKO &&
            ((registered && !rules.paymentsRegistered.includes(key)) ||
                (!registered && !rules.paymentsGuest.includes(key)))
        ) {
            return false;
        }

        if (ripley && !rules.paymentsRipley.includes(key)) {
            return false;
        }

        if (marketplace && !rules.paymentsMarketplace.includes(key)) {
            return false;
        }

        if (
            (delivery === RT && !rules.paymentsPickup.includes(key)) ||
            (delivery !== RT && !rules.paymentsShipping.includes(key))
        ) {
            return false;
        }

        if (cart)
            if (isPaymentMethodRangeNotValid(key, rules['paymentAmountRange'], totals)) {
                return false;
            }

        return true;
    }

    return {
        feature,
        parameter<T extends RulesParameter>(key: T): Rules['parameters'][T] {
            return rules.parameters[key];
        },
        raw: rules,
    };
};

export type RulesHelper = {
    // Returns whether a feature is active or not.
    feature(key: RulesFeature): boolean;

    // Returns whether a payment method is allowed according to state.
    feature(key: PaymentMethodName): boolean;

    // Returns a correctly typed value from a string identifier of a parameter.
    parameter<T extends RulesParameter>(key: T): Rules['parameters'][T];

    // Structure with all data.
    raw: Rules;
};

export function isRulesFeature(key: string): key is RulesFeature {
    return (
        key === 'cardOnFile' ||
        key === 'copago' ||
        key === 'coupons' ||
        key === 'deferred' ||
        key === 'factura' ||
        key === 'marketplacePickup' ||
        key === 'separateFulfillment' ||
        key === 'separateFulfillmentPromises' ||
        key === 'simulation'
    );
}

export const isPaymentMethodRangeNotValid = (
    paymentMethodName: PaymentMethodName,
    paymentAmountRanges: PaymentAmountRange[],
    totals: Totals,
) => {
    // Min and Max value of a price
    const max =
        paymentAmountRanges?.find((range) => range.paymentMethod == paymentMethodName)?.max ?? 0;
    const min =
        paymentAmountRanges?.find((range) => range.paymentMethod == paymentMethodName)?.min ?? 0;

    // The total at this stage will be always normal (no payment methods selected)
    const totalAmount = totals['normal'];

    // validation
    if (totalAmount) {
        if (max === 0) {
            return totalAmount < min;
        }
        if (totalAmount < min) {
            return true;
        }
        if (totalAmount > max) {
            return true;
        }
    }
    return false;
};
