/**
 * Builds the className to be passed to the component
 * @param invalid change the text and the text box to red
 * @param disabled turns the text and the text box gray and disables it
 * @param shouldOpen handles the opening and closing animation of the component
 * @param className custom className passed from outside
 */
export function getComputedClassName(
    invalid: boolean,
    disabled: boolean,
    shouldOpen: boolean,
    className: string,
    styles: Record<string, string>,
): string {
    let computedClassName = styles['textfield'];

    if (invalid) {
        computedClassName += ' ' + styles['textfield--invalid'];
    }
    if (disabled) {
        computedClassName += ' ' + styles['textfield--disabled'];
    }
    if (shouldOpen) {
        computedClassName += ' ' + styles['textfield--open'];
    }
    if (className) {
        computedClassName += ' ' + className;
    }
    return computedClassName;
}
