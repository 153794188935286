import React from 'react';
import styles from './ShippingGroup.module.scss';
import Card from 'components/common/Card';
import Row from 'components/common/Skeleton/Skeleton';
import ProductThumb from 'components/common/ProductThumb';
import useWindowSize from 'lib/hooks/useWindowSize';

export function Skeleton(props: { index: number }): JSX.Element {
    const [width] = useWindowSize();
    const isMobile = width <= 768;

    return (
        <Card title={`Envío ${props.index}`} className={styles['sg__card']} link={() => {}}>
            <div className={styles['sg__subtitle']}>
                <Row width={isMobile ? 60 : 30} />
            </div>
            <div className={styles['sg__product-thumbs']}>
                <ProductThumb src={''} />
            </div>
            <div>
                <div>
                    <span>
                        <Row width={isMobile ? 60 : 30} />
                    </span>
                    <span>
                        <Row width={isMobile ? 30 : 10} />
                    </span>
                </div>
            </div>
        </Card>
    );
}
