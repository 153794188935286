import slice, {
    getDeliveryMethods,
    changeDeliveryMethod,
    changeProvisionalMethod,
    togglePickupModal,
    toggleStoreModal,
} from './deliveryMethod.slice';

export {
    getDeliveryMethods,
    changeDeliveryMethod,
    changeProvisionalMethod,
    togglePickupModal,
    toggleStoreModal,
};
export default slice.reducer;
