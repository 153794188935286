import React, { FC } from 'react';
import DateBadge from '../DateBadge';
import styles from './ScheduleDescription.module.scss';

export type ScheduleDescriptionProps = {
    /**
     * If there's no date this will hold the entire text: `"Mañana, 9 a 14 hrs."`
     * If `date` is provided this acts as the start of the message, eg:
     *  - `"Recibelo el"`,
     *  - `"Sabado"`
     */
    message: string;
    /**
     * Specially styled closest (or only) date, eg: `"13 de marzo"`.
     */
    closestDate?: string;
    /**
     *  End of message, to be used along with `closestDate`, eg: `"de 9 a 14 hrs."`
     *  When `latestDate` is provided it serves as a connector, eg: `"y el"`
     */
    end?: string;
    /**
     * Specially styled latest date, eg: `"18 de marzo"`.
     */
    latestDate?: string;
};

export const ScheduleDescription: FC<ScheduleDescriptionProps> = (
    props: ScheduleDescriptionProps,
) => {
    const { message, closestDate = '', end = '', latestDate = '' } = props;

    return (
        <div className={styles['description']}>
            {message}{' '}
            {closestDate ? (
                <>
                    <DateBadge date={closestDate} /> {end}
                    {latestDate ? <DateBadge date={latestDate} /> : null}
                </>
            ) : null}
        </div>
    );
};

export default ScheduleDescription;
