import jsonata from 'jsonata';

export function normalizeAuth(auth: ExtAuth): Auth {
    const schema = `{
        "accessToken": accessToken,
        "expiresIn": $number(expiresIn),
        "userId": userId,
        "cartId": cartId,
        "channel": channel
      }`;

    return jsonata(schema).evaluate(auth);
}
