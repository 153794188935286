import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';

import Button from 'components/common/Button';
import Products from 'components/common/Products';

import { ErrorTemplate, ErrorButton } from 'components/common/RipleyError/ErrorTemplate';
import { getProducts } from 'containers/CartContainer/CartContainer.utils';

import { separateShipping } from 'store/checkout/checkout.slice';
import { getEnvironment } from 'config';
import { RootState } from 'types/store';

export function ErrorShippingProductsContainer(): JSX.Element {
    const { app } = getEnvironment();

    const { t } = useTranslation();
    const { t: localize } = useTranslation('format');
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const message = useAppSelector((state: RootState) => state.checkout.error?.message);
    const error = JSON.parse(String(message));
    const dispatch = useAppDispatch();

    const unscheduledProducts = error.unscheduledProducts.map((up: Product) => {
        return cart?.products.find((p) => p.id == up.id);
    });

    const notFoundProducts = error.notFoundProducts.map((nfp: Product) => {
        return cart?.products.find((p) => p.id == nfp.id);
    });

    const list = unscheduledProducts.concat(notFoundProducts).filter((l: Product) => l != null);

    const availableProducts = cart?.products.filter((p) => {
        return list.some((sp: Product) => sp.id != p.id);
    });

    const shippingProductsUnavailable = (
        <Products
            list={getProducts(
                list.map((p: Product) => ({ ...p, quantity: 0 })),
                localize,
            )}
        />
    );

    if (availableProducts && availableProducts.length > 0) {
        const urlBolsa =
            app.bolsaUrl ||
            (app.country == 'pe'
                ? 'https://simple.ripley.com.pe/bolsa'
                : 'https://simple.ripley.cl/bolsa');

        const handleContinue = async () => {
            await dispatch(separateShipping(list));
            window.location.href = '/';
        };

        return (
            <ErrorTemplate
                image="errorshipping"
                accept={
                    <Button theme="primary" size="medium" onClick={handleContinue}>
                        {t('continueWithOutProduct')}
                    </Button>
                }
                reject={
                    <ErrorButton theme="secondary" url={urlBolsa}>
                        {t('backToBag')}
                    </ErrorButton>
                }
                title={t('unavailableShippingsTitle')}
                content={shippingProductsUnavailable}
            >
                {t('unavailableProducts')}
            </ErrorTemplate>
        );
    } else {
        const urlTienda =
            app.tiendaUrl ||
            (app.country == 'pe' ? 'https://simple.ripley.com.pe' : 'https://simple.ripley.cl');

        const handleContinue = async () => {
            await dispatch(separateShipping(list));
            window.location.href = urlTienda;
        };

        return (
            <ErrorTemplate
                image="errorshipping"
                accept={
                    <Button theme="primary" size="medium" onClick={handleContinue}>
                        {t('backToRipley')}
                    </Button>
                }
                title={t('unavailableShippingTitle')}
                content={shippingProductsUnavailable}
            >
                {t('inviteToNavigation')}
            </ErrorTemplate>
        );
    }
}
