import React, { useState, ChangeEvent } from 'react';
import styles from './PhoneField.module.scss';

import { CL } from 'consts';

import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';
import { getComputedClassName } from 'lib/utils/inputs';

export type PhoneFieldProps = {
    /**
     * Sets the identifier of the text field
     */
    id?: string;
    /**
     * Sets the code of the phone number
     */
    country?: 'CL' | 'PE';
    /**
     * Specifies the name of the field
     */
    name?: string;
    /**
     * Specifies that input should be disabled
     */
    disabled?: boolean;
    /**
     * Specifies the text or label that describes the text field
     */
    label?: string;
    /**
     * Sets a class name on the text field
     */
    className?: string;
    /**
     * Sets a placeholder on the text field
     */
    placeholder?: string;
    /**
     * Sets a default value for the text field
     */
    defaultValue?: string;
    /**
     * Mask character to put in each empty place.
     * eg: for mask="_" and format="##/##" it would display "__/__"
     */
    mask?: string;
    /**
     * Formatting to apply to a number with '#' as placeholders.
     * eg: '##/##' would format '1234' as '12/34'.
     */
    format?: string;
    /**
     * Sets whether the text field is invalid
     */
    isInvalid?: boolean;
    /**
     * Sets a callback that returns the raw and formatted values for masked inputs
     */
    onChangeMasked?: (value: string, formatted: string) => void;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
};

export const PhoneField = React.forwardRef(function PhoneField(props: PhoneFieldProps, ref) {
    const {
        name,
        mask = '_',
        country,
        format = '#-####-####',
        isInvalid,
        disabled,
        className,
        label = 'Celular*',
        placeholder = '_-____-____',
        defaultValue,
        onChange,
        onFocus,
        onBlur,
        onChangeMasked,
    } = props;

    const [shouldOpen, setShouldOpen] = useState(defaultValue?.length ? true : false);

    const codPhone = country === CL ? '+56' : '+51';

    /**
     * Handles the input's change event.
     * @param event
     */
    const handleOnChange = (values: NumberFormatValues, s: SourceInfo) => {
        onChangeMasked?.(values.value, values.formattedValue);

        // Si es un evento se le pasa al `onChange` el valor en crudo
        if (s.source == 'event') {
            s.event.currentTarget.value = values.value;
            onChange?.(s.event);
        }
    };

    /**
     * Handles the input's focus event.
     * @param event
     */
    const handleOnFocus = (event: React.FormEvent<HTMLInputElement>) => {
        setShouldOpen(true);

        // Custom onFocus passed in from outside
        onFocus?.(event);
    };

    /**
     * Handles the input's blur event.
     * @param event
     */
    const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
        // If the field is not empty it should remain open
        if (!event.currentTarget.value) setShouldOpen(false);

        // Custom onFocus passed in from outside
        onBlur?.(event);
    };

    /**
     * Builds the className to be passed to the component
     */
    const computedClassName = getComputedClassName(
        !!isInvalid,
        !!disabled,
        shouldOpen,
        className ?? '',
        styles,
    );

    /**
     * Builds the props passed to the NumberFormat component
     */
    const numberFormatProps = {
        id: name,
        name: name,
        mask: mask,
        format: format?.replace(/ /g, ''), // remove white space
        getInputRef: ref,
        className: styles['textfield__control'],
        placeholder: shouldOpen ? placeholder : undefined,
        'data-testid': name,
        defaultValue: defaultValue,
        onFocus: (e: ChangeEvent<HTMLInputElement>) => handleOnFocus(e),
        onBlur: (e: ChangeEvent<HTMLInputElement>) => handleOnBlur(e),
        onValueChange: handleOnChange,
    };

    return (
        <div className={styles['textfield__container']}>
            <div className={computedClassName}>
                <span className={styles['textfield__label']}>{label}</span>
                {shouldOpen && <span className={styles['textfield__code']}>{codPhone}</span>}

                <NumberFormat type="tel" {...numberFormatProps} />

                <fieldset className={styles['textfield__fill']}>
                    <legend className={styles['textfield__legend']}>
                        <span id="label">{label}</span>
                    </legend>
                </fieldset>
            </div>
        </div>
    );
});
