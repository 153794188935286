import * as React from 'react';
import RadioButton from '../../common/RadioButton';
import Link from '../../common/Link';
import styles from './AddressItem.module.scss';
import editImg from './img/edit.svg';
import removeImg from './img/removeIcon.svg';

export type AddressItemProps = Address & {
    /** */
    /**
     * Callback triggered when this address is selected
     */
    onChange: (addressId: string) => void;
    /**
     * It indicates the "to" attribute for the link
     */
    onDelete: ((addressId: string) => void) | null;
    /**
     * It indicates the "to" attribute for the link
     */
    onEdit: () => void;
};

export const AddressItem: React.FC<AddressItemProps> = ({
    id,
    checked,
    fullAddress,
    name,
    onChange,
    onDelete,
    onEdit,
}: AddressItemProps) => {
    return (
        <div className={styles['row']} id={`address-item-${id}`} data-testid="address-item">
            <RadioButton
                checked={!!checked}
                className={styles['fullwidth']}
                id={`select-address-${id}`}
                name={`select_address_${id}`}
                onChange={() => onChange(id)}
                value={name}
                variant="filled"
            >
                <div className={styles['row__address']}>
                    <p className={styles['text']}>{fullAddress}</p>
                    {!!name && <p className={styles['custom-message']}>{name}</p>}
                </div>
            </RadioButton>

            <div className={styles['row__options']}>
                <Link type="next" to={onEdit} title="Editar">
                    <img alt="Editar" src={editImg} width={20} height={20} />
                </Link>
                {onDelete && (
                    <Link
                        type="next"
                        to={() => {
                            onDelete(id);
                        }}
                        title="Borrar"
                    >
                        <img src={removeImg} alt={'Borrar'} title={'Borrar'} />
                    </Link>
                )}
            </div>
        </div>
    );
};

export type Address = {
    /** Address id */
    id: string;
    /**
     * Identifier for this address
     */
    name: string;
    /**
     * Address including street name, house number, apartment, etc
     */
    fullAddress: string;
    /**
     * If true, marks item as selected.
     */
    checked?: boolean;
};
