import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCardType, getMaskedPan, isRipleyCard } from 'lib/utils/payments';
import { CybersourceData } from 'store/paymentMethod/cybersource.slice';
import { GetOptionsPayload } from './options';

export const setCard = createAsyncThunk(
    'card/cybersource/setCard',
    async ({ user, card, payment }: SetCardPayload, { dispatch }): Promise<ExternalCard> => {
        const century = new Date().getFullYear().toString().slice(0, -2);

        const tokenResponse = await window.paymenthub.tokenizeCybersourceV2Card(
            {
                cardNumber: card.pan,
                expiryMonth: card.month,
                expiryYear: century + card.year,
            },
            {
                name: user.firstname,
                lastName: user.lastname,
                email: user.email,
                phoneNumber: user.phoneNumber,
            },
            payment.jwk,
            payment.id,
            card.remember,
        );
        if (tokenResponse.success === false) {
            throw new Error(tokenResponse.message);
        }

        const installments = 1;
        await window.paymenthub.updateInstallments(payment.id, installments);
        const encryptedData = await window.paymenthub.encryptCybersourceCVV(card.cvv);
        const maskedPan = getMaskedPan(card);
        dispatch(getOptions({ paymentId: payment.id, maskedPan }));

        return {
            id: payment.id,
            deferred: 0,
            encryptedData,
            installments,
            isRipley: isRipleyCard(card.pan),
            maskedPan,
            paymentId: payment.id,
            paymentMethod: 'Cybersource',
            type: getCardType(card.pan),
        };
    },
);

export type SetCardPayload = {
    user: NormalizedUser;
    card: Card;
    payment: CybersourceData;
};

export const getOptions = createAsyncThunk(
    'card/cybersource/getOptions',
    async ({ paymentId, maskedPan }: GetOptionsPayload): Promise<CardOptions> => {
        // Example `payload`: [1, 3, 6, 12, 24, 48]
        const installments = await window.paymenthub.getInstallments(paymentId, null, maskedPan);
        return { installments, deferred: [] };
    },
);

export const setCardByToken = createAsyncThunk(
    'cybersource/setCardByToken',
    async ({ card, cvv, payment }: SetCardByTokenArg, { dispatch }): Promise<ExternalCard> => {
        const encryptedData = await window.paymenthub.encryptCybersourceCVV(cvv);

        const installments = 1;
        await window.paymenthub.updateInstallments(payment.id, installments);

        dispatch(getOptions({ paymentId: payment.id, maskedPan: card.maskedPan }));

        return {
            ...card,
            installments,
            deferred: 0,
            paymentId: payment.id,
            encryptedData,
        };
    },
);

export type SetCardByTokenArg = {
    card: CardToken;
    cvv: string;
    payment: CybersourceData;
};
