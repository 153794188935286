import { datadogRum } from '@datadog/browser-rum';

import json from '../../../package.json';
import { getEnvironment } from 'config';

export const createDataDogReporter = (country: Country, isMobile: boolean): void => {
    const { dataDog } = getEnvironment();

    const { version } = json;

    const {
        availableDataDog,
        applicationId,
        clientToken,
        site,
        env,
        sessionReplaySampleRate,
        sessionSampleRate,
    } = dataDog;

    if (availableDataDog) {
        const service = `${isMobile ? 'app' : 'web'}-checkout-${country}`;

        datadogRum.init({
            applicationId,
            clientToken,
            site,
            service: service,
            env,
            version: version,
            sessionSampleRate,
            sessionReplaySampleRate,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'mask-user-input',
        });

        datadogRum.startSessionReplayRecording();
    }
};
