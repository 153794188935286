import React from 'react';
import styles from './StorePreview.module.scss';
import { Link } from 'components/common/Link/Link';
import AlertBadge from 'components/common/AlertBadge';
import { CL, country } from 'consts';
import { useTranslation } from 'react-i18next';

type StorePreviewProps = {
    icon: React.ReactElement;
    storeName: string;
    storeAddress: string;
    storeBusinessHours?: string;
    deliverDate: string;
    deliveryPrice?: string;
    linkTo: () => void;
};

export const StorePreview: React.FC<StorePreviewProps> = (props: StorePreviewProps) => {
    const { t: format } = useTranslation('format');
    return (
        <div className={styles['store-container']}>
            <div className={styles['store-header']}>
                {props.icon}
                <div className={styles['store-name']}>{props.storeName}</div>
            </div>

            <div className={styles['store-address']}>{props.storeAddress}</div>

            {props.storeBusinessHours && (
                <div className={styles['store-address']}>{props.storeBusinessHours}</div>
            )}

            <div className={styles['store-deliver-date']}>
                {props.deliverDate}{' '}
                {props.deliveryPrice && `- ${format('amount', { value: props.deliveryPrice })}`}
            </div>

            {props.deliveryPrice && country === CL && (
                <div style={{ marginTop: '20px' }}>
                    <AlertBadge theme="info">
                        El envío del producto de Marketplace a tienda tiene un costo adicional de
                        <strong> {format('amount', { value: props.deliveryPrice })}</strong>
                    </AlertBadge>
                </div>
            )}

            <Link className={styles['store-link']} to={props.linkTo} title={'Modificar tienda'}>
                Modificar tienda
            </Link>
        </div>
    );
};
