import ProductWithWarranty, { Product } from 'components/common/ProductWithWarranty';
import React, { FC, ReactNode } from 'react';
import { Deliveries, DeliveryAddress, DeliveryResumeProps } from './DeliveryResume.interface';
import styles from './DeliveryResume.module.scss';
import { useTranslation } from 'react-i18next';

export const DeliveryResume: FC<DeliveryResumeProps> = (props: DeliveryResumeProps) => {
    const { address, additionalAddress, deliveries, storeBusinessHours } = props;
    const { t } = useTranslation('translation');

    const displayDeliveries = (deliveries: Array<Deliveries>): ReactNode => {
        let index = 1;

        return deliveries.map((row) => {
            return (
                <div key={`product-${index++}`}>
                    <div className={styles['delivery-date-index']}>Envío {index}</div>
                    <div className={styles['delivery-date']}>{row.estimatedDate}</div>
                    <div>{displayProduct(row.products)}</div>
                </div>
            );
        });
    };

    const displayProduct = (products: Array<Product>): ReactNode => {
        let index = 0;
        return products.map((row) => <ProductWithWarranty key={index++} product={row} />);
    };

    const displayInfo = (customerAddress: DeliveryAddress): ReactNode => {
        const homeNumber =
            customerAddress.homeNumber?.length > 0 ? `${customerAddress.homeNumber}, ` : '';
        return (
            <div>
                <div className={styles['title']}>
                    {customerAddress.type === 'RT'
                        ? t('shippingStoreTitle')
                        : t('shippingDeliveryTitle')}
                </div>
                <div className={styles['address']}>
                    {customerAddress.type === 'RT' ? (
                        <>
                            <div className={styles['store-name']}>{customerAddress.storeName}</div>
                            <div>{customerAddress.storeDetails}</div>
                            <div>{customerAddress.street}</div>
                            <div>{customerAddress.city}</div>
                        </>
                    ) : (
                        <>
                            {customerAddress.street}, {homeNumber} {customerAddress.city}
                        </>
                    )}
                    {storeBusinessHours && <div> {storeBusinessHours} </div>}
                </div>
            </div>
        );
    };

    return (
        <div>
            {displayInfo(address)}
            {additionalAddress && <div className={styles['address']}>{additionalAddress}</div>}
            {displayDeliveries(deliveries)}
        </div>
    );
};
