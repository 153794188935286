import * as React from 'react';
import { PaymentMethodIcon } from 'components/payment/PaymentMethodIcon/PaymentMethodIcon';
import useWindowSize from 'lib/hooks/useWindowSize';
import styles from './Prices.module.scss';

export type PricesProps = {
    /**
     * List of posible amounts to pay with different payment methods
     */
    list: Price[];
    /**
     * Validation to render the text unit or total price.
     */
    isTotal: boolean;
    /**
     * Validation to not render anything if is warranty.
     */
    isWarranty?: boolean;
    /**
     * Place where Prices is being rendered
     */
    type?: 'cart';
    /**
     * Sets the country
     */
    country?: 'cl' | 'pe';
};

export const Prices: React.FC<PricesProps> = (props: PricesProps) => {
    const [width] = useWindowSize();
    return (
        <div
            className={
                props.type === 'cart' ? `${styles['prices']} ${styles['cart']}` : styles['prices']
            }
        >
            {props.list.map((price) => (
                <div
                    key={`${price.type} ${price.amount}`}
                    className={
                        width <= 768
                            ? isHighlight(price)
                                ? styles['prices--highlight']
                                : styles['prices--normal']
                            : isHighlight(price)
                            ? styles['prices--highlight']
                            : ''
                    }
                >
                    <div className={styles['prices--label']}>
                        {getLabel(price, props.isTotal, props.country || 'cl')}
                    </div>
                    <div className={styles['prices--value']}>{price.amount}</div>
                </div>
            ))}
        </div>
    );
};

export interface Price {
    /**
     * Type is used to display predefined texts or a payment method icon next
     * to the amount of this price
     */
    type: '' | 'normal' | 'ripley' | 'giftcard' | 'difftopay';
    /**
     * Amount should already contain currecy and thousands separator
     */
    amount: string;
}

const isHighlight = (price: Price): boolean => {
    return price.type === 'ripley';
};
export const getLabel = (price: Price, isTotal: boolean, country: string): React.ReactNode => {
    if (price.type == 'normal') {
        return isTotal ? 'Total' : '';
    } else if (price.type == 'ripley' && country == 'cl') {
        return (
            <>
                <PaymentMethodIcon type="ripleycredito" width="23px" />
                <PaymentMethodIcon type="chekcredito" width="23px" />
            </>
        );
    } else if (price.type == 'ripley' && country == 'pe') {
        return <PaymentMethodIcon type="ripleycredito" width="23px" />;
    }
};
