import jsonata from 'jsonata';

export function normalizeUserInfo(user: ExternalUser): {
    user: NormalizedUser;
    addresses: NormalizedAddresses;
} {
    /**
     * JSONata schema https://try.jsonata.org/dJfNHolOA
     */
    const schema = `{
        "user":{
            "id": id,
            "uid": uid,
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "nin": nin,
            "phoneNumber": phoneNumber,
            "selectedAddress": "",
            "addresses": addresses[].id,
            "registered": registered,
            "createdAt": createdAt,
            "canSignup": canSignup
        },
        "addresses":{
            "byId": addresses{
                id: {
                    "id": id,
                    "primary": primary,
                    "nickname": nickname,
                    "phoneNumber": phoneNumber,
                    "formattedAddress": formattedAddress,
                    "addressComponents": addressComponents
                }
            },
            "allIds": [$.addresses[].id]
        }
    }`;

    return jsonata(schema).evaluate(user) as any;
}
