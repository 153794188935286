import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useTranslation } from 'react-i18next';
import { useRules } from 'lib/hooks/rules';

import {
    getAddressCityName,
    getAddressComponentValue,
    getAddressProvinceName,
    getAddressRegionName,
} from 'lib/utils/addresses';

import { changeProvisionalMethod } from 'store/deliveryMethod';

import { PE, country, SELLER_RIPLEY, RT } from 'consts';
import AddressSelectorContainer from 'containers/aio/AddressSelectorContainer';
import AddressSummary, { Skeleton } from 'components/aio/AddressSummary';
import PickupModalContainer from '../PickupModalContainer';
import Button from 'components/common/Button';
import { RootState } from 'types/store';

export const AddressSummaryContainer: React.FC = () => {
    const { t } = useTranslation();
    const rules = useRules();
    const dispatch = useAppDispatch();
    const address = useAppSelector((state: RootState) => state.address.data);
    const areas = useAppSelector((state: RootState) => state.area.data);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const provisionalDeliveryMethod = useAppSelector(
        (state: RootState) => state.deliveryMethod.provisional,
    );

    const selectedAddressId = address?.selected;
    const selectedAddress = selectedAddressId && address?.byId[selectedAddressId];
    const isPickupModalOpen = provisionalDeliveryMethod === RT;

    const [isModalOpen, setIsModalOpen] = useState(false);

    if (selectedAddress) {
        const city = getAddressCityName(selectedAddress, areas);
        const province = getAddressProvinceName(selectedAddress, areas);
        const region = getAddressRegionName(selectedAddress, areas);
        const homeNumber: string = getAddressComponentValue(selectedAddress, 'homeNumber');

        let isPickupDisabled = false;
        if (!rules.feature('marketplacePickup') && cart) {
            const allMarketplace = cart.products.every(
                (product) => product.details.sellerId != SELLER_RIPLEY,
            );

            if (allMarketplace) {
                isPickupDisabled = true;
            }
        }

        let address = selectedAddress.formattedAddress;
        let additional = '';

        if (homeNumber) {
            address += `, ${homeNumber}`;
        }

        if (city) {
            address += `, ${city}`;
        }

        if (country === PE && province && region) {
            additional = `${province}, ${region}`;
        }

        const closePickupModal = () => {
            (async () => {
                await dispatch(changeProvisionalMethod(null));
            })();
        };

        const openPickupModal = () => {
            (async () => {
                await dispatch(changeProvisionalMethod(RT));
            })();
        };

        return (
            <>
                <AddressSummary
                    address={address}
                    additionalAddress={additional}
                    to={() => setIsModalOpen(true)}
                >
                    <Button
                        size="large"
                        theme="dark"
                        onClick={openPickupModal}
                        disabled={isPickupDisabled}
                    >
                        {t('iWantPickup')}
                    </Button>

                    {isPickupModalOpen && (
                        <PickupModalContainer
                            isOpen={isPickupModalOpen}
                            onToggle={closePickupModal}
                        />
                    )}
                </AddressSummary>

                <AddressSelectorContainer
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            </>
        );
    }

    return <Skeleton />;
};
