import React, { useEffect, useRef } from 'react';
import { useAppSelector } from 'lib/hooks/redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'types/store';

export const WebpayRedirect: React.FC = () => {
    const form = useRef<HTMLFormElement>(null);
    const pm = useAppSelector((state: RootState) => state.paymentMethod);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const history = useHistory();

    let token: string | undefined, url: string | undefined;

    if (pm.data.selected.includes('WebpayCredito')) {
        url = pm.types.webpayCredito.data?.webpayUrl;
        token = pm.types.webpayCredito.data?.webpayToken;
    } else {
        url = pm.types.webpayDebito.data?.webpayUrl;
        token = pm.types.webpayDebito.data?.webpayToken;
    }

    useEffect(() => {
        if (!cart?.shippingInfo?.length || !cart?.paymentInfo?.length) {
            history.push('/');
            return;
        }

        if (token && url) form.current?.submit();
    }, [token, url, cart, history]);

    return (
        <React.Fragment>
            <form
                data-testid="webpay-form"
                name="transbank-redirect"
                method="post"
                action={url}
                ref={form}
            >
                <input type="hidden" name="token_ws" value={token} />
            </form>
        </React.Fragment>
    );
};
