import { RootState } from 'types/store';
import { useAppSelector } from './redux';
import { useRules } from './rules';

type PaymentsHook = {
    /** Verifica que hayan medios de pagos seleccionados y esten Ok */
    areReady: () => boolean;
};

export function usePayments(): PaymentsHook {
    const coupon = useAppSelector((s: RootState) => s.coupon);
    const pm = useAppSelector((s: RootState) => s.paymentMethod);
    const selected = pm.data.selected;
    const isCopago = useAppSelector((state: RootState) => state.app.data?.paymentMode === 'copago');
    const chekAmountLimitExceeded = useChekAmountLimitExceeded();

    function areReady() {
        if (isCopago && selected.length < 2) return false;
        if (!isCopago && selected.length == 0) return false;
        if (pm.data.status !== 'ok') return false;
        if (coupon.status === 'pending') return false;
        if (selected.includes('RipleyChek') && chekAmountLimitExceeded) return false;
        if (selected.includes('Niubiz') && pm.types.niubiz.card == null) return false;
        if (selected.includes('RipleyNiubiz') && pm.types.ripleyNiubiz.card == null) return false;

        return selected.every((methodKey) => {
            return Object.entries(pm.types).every((element) => {
                const [key, method] = element;
                if (key.toLocaleLowerCase() == methodKey.toLocaleLowerCase())
                    return method.status === 'ok';

                return true;
            });
        });
    }

    return {
        areReady,
    };
}

export function useChekAmountLimitExceeded(): boolean {
    const rules = useRules();
    return useAppSelector((state: RootState) => {
        const total = state.cart.data?.grandTotal;
        if (total == null) return false;
        const price = total['ripley_promo'] ?? total['discount'] ?? total['master'];
        const value = parseFloat(price?.value ?? '0');
        const limit = parseFloat(rules.parameter('chekAmountLimit') || '1000000');
        return value > limit;
    });
}

type Niubiz3DSHook = {
    openModal: () => Promise<void>;
};

export function useNiubiz3ds(): Niubiz3DSHook {
    const niubiz3ds = useAppSelector((state) => state.paymentMethod.types.niubiz3Ds);

    const openModal = async () => {
        if (niubiz3ds.data) {
            const data = niubiz3ds.data;
            const configuration = {
                action: data?.action,
                amount: data?.amount,
                channel: data?.channel,
                expirationminutes: data?.expirationTime,
                formbuttoncolor: data?.formButtonColor,
                merchantid: data?.merchantId,
                merchantlogo: data?.merchantlogo,
                purchasenumber: data?.purchaseNumber,
                sessiontoken: data?.sessionToken,
                timeouturl: data?.timeoutUrl,
            };
            try {
                const paymentHubSDK = window.paymenthub;
                await paymentHubSDK.loadScript(paymentHubSDK.NIUBIZ_BUTTON_JS);
                await paymentHubSDK.createNiubizButtom(configuration);
            } catch (e) {
                throw new Error('Ha ocurrido un problema abriendo el modal de Niubiz');
            }
        } else {
            throw new Error('Los datos para usar Niubiz 3Ds estan incompletos');
        }
    };

    return {
        openModal,
    };
}
