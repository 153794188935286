import React, { createContext, useContext } from 'react';
import styles from './Layout.module.scss';

import Link from 'components/common/Link';

import ayuda from './ayuda.svg';
import whatsapp from './whatsapp.svg';
import ripleyLogo from './logo.svg';

import { CL, KIOSKO, MOBILE, PE } from 'consts';

export type LayoutProps = {
    content?: React.ReactNode;
    side?: React.ReactNode;
};
import { useTranslation } from 'react-i18next';
import { getEnvironment } from 'config';

export const ChannelContext = createContext('');

/**
 * Desktop layout
 * @param props
 */
export function Layout({ content, side }: LayoutProps): JSX.Element {
    const { app } = getEnvironment();
    const { t } = useTranslation('translation');

    const channel = useContext(ChannelContext);
    const sidebarClass = channel == KIOSKO ? ' ' + styles['sidebar'] : '';
    const mobileClass = channel == MOBILE ? ' ' + styles['mobile'] : '';

    const country = (app.country?.toUpperCase() as Country) || CL;

    const REACT_HELP = country === PE ? app.helpWhatsApp : app.help;

    return (
        <section
            className={styles['wrapper'] + sidebarClass + mobileClass}
            data-testid="layout-wrapper"
        >
            <section className={styles['header'] + sidebarClass + mobileClass}>
                <section>
                    <a title="ir a R.com" href={app.tiendaUrl} target="_self">
                        <img className={styles['logo']} src={ripleyLogo} alt="Ripley" />
                    </a>
                    <Link to={REACT_HELP || ''} title="Ayúdanos a mejorar">
                        <img
                            className={styles['ayuda']}
                            data-testid="ayuda"
                            src={country === PE ? whatsapp : ayuda}
                            alt="Ayuda"
                        />
                        {t('help')}
                        <span className={styles['whatsapp']}>&nbsp;{t('helpWhastapp')}</span>
                    </Link>
                </section>
            </section>
            <section className={styles['body']}>
                <section className={`${styles['container']} ${!side && styles['no-side']}`}>
                    <div className={styles['content']}>{content}</div>
                    {side ? <div className={styles['side']}>{side}</div> : null}
                </section>
            </section>
        </section>
    );
}

export default Layout;
