import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';

import Select from 'components/common/Select';
import AlertBadge from 'components/common/AlertBadge';
import { setOptions } from 'store/card/options';
import { RootState } from 'types/store';

type NiubizOptionsProps = {
    isRipley: boolean;
};

export const NiubizOptions: React.FC<NiubizOptionsProps> = ({ isRipley }: NiubizOptionsProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const niubiz = useAppSelector((state: RootState) =>
        isRipley ? state.paymentMethod.types.ripleyNiubiz : state.paymentMethod.types.niubiz,
    );
    const isLoading = niubiz.options.status === 'pending';
    const paymentMethod = isRipley ? 'RipleyNiubiz' : 'Niubiz';

    const handleChangeInstallments = (n: string) => {
        dispatch(
            setOptions({
                paymentMethod,
                paymentId: niubiz.card?.paymentId ?? '',
                installments: parseInt(n),
            }),
        );
    };
    const options = niubiz.options.data.installments.map((n) => {
        const value = n.toString(10);
        return { value, label: n <= 1 ? '1 cuota' : `${value} cuotas` };
    });

    return (
        <div>
            <div style={{ marginBottom: '1em' }}>
                <AlertBadge theme="info" size="large">
                    {t('installmentsValuesAreConditional')}
                </AlertBadge>
            </div>
            <Select
                label={t('installmentsNumber')}
                options={options}
                empty={options.length && options[0].value == '1' ? false : '1 cuota'}
                onChange={handleChangeInstallments}
                value={niubiz.card?.installments.toString()}
                disabled={isLoading}
            />
        </div>
    );
};
