import React, { FC, useLayoutEffect } from 'react';
import styles from './AlertBadge.module.scss';
import Addon from 'components/common/Addon';

import { ReactComponent as Warning } from './assets/Warning.svg';
import { ReactComponent as Danger } from './assets/Danger.svg';
import { ReactComponent as Info } from './assets/Info.svg';
import { ReactComponent as Success } from './assets/Success.svg';

export type AlertBadgeProps = {
    /**
     * Badge type
     */
    theme: 'danger' | 'info' | 'success' | 'warning';
    /**
     * Set component width. With 'medium' width is automatic and adjusted to
     * content. With 'large' width is the full width of its parent.
     */
    size?: 'medium' | 'large';
    /**
     * Message displayed inside badge
     */
    children: React.ReactNode;
    /**
     * Timeout to execute the callback
     */
    timeout?: number;
    /**
     * Callback to hide the error message
     */
    callback?: () => void;
};

export const AlertBadge: FC<AlertBadgeProps> = (props: AlertBadgeProps) => {
    const { children, theme, size = 'medium', timeout, callback } = props;

    useLayoutEffect(() => {
        if (callback && timeout) {
            const id = setTimeout(() => callback(), timeout);
            return () => clearTimeout(id);
        }
    });

    let badge;
    switch (theme) {
        case 'warning':
            badge = <Warning />;
            break;
        case 'danger':
            badge = <Danger />;
            break;
        case 'info':
            badge = <Info />;
            break;
        case 'success':
            badge = <Success />;
            break;
    }

    return (
        <div className={`${styles['container']} ${styles[theme]} ${styles[size]}`}>
            <Addon element={badge} position="left">
                {children}
            </Addon>
        </div>
    );
};
