type BusinessActivity = {
    label: string;
    value: string;
};

export const businessActivities = (): BusinessActivity[] => [
    {
        label: 'ACABADO DE PRODUCTOS TEXTILES',
        value: '131300',
    },
    {
        label: 'ACTIVIDADES ARTÍSTICAS REALIZADAS POR BANDAS DE MÚSICA, COMPAÑÍAS DE TEATRO, CIRCENSES Y SIMILARES',
        value: '900002',
    },
    {
        label: 'ACTIVIDADES BANCARIAS',
        value: '641910',
    },
    {
        label: 'ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES',
        value: '811000',
    },
    {
        label: 'ACTIVIDADES COMBINADAS DE SERVICIOS ADMINISTRATIVOS DE OFICINA',
        value: '821100',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS DE CALIFICACIÓN CREDITICIA',
        value: '829120',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS DE COBRO',
        value: '829110',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL (INCLUYE EMPRESAS DE SERVICIOS TRANSITORIOS)',
        value: '782000',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS DE EMPLEO',
        value: '781000',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS DE NOTICIAS',
        value: '639100',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS DE VIAJES',
        value: '791100',
    },
    {
        label: 'ACTIVIDADES DE AGENCIAS Y AGENTES DE REPRESENTACIÓN DE ACTORES, DEPORTISTAS Y OTRAS FIGURAS PÚBLICAS',
        value: '749004',
    },
    {
        label: 'ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS',
        value: '662200',
    },
    {
        label: 'ACTIVIDADES DE APOYO A LA AGRICULTURA',
        value: '16100',
    },
    {
        label: 'ACTIVIDADES DE APOYO A LA ENSEÑANZA',
        value: '855000',
    },
    {
        label: 'ACTIVIDADES DE APOYO A LA GANADERÍA',
        value: '16200',
    },
    {
        label: 'ACTIVIDADES DE APOYO PARA LA EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS PRESTADOS POR EMPRESAS',
        value: '99001',
    },
    {
        label: 'ACTIVIDADES DE APOYO PARA LA EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS PRESTADOS POR PROFESIONALES',
        value: '99002',
    },
    {
        label: 'ACTIVIDADES DE APOYO PARA LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL PRESTADOS POR EMPRESAS',
        value: '91001',
    },
    {
        label: 'ACTIVIDADES DE APOYO PARA LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL PRESTADOS POR PROFESIONALES',
        value: '91002',
    },
    {
        label: 'ACTIVIDADES DE ARTISTAS REALIZADAS DE FORMA INDEPENDIENTE: ACTORES, MÚSICOS, ESCRITORES, ENTRE OTROS',
        value: '900003',
    },
    {
        label: 'ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD',
        value: '881000',
    },
    {
        label: 'ACTIVIDADES DE ASOCIACIONES EMPRESARIALES Y DE EMPLEADORES',
        value: '941100',
    },
    {
        label: 'ACTIVIDADES DE ASOCIACIONES PROFESIONALES',
        value: '941200',
    },
    {
        label: 'ACTIVIDADES DE ATENCIÓN DE ENFERMERÍA EN INSTITUCIONES',
        value: '871000',
    },
    {
        label: 'ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS CON DISCAPACIDAD MENTAL Y TOXICÓMANOS',
        value: '872000',
    },
    {
        label: 'ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD FÍSICA',
        value: '873000',
    },
    {
        label: 'ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS',
        value: '910100',
    },
    {
        label: 'ACTIVIDADES DE CALL-CENTER',
        value: '822000',
    },
    {
        label: 'ACTIVIDADES DE CÁMARAS DE COMPENSACIÓN',
        value: '661901',
    },
    {
        label: 'ACTIVIDADES DE CAMPING Y DE PARQUES PARA CASAS RODANTES',
        value: '552000',
    },
    {
        label: 'ACTIVIDADES DE CASAS DE CAMBIO Y OPERADORES DE DIVISA',
        value: '661204',
    },
    {
        label: 'ACTIVIDADES DE CASINOS DE JUEGOS',
        value: '920010',
    },
    {
        label: 'ACTIVIDADES DE CENTROS DE MADRES',
        value: '949901',
    },
    {
        label: 'ACTIVIDADES DE CENTROS DE SALUD MUNICIPALIZADOS (SERVICIOS DE SALUD PÚBLICA)',
        value: '862010',
    },
    {
        label: 'ACTIVIDADES DE CLASIFICADORAS DE RIESGO',
        value: '661904',
    },
    {
        label: 'ACTIVIDADES DE CLÍNICAS VETERINARIAS',
        value: '750001',
    },
    {
        label: 'ACTIVIDADES DE CLUBES DE FÚTBOL AMATEUR Y PROFESIONAL',
        value: '931201',
    },
    {
        label: 'ACTIVIDADES DE CLUBES SOCIALES',
        value: '949902',
    },
    {
        label: 'ACTIVIDADES DE CONSULTORÍA DE GESTIÓN',
        value: '702000',
    },
    {
        label: 'ACTIVIDADES DE CONSULTORÍA DE INFORMÁTICA Y DE GESTIÓN DE INSTALACIONES INFORMÁTICAS',
        value: '620200',
    },
    {
        label: 'ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORÍA; CONSULTORÍA FISCAL',
        value: '692000',
    },
    {
        label: 'ACTIVIDADES DE CRÉDITO PRENDARIO',
        value: '649202',
    },
    {
        label: 'ACTIVIDADES DE DEFENSA',
        value: '842200',
    },
    {
        label: 'ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS',
        value: '390000',
    },
    {
        label: 'ACTIVIDADES DE DISCOTECAS Y CABARET (NIGHT CLUB), CON PREDOMINIO DEL SERVICIO DE BEBIDAS',
        value: '563001',
    },
    {
        label: 'ACTIVIDADES DE DISEÑO DE VESTUARIO',
        value: '741001',
    },
    {
        label: 'ACTIVIDADES DE DISEÑO Y DECORACIÓN DE INTERIORES',
        value: '741002',
    },
    {
        label: 'ACTIVIDADES DE DISTRIBUCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VIDEOS Y PROGRAMAS DE TELEVISIÓN',
        value: '591300',
    },
    {
        label: 'ACTIVIDADES DE ELABORACIÓN Y CONSERVACIÓN DE PESCADO, REALIZADAS EN BARCOS FACTORÍA',
        value: '102050',
    },
    {
        label: 'ACTIVIDADES DE ENVASADO Y EMPAQUETADO',
        value: '829200',
    },
    {
        label: 'ACTIVIDADES DE EXHIBICIÓN DE PELÍCULAS CINEMATOGRÁFICAS Y CINTAS DE VIDEO',
        value: '591400',
    },
    {
        label: 'ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA',
        value: '592000',
    },
    {
        label: 'ACTIVIDADES DE HOSPITALES Y CLÍNICAS PRIVADAS',
        value: '861020',
    },
    {
        label: 'ACTIVIDADES DE HOSPITALES Y CLÍNICAS PÚBLICAS',
        value: '861010',
    },
    {
        label: 'ACTIVIDADES DE HOTELES',
        value: '551001',
    },
    {
        label: 'ACTIVIDADES DE INVESTIGACIÓN (INCLUYE ACTIVIDADES DE INVESTIGADORES Y DETECTIVES PRIVADOS)',
        value: '803000',
    },
    {
        label: 'ACTIVIDADES DE ISAPRES',
        value: '651220',
    },
    {
        label: 'ACTIVIDADES DE JARDINES BOTÁNICOS, ZOOLÓGICOS Y RESERVAS NATURALES',
        value: '910300',
    },
    {
        label: 'ACTIVIDADES DE LA ADMINISTRACIÓN PÚBLICA EN GENERAL',
        value: '841100',
    },
    {
        label: 'ACTIVIDADES DE LABORATORIOS CLÍNICOS Y BANCOS DE SANGRE',
        value: '869010',
    },
    {
        label: 'ACTIVIDADES DE LABORATORIOS DENTALES',
        value: '325001',
    },
    {
        label: 'ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO',
        value: '970000',
    },
    {
        label: 'ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PÚBLICO Y DE SEGURIDAD',
        value: '842300',
    },
    {
        label: 'ACTIVIDADES DE MENSAJERÍA',
        value: '532000',
    },
    {
        label: 'ACTIVIDADES DE MOTELES',
        value: '551002',
    },
    {
        label: 'ACTIVIDADES DE MUSEOS, GESTIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS',
        value: '910200',
    },
    {
        label: 'ACTIVIDADES DE OFICINAS PRINCIPALES',
        value: '701000',
    },
    {
        label: 'ACTIVIDADES DE OPERADORES TURÍSTICOS',
        value: '791200',
    },
    {
        label: 'ACTIVIDADES DE ORGANIZACIONES POLÍTICAS',
        value: '949200',
    },
    {
        label: 'ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS',
        value: '949100',
    },
    {
        label: 'ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES',
        value: '990000',
    },
    {
        label: 'ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P.',
        value: '949909',
    },
    {
        label: 'ACTIVIDADES DE OTROS CLUBES DEPORTIVOS N.C.P.',
        value: '931209',
    },
    {
        label: 'ACTIVIDADES DE PAISAJISMO, SERVICIOS DE JARDINERÍA Y SERVICIOS CONEXOS',
        value: '813000',
    },
    {
        label: 'ACTIVIDADES DE PARQUES DE ATRACCIONES Y PARQUES TEMÁTICOS',
        value: '932100',
    },
    {
        label: 'ACTIVIDADES DE PLANTAS DE REVISIÓN TÉCNICA PARA VEHÍCULOS AUTOMOTORES',
        value: '712001',
    },
    {
        label: 'ACTIVIDADES DE POSTPRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VIDEOS Y PROGRAMAS DE TELEVISIÓN',
        value: '591200',
    },
    {
        label: 'ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VIDEOS Y PROGRAMAS DE TELEVISIÓN',
        value: '591100',
    },
    {
        label: 'ACTIVIDADES DE PROGRAMACIÓN INFORMÁTICA',
        value: '620100',
    },
    {
        label: 'ACTIVIDADES DE RESIDENCIALES PARA ESTUDIANTES Y TRABAJADORES ',
        value: '559001',
    },
    {
        label: 'ACTIVIDADES DE RESIDENCIALES PARA TURISTAS',
        value: '551003',
    },
    {
        label: 'ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MÓVIL DE COMIDAS',
        value: '561000',
    },
    {
        label: 'ACTIVIDADES DE SALONES DE MASAJES, BAÑOS TURCOS, SAUNAS, SERVICIO DE BAÑOS PÚBLICOS',
        value: '960902',
    },
    {
        label: 'ACTIVIDADES DE SECURITIZADORAS',
        value: '661201',
    },
    {
        label: 'ACTIVIDADES DE SERVICIOS DE SISTEMAS DE SEGURIDAD (INCLUYE SERVICIOS DE CERRAJERÍA)',
        value: '802000',
    },
    {
        label: 'ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESIÓN',
        value: '181200',
    },
    {
        label: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACUÁTICO',
        value: '522200',
    },
    {
        label: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE AÉREO',
        value: '522300',
    },
    {
        label: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE N.C.P.',
        value: '522190',
    },
    {
        label: 'ACTIVIDADES DE SINDICATOS',
        value: '942000',
    },
    {
        label: 'ACTIVIDADES DE SOCIEDADES DE CARTERA',
        value: '642000',
    },
    {
        label: 'ACTIVIDADES DE VETERINARIOS, TÉCNICOS Y OTRO PERSONAL AUXILIAR, PRESTADOS DE FORMA INDEPENDIENTE',
        value: '750002',
    },
    {
        label: 'ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA',
        value: '682000',
    },
    {
        label: 'ACTIVIDADES POSCOSECHA',
        value: '16300',
    },
    {
        label: 'ACTIVIDADES POSTALES',
        value: '531000',
    },
    {
        label: 'ACUICULTURA DE AGUA DULCE',
        value: '32200',
    },
    {
        label: 'ADMINISTRACIÓN DE MERCADOS FINANCIEROS',
        value: '661100',
    },
    {
        label: 'ADMINISTRACIÓN DE TARJETAS DE CRÉDITO',
        value: '661902',
    },
    {
        label: 'ADMINISTRADORAS DE FICES (FONDOS DE INVERSIÓN DE CAPITAL EXTRANJERO)',
        value: '663093',
    },
    {
        label: 'ADMINISTRADORAS DE FONDOS DE INVERSIÓN',
        value: '663091',
    },
    {
        label: 'ADMINISTRADORAS DE FONDOS DE PENSIONES (AFP)',
        value: '663010',
    },
    {
        label: 'ADMINISTRADORAS DE FONDOS MUTUOS',
        value: '663092',
    },
    {
        label: 'ADMINISTRADORAS DE FONDOS PARA LA VIVIENDA',
        value: '663094',
    },
    {
        label: 'ADMINISTRADORAS DE FONDOS PARA OTROS FINES N.C.P.',
        value: '663099',
    },
    {
        label: 'AGENCIAS DE ADUANAS',
        value: '522910',
    },
    {
        label: 'AGENCIAS DE NAVES',
        value: '522920',
    },
    {
        label: 'AGENTES DE VALORES',
        value: '661203',
    },
    {
        label: 'ALQUILER DE BIENES INMUEBLES AMOBLADOS O CON EQUIPOS Y MAQUINARIAS',
        value: '681011',
    },
    {
        label: 'ALQUILER DE CINTAS DE VIDEO Y DISCOS',
        value: '772200',
    },
    {
        label: 'ALQUILER DE EQUIPOS DE TRANSPORTE SIN OPERARIO, EXCEPTO VEHÍCULOS AUTOMOTORES',
        value: '773001',
    },
    {
        label: 'ALQUILER DE MAQUINARIA Y EQUIPO AGROPECUARIO, FORESTAL, DE CONSTRUCCIÓN E ING. CIVIL, SIN OPERARIOS',
        value: '773002',
    },
    {
        label: 'ALQUILER DE MAQUINARIA Y EQUIPO DE OFICINA, SIN OPERARIOS (SIN SERVICIO ADMINISTRATIVO)',
        value: '773003',
    },
    {
        label: 'ALQUILER DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS (INCLUYE MOBILIARIO PARA EVENTOS)',
        value: '772900',
    },
    {
        label: 'ALQUILER DE OTROS TIPOS DE MAQUINARIAS Y EQUIPOS SIN OPERARIO N.C.P.',
        value: '773009',
    },
    {
        label: 'ALQUILER DE VEHÍCULOS AUTOMOTORES SIN CHOFER',
        value: '771000',
    },
    {
        label: 'ALQUILER Y ARRENDAMIENTO DE EQUIPO RECREATIVO Y DEPORTIVO',
        value: '772100',
    },
    {
        label: 'APICULTURA',
        value: '14901',
    },
    {
        label: 'ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y SIMILARES, EXCEPTO OBRAS PROTEGIDAS POR DERECHOS DE AUTOR',
        value: '774000',
    },
    {
        label: 'ASERRADO Y ACEPILLADURA DE MADERA',
        value: '161000',
    },
    {
        label: 'ASESORÍA Y GESTIÓN EN LA COMPRA O VENTA DE PEQUEÑAS Y MEDIANAS EMPRESAS',
        value: '749001',
    },
    {
        label: 'BANCA CENTRAL',
        value: '641100',
    },
    {
        label: 'CAJAS DE COMPENSACIÓN',
        value: '649203',
    },
    {
        label: 'CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA',
        value: '360000',
    },
    {
        label: 'CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS',
        value: '17000',
    },
    {
        label: 'CENTROS DE ATENCIÓN ODONTOLÓGICA PRIVADOS (ESTABLECIMIENTOS DE ATENCIÓN AMBULATORIA)',
        value: '862022',
    },
    {
        label: 'CENTROS DE LLAMADOS Y CENTROS DE ACCESO A INTERNET',
        value: '619010',
    },
    {
        label: 'CENTROS MÉDICOS PRIVADOS (ESTABLECIMIENTOS DE ATENCIÓN AMBULATORIA)',
        value: '862021',
    },
    {
        label: 'COMPRA, VENTA Y ALQUILER (EXCEPTO AMOBLADOS) DE INMUEBLES',
        value: '681012',
    },
    {
        label: 'CONSEJO DE ADMINISTRACIÓN DE EDIFICIOS Y CONDOMINIOS',
        value: '949904',
    },
    {
        label: 'CONSERVADOR DE BIENES RAÍCES',
        value: '691003',
    },
    {
        label: 'CONSTRUCCIÓN DE BUQUES, EMBARCACIONES MENORES Y ESTRUCTURAS FLOTANTES',
        value: '301100',
    },
    {
        label: 'CONSTRUCCIÓN DE CARRETERAS Y LÍNEAS DE FERROCARRIL',
        value: '421000',
    },
    {
        label: 'CONSTRUCCIÓN DE EDIFICIOS PARA USO NO RESIDENCIAL',
        value: '410020',
    },
    {
        label: 'CONSTRUCCIÓN DE EDIFICIOS PARA USO RESIDENCIAL',
        value: '410010',
    },
    {
        label: 'CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DE DEPORTE',
        value: '301200',
    },
    {
        label: 'CONSTRUCCIÓN DE OTRAS OBRAS DE INGENIERÍA CIVIL',
        value: '429000',
    },
    {
        label: 'CONSTRUCCIÓN DE PROYECTOS DE SERVICIO PÚBLICO',
        value: '422000',
    },
    {
        label: 'CORREDORES DE BOLSA',
        value: '661202',
    },
    {
        label: 'CORRETAJE AL POR MAYOR DE GANADO',
        value: '461002',
    },
    {
        label: 'CORRETAJE AL POR MAYOR DE PRODUCTOS AGRÍCOLAS',
        value: '461001',
    },
    {
        label: 'CORTE, TALLA Y ACABADO DE LA PIEDRA',
        value: '239600',
    },
    {
        label: 'CRÍA DE AVES DE CORRAL PARA LA PRODUCCIÓN DE CARNE',
        value: '14601',
    },
    {
        label: 'CRÍA DE AVES DE CORRAL PARA LA PRODUCCIÓN DE HUEVOS',
        value: '14602',
    },
    {
        label: 'CRÍA DE CABALLOS Y OTROS EQUINOS',
        value: '14200',
    },
    {
        label: 'CRÍA DE CABRAS (CAPRINOS)',
        value: '14420',
    },
    {
        label: 'CRÍA DE CERDOS',
        value: '14500',
    },
    {
        label: 'CRÍA DE GANADO BOVINO PARA LA PRODUCCIÓN DE CARNE O COMO GANADO REPRODUCTOR',
        value: '14102',
    },
    {
        label: 'CRÍA DE GANADO BOVINO PARA LA PRODUCCIÓN LECHERA',
        value: '14101',
    },
    {
        label: 'CRÍA DE LLAMAS, ALPACAS, VICUÑAS, GUANACOS Y OTROS CAMÉLIDOS',
        value: '14300',
    },
    {
        label: 'CRÍA DE OTROS ANIMALES N.C.P.',
        value: '14909',
    },
    {
        label: 'CRÍA DE OVEJAS (OVINOS)',
        value: '14410',
    },
    {
        label: 'CULTIVO DE ARROZ',
        value: '11200',
    },
    {
        label: 'CULTIVO DE AVENA',
        value: '11103',
    },
    {
        label: 'CULTIVO DE CAMOTES',
        value: '11302',
    },
    {
        label: 'CULTIVO DE CAÑA DE AZÚCAR',
        value: '11400',
    },
    {
        label: 'CULTIVO DE CEBADA',
        value: '11104',
    },
    {
        label: 'CULTIVO DE CÍTRICOS',
        value: '12300',
    },
    {
        label: 'CULTIVO DE ESPECIAS',
        value: '12801',
    },
    {
        label: 'CULTIVO DE FLORES',
        value: '11901',
    },
    {
        label: 'CULTIVO DE FRUTAS DE PEPITA Y DE HUESO',
        value: '12400',
    },
    {
        label: 'CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES (INCLUYE EL CULTIVO DE PALTAS)',
        value: '12200',
    },
    {
        label: 'CULTIVO DE FRUTOS OLEAGINOSOS (INCLUYE EL CULTIVO DE ACEITUNAS)',
        value: '12600',
    },
    {
        label: 'CULTIVO DE HORTALIZAS Y MELONES ',
        value: '11306',
    },
    {
        label: 'CULTIVO DE LUPINO',
        value: '11107',
    },
    {
        label: 'CULTIVO DE MAÍZ',
        value: '11102',
    },
    {
        label: 'CULTIVO DE OTRAS LEGUMBRES (EXCEPTO POROTOS Y LUPINO)',
        value: '11108',
    },
    {
        label: 'CULTIVO DE OTRAS PLANTAS PERENNES',
        value: '12900',
    },
    {
        label: 'CULTIVO DE OTROS CEREALES (EXCEPTO TRIGO, MAÍZ, AVENA Y CEBADA)',
        value: '11105',
    },
    {
        label: 'CULTIVO DE OTROS FRUTOS Y NUECES DE ÁRBOLES Y ARBUSTOS',
        value: '12502',
    },
    {
        label: 'CULTIVO DE OTROS TUBÉRCULOS (EXCEPTO PAPAS Y CAMOTES)',
        value: '11303',
    },
    {
        label: 'CULTIVO DE PAPAS',
        value: '11301',
    },
    {
        label: 'CULTIVO DE PLANTAS AROMÁTICAS, MEDICINALES Y FARMACÉUTICAS',
        value: '12802',
    },
    {
        label: 'CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS (INCLUYE EL CULTIVO DE CAFÉ, TÉ Y MATE)',
        value: '12700',
    },
    {
        label: 'CULTIVO DE PLANTAS DE FIBRA',
        value: '11600',
    },
    {
        label: 'CULTIVO DE PLANTAS VIVAS INCLUIDA LA PRODUCCIÓN EN VIVEROS (EXCEPTO VIVEROS FORESTALES)',
        value: '13000',
    },
    {
        label: 'CULTIVO DE POROTOS',
        value: '11106',
    },
    {
        label: 'CULTIVO DE PRODUCTOS AGRÍCOLAS EN COMBINACIÓN CON LA CRÍA DE ANIMALES (EXPLOTACIÓN MIXTA)',
        value: '15000',
    },
    {
        label: 'CULTIVO DE REMOLACHA AZUCARERA',
        value: '11304',
    },
    {
        label: 'CULTIVO DE SEMILLAS DE CEREALES, LEGUMBRES Y OLEAGINOSAS (EXCEPTO SEMILLAS DE RAPS Y MARAVILLA)',
        value: '11111',
    },
    {
        label: 'CULTIVO DE SEMILLAS DE FRUTAS',
        value: '12501',
    },
    {
        label: 'CULTIVO DE SEMILLAS DE HORTALIZAS',
        value: '11305',
    },
    {
        label: 'CULTIVO DE SEMILLAS DE MARAVILLA (GIRASOL)',
        value: '11110',
    },
    {
        label: 'CULTIVO DE SEMILLAS DE RAPS',
        value: '11109',
    },
    {
        label: 'CULTIVO DE TABACO',
        value: '11500',
    },
    {
        label: 'CULTIVO DE TRIGO',
        value: '11101',
    },
    {
        label: 'CULTIVO DE UVA DESTINADA A LA PRODUCCIÓN DE PISCO Y AGUARDIENTE',
        value: '12111',
    },
    {
        label: 'CULTIVO DE UVA DESTINADA A LA PRODUCCIÓN DE VINO',
        value: '12112',
    },
    {
        label: 'CULTIVO DE UVA PARA MESA',
        value: '12120',
    },
    {
        label: 'CULTIVO Y CRIANZA DE PECES MARINOS',
        value: '32110',
    },
    {
        label: 'CULTIVO, REPRODUCCIÓN Y MANEJO DE ALGAS MARINAS',
        value: '32120',
    },
    {
        label: 'CULTIVOS DE SEMILLAS DE FLORES; CULTIVO DE SEMILLAS DE PLANTAS FORRAJERAS',
        value: '11903',
    },
    {
        label: 'CULTIVOS FORRAJEROS EN PRADERAS MEJORADAS O SEMBRADAS; CULTIVOS SUPLEMENTARIOS FORRAJEROS',
        value: '11902',
    },
    {
        label: 'CURTIDO Y ADOBO DE CUEROS; ADOBO Y TEÑIDO DE PIELES',
        value: '151100',
    },
    {
        label: 'DEMOLICIÓN',
        value: '431100',
    },
    {
        label: 'DESRATIZACIÓN, DESINFECCIÓN Y EXTERMINIO DE PLAGAS NO AGRÍCOLAS',
        value: '812901',
    },
    {
        label: 'DESTILACIÓN, RECTIFICACIÓN Y MEZCLAS DE BEBIDAS ALCOHÓLICAS; EXCEPTO PISCO',
        value: '110120',
    },
    {
        label: 'DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA',
        value: '351030',
    },
    {
        label: 'EDICIÓN DE DIARIOS, REVISTAS Y OTRAS PUBLICACIONES PERIÓDICAS',
        value: '581300',
    },
    {
        label: 'EDICIÓN DE DIRECTORIOS Y LISTAS DE CORREO',
        value: '581200',
    },
    {
        label: 'EDICIÓN DE LIBROS',
        value: '581100',
    },
    {
        label: 'EDICIÓN DE PROGRAMAS INFORMÁTICOS',
        value: '582000',
    },
    {
        label: 'ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL (EXCEPTO ELABORACIÓN DE MANTEQUILLA)',
        value: '104000',
    },
    {
        label: 'ELABORACIÓN DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDÓN',
        value: '106200',
    },
    {
        label: 'ELABORACIÓN DE AZÚCAR',
        value: '107200',
    },
    {
        label: 'ELABORACIÓN DE BEBIDAS MALTEADAS Y DE MALTA',
        value: '110300',
    },
    {
        label: 'ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS',
        value: '110401',
    },
    {
        label: 'ELABORACIÓN DE CACAO, CHOCOLATE Y DE PRODUCTOS DE CONFITERÍA',
        value: '107300',
    },
    {
        label: 'ELABORACIÓN DE CIGARROS Y CIGARRILLOS',
        value: '120001',
    },
    {
        label: 'ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS ENVASADOS, ROTULADOS Y CON INFORMACIÓN NUTRICIONAL',
        value: '107500',
    },
    {
        label: 'ELABORACIÓN DE HIELO (EXCEPTO FABRICACIÓN DE HIELO SECO)',
        value: '353002',
    },
    {
        label: 'ELABORACIÓN DE LEVADURAS NATURALES O ARTIFICIALES',
        value: '107902',
    },
    {
        label: 'ELABORACIÓN DE MACARRONES, FIDEOS, ALCUZCUZ Y PRODUCTOS FARINÁCEOS SIMILARES',
        value: '107400',
    },
    {
        label: 'ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS N.C.P.',
        value: '107909',
    },
    {
        label: 'ELABORACIÓN DE OTROS PRODUCTOS DE MOLINERÍA N.C.P.',
        value: '106109',
    },
    {
        label: 'ELABORACIÓN DE OTROS PRODUCTOS DE TABACO N.C.P.',
        value: '120009',
    },
    {
        label: 'ELABORACIÓN DE PIENSOS PREPARADOS PARA ANIMALES',
        value: '108000',
    },
    {
        label: 'ELABORACIÓN DE PISCO (INDUSTRIAS PISQUERAS)',
        value: '110110',
    },
    {
        label: 'ELABORACIÓN DE PRODUCTOS DE PANADERÍA Y PASTELERÍA',
        value: '107100',
    },
    {
        label: 'ELABORACIÓN DE PRODUCTOS LÁCTEOS',
        value: '105000',
    },
    {
        label: 'ELABORACIÓN DE TÉ, CAFÉ, MATE E INFUSIONES DE HIERBAS',
        value: '107901',
    },
    {
        label: 'ELABORACIÓN DE VINAGRES, MOSTAZAS, MAYONESAS Y CONDIMENTOS EN GENERAL',
        value: '107903',
    },
    {
        label: 'ELABORACIÓN DE VINOS',
        value: '110200',
    },
    {
        label: 'ELABORACIÓN Y CONSERVACIÓN DE CARNE Y PRODUCTOS CÁRNICOS',
        value: '101020',
    },
    {
        label: 'ELABORACIÓN Y CONSERVACIÓN DE CRUSTÁCEOS, MOLUSCOS Y OTROS PRODUCTOS ACUÁTICOS, EN PLANTAS EN TIERRA',
        value: '102040',
    },
    {
        label: 'ELABORACIÓN Y CONSERVACIÓN DE FRUTAS, LEGUMBRES Y HORTALIZAS',
        value: '103000',
    },
    {
        label: 'ELABORACIÓN Y CONSERVACIÓN DE OTROS PESCADOS, EN PLANTAS EN TIERRA (EXCEPTO BARCOS FACTORÍA)',
        value: '102030',
    },
    {
        label: 'ELABORACIÓN Y CONSERVACIÓN DE SALMÓNIDOS',
        value: '102020',
    },
    {
        label: 'ELABORACIÓN Y PROCESAMIENTO DE ALGAS',
        value: '102060',
    },
    {
        label: 'EMPRESAS DE ASESORÍA Y CONSULTORÍA EN INVERSIÓN FINANCIERA; SOCIEDADES DE APOYO AL GIRO',
        value: '661903',
    },
    {
        label: 'EMPRESAS DE SERVICIOS DE INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA ',
        value: '711002',
    },
    {
        label: 'ENSEÑANZA CULTURAL',
        value: '854200',
    },
    {
        label: 'ENSEÑANZA DEPORTIVA Y RECREATIVA',
        value: '854100',
    },
    {
        label: 'ENSEÑANZA PREESCOLAR PRIVADA',
        value: '850021',
    },
    {
        label: 'ENSEÑANZA PREESCOLAR PÚBLICA',
        value: '850011',
    },
    {
        label: 'ENSEÑANZA PREUNIVERSITARIA',
        value: '854901',
    },
    {
        label: 'ENSEÑANZA PRIMARIA, SECUNDARIA CIENTÍFICO HUMANISTA Y TÉCNICO PROFESIONAL PRIVADA',
        value: '850022',
    },
    {
        label: 'ENSEÑANZA PRIMARIA, SECUNDARIA CIENTÍFICO HUMANISTA Y TÉCNICO PROFESIONAL PÚBLICA',
        value: '850012',
    },
    {
        label: 'ENSEÑANZA SUPERIOR EN CENTROS DE FORMACIÓN TÉCNICA',
        value: '853202',
    },
    {
        label: 'ENSEÑANZA SUPERIOR EN INSTITUTOS PROFESIONALES',
        value: '853201',
    },
    {
        label: 'ENSEÑANZA SUPERIOR EN UNIVERSIDADES PRIVADAS',
        value: '853120',
    },
    {
        label: 'ENSEÑANZA SUPERIOR EN UNIVERSIDADES PÚBLICAS',
        value: '853110',
    },
    {
        label: 'ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINIÓN PÚBLICA',
        value: '732000',
    },
    {
        label: 'EVACUACIÓN Y TRATAMIENTO DE AGUAS SERVIDAS',
        value: '370000',
    },
    {
        label: 'EVALUACIÓN DE RIESGOS Y DAÑOS (INCLUYE ACTIVIDADES DE LIQUIDADORES DE SEGUROS)',
        value: '662100',
    },
    {
        label: 'EXPLOTACIÓN DE ESTACIONAMIENTOS DE VEHÍCULOS AUTOMOTORES Y PARQUÍMETROS',
        value: '522120',
    },
    {
        label: 'EXPLOTACIÓN DE FRIGORÍFICOS PARA ALMACENAMIENTO Y DEPÓSITO',
        value: '521001',
    },
    {
        label: 'EXPLOTACIÓN DE MATADEROS DE AVES Y DE OTROS TIPOS DE ANIMALES N.C.P.',
        value: '101019',
    },
    {
        label: 'EXPLOTACIÓN DE MATADEROS DE BOVINOS, OVINOS, EQUINOS, CAPRINOS, PORCINOS Y CAMÉLIDOS ',
        value: '101011',
    },
    {
        label: 'EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS N.C.P.',
        value: '89900',
    },
    {
        label: 'EXPLOTACIÓN DE TERMINALES TERRESTRES DE PASAJEROS',
        value: '522110',
    },
    {
        label: 'EXPLOTACIÓN DE VIVEROS FORESTALES',
        value: '21001',
    },
    {
        label: 'EXTRACCIÓN DE CARBÓN DE PIEDRA',
        value: '51000',
    },
    {
        label: 'EXTRACCIÓN DE GAS NATURAL',
        value: '62000',
    },
    {
        label: 'EXTRACCIÓN DE LIGNITO',
        value: '52000',
    },
    {
        label: 'EXTRACCIÓN DE MADERA',
        value: '22000',
    },
    {
        label: 'EXTRACCIÓN DE MANGANESO',
        value: '72992',
    },
    {
        label: 'EXTRACCIÓN DE MINERALES DE HIERRO',
        value: '71000',
    },
    {
        label: 'EXTRACCIÓN DE MINERALES DE URANIO Y TORIO',
        value: '72100',
    },
    {
        label: 'EXTRACCIÓN DE MINERALES PARA LA FABRICACIÓN DE ABONOS Y PRODUCTOS QUÍMICOS N.C.P.',
        value: '89190',
    },
    {
        label: 'EXTRACCIÓN DE ORO Y PLATA',
        value: '72910',
    },
    {
        label: 'EXTRACCIÓN DE OTROS MINERALES METALÍFEROS NO FERROSOS N.C.P. (EXCEPTO ZINC, PLOMO Y MANGANESO)',
        value: '72999',
    },
    {
        label: 'EXTRACCIÓN DE PETRÓLEO CRUDO',
        value: '61000',
    },
    {
        label: 'EXTRACCIÓN DE PIEDRA, ARENA Y ARCILLA',
        value: '81000',
    },
    {
        label: 'EXTRACCIÓN DE SAL',
        value: '89300',
    },
    {
        label: 'EXTRACCIÓN DE TURBA',
        value: '89200',
    },
    {
        label: 'EXTRACCIÓN DE ZINC Y PLOMO',
        value: '72991',
    },
    {
        label: 'EXTRACCIÓN Y PROCESAMIENTO DE COBRE',
        value: '40000',
    },
    {
        label: 'EXTRACCIÓN Y PROCESAMIENTO DE LITIO',
        value: '89110',
    },
    {
        label: 'FABRICACIÓN DE ABONOS Y COMPUESTOS DE NITRÓGENO',
        value: '201200',
    },
    {
        label: 'FABRICACIÓN DE ACCESORIOS DE VESTIR',
        value: '141003',
    },
    {
        label: 'FABRICACIÓN DE AERONAVES, NAVES ESPACIALES Y MAQUINARIA CONEXA',
        value: '303000',
    },
    {
        label: 'FABRICACIÓN DE APARATOS DE USO DOMÉSTICO',
        value: '275000',
    },
    {
        label: 'FABRICACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO',
        value: '264000',
    },
    {
        label: 'FABRICACIÓN DE ARMAS Y MUNICIONES',
        value: '252000',
    },
    {
        label: 'FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR',
        value: '139200',
    },
    {
        label: 'FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA, HERRAMIENTAS DE MANO Y ARTÍCULOS DE FERRETERÍA',
        value: '259300',
    },
    {
        label: 'FABRICACIÓN DE ARTÍCULOS DE DEPORTE',
        value: '323000',
    },
    {
        label: 'FABRICACIÓN DE ARTÍCULOS DE HORMIGÓN, CEMENTO Y YESO',
        value: '239500',
    },
    {
        label: 'FABRICACIÓN DE ARTÍCULOS DE PIEL',
        value: '142000',
    },
    {
        label: 'FABRICACIÓN DE ARTÍCULOS DE PUNTO Y GANCHILLO',
        value: '143000',
    },
    {
        label: 'FABRICACIÓN DE BICICLETAS Y DE SILLAS DE RUEDAS',
        value: '309200',
    },
    {
        label: 'FABRICACIÓN DE BISUTERÍA Y ARTÍCULOS CONEXOS',
        value: '321200',
    },
    {
        label: 'FABRICACIÓN DE CABLES DE FIBRA ÓPTICA',
        value: '273100',
    },
    {
        label: 'FABRICACIÓN DE CALZADO',
        value: '152000',
    },
    {
        label: 'FABRICACIÓN DE CARBÓN VEGETAL (EXCEPTO ACTIVADO); FABRICACIÓN DE BRIQUETAS DE CARBÓN VEGETAL',
        value: '201101',
    },
    {
        label: 'FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES',
        value: '292000',
    },
    {
        label: 'FABRICACIÓN DE CELULOSA Y OTRAS PASTAS DE MADERA',
        value: '170110',
    },
    {
        label: 'FABRICACIÓN DE CEMENTO, CAL Y YESO',
        value: '239400',
    },
    {
        label: 'FABRICACIÓN DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISIÓN',
        value: '281400',
    },
    {
        label: 'FABRICACIÓN DE COLCHONES; FABRICACIÓN DE OTROS MUEBLES N.C.P.',
        value: '310009',
    },
    {
        label: 'FABRICACIÓN DE COMPONENTES Y TABLEROS ELECTRÓNICOS',
        value: '261000',
    },
    {
        label: 'FABRICACIÓN DE COMPUTADORES Y EQUIPO PERIFÉRICO',
        value: '262000',
    },
    {
        label: 'FABRICACIÓN DE CUBIERTAS Y CÁMARAS DE CAUCHO; RECAUCHUTADO Y RENOVACIÓN DE CUBIERTAS DE CAUCHO',
        value: '221100',
    },
    {
        label: 'FABRICACIÓN DE CUERDAS, CORDELES, BRAMANTES Y REDES',
        value: '139400',
    },
    {
        label: 'FABRICACIÓN DE DISPOSITIVOS DE CABLEADO',
        value: '273300',
    },
    {
        label: 'FABRICACIÓN DE EQUIPO DE COMUNICACIONES',
        value: '263000',
    },
    {
        label: 'FABRICACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN',
        value: '281600',
    },
    {
        label: 'FABRICACIÓN DE EQUIPO DE IRRADIACIÓN Y EQUIPO ELECTRÓNICO DE USO MÉDICO Y TERAPÉUTICO',
        value: '266000',
    },
    {
        label: 'FABRICACIÓN DE EQUIPO DE MEDICIÓN, PRUEBA, NAVEGACIÓN Y CONTROL',
        value: '265100',
    },
    {
        label: 'FABRICACIÓN DE EQUIPO DE PROPULSIÓN DE FLUIDOS',
        value: '281200',
    },
    {
        label: 'FABRICACIÓN DE EQUIPO ELÉCTRICO DE ILUMINACIÓN',
        value: '274000',
    },
    {
        label: 'FABRICACIÓN DE EXPLOSIVOS Y PRODUCTOS PIROTÉCNICOS',
        value: '202901',
    },
    {
        label: 'FABRICACIÓN DE FIBRAS ARTIFICIALES',
        value: '203000',
    },
    {
        label: 'FABRICACIÓN DE FIBRAS DE VIDRIO',
        value: '231003',
    },
    {
        label: 'FABRICACIÓN DE GAS; DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍA, EXCEPTO REGASIFICACIÓN DE GNL',
        value: '352020',
    },
    {
        label: 'FABRICACIÓN DE GENERADORES DE VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCIÓN CENTRAL',
        value: '251300',
    },
    {
        label: 'FABRICACIÓN DE HERRAMIENTAS DE MANO MOTORIZADAS',
        value: '281800',
    },
    {
        label: 'FABRICACIÓN DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA',
        value: '162100',
    },
    {
        label: 'FABRICACIÓN DE HORNOS, CALDERAS Y QUEMADORES',
        value: '281500',
    },
    {
        label: 'FABRICACIÓN DE INSTRUMENTOS MUSICALES',
        value: '322000',
    },
    {
        label: 'FABRICACIÓN DE INSTRUMENTOS ÓPTICOS Y EQUIPO FOTOGRÁFICO',
        value: '267000',
    },
    {
        label: 'FABRICACIÓN DE INSTRUMENTOS Y MATERIALES MÉDICOS, OFTALMOLÓGICOS Y ODONTOLÓGICOS N.C.P.',
        value: '325009',
    },
    {
        label: 'FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR, PERFUMES Y PREPARADOS DE TOCADOR',
        value: '202300',
    },
    {
        label: 'FABRICACIÓN DE JOYAS Y ARTÍCULOS CONEXOS',
        value: '321100',
    },
    {
        label: 'FABRICACIÓN DE JUEGOS Y JUGUETES',
        value: '324000',
    },
    {
        label: 'FABRICACIÓN DE LOCOMOTORAS Y MATERIAL RODANTE',
        value: '302000',
    },
    {
        label: 'FABRICACIÓN DE MALETAS, BOLSOS Y ARTÍCULOS SIMILARES, ARTÍCULOS DE TALABARTERÍA Y GUARNICIONERÍA',
        value: '151200',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL',
        value: '282100',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA METALÚRGICA',
        value: '282300',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA PARA LA CONFORMACIÓN DE METALES Y DE MÁQUINAS HERRAMIENTA',
        value: '282200',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO',
        value: '282500',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CUEROS',
        value: '282600',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA PARA LA EXPLOTACIÓN DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCIÓN',
        value: '282400',
    },
    {
        label: 'FABRICACIÓN DE MAQUINARIA Y EQUIPO DE OFICINA (EXCEPTO COMPUTADORES Y EQUIPO PERIFÉRICO)',
        value: '281700',
    },
    {
        label: 'FABRICACIÓN DE MATERIALES DE CONSTRUCCIÓN DE ARCILLA',
        value: '239200',
    },
    {
        label: 'FABRICACIÓN DE MOTOCICLETAS',
        value: '309100',
    },
    {
        label: 'FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO PARA AERONAVES, VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS',
        value: '281100',
    },
    {
        label: 'FABRICACIÓN DE MOTORES, GENERADORES Y TRANSFORMADORES ELÉCTRICOS, APARATOS DE DISTRIBUCIÓN Y CONTROL',
        value: '271000',
    },
    {
        label: 'FABRICACIÓN DE MUEBLES PRINCIPALMENTE DE MADERA',
        value: '310001',
    },
    {
        label: 'FABRICACIÓN DE OTRAS BOMBAS, COMPRESORES, GRIFOS Y VÁLVULAS',
        value: '281300',
    },
    {
        label: 'FABRICACIÓN DE OTRAS SUSTANCIAS QUÍMICAS BÁSICAS N.C.P.',
        value: '201109',
    },
    {
        label: 'FABRICACIÓN DE OTROS ARTÍCULOS DE PAPEL Y CARTÓN',
        value: '170900',
    },
    {
        label: 'FABRICACIÓN DE OTROS HILOS Y CABLES ELÉCTRICOS',
        value: '273200',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO',
        value: '221900',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS DE MADERA, DE ARTÍCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES',
        value: '162900',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS DE PORCELANA Y DE CERÁMICA',
        value: '239300',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.',
        value: '259900',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.P.',
        value: '239900',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.P.',
        value: '202909',
    },
    {
        label: 'FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.P.',
        value: '139900',
    },
    {
        label: 'FABRICACIÓN DE OTROS TIPOS DE EQUIPO DE TRANSPORTE N.C.P.',
        value: '309900',
    },
    {
        label: 'FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO',
        value: '279000',
    },
    {
        label: 'FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL',
        value: '282900',
    },
    {
        label: 'FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO GENERAL',
        value: '281900',
    },
    {
        label: 'FABRICACIÓN DE PAPEL Y CARTÓN ONDULADO Y DE ENVASES DE PAPEL Y CARTÓN',
        value: '170200',
    },
    {
        label: 'FABRICACIÓN DE PAPEL Y CARTÓN PARA SU POSTERIOR USO INDUSTRIAL N.C.P.',
        value: '170190',
    },
    {
        label: 'FABRICACIÓN DE PARTES Y PIEZAS DE CARPINTERÍA PARA EDIFICIOS Y CONSTRUCCIONES',
        value: '162200',
    },
    {
        label: 'FABRICACIÓN DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES',
        value: '293000',
    },
    {
        label: 'FABRICACIÓN DE PILAS, BATERÍAS Y ACUMULADORES',
        value: '272000',
    },
    {
        label: 'FABRICACIÓN DE PINTURAS, BARNICES Y PRODUCTOS DE REVESTIMIENTO, TINTAS DE IMPRENTA Y MASILLAS',
        value: '202200',
    },
    {
        label: 'FABRICACIÓN DE PLAGUICIDAS Y OTROS PRODUCTOS QUÍMICOS DE USO AGROPECUARIO',
        value: '202100',
    },
    {
        label: 'FABRICACIÓN DE PLÁSTICOS Y CAUCHO SINTÉTICO EN FORMAS PRIMARIAS',
        value: '201300',
    },
    {
        label: 'FABRICACIÓN DE PRENDAS DE VESTIR DE CUERO NATURAL O ARTIFICIAL',
        value: '141002',
    },
    {
        label: 'FABRICACIÓN DE PRENDAS DE VESTIR DE MATERIALES TEXTILES Y SIMILARES',
        value: '141001',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS DE HORNOS DE COQUE',
        value: '191000',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO',
        value: '192000',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS DE PLÁSTICO',
        value: '222000',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS DE VIDRIO N.C.P.',
        value: '231009',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS',
        value: '210000',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS METÁLICOS PARA USO ESTRUCTURAL',
        value: '251100',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS PRIMARIOS DE ALUMINIO',
        value: '242002',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS PRIMARIOS DE COBRE',
        value: '242001',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y DE OTROS METALES NO FERROSOS N.C.P.',
        value: '242009',
    },
    {
        label: 'FABRICACIÓN DE PRODUCTOS REFRACTARIOS',
        value: '239100',
    },
    {
        label: 'FABRICACIÓN DE RECIPIENTES DE MADERA',
        value: '162300',
    },
    {
        label: 'FABRICACIÓN DE RECIPIENTES DE METAL PARA GASES COMPRIMIDOS O LICUADOS',
        value: '251201',
    },
    {
        label: 'FABRICACIÓN DE RELOJES',
        value: '265200',
    },
    {
        label: 'FABRICACIÓN DE ROPA DE TRABAJO',
        value: '141004',
    },
    {
        label: 'FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS',
        value: '268000',
    },
    {
        label: 'FABRICACIÓN DE TANQUES, DEPÓSITOS Y RECIPIENTES DE METAL N.C.P.',
        value: '251209',
    },
    {
        label: 'FABRICACIÓN DE TAPICES Y ALFOMBRAS',
        value: '139300',
    },
    {
        label: 'FABRICACIÓN DE TEJIDOS DE PUNTO Y GANCHILLO',
        value: '139100',
    },
    {
        label: 'FABRICACIÓN DE VEHÍCULOS AUTOMOTORES',
        value: '291000',
    },
    {
        label: 'FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE',
        value: '304000',
    },
    {
        label: 'FABRICACIÓN DE VIDRIO HUECO',
        value: '231002',
    },
    {
        label: 'FABRICACIÓN DE VIDRIO PLANO',
        value: '231001',
    },
    {
        label: 'FINANCIERAS',
        value: '649201',
    },
    {
        label: 'FONDO NACIONAL DE SALUD (FONASA) ',
        value: '843010',
    },
    {
        label: 'FONDOS DE PENSIONES',
        value: '653000',
    },
    {
        label: 'FONDOS Y SOCIEDADES DE INVERSIÓN Y ENTIDADES FINANCIERAS SIMILARES',
        value: '643000',
    },
    {
        label: 'FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES; PULVIMETALURGIA',
        value: '259100',
    },
    {
        label: 'FOTOCOPIADO, PREPARACIÓN DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE APOYO DE OFICINA',
        value: '821900',
    },
    {
        label: 'FUNDACIONES Y CORPORACIONES; ASOCIACIONES QUE PROMUEVEN ACTIVIDADES CULTURALES O RECREATIVAS',
        value: '949903',
    },
    {
        label: 'FUNDICIÓN DE HIERRO Y ACERO',
        value: '243100',
    },
    {
        label: 'FUNDICIÓN DE METALES NO FERROSOS',
        value: '243200',
    },
    {
        label: 'GENERACIÓN DE ENERGÍA ELÉCTRICA EN CENTRALES HIDROELÉCTRICAS',
        value: '351011',
    },
    {
        label: 'GENERACIÓN DE ENERGÍA ELÉCTRICA EN CENTRALES TERMOELÉCTRICAS',
        value: '351012',
    },
    {
        label: 'GENERACIÓN DE ENERGÍA ELÉCTRICA EN OTRAS CENTRALES N.C.P.',
        value: '351019',
    },
    {
        label: 'GESTIÓN DE OTRAS INSTALACIONES DEPORTIVAS N.C.P.',
        value: '931109',
    },
    {
        label: 'GESTIÓN DE SALAS DE BILLAR; GESTIÓN DE SALAS DE BOLOS (BOWLING)',
        value: '931102',
    },
    {
        label: 'GESTIÓN DE SALAS DE POOL; GESTIÓN (EXPLOTACIÓN) DE JUEGOS ELECTRÓNICOS',
        value: '932901',
    },
    {
        label: 'HIPÓDROMOS',
        value: '931101',
    },
    {
        label: 'IMPRESIÓN DE LIBROS',
        value: '181101',
    },
    {
        label: 'INDUSTRIAS BÁSICAS DE HIERRO Y ACERO',
        value: '241000',
    },
    {
        label: 'INSTALACIÓN DE MAQUINARIA Y EQUIPOS INDUSTRIALES',
        value: '332000',
    },
    {
        label: 'INSTALACIONES DE GASFITERÍA, CALEFACCIÓN Y AIRE ACONDICIONADO',
        value: '432200',
    },
    {
        label: 'INSTALACIONES ELÉCTRICAS',
        value: '432100',
    },
    {
        label: 'INSTITUTO DE PREVISIÓN SOCIAL (IPS)',
        value: '843020',
    },
    {
        label: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA',
        value: '721000',
    },
    {
        label: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES',
        value: '722000',
    },
    {
        label: 'LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL',
        value: '960100',
    },
    {
        label: 'LEASING FINANCIERO',
        value: '649100',
    },
    {
        label: 'LIMPIEZA GENERAL DE EDIFICIOS',
        value: '812100',
    },
    {
        label: 'MANIPULACIÓN DE LA CARGA',
        value: '522400',
    },
    {
        label: 'MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS',
        value: '454003',
    },
    {
        label: 'MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES',
        value: '452002',
    },
    {
        label: 'MOLIENDA DE ARROZ; PRODUCCIÓN DE HARINA DE ARROZ',
        value: '106102',
    },
    {
        label: 'MOLIENDA DE TRIGO: PRODUCCIÓN DE HARINA, SÉMOLA Y GRÁNULOS',
        value: '106101',
    },
    {
        label: 'ORGANIZACIÓN DE CONVENCIONES Y EXPOSICIONES COMERCIALES',
        value: '823000',
    },
    {
        label: 'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES',
        value: '662900',
    },
    {
        label: 'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS N.C.P.',
        value: '661909',
    },
    {
        label: 'OTRAS ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO N.C.P.',
        value: '900009',
    },
    {
        label: 'OTRAS ACTIVIDADES DE ALOJAMIENTO N.C.P.',
        value: '559009',
    },
    {
        label: 'OTRAS ACTIVIDADES DE ALOJAMIENTO PARA TURISTAS N.C.P.',
        value: '551009',
    },
    {
        label: 'OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE N.C.P.',
        value: '522990',
    },
    {
        label: 'OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO',
        value: '889000',
    },
    {
        label: 'OTRAS ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES',
        value: '879000',
    },
    {
        label: 'OTRAS ACTIVIDADES DE CONCESIÓN DE CRÉDITO N.C.P.',
        value: '649209',
    },
    {
        label: 'OTRAS ACTIVIDADES DE DOTACIÓN DE RECURSOS HUMANOS',
        value: '783000',
    },
    {
        label: 'OTRAS ACTIVIDADES DE EDICIÓN',
        value: '581900',
    },
    {
        label: 'OTRAS ACTIVIDADES DE ESPARCIMIENTO Y RECREATIVAS N.C.P.',
        value: '932909',
    },
    {
        label: 'OTRAS ACTIVIDADES DE IMPRESIÓN N.C.P.',
        value: '181109',
    },
    {
        label: 'OTRAS ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS N.C.P.',
        value: '920090',
    },
    {
        label: 'OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES N.C.P.',
        value: '812909',
    },
    {
        label: 'OTRAS ACTIVIDADES DE SERVICIO DE BEBIDAS N.C.P.',
        value: '563009',
    },
    {
        label: 'OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P.',
        value: '829900',
    },
    {
        label: 'OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACIÓN N.C.P.',
        value: '639900',
    },
    {
        label: 'OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES N.C.P.',
        value: '649900',
    },
    {
        label: 'OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P.',
        value: '960909',
    },
    {
        label: 'OTRAS ACTIVIDADES DE TECNOLOGÍA DE LA INFORMACIÓN Y DE SERVICIOS INFORMÁTICOS',
        value: '620900',
    },
    {
        label: 'OTRAS ACTIVIDADES DE TELECOMUNICACIONES N.C.P.',
        value: '619090',
    },
    {
        label: 'OTRAS ACTIVIDADES DE TRANSPORTE DE PASAJEROS POR VÍA TERRESTRE N.C.P.',
        value: '492290',
    },
    {
        label: 'OTRAS ACTIVIDADES DE TRANSPORTE POR TUBERÍAS N.C.P.',
        value: '493090',
    },
    {
        label: 'OTRAS ACTIVIDADES DE TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR VÍA TERRESTRE N.C.P.',
        value: '492190',
    },
    {
        label: 'OTRAS ACTIVIDADES DE VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS N.C.P.',
        value: '471990',
    },
    {
        label: 'OTRAS ACTIVIDADES DE VENTA POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS N.C.P.',
        value: '479909',
    },
    {
        label: 'OTRAS ACTIVIDADES DEPORTIVAS N.C.P.',
        value: '931909',
    },
    {
        label: 'OTRAS ACTIVIDADES ESPECIALIZADAS DE CONSTRUCCIÓN',
        value: '439000',
    },
    {
        label: 'OTRAS ACTIVIDADES ESPECIALIZADAS DE DISEÑO N.C.P.',
        value: '741009',
    },
    {
        label: 'OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.P.',
        value: '749009',
    },
    {
        label: 'OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.',
        value: '329000',
    },
    {
        label: 'OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCIÓN',
        value: '432900',
    },
    {
        label: 'OTROS PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA N.C.P.',
        value: '843090',
    },
    {
        label: 'OTROS SERVICIOS DE ALMACENAMIENTO Y DEPÓSITO N.C.P.',
        value: '521009',
    },
    {
        label: 'OTROS SERVICIOS DE APOYO A LA SILVICULTURA N.C.P.',
        value: '24009',
    },
    {
        label: 'OTROS SERVICIOS DE ATENCIÓN DE LA SALUD HUMANA PRESTADOS POR EMPRESAS',
        value: '869091',
    },
    {
        label: 'OTROS SERVICIOS DE CORRETAJE DE VALORES Y COMMODITIES N.C.P.',
        value: '661209',
    },
    {
        label: 'OTROS SERVICIOS DE ENSAYOS Y ANÁLISIS TÉCNICOS (EXCEPTO ACTIVIDADES DE PLANTAS DE REVISIÓN TÉCNICA)',
        value: '712009',
    },
    {
        label: 'OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS (INCLUYE VENTA DE ENTRADAS PARA TEATRO, Y OTROS)',
        value: '799000',
    },
    {
        label: 'OTROS SERVICIOS DE TELECOMUNICACIONES ALÁMBRICAS N.C.P.',
        value: '611090',
    },
    {
        label: 'OTROS SERVICIOS DE TELECOMUNICACIONES INALÁMBRICAS N.C.P.',
        value: '612090',
    },
    {
        label: 'OTROS SERVICIOS DE TELECOMUNICACIONES POR SATÉLITE N.C.P.',
        value: '613090',
    },
    {
        label: 'OTROS TIPOS DE CORRETAJES O REMATES AL POR MAYOR N.C.P.',
        value: '461009',
    },
    {
        label: 'OTROS TIPOS DE ENSEÑANZA N.C.P.',
        value: '854909',
    },
    {
        label: 'OTROS TIPOS DE INTERMEDIACIÓN MONETARIA N.C.P.',
        value: '641990',
    },
    {
        label: 'PELUQUERÍA Y OTROS TRATAMIENTOS DE BELLEZA',
        value: '960200',
    },
    {
        label: 'PESCA DE AGUA DULCE',
        value: '31200',
    },
    {
        label: 'PESCA MARÍTIMA ARTESANAL',
        value: '31120',
    },
    {
        label: 'PESCA MARÍTIMA INDUSTRIAL, EXCEPTO DE BARCOS FACTORÍA',
        value: '31110',
    },
    {
        label: 'PORTALES WEB',
        value: '631200',
    },
    {
        label: 'PREPARACIÓN DEL TERRENO',
        value: '431200',
    },
    {
        label: 'PREPARACIÓN E HILATURA DE FIBRAS TEXTILES',
        value: '131100',
    },
    {
        label: 'PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS',
        value: '631100',
    },
    {
        label: 'PRODUCCIÓN DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS',
        value: '110402',
    },
    {
        label: 'PRODUCCIÓN DE HARINA DE PESCADO',
        value: '102010',
    },
    {
        label: 'PROGRAMACIÓN Y TRANSMISIONES DE TELEVISIÓN',
        value: '602000',
    },
    {
        label: 'PROMOCIÓN Y ORGANIZACIÓN DE COMPETENCIAS DEPORTIVAS',
        value: '931901',
    },
    {
        label: 'RADIOCOMUNICACIONES MÓVILES',
        value: '612020',
    },
    {
        label: 'REASEGUROS',
        value: '652000',
    },
    {
        label: 'RECEPTORES JUDICIALES',
        value: '691004',
    },
    {
        label: 'RECOGIDA DE DESECHOS NO PELIGROSOS',
        value: '381100',
    },
    {
        label: 'RECOGIDA DE DESECHOS PELIGROSOS',
        value: '381200',
    },
    {
        label: 'RECOLECCIÓN DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA',
        value: '23000',
    },
    {
        label: 'RECOLECCIÓN Y EXTRACCIÓN DE PRODUCTOS MARINOS',
        value: '31130',
    },
    {
        label: 'RECUPERACIÓN Y RECICLAMIENTO DE DESPERDICIOS Y DESECHOS METÁLICOS',
        value: '383001',
    },
    {
        label: 'RECUPERACIÓN Y RECICLAMIENTO DE OTROS DESPERDICIOS Y DESECHOS N.C.P.',
        value: '383009',
    },
    {
        label: 'RECUPERACIÓN Y RECICLAMIENTO DE PAPEL',
        value: '383002',
    },
    {
        label: 'RECUPERACIÓN Y RECICLAMIENTO DE VIDRIO',
        value: '383003',
    },
    {
        label: 'REGASIFICACIÓN DE GAS NATURAL LICUADO (GNL)',
        value: '352010',
    },
    {
        label: 'REGULACIÓN DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES',
        value: '841200',
    },
    {
        label: 'REGULACIÓN Y FACILITACIÓN DE LA ACTIVIDAD ECONÓMICA',
        value: '841300',
    },
    {
        label: 'RELACIONES EXTERIORES',
        value: '842100',
    },
    {
        label: 'REPARACIÓN DE AERONAVES Y NAVES ESPACIALES',
        value: '331502',
    },
    {
        label: 'REPARACIÓN DE APARATOS DE USO DOMÉSTICO, EQUIPO DOMÉSTICO Y DE JARDINERÍA',
        value: '952200',
    },
    {
        label: 'REPARACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO (INCLUYE APARATOS DE TELEVISIÓN Y RADIO)',
        value: '952100',
    },
    {
        label: 'REPARACIÓN DE BUQUES, EMBARCACIONES MENORES Y ESTRUCTURAS FLOTANTES',
        value: '331501',
    },
    {
        label: 'REPARACIÓN DE CALZADO Y DE ARTÍCULOS DE CUERO',
        value: '952300',
    },
    {
        label: 'REPARACIÓN DE COMPUTADORES Y EQUIPO PERIFÉRICO',
        value: '951100',
    },
    {
        label: 'REPARACIÓN DE EQUIPO DE COMUNICACIONES (INCLUYE LA REPARACIÓN TELÉFONOS CELULARES)',
        value: '951200',
    },
    {
        label: 'REPARACIÓN DE EQUIPO DE MEDICIÓN, PRUEBA, NAVEGACIÓN Y CONTROL',
        value: '331301',
    },
    {
        label: 'REPARACIÓN DE EQUIPO ELÉCTRICO (EXCEPTO REPARACIÓN DE EQUIPO Y ENSERES DOMÉSTICOS)',
        value: '331400',
    },
    {
        label: 'REPARACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL',
        value: '331201',
    },
    {
        label: 'REPARACIÓN DE MAQUINARIA METALÚRGICA, PARA LA MINERÍA, EXTRACCIÓN DE PETRÓLEO Y PARA LA CONSTRUCCIÓN',
        value: '331202',
    },
    {
        label: 'REPARACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO',
        value: '331203',
    },
    {
        label: 'REPARACIÓN DE MAQUINARIA PARA PRODUCIR TEXTILES, PRENDAS DE VESTIR, ARTÍCULOS DE CUERO Y CALZADO  ',
        value: '331204',
    },
    {
        label: 'REPARACIÓN DE MUEBLES Y ACCESORIOS DOMÉSTICOS',
        value: '952400',
    },
    {
        label: 'REPARACIÓN DE OTRO TIPO DE MAQUINARIA Y EQUIPOS INDUSTRIALES N.C.P.',
        value: '331209',
    },
    {
        label: 'REPARACIÓN DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS',
        value: '952900',
    },
    {
        label: 'REPARACIÓN DE OTROS EQUIPOS DE TRANSPORTE N.C.P., EXCEPTO VEHÍCULOS AUTOMOTORES',
        value: '331509',
    },
    {
        label: 'REPARACIÓN DE OTROS EQUIPOS ELECTRÓNICOS Y ÓPTICOS N.C.P.',
        value: '331309',
    },
    {
        label: 'REPARACIÓN DE OTROS TIPOS DE EQUIPO',
        value: '331900',
    },
    {
        label: 'REPARACIÓN DE PRODUCTOS ELABORADOS DE METAL',
        value: '331100',
    },
    {
        label: 'REPRODUCCIÓN DE GRABACIONES',
        value: '182000',
    },
    {
        label: 'REPRODUCCIÓN Y CRÍA DE MOLUSCOS, CRUSTÁCEOS Y GUSANOS MARINOS',
        value: '32130',
    },
    {
        label: 'SEGUROS DE VIDA',
        value: '651100',
    },
    {
        label: 'SEGUROS GENERALES, EXCEPTO ACTIVIDADES DE ISAPRES',
        value: '651210',
    },
    {
        label: 'SERVICIO DE LAVADO DE VEHÍCULOS AUTOMOTORES',
        value: '452001',
    },
    {
        label: 'SERVICIO DE TRANSPORTE DE VALORES EN VEHÍCULOS BLINDADOS',
        value: '801002',
    },
    {
        label: 'SERVICIO NOTARIAL',
        value: '691002',
    },
    {
        label: 'SERVICIOS DE ADIESTRAMIENTO, GUARDERÍA, PELUQUERÍA, PASEO DE MASCOTAS (EXCEPTO ACT. VETERINARIAS)',
        value: '960901',
    },
    {
        label: 'SERVICIOS DE ARBITRAJE; SÍNDICOS DE QUIEBRA Y PERITOS JUDICIALES; OTRAS ACTIVIDADES JURÍDICAS N.C.P.',
        value: '691009',
    },
    {
        label: 'SERVICIOS DE ARQUITECTURA (DISEÑO DE EDIFICIOS, DIBUJO DE PLANOS DE CONSTRUCCIÓN, ENTRE OTROS)',
        value: '711001',
    },
    {
        label: 'SERVICIOS DE ASESORAMIENTO Y REPRESENTACIÓN JURÍDICA',
        value: '691001',
    },
    {
        label: 'SERVICIOS DE CEMENTERIOS',
        value: '960320',
    },
    {
        label: 'SERVICIOS DE CORTA DE MADERA A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA',
        value: '24002',
    },
    {
        label: 'SERVICIOS DE EXTINCIÓN Y PREVENCIÓN DE INCENDIOS FORESTALES',
        value: '24003',
    },
    {
        label: 'SERVICIOS DE FORESTACIÓN A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA',
        value: '24001',
    },
    {
        label: 'SERVICIOS DE MÉDICOS PRESTADOS DE FORMA INDEPENDIENTE',
        value: '862031',
    },
    {
        label: 'SERVICIOS DE ODONTÓLOGOS PRESTADOS DE FORMA INDEPENDIENTE',
        value: '862032',
    },
    {
        label: 'SERVICIOS DE PRODUCCIÓN DE OBRAS DE TEATRO, CONCIERTOS, ESPECTÁCULOS DE DANZA, OTRAS PROD. ESCÉNICAS',
        value: '900001',
    },
    {
        label: 'SERVICIOS DE PUBLICIDAD PRESTADOS POR EMPRESAS',
        value: '731001',
    },
    {
        label: 'SERVICIOS DE PUBLICIDAD PRESTADOS POR PROFESIONALES',
        value: '731002',
    },
    {
        label: 'SERVICIOS DE REVELADO, IMPRESIÓN Y AMPLIACIÓN DE FOTOGRAFÍAS',
        value: '742001',
    },
    {
        label: 'SERVICIOS DE SEGURIDAD PRIVADA PRESTADOS POR EMPRESAS',
        value: '801001',
    },
    {
        label: 'SERVICIOS DE SEGURIDAD PRIVADA PRESTADOS POR INDEPENDIENTES',
        value: '801003',
    },
    {
        label: 'SERVICIOS DE TRADUCCIÓN E INTERPRETACIÓN PRESTADOS POR EMPRESAS',
        value: '749002',
    },
    {
        label: 'SERVICIOS DE TRANSPORTE A TURISTAS',
        value: '492240',
    },
    {
        label: 'SERVICIOS DE TRANSPORTE DE ESCOLARES',
        value: '492210',
    },
    {
        label: 'SERVICIOS DE TRANSPORTE DE PASAJEROS EN TAXIS LIBRES Y RADIOTAXIS',
        value: '492230',
    },
    {
        label: 'SERVICIOS DE TRANSPORTE DE TRABAJADORES',
        value: '492220',
    },
    {
        label: 'SERVICIOS FUNERARIOS',
        value: '960310',
    },
    {
        label: 'SERVICIOS IMPUTADOS DE ALQUILER DE VIVIENDAS',
        value: '681020',
    },
    {
        label: 'SERVICIOS PERSONALES DE EDUCACIÓN',
        value: '854902',
    },
    {
        label: 'SERVICIOS PERSONALES DE FOTOGRAFÍA ',
        value: '742003',
    },
    {
        label: 'SERVICIOS PERSONALES DE TRADUCCIÓN E INTERPRETACIÓN',
        value: '749003',
    },
    {
        label: 'SERVICIOS PRESTADOS DE FORMA INDEPENDIENTE POR OTROS PROFESIONALES DE LA SALUD',
        value: '869092',
    },
    {
        label: 'SERVICIOS PRESTADOS POR CONCESIONARIOS DE CARRETERAS',
        value: '522130',
    },
    {
        label: 'SERVICIOS PRESTADOS POR PERIODISTAS INDEPENDIENTES',
        value: '900004',
    },
    {
        label: 'SERVICIOS PROFESIONALES DE INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA ',
        value: '711003',
    },
    {
        label: 'SERVICIOS RELACIONADOS CON LA ACUICULTURA MARINA',
        value: '32140',
    },
    {
        label: 'SERVICIOS RELACIONADOS CON LA PESCA MARÍTIMA',
        value: '31140',
    },
    {
        label: 'SERVICIOS Y ACTIVIDADES DE FOTOGRAFÍA ',
        value: '742002',
    },
    {
        label: 'SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES (EXCEPTO EXPLOTACIÓN DE VIVEROS FORESTALES)',
        value: '21002',
    },
    {
        label: 'SUMINISTRO DE COMIDAS POR ENCARGO (SERVICIOS DE BANQUETERÍA)',
        value: '562100',
    },
    {
        label: 'SUMINISTRO DE VAPOR Y DE AIRE ACONDICIONADO',
        value: '353001',
    },
    {
        label: 'SUMINISTRO INDUSTRIAL DE COMIDAS POR ENCARGO; CONCESIÓN DE SERVICIOS DE ALIMENTACIÓN ',
        value: '562900',
    },
    {
        label: 'TEJEDURA DE PRODUCTOS TEXTILES',
        value: '131200',
    },
    {
        label: 'TELEFONÍA FIJA',
        value: '611010',
    },
    {
        label: 'TELEFONÍA LARGA DISTANCIA',
        value: '611020',
    },
    {
        label: 'TELEFONÍA MÓVIL CELULAR',
        value: '612010',
    },
    {
        label: 'TELEFONÍA MÓVIL SATELITAL',
        value: '613010',
    },
    {
        label: 'TELEVISIÓN DE PAGO INALÁMBRICA',
        value: '612030',
    },
    {
        label: 'TELEVISIÓN DE PAGO POR CABLE',
        value: '611030',
    },
    {
        label: 'TELEVISIÓN DE PAGO SATELITAL',
        value: '613020',
    },
    {
        label: 'TERMINACIÓN Y ACABADO DE EDIFICIOS',
        value: '433000',
    },
    {
        label: 'TRANSMISIÓN DE ENERGÍA ELÉCTRICA',
        value: '351020',
    },
    {
        label: 'TRANSMISIONES DE RADIO',
        value: '601000',
    },
    {
        label: 'TRANSPORTE DE CARGA MARÍTIMO Y DE CABOTAJE',
        value: '501200',
    },
    {
        label: 'TRANSPORTE DE CARGA POR CARRETERA',
        value: '492300',
    },
    {
        label: 'TRANSPORTE DE CARGA POR FERROCARRIL',
        value: '491200',
    },
    {
        label: 'TRANSPORTE DE CARGA POR VÍA AÉREA',
        value: '512000',
    },
    {
        label: 'TRANSPORTE DE CARGA POR VÍAS DE NAVEGACIÓN INTERIORES',
        value: '502200',
    },
    {
        label: 'TRANSPORTE DE PASAJEROS EN BUSES INTERURBANOS ',
        value: '492250',
    },
    {
        label: 'TRANSPORTE DE PASAJEROS MARÍTIMO Y DE CABOTAJE',
        value: '501100',
    },
    {
        label: 'TRANSPORTE DE PASAJEROS POR VÍA AÉREA',
        value: '511000',
    },
    {
        label: 'TRANSPORTE DE PASAJEROS POR VÍAS DE NAVEGACIÓN INTERIORES',
        value: '502100',
    },
    {
        label: 'TRANSPORTE DE PASAJEROS VÍA TAXI COLECTIVO',
        value: '492130',
    },
    {
        label: 'TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL',
        value: '491100',
    },
    {
        label: 'TRANSPORTE POR GASODUCTOS',
        value: '493020',
    },
    {
        label: 'TRANSPORTE POR OLEODUCTOS',
        value: '493010',
    },
    {
        label: 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS VÍA LOCOMOCIÓN COLECTIVA',
        value: '492120',
    },
    {
        label: 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS VÍA METRO Y METROTREN',
        value: '492110',
    },
    {
        label: 'TRATAMIENTO DE SEMILLAS PARA PROPAGACIÓN',
        value: '16400',
    },
    {
        label: 'TRATAMIENTO Y ELIMINACIÓN DE DESECHOS NO PELIGROSOS',
        value: '382100',
    },
    {
        label: 'TRATAMIENTO Y ELIMINACIÓN DE DESECHOS PELIGROSOS',
        value: '382200',
    },
    {
        label: 'TRATAMIENTO Y REVESTIMIENTO DE METALES; MAQUINADO',
        value: '259200',
    },
    {
        label: 'VENTA AL POR MAYOR DE ANIMALES VIVOS',
        value: '462020',
    },
    {
        label: 'VENTA AL POR MAYOR DE ARTÍCULOS DE PAPELERÍA Y ESCRITORIO',
        value: '464904',
    },
    {
        label: 'VENTA AL POR MAYOR DE ARTÍCULOS DE PERFUMERÍA, DE TOCADOR Y COSMÉTICOS',
        value: '464903',
    },
    {
        label: 'VENTA AL POR MAYOR DE ARTÍCULOS ELÉCTRICOS Y ELECTRÓNICOS PARA EL HOGAR',
        value: '464902',
    },
    {
        label: 'VENTA AL POR MAYOR DE BEBIDAS ALCOHÓLICAS Y NO ALCOHÓLICAS',
        value: '463020',
    },
    {
        label: 'VENTA AL POR MAYOR DE CARNE Y PRODUCTOS CÁRNICOS',
        value: '463012',
    },
    {
        label: 'VENTA AL POR MAYOR DE COMBUSTIBLES SÓLIDOS, LÍQUIDOS Y GASEOSOS Y PRODUCTOS CONEXOS',
        value: '466100',
    },
    {
        label: 'VENTA AL POR MAYOR DE COMPUTADORES, EQUIPO PERIFÉRICO Y PROGRAMAS INFORMÁTICOS',
        value: '465100',
    },
    {
        label: 'VENTA AL POR MAYOR DE DESECHOS METÁLICOS (CHATARRA)',
        value: '466902',
    },
    {
        label: 'VENTA AL POR MAYOR DE DESPERDICIOS, DESECHOS Y OTROS PRODUCTOS N.C.P.',
        value: '466909',
    },
    {
        label: 'VENTA AL POR MAYOR DE DIARIOS Y REVISTAS',
        value: '464906',
    },
    {
        label: 'VENTA AL POR MAYOR DE EQUIPO DE TRANSPORTE(EXCEPTO VEHÍCULOS AUTOMOTORES, MOTOCICLETAS Y BICICLETAS)',
        value: '465905',
    },
    {
        label: 'VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTRÓNICOS Y DE TELECOMUNICACIONES',
        value: '465200',
    },
    {
        label: 'VENTA AL POR MAYOR DE FRUTAS Y VERDURAS',
        value: '463011',
    },
    {
        label: 'VENTA AL POR MAYOR DE HUEVOS, LÁCTEOS, ABARROTES Y DE OTROS ALIMENTOS N.C.P.',
        value: '463019',
    },
    {
        label: 'VENTA AL POR MAYOR DE INSTRUMENTOS CIENTÍFICOS Y QUIRÚRGICOS',
        value: '464908',
    },
    {
        label: 'VENTA AL POR MAYOR DE LIBROS',
        value: '464905',
    },
    {
        label: 'VENTA AL POR MAYOR DE MADERA EN BRUTO Y PRODUCTOS PRIMARIOS DE LA ELABORACIÓN DE MADERA',
        value: '466301',
    },
    {
        label: 'VENTA AL POR MAYOR DE MAQUINARIA METALÚRGICA, PARA LA MINERÍA, EXTRACCIÓN DE PETRÓLEO Y CONSTRUCCIÓN',
        value: '465901',
    },
    {
        label: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO',
        value: '465902',
    },
    {
        label: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA INDUSTRIA TEXTIL, DEL CUERO Y DEL CALZADO',
        value: '465903',
    },
    {
        label: 'VENTA AL POR MAYOR DE MAQUINARIA Y EQUIPO DE OFICINA; VENTA AL POR MAYOR DE MUEBLES DE OFICINA',
        value: '465904',
    },
    {
        label: 'VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS',
        value: '465300',
    },
    {
        label: 'VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCIÓN, ARTÍCULOS DE FERRETERÍA, GASFITERÍA Y CALEFACCIÓN',
        value: '466302',
    },
    {
        label: 'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGRÍCOLAS',
        value: '462010',
    },
    {
        label: 'VENTA AL POR MAYOR DE METALES Y MINERALES METALÍFEROS',
        value: '466200',
    },
    {
        label: 'VENTA AL POR MAYOR DE MUEBLES, EXCEPTO MUEBLES DE OFICINA',
        value: '464901',
    },
    {
        label: 'VENTA AL POR MAYOR DE OTRAS MATERIAS PRIMAS AGROPECUARIAS N.C.P.',
        value: '462090',
    },
    {
        label: 'VENTA AL POR MAYOR DE OTROS ENSERES DOMÉSTICOS N.C.P.',
        value: '464909',
    },
    {
        label: 'VENTA AL POR MAYOR DE OTROS TIPOS DE MAQUINARIA Y EQUIPO N.C.P.',
        value: '465909',
    },
    {
        label: 'VENTA AL POR MAYOR DE PRODUCTOS DE CONFITERÍA',
        value: '463014',
    },
    {
        label: 'VENTA AL POR MAYOR DE PRODUCTOS DEL MAR (PESCADOS, MARISCOS Y ALGAS)',
        value: '463013',
    },
    {
        label: 'VENTA AL POR MAYOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES ',
        value: '464907',
    },
    {
        label: 'VENTA AL POR MAYOR DE PRODUCTOS QUÍMICOS',
        value: '466901',
    },
    {
        label: 'VENTA AL POR MAYOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO',
        value: '464100',
    },
    {
        label: 'VENTA AL POR MAYOR DE TABACO',
        value: '463030',
    },
    {
        label: 'VENTA AL POR MAYOR DE VEHÍCULOS AUTOMOTORES',
        value: '451001',
    },
    {
        label: 'VENTA AL POR MAYOR NO ESPECIALIZADA',
        value: '469000',
    },
    {
        label: 'VENTA AL POR MENOR DE ALIMENTO Y ACCESORIOS PARA MASCOTAS EN COMERCIOS ESPECIALIZADOS',
        value: '477391',
    },
    {
        label: 'VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS (ALMACENES PEQUEÑOS Y MINIMARKET)',
        value: '472101',
    },
    {
        label: 'VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS (INCLUYE FERIAS)',
        value: '478100',
    },
    {
        label: 'VENTA AL POR MENOR DE ANTIGÜEDADES EN COMERCIOS',
        value: '477401',
    },
    {
        label: 'VENTA AL POR MENOR DE APARATOS ELÉCTRICOS, TEXTILES PARA EL HOGAR Y OTROS ENSERES DOMÉSTICOS N.C.P.',
        value: '475909',
    },
    {
        label: 'VENTA AL POR MENOR DE ARMAS Y MUNICIONES EN COMERCIOS ESPECIALIZADOS',
        value: '477392',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS DE CAZA Y PESCA EN COMERCIOS ESPECIALIZADOS',
        value: '476301',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS DE FERRETERÍA Y MATERIALES DE CONSTRUCCIÓN ',
        value: '475201',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS DE JOYERÍA, BISUTERÍA Y RELOJERÍA EN COMERCIOS ESPECIALIZADOS',
        value: '477394',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS DE PAPELERÍA Y ESCRITORIO EN COMERCIOS ESPECIALIZADOS',
        value: '476103',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS DE PERFUMERÍA, DE TOCADOR Y COSMÉTICOS EN COMERCIOS ESPECIALIZADOS',
        value: '477203',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS ÓPTICOS EN COMERCIOS ESPECIALIZADOS',
        value: '477393',
    },
    {
        label: 'VENTA AL POR MENOR DE ARTÍCULOS ORTOPÉDICOS EN COMERCIOS ESPECIALIZADOS',
        value: '477202',
    },
    {
        label: 'VENTA AL POR MENOR DE BEBIDAS ALCOHÓLICAS Y NO ALCOHÓLICAS EN COMERCIOS ESPECIALIZADOS (BOTILLERÍAS)',
        value: '472200',
    },
    {
        label: 'VENTA AL POR MENOR DE BICICLETAS Y SUS REPUESTOS EN COMERCIOS ESPECIALIZADOS',
        value: '476302',
    },
    {
        label: 'VENTA AL POR MENOR DE CALZADO EN COMERCIOS ESPECIALIZADOS',
        value: '477101',
    },
    {
        label: 'VENTA AL POR MENOR DE CARBÓN, LEÑA Y OTROS COMBUSTIBLES DE USO DOMÉSTICO EN COMERCIOS ESPECIALIZADOS',
        value: '477395',
    },
    {
        label: 'VENTA AL POR MENOR DE CARTERAS, MALETAS Y OTROS ACCESORIOS DE VIAJE EN COMERCIOS ESPECIALIZADOS',
        value: '477103',
    },
    {
        label: 'VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHÍCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS',
        value: '473000',
    },
    {
        label: 'VENTA AL POR MENOR DE COMPUTADORES, EQUIPO PERIFÉRICO, PROGRAMAS INFORMÁTICOS Y EQUIPO DE TELECOM.',
        value: '474100',
    },
    {
        label: 'VENTA AL POR MENOR DE DIARIOS Y REVISTAS EN COMERCIOS ESPECIALIZADOS',
        value: '476102',
    },
    {
        label: 'VENTA AL POR MENOR DE EQUIPO DE SONIDO Y DE VIDEO EN COMERCIOS ESPECIALIZADOS',
        value: '474200',
    },
    {
        label: 'VENTA AL POR MENOR DE FLORES, PLANTAS, ARBOLES, SEMILLAS Y ABONOS EN COMERCIOS ESPECIALIZADOS',
        value: '477397',
    },
    {
        label: 'VENTA AL POR MENOR DE GAS LICUADO EN BOMBONAS (CILINDROS) EN COMERCIOS ESPECIALIZADOS',
        value: '477310',
    },
    {
        label: 'VENTA AL POR MENOR DE GRABACIONES DE MÚSICA Y DE VIDEO EN COMERCIOS ESPECIALIZADOS',
        value: '476200',
    },
    {
        label: 'VENTA AL POR MENOR DE INSTRUMENTOS MUSICALES EN COMERCIOS ESPECIALIZADOS',
        value: '475902',
    },
    {
        label: 'VENTA AL POR MENOR DE JUEGOS Y JUGUETES EN COMERCIOS ESPECIALIZADOS',
        value: '476400',
    },
    {
        label: 'VENTA AL POR MENOR DE LIBROS EN COMERCIOS ESPECIALIZADOS',
        value: '476101',
    },
    {
        label: 'VENTA AL POR MENOR DE MASCOTAS EN COMERCIOS ESPECIALIZADOS',
        value: '477398',
    },
    {
        label: 'VENTA AL POR MENOR DE MUEBLES Y COLCHONES EN COMERCIOS ESPECIALIZADOS',
        value: '475901',
    },
    {
        label: 'VENTA AL POR MENOR DE OTROS ARTÍCULOS DE SEGUNDA MANO EN COMERCIOS N.C.P.',
        value: '477409',
    },
    {
        label: 'VENTA AL POR MENOR DE OTROS ARTÍCULOS Y EQUIPOS DE DEPORTE N.C.P.',
        value: '476309',
    },
    {
        label: 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN COMERCIOS ESPECIALIZADOS N.C.P.',
        value: '477399',
    },
    {
        label: 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS (INCLUYE FERIAS)',
        value: '478900',
    },
    {
        label: 'VENTA AL POR MENOR DE PINTURAS, BARNICES Y LACAS EN COMERCIOS ESPECIALIZADOS',
        value: '475202',
    },
    {
        label: 'VENTA AL POR MENOR DE PRENDAS Y ACCESORIOS DE VESTIR EN COMERCIOS ESPECIALIZADOS',
        value: '477102',
    },
    {
        label: 'VENTA AL POR MENOR DE PRODUCTOS DE VIDRIO EN COMERCIOS ESPECIALIZADOS',
        value: '475203',
    },
    {
        label: 'VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES EN COMERCIOS ESPECIALIZADOS',
        value: '477201',
    },
    {
        label: 'VENTA AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y MERCADOS',
        value: '478200',
    },
    {
        label: 'VENTA AL POR MENOR DE RECUERDOS, ARTESANÍAS Y ARTÍCULOS RELIGIOSOS EN COMERCIOS ESPECIALIZADOS',
        value: '477396',
    },
    {
        label: 'VENTA AL POR MENOR DE ROPA USADA EN COMERCIOS',
        value: '477402',
    },
    {
        label: 'VENTA AL POR MENOR DE TABACO Y PRODUCTOS DE TABACO EN COMERCIOS ESPECIALIZADOS',
        value: '472300',
    },
    {
        label: 'VENTA AL POR MENOR DE TAPICES, ALFOMBRAS Y CUBRIMIENTOS PARA PAREDES Y PISOS',
        value: '475300',
    },
    {
        label: 'VENTA AL POR MENOR DE TELAS, LANAS, HILOS Y SIMILARES EN COMERCIOS ESPECIALIZADOS',
        value: '475100',
    },
    {
        label: 'VENTA AL POR MENOR DE VEHÍCULOS AUTOMOTORES NUEVOS O USADOS (INCLUYE COMPRAVENTA)',
        value: '451002',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS DE ALIMENTOS, BEBIDAS O TABACO (SUPERMERCADOS E HIPERMERCADOS)',
        value: '471100',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS DE VESTUARIO Y PRODUCTOS PARA EL HOGAR (GRANDES TIENDAS)',
        value: '471910',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE CARNE Y PRODUCTOS CÁRNICOS ',
        value: '472102',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE FRUTAS Y VERDURAS (VERDULERÍAS)',
        value: '472103',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE HUEVOS, CONFITES Y PRODUCTOS ALIMENTICIOS N.C.P.',
        value: '472109',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE PESCADO, MARISCOS Y PRODUCTOS CONEXOS',
        value: '472104',
    },
    {
        label: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE PRODUCTOS DE PANADERÍA Y PASTELERÍA',
        value: '472105',
    },
    {
        label: 'VENTA AL POR MENOR MEDIANTE MAQUINAS EXPENDEDORAS',
        value: '479902',
    },
    {
        label: 'VENTA AL POR MENOR POR COMISIONISTAS (NO DEPENDIENTES DE COMERCIOS)',
        value: '479903',
    },
    {
        label: 'VENTA AL POR MENOR POR CORREO, POR INTERNET Y VÍA TELEFÓNICA',
        value: '479100',
    },
    {
        label: 'VENTA AL POR MENOR REALIZADA POR INDEPENDIENTES EN LA LOCOMOCIÓN COLECTIVA (LEY 20.388)',
        value: '479901',
    },
    {
        label: 'VENTA DE MOTOCICLETAS',
        value: '454001',
    },
    {
        label: 'VENTA DE PARTES, PIEZAS Y ACCESORIOS DE MOTOCICLETAS',
        value: '454002',
    },
    {
        label: 'VENTA DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES',
        value: '453000',
    },
];
