import { DD, RT } from 'consts';
import { RootState } from 'types/store';
import { useAppSelector } from './redux';

type DeliveryHook = {
    // Return the selected delivery method
    selected: () => string | null;
    // Check if the schedule is set according to the selected delivery method.
    isReady: () => boolean;
    // Checks if the delivery method has schedules
    hasSchedules: () => boolean;
};

export function useDeliveryMethod(): DeliveryHook {
    const pickup = useAppSelector((state: RootState) => state.pickup);
    const shipping = useAppSelector((state: RootState) => state.shipping);
    const dm = useAppSelector((state: RootState) => state.deliveryMethod);

    /**
     * Return the selected delivery method
     */
    const selected = (): string | null => {
        if (dm.status == 'ok' && dm.selected) {
            return dm.selected;
        }
        return null;
    };

    /**
     * Checks if the delivery method has schedules
     */
    const hasSchedules = (): boolean => {
        const checking = (selected: string, status: string, data: Delivery | null): boolean => {
            if (selected === dm.selected && status === 'ok' && data !== null)
                return data.groups.allIds.length > 0;

            return false;
        };

        if (checking(RT, pickup.status, pickup.data)) {
            return true;
        }

        if (checking(DD, shipping.status, shipping.data)) {
            return true;
        }

        return false;
    };

    /**
     * Check if the schedule is set according to the selected delivery method.
     */
    const isReady = (): boolean => {
        const checking = (selected: string, status: string, data: Delivery | null): boolean => {
            if (selected === dm.selected && status === 'ok' && data !== null)
                return Object.keys(data.selectedSchedules).length > 0;

            return false;
        };

        if (checking(RT, pickup.status, pickup.data)) {
            return true;
        }

        if (checking(DD, shipping.status, shipping.data)) {
            return true;
        }

        return false;
    };

    return {
        isReady,
        selected,
        hasSchedules,
    };
}
