import React from 'react';
import styles from './Spinner.module.scss';

export type SpinnerProps = {
    /**
     * Color (default: primary)
     */
    theme?: 'primary' | 'secondary' | 'none';
    /**
     * Width in pixels (default: 32)
     */
    size?: number;
    /**
     * Thickness of spinner in pixels (default: 3)
     */
    strokeWidth?: number;
    /**
     * Adds space between this element and its neighbors
     */
    margin?: string;
};

export const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
    const { size = 36, theme = 'primary', strokeWidth = 3, margin } = props;
    const outer = size;
    const outerPx = `${outer}px`;
    const half = Math.round(outer / 2);
    const radius = half - strokeWidth;

    let className = styles['spinner'];
    if (theme !== 'none') {
        className += ' ' + styles[theme];
    }

    return (
        <div className={styles['wrapper']} style={{ margin }} data-testid="spinner">
            <svg width={outerPx} height={outerPx} viewBox={`0 0 ${outer} ${outer}`}>
                <circle
                    className={className}
                    style={{ strokeWidth }}
                    cx={half}
                    cy={half}
                    r={radius}
                />
            </svg>
        </div>
    );
};
