import { createAction, createSlice } from '@reduxjs/toolkit';
import { addItem, deleteItem } from 'lib/utils/array';

import { setCard as setCardCyb, setCardByToken as setTokenCyb } from 'store/card/cybersource';
import { setCard as setCardNiubiz } from 'store/card/niubiz';
import {
    setCard as setCardRipley,
    setCardByToken as setTokenRipley,
} from 'store/card/ripleyCard.slice';

import { setPaymentMethod as setCybersource } from './cybersource.slice';
import { setPaymentMethod as setInStore } from './inStore.slice';
import { setPaymentMethod as setNiubiz, setPaymentByToken as setTokenNiubiz } from './niubiz.slice';
import { setPaymentMethod as setRipleyCard, setPaymentWithoutCvv } from './ripleyCard.slice';
import { setPaymentMethod as setNiubiz3Ds } from './niubiz3ds.slice';
import {
    setPaymentMethod as setRipleyChek,
    initializePayment as initializeChek,
} from './ripleyChek.slice';
import { setPaymentMethod as setWebpayCredito } from './webpayCredito.slice';
import { setPaymentMethod as setAlignetMethod } from './alignet.slice';
import { setPaymentMethod as setWebpayDebito } from './webpayDebito.slice';
import {
    setPaymentMethod as setRipleyGiftCard,
    setPaymentWithCopago as setRipleyGiftCardCopago,
    setPaymentMethodDummy as setRipleyGiftCardDummy,
} from './ripleyGiftCard.slice';

import {
    setPaymentDummy,
    resetPayments,
    restartPaymentMethodsPage,
    reloadPaymentMethodsPage,
} from './actions';
export * from './actions';

export interface PaymentMethodState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    error: SerializedError | null;
    selected: PaymentMethodName[];
    loading: PaymentMethodName[];
    docType: DocType;
    clean: boolean;
}

export const initialState: PaymentMethodState = {
    status: 'idle',
    error: null,
    selected: [],
    loading: [],
    docType: 'boleta',
    clean: false,
};

export const setDocType = createAction<DocType>('paymentMethod/setDocType');

export const slice = createSlice({
    name: 'paymentMethod',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setDocType, (state: PaymentMethodState, action) => {
            state.docType = action.payload;
        });

        builder.addCase(resetPayments.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
        });
        builder.addCase(resetPayments.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = [];
            state.error = null;
            state.docType = 'boleta';
        });
        builder.addCase(resetPayments.rejected, (state: PaymentMethodState, { error }) => {
            state.status = 'error';
            state.error = error;
            state.selected = [];
            state.docType = 'boleta';
        });

        builder.addCase(setPaymentDummy.pending, (state: PaymentMethodState, { meta }) => {
            state.status = 'pending';
            state.loading = [meta.arg.paymentMethod];
        });
        builder.addCase(setPaymentDummy.rejected, (state: PaymentMethodState, { error }) => {
            state.status = 'error';
            state.error = error;
            state.loading = [];
        });
        builder.addCase(setPaymentDummy.fulfilled, (state: PaymentMethodState, { meta }) => {
            state.status = 'ok';
            if (meta.isOverwrite) {
                state.selected = [meta.arg.paymentMethod];
            } else {
                state.selected = addItem(state.selected, meta.arg.paymentMethod);
            }
            state.loading = [];
        });

        builder.addCase(setWebpayDebito.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = addItem(state.loading, 'WebpayDebito');
        });
        builder.addCase(setWebpayDebito.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.loading = deleteItem(state.loading, 'WebpayDebito');
        });
        builder.addCase(setWebpayDebito.fulfilled, (state: PaymentMethodState, { payload }) => {
            if (payload.isOverwrite) {
                state.selected = ['WebpayDebito'];
            } else {
                state.selected = state.selected.filter((x) => x === 'RipleyGiftCard');
                state.selected = addItem(state.selected, 'WebpayDebito');
            }
            state.status = 'ok';
            state.loading = deleteItem(state.loading, 'WebpayDebito');
        });

        builder.addCase(setAlignetMethod.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['Alignet'];
            state.selected = ['Alignet'];
        });
        builder.addCase(setAlignetMethod.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(setAlignetMethod.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = ['Alignet'];
            state.loading = [];
        });

        builder.addCase(setWebpayCredito.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = addItem(state.loading, 'WebpayCredito');
        });
        builder.addCase(setWebpayCredito.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.loading = deleteItem(state.loading, 'WebpayCredito');
        });
        builder.addCase(setWebpayCredito.fulfilled, (state: PaymentMethodState, { payload }) => {
            if (payload.isOverwrite) {
                state.selected = ['WebpayCredito'];
            } else {
                state.selected = state.selected.filter((x) => x === 'RipleyGiftCard');
                state.selected = addItem(state.selected, 'WebpayCredito');
            }
            state.status = 'ok';
            state.loading = deleteItem(state.loading, 'WebpayCredito');
        });

        builder.addCase(setCybersource.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['Cybersource'];
        });
        builder.addCase(setCybersource.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = ['Cybersource'];
            state.loading = [];
        });

        builder.addCase(setNiubiz.pending, (state: PaymentMethodState, { meta }) => {
            state.status = 'pending';
            state.loading = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
            // It is necessary to set Niubiz as selected in order for its form
            // to exist before the call to `createForm`.
            state.selected = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
        });
        builder.addCase(setNiubiz.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.loading = [];
            state.selected = [];
        });
        builder.addCase(setNiubiz.fulfilled, (state: PaymentMethodState, { meta }) => {
            state.status = 'ok';
            state.selected = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
            state.loading = [];
        });

        builder.addCase(setRipleyChek.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['RipleyChek'];
        });

        builder.addCase(setRipleyChek.rejected, (state: PaymentMethodState, payload) => {
            if (payload.error.code === 'errRiskyGiftCard') {
                state.status = 'error';
                state.selected = [];
                state.loading = [];
                state.error = payload.error;
            } else {
                state.status = 'idle';
                state.selected = [];
                state.loading = [];
            }
        });
        builder.addCase(setRipleyChek.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = ['RipleyChek'];
            state.loading = [];
        });
        builder.addCase(initializeChek.rejected, (state: PaymentMethodState, payload) => {
            state.status = 'error';
            state.selected = [];
            state.loading = [];
            state.error = payload.error;
        });
        builder.addCase(setRipleyCard.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = addItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setRipleyCard.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setRipleyCard.fulfilled, (state: PaymentMethodState, { payload }) => {
            if (payload.isOverwrite) {
                state.selected = [payload.paymentMethod];
            } else {
                state.selected = state.selected.filter((x) => x === 'RipleyGiftCard');
                state.selected = addItem(state.selected, 'RipleyCard');
                state.selected = deleteItem(state.selected, 'RipleyChekCard');
            }
            state.status = 'ok';
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });

        builder.addCase(setPaymentWithoutCvv.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = addItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setPaymentWithoutCvv.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });
        builder.addCase(
            setPaymentWithoutCvv.fulfilled,
            (state: PaymentMethodState, { payload }) => {
                state.status = 'ok';
                state.loading = deleteItem(state.loading, 'RipleyCard');

                if (payload?.isOverwrite) {
                    state.selected = ['RipleyCard'];
                } else {
                    state.selected = state.selected.filter((x) => x === 'RipleyGiftCard');
                    state.selected = addItem(state.selected, 'RipleyCard');
                }
            },
        );

        builder.addCase(setRipleyGiftCardDummy.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['RipleyGiftCard'];
        });
        builder.addCase(setRipleyGiftCardDummy.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(setRipleyGiftCardDummy.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = ['RipleyGiftCard'];
            state.loading = [];
        });

        builder.addCase(setRipleyGiftCard.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['RipleyGiftCard'];
        });
        builder.addCase(setRipleyGiftCard.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(setRipleyGiftCard.fulfilled, (state: PaymentMethodState, { payload }) => {
            state.status = 'ok';
            state.selected = ['RipleyGiftCard'];
            state.loading = [];
            if (payload.billingType === 1) {
                state.docType = 'boleta';
            }
        });

        builder.addCase(setRipleyGiftCardCopago.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['RipleyGiftCard'];
        });
        builder.addCase(setRipleyGiftCardCopago.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(
            setRipleyGiftCardCopago.fulfilled,
            (state: PaymentMethodState, { payload }) => {
                state.status = 'ok';
                state.selected = ['RipleyGiftCard'];
                state.loading = [];
                if (payload.billingType === 1) {
                    state.docType = 'boleta';
                }
            },
        );

        builder.addCase(setInStore.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = ['InStore'];
        });
        builder.addCase(setInStore.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = ['InStore'];
            state.loading = [];
        });

        builder.addCase(setCardCyb.pending, (state: PaymentMethodState) => {
            state.selected = ['Cybersource'];
            state.loading = ['Cybersource'];
        });
        builder.addCase(setCardCyb.rejected, (state: PaymentMethodState) => {
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(setCardCyb.fulfilled, (state: PaymentMethodState) => {
            state.selected = ['Cybersource'];
            state.loading = [];
        });

        builder.addCase(setTokenCyb.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.selected = [];
            state.loading = ['Cybersource'];
        });
        builder.addCase(setTokenCyb.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(setTokenCyb.fulfilled, (state: PaymentMethodState) => {
            state.status = 'ok';
            state.selected = ['Cybersource'];
            state.loading = [];
        });

        builder.addCase(setCardNiubiz.pending, (state: PaymentMethodState, { meta }) => {
            state.status = 'pending';
            // It is necessary to set Niubiz as selected in order for its form
            // to exist before the call to `tokenizeNiubizCard`
            state.selected = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
        });
        builder.addCase(setCardNiubiz.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.selected = [];
        });
        builder.addCase(setCardNiubiz.fulfilled, (state: PaymentMethodState, { meta }) => {
            state.status = 'ok';
            state.selected = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
        });

        builder.addCase(setTokenNiubiz.pending, (state: PaymentMethodState, { meta }) => {
            state.status = 'pending';
            state.loading = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
        });
        builder.addCase(setTokenNiubiz.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.selected = [];
            state.loading = [];
        });
        builder.addCase(setTokenNiubiz.fulfilled, (state: PaymentMethodState, { meta }) => {
            state.status = 'ok';
            state.selected = [meta.arg.isRipley ? 'RipleyNiubiz' : 'Niubiz'];
            state.loading = [];
        });

        builder.addCase(setCardRipley.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = addItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setCardRipley.rejected, (state: PaymentMethodState) => {
            state.status = 'idle';
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setCardRipley.fulfilled, (state: PaymentMethodState, { payload }) => {
            state.status = 'ok';
            state.selected = addItem(state.selected, payload.paymentMethod);
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });

        builder.addCase(setTokenRipley.pending, (state: PaymentMethodState) => {
            state.status = 'pending';
            state.loading = addItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setTokenRipley.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });
        builder.addCase(setTokenRipley.fulfilled, (state: PaymentMethodState, { payload }) => {
            state.status = 'ok';
            state.selected = addItem(state.selected, payload.paymentMethod);
            state.loading = deleteItem(state.loading, 'RipleyCard');
        });

        builder.addCase(setNiubiz3Ds.pending, (state) => {
            state.status = 'pending';
            state.loading = ['Niubiz3DS'];
        });
        builder.addCase(setNiubiz3Ds.rejected, (state) => {
            state.status = 'idle';
            state.loading = [];
            state.selected = [];
        });
        builder.addCase(setNiubiz3Ds.fulfilled, (state) => {
            state.status = 'ok';
            state.selected = ['Niubiz3DS'];
            state.loading = [];
        });

        builder.addCase(restartPaymentMethodsPage.pending, (state: PaymentMethodState) => {
            state.clean = false;
        });
        builder.addCase(restartPaymentMethodsPage.rejected, () => {});
        builder.addCase(restartPaymentMethodsPage.fulfilled, (state: PaymentMethodState) => {
            state.clean = true;
        });

        builder.addCase(reloadPaymentMethodsPage.pending, (state: PaymentMethodState) => {
            state.clean = false;
        });
        builder.addCase(reloadPaymentMethodsPage.rejected, () => {});
        builder.addCase(reloadPaymentMethodsPage.fulfilled, () => {});
    },
});
