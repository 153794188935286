import * as React from 'react';
import PaymentMethodIcon from '../PaymentMethodIcon';
import styles from './PaymentSummary.module.scss';

export type PaymentSummaryProps = {
    /**
     * Cart totals
     */
    totals: Total[];
    /**
     * List of any other information relevant to the user in order to confirm
     * a payment
     */
    details: PaymentDetail[];
};

export type PaymentDetail = {
    /**
     * Name for this detail
     */
    label: string;
    /**
     * This text, element or component will be rendered as-is next to
     * its `label`
     */
    value: React.ReactNode;
};

export type Total = {
    type: '' | 'normal' | 'ripley' | 'paid';
    amount: string;
};

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
    totals,
    details,
}: PaymentSummaryProps) => (
    <div>
        <h3 className={styles['title']}>Detalles del pago</h3>
        <table className={styles['table']}>
            <thead className={styles['header']}>
                {totals &&
                    totals.map((total, index) => (
                        <tr key={index} className={isHighlight(total) ? styles['highlight'] : ''}>
                            <th>{getLabel(total)}</th>
                            <th>
                                {getIcon(total)}
                                {total.amount}
                            </th>
                        </tr>
                    ))}
            </thead>
            {details.length > 0 && (
                <tbody>
                    {details.map((detail) => (
                        <tr key={detail.label}>
                            <td>{detail.label}</td>
                            <td className={styles['right']}>{detail.value}</td>
                        </tr>
                    ))}
                </tbody>
            )}
        </table>
    </div>
);

const isHighlight = (total: Total): boolean => {
    return total.type === 'ripley';
};

const getLabel = (total: Total): React.ReactNode => {
    if (total.type == 'normal') {
        return 'Total otros medios de pago';
    } else if (total.type == 'ripley') {
        return 'T. Crédito (Ripley / Chek)';
    }
    return 'Total pagado';
};

export const getIcon = (total: Total): React.ReactNode => {
    if (total.type == 'ripley') {
        return (
            <>
                <PaymentMethodIcon type="ripleycredito" width="25px" />
                <PaymentMethodIcon type="chekcredito" width="17px" />
            </>
        );
    }
};
