import React from 'react';
import styles from './LoadingPayment.module.scss';
import LoadingCircle from '../LoadingCircle';

type LoadingPaymentProps = {
    content?: string;
};

function LoadingPayment(props: LoadingPaymentProps): JSX.Element {
    return (
        <div className={styles.frame}>
            <div className={styles.img}>
                <LoadingCircle />
            </div>
            <p className={styles.text}>{props.content ? props.content : 'Realizando el pago...'}</p>
        </div>
    );
}

export default LoadingPayment;
