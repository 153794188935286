import api from '../../api';
import { normalizeUserInfo } from './normalizers';
import {
    initialValuesFormAddress,
    loadFormAddress,
    validateFormOnLoad,
} from '../../lib/utils/addresses';

class UserService implements UserService {
    provider: BFFImpl;

    constructor() {
        this.provider = api.bff;
    }

    async createUser(createUserPayload: CreateUserPayload): Promise<HttpResponse> {
        const response = await this.provider.createUser(createUserPayload);
        return response;
    }

    /**
     * `getInfo' fetches the user information and normalizes it
     * @returns
     */
    async getInfo(
        cart: Cart,
        areas: ExtArea,
        light?: boolean,
    ): Promise<{
        user: NormalizedUser;
        addresses: NormalizedAddresses;
    }> {
        const response = await this.provider.getUser(light);
        const { user, addresses } = await normalizeUserInfo(response.data);
        if (addresses?.allIds?.length > 0) {
            const addressesArray = Object.values(addresses.byId);
            const primary = addressesArray.find((a: ExternalAddress) => a.primary);
            const validAddresses = addressesArray.filter((a) => {
                return validateFormOnLoad(
                    loadFormAddress(a, initialValuesFormAddress, areas, false),
                    true, // termsChecked
                );
            });

            // Address validation and filtering logic.
            if (validAddresses.length === 0) {
                // Case 1: Return the primary address so the user can fix it.
                if (primary) {
                    return {
                        user,
                        addresses: {
                            byId: { [primary.id]: primary },
                            allIds: [primary.id],
                            selected: primary.id,
                        },
                    };
                }
                // Case 2: If there's no primary address return empty addresses.
                return { user, addresses: { byId: {}, allIds: [], selected: '' } };
            } else if (addressesArray.length === validAddresses.length) {
                // Case 3: All addresses are valid.
                addresses.selected = primary ? primary.id : addressesArray[0].id;
            } else {
                // Case 4: Some addresses are valid.
                addressesArray.forEach((a) => {
                    if (!validAddresses.find((va) => va.id === a.id)) {
                        delete addresses.byId[a.id];
                        addresses.allIds = addresses.allIds.filter((id) => id !== a.id);
                        addresses.selected =
                            primary && addresses.allIds.includes(primary.id)
                                ? primary.id
                                : validAddresses[0].id;
                    }
                });
            }

            // Se verifica la información del carro para seleccionar la dirección en base a la ultima consulta de agenda
            if (cart.shippingInfo && cart.shippingInfo.length > 0) {
                const addressId = cart.shippingInfo[0].customerAddress['addressId'] ?? '';
                const address = addresses.byId[addressId] ?? '';
                if (address) {
                    addresses.selected = address.id;
                }
            }
        }

        return { user, addresses };
    }

    /**
     * Creates new address.
     * @returns
     */
    async createAddress(paramsAddAddress: ExternalAddressComponents[]): Promise<HttpResponse> {
        const response = await this.provider.createAddress(paramsAddAddress);
        return response;
    }

    /**
     * Deletes an existing address.
     * @returns
     */
    async deleteAddress(id: string): Promise<HttpResponse> {
        const response = await this.provider.deleteAddress(id);
        return response;
    }

    /**
     * Modifies an existing address.
     * @returns
     */
    async modifyAddress(
        paramsAddAddress: ExternalAddressComponents[],
        id: string,
    ): Promise<HttpResponse> {
        const response = await this.provider.modifyAddress(paramsAddAddress, id);
        return response;
    }
}

export default UserService;
