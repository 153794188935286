import React from 'react';
import emptyVertical from './empty-vertical.svg';
import emptyHorizontal from './empty-horizontal.svg';

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    /**
     * Orientation of placeholder image (default: 'vertical')
     */
    orientation?: 'vertical' | 'horizontal';
    /**
     * Alternative text
     */
    alt?: string;
};

export const Image: React.FC<ImageProps> = (props: ImageProps) => {
    const { orientation = 'vertical' } = props;
    const [pending, setPending] = React.useState(true);
    const initialSrc = orientation === 'vertical' ? emptyVertical : emptyHorizontal;

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
                {...props}
                alt={`Esperando imagen "${props.alt}"`}
                src={initialSrc}
                style={{ display: pending ? 'unset' : 'none' }}
            />
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
                {...props}
                style={{ display: pending ? 'none' : 'unset' }}
                onLoad={() => setPending(false)}
                onError={() => setPending(true)}
            />
        </>
    );
};
