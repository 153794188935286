import * as React from 'react';
import styles from './Alert.module.scss';
import Collapsible from '../Collapsible';
import { CollapsibleProps } from '../Collapsible/Collapsible';

export type AlertProps = Omit<CollapsibleProps, 'footer'>;

export const Alert: React.FC<AlertProps> = (props: AlertProps) => (
    <div className={styles[props.theme ? props.theme : defaultTheme]}>
        <Collapsible
            {...props}
            header={
                <div className={styles['header']}>
                    <div className={styles['icon']} />
                    <div>{props.header}</div>
                </div>
            }
        />
    </div>
);

// HACK: needed because styles[] should not be indexed with the undefined that could come from
// accessing defaultProps.theme with a dot operator.
const defaultTheme = 'info';

Alert.defaultProps = {
    theme: defaultTheme,
};
