import React from 'react';
import styles from './WarrantyItem.module.scss';
import Prices, { Price } from '../Prices';
import ProductThumb from '../ProductThumb';
import { ProductThumbAvailability } from '../ProductThumb/ProductThumb';
import extraGarantia from './img/extra-garantia.png';
import { getEnvironment } from 'config';

export type WarrantyItemProps = {
    /**
     *  Warranty displayed as an item of a list of related products
     */
    warranty: Warranty;
};

export const WarrantyItem: React.FC<WarrantyItemProps> = ({ warranty }: WarrantyItemProps) => {
    // Format name first letter to upper case and the rest to lower case
    const warrantyName = `${warranty.name.toUpperCase()[0]}${warranty.name.toLowerCase().slice(1)}`;

    const { app } = getEnvironment();

    const warrantyCountry = app.country;
    return (
        <div className={styles['product']}>
            <div className={styles['item']}>
                <ProductThumb
                    src={warrantyCountry == 'cl' ? warranty.thumbnail : extraGarantia}
                    quantity={warranty.quantity}
                    alt={warranty.sku}
                    availability={warranty.availability}
                />
            </div>
            <div className={styles['item--center']}>
                <p className={styles['name']} title={warrantyName}>
                    {warrantyName}
                </p>
            </div>
            {warranty.prices && (
                <div className={styles['item--right']}>
                    <Prices list={warranty.prices} isTotal={false} isWarranty={true} />
                </div>
            )}
        </div>
    );
};

export interface Warranty {
    sku: string;
    name: string;
    tags: string[];
    thumbnail: string;
    quantity: number;
    prices?: Price[];
    availability?: ProductThumbAvailability;
}
