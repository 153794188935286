import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from '../../services';
import { RootState } from 'types/store';

export interface SetPaymentMethodArgs {
    cartId: string;
    paymentInfo: PaymentInfo[];
}

export const setPaymentMethod = createAsyncThunk('alignet/setPayment', async (_, { getState }) => {
    const state = getState() as RootState;
    const { cart, billing } = state;
    if (cart.data && billing.data) {
        let price: Price = cart.data.grandTotal['ripley_promo'];
        if (price == null) price = cart.data.grandTotal['discount'];
        if (price == null) price = cart.data.grandTotal['master'];

        const { cart: updatedCart, paymentInfo } = await services.cart.setPayment(cart.data.id, [
            {
                documentType: billing.data.documentType,
                paymentMethod: 'Alignet',
                billingInfo: {
                    business: billing.data.billingInfo.business,
                    customer: billing.data.billingInfo.customer,
                    address: billing.data.billingInfo.address,
                },
                amount: price,
                customFields: {
                    returnUrl: window.location.origin + '/checkout',
                },
            },
        ]);

        return { ...updatedCart, paymentInfo };
    } else {
        throw new Error('Datos incompletos para usar Alignet');
    }
});

export interface EvaluatePayload {
    cartId: string;
    orderId: string;
}
export const evaluate = createAsyncThunk('alignet/evaluate', async (payload: EvaluatePayload) => {
    const { cartId, orderId } = payload;
    const response = await services.cart.evaluate(cartId, orderId);
    return response.data;
});

interface AlignetData {
    url: string;
    acquireId: string;
    commerceCode: string;
    purchaseOperationNumber: string;
    purchaseVerification: string;
    purchaseAmount: string;
    purchaseCurrencyCode: string;
    language: string;
    logged: string;
    shippingFirstName: string;
    shippingLastName: string;
    shippingEmail: string;
    shippingAddress: string;
    shippingZIP: string;
    shippingCity: string;
    shippingState: string;
    shippingCountry: string;
    descriptionProducts: string;
    clientIdentifier: string;
    programmingLanguage: string;
}

interface AlignetState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: AlignetData | null;
    error: SerializedError | null;
}

const initialState: AlignetState = {
    status: 'idle',
    data: null,
    error: null,
};

export const slice = createSlice({
    name: 'alignet',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPaymentMethod.pending, (state: AlignetState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentMethod.fulfilled, (state, { payload }) => {
            state.status = 'ok';
            state.error = null;
            state.data = {
                url: payload.paymentInfo?.[0].customFields?.url || '',
                acquireId: payload.paymentInfo?.[0].customFields?.acquireId,
                commerceCode: payload.paymentInfo?.[0].customFields?.commerceCode,
                purchaseOperationNumber:
                    payload.paymentInfo?.[0].customFields?.purchaseOperationNumber,
                purchaseVerification: payload.paymentInfo?.[0].customFields?.purchaseVerification,
                purchaseAmount: payload.paymentInfo?.[0].customFields?.purchaseAmount,
                purchaseCurrencyCode: payload.paymentInfo?.[0].customFields?.purchaseCurrencyCode,
                language: payload.paymentInfo?.[0].customFields?.language,
                logged: payload.paymentInfo?.[0].customFields?.logged,
                shippingFirstName: payload.paymentInfo?.[0].customFields?.shippingFirstName,
                shippingLastName: payload.paymentInfo?.[0].customFields?.shippingLastName,
                shippingEmail: payload.paymentInfo?.[0].customFields?.shippingEmail,
                shippingAddress: payload.paymentInfo?.[0].customFields?.shippingAddress,
                shippingZIP: payload.paymentInfo?.[0].customFields?.shippingZIP,
                shippingCity: payload.paymentInfo?.[0].customFields?.shippingCity,
                shippingState: payload.paymentInfo?.[0].customFields?.shippingState,
                shippingCountry: payload.paymentInfo?.[0].customFields?.shippingCountry,
                descriptionProducts: payload.paymentInfo?.[0].customFields?.descriptionProducts,
                clientIdentifier: payload.paymentInfo?.[0].customFields?.clientIdentifier,
                programmingLanguage: payload.paymentInfo?.[0].customFields?.programmingLanguage,
            };
        });
        builder.addCase(setPaymentMethod.rejected, (state, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
    },
});
