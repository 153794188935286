import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';
import { setCard, setCardByToken } from 'store/card/cybersource';
import { getPayments, setPaymentDummy } from './actions';

export type SetPaymentMethodPayload = {
    cartId: string;
    paymentInfo: PaymentInfo[];
};

/**
 * Creates a payment of type 'Cybersource' and configures it in the cart
 */
export const setPaymentMethod = createAsyncThunk(
    'cybersource/setPayment',
    async (payload: SetPaymentMethodPayload) => {
        const { paymentInfo, cart } = await services.cart.setPayment(
            payload.cartId,
            payload.paymentInfo,
        );

        /* TODO: there should be a better way to identify a new payment */
        const payment =
            paymentInfo.find((payment) => payment.paymentMethod == 'Cybersource') || null;

        if (payment?.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        return {
            id: payment.id || '',
            jwk: payment.customFields.jwk,
            cart,
        };
    },
);

export type CybersourceData = {
    id: string;
    jwk: string;
};

export type CybersourceState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: CybersourceData | null;
    error: SerializedError | null;
};

const initialState: CybersourceState = {
    status: 'idle',
    data: null,
    error: null,
};

export const setPaymentByToken = createAsyncThunk(
    'cybersource/setPaymentByToken',
    async ({ card, cvv }: SetPaymentByTokenArg, { getState, dispatch }) => {
        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('cart, use, address or area store are empty');
        }
        const payments = getPayments({
            paymentMethod: 'Cybersource',
            cart: state.cart.data,
            user: state.user.data,
            addresses: state.address.data,
            areas: state.area.data,
            card,
        });

        const isOverwrite = state.app.data?.paymentMode !== 'copago';
        const paymentInfo = await services.cart.setPaymentOnce(
            state.cart.data.id,
            payments,
            /*isDummy*/ false,
            isOverwrite,
        );

        /* TODO: there should be a better way to identify a new payment */
        const payment = paymentInfo.find((p) => p.paymentMethod == 'Cybersource');
        if (payment == null || payment.id == null || payment.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        const cybPayment = { id: payment.id, jwk: payment.customFields.jwk };
        await dispatch(setCardByToken({ card, cvv, payment: cybPayment })).unwrap();
        return cybPayment;
    },
);

export type SetPaymentByTokenArg = {
    card: CardToken;
    cvv: string;
};

export default createSlice({
    name: 'cybersource',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPaymentDummy.fulfilled, (state: CybersourceState) => {
            state.status = 'idle';
            state.error = null;
            state.data = null;
        });

        builder.addCase(setPaymentMethod.pending, (state: CybersourceState) => {
            state.status = 'pending';
            state.error = null;
            state.data = null;
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: CybersourceState, { payload }) => {
            state.status = 'ok';
            state.error = null;
            state.data = { id: payload.id, jwk: payload.jwk };
        });
        builder.addCase(setPaymentMethod.rejected, (state: CybersourceState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });

        builder.addCase(setPaymentByToken.pending, (state: CybersourceState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentByToken.rejected, (state: CybersourceState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(setPaymentByToken.fulfilled, (state: CybersourceState, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });

        builder.addCase(setCard.pending, (state: CybersourceState) => {
            state.status = 'pending';
        });
        builder.addCase(setCard.rejected, (state: CybersourceState) => {
            state.status = 'error';
        });
        builder.addCase(setCard.fulfilled, (state: CybersourceState) => {
            state.status = 'ok';
        });

        builder.addCase(setCardByToken.pending, (state: CybersourceState) => {
            state.status = 'pending';
        });
        builder.addCase(setCardByToken.rejected, (state: CybersourceState) => {
            state.status = 'error';
        });
        builder.addCase(setCardByToken.fulfilled, (state: CybersourceState) => {
            state.status = 'ok';
        });
    },
});
