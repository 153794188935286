import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { setCard, setCardByToken } from 'store/card/ripleyCard.slice';
import { RootState } from 'types/store';
import { getPayments, setPaymentDummy } from './actions';
import { RipleyGiftCardState } from './ripleyGiftCard.slice';
import { fetchCartById } from 'store/cart';

/**
 * Creates a payment of type 'ITF' and configures it in the cart
 */
export const setPaymentMethod = createAsyncThunk(
    'ripleyCard/setPayment',
    async ({ cartId, card, paymentMethod }: SetPaymentMethodPayload, { getState }) => {
        if (card == null) {
            throw new Error('missing card information');
        }
        const encryptedCard = await window.paymenthub.getITFEncryptedCard({
            PAN: card.pan,
            CVV2: card.cvv,
            expirationDate: `${card.month}/${card.year}`,
        });
        if (encryptedCard == null) {
            // NOTE: getITFEncryptedCard is try/catching its error.
            throw new Error('cannot encrypt card');
        }

        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('cart, use, address or area store are empty');
        }

        let payments = getPayments({
            paymentMethod: paymentMethod,
            cart: state.cart.data,
            user: state.user.data,
            addresses: state.address.data,
            areas: state.area.data,
            customFields: encryptedCard,
        });

        const isOverwrite = state.app.data?.paymentMode !== 'copago';

        if (!isOverwrite) {
            const giftCardData = state.paymentMethod.types.ripleyGiftCard.data;
            const cartData = state.cart.data;
            const grandTotal = cartData.grandTotal['discount'] || cartData.grandTotal['master'];

            if (giftCardData && grandTotal) {
                const grandTotalValue = Number(grandTotal.value);
                const amount = grandTotalValue - giftCardData.paymentAmount;
                payments = payments.map((payment) => {
                    return {
                        ...payment,
                        amount: {
                            currency: 'CLP',
                            value: amount.toString(),
                        },
                    };
                });
            }
        }

        const paymentInfo = await services.cart.setPaymentOnce(
            cartId,
            payments,
            false,
            isOverwrite,
        );

        /* TODO: there should be a better way to identify a new payment */
        const payment = paymentInfo.find((p) => p.paymentMethod === paymentMethod);
        if (payment == null || payment.id == null || payment.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        return {
            id: payment.id,
            jwk: payment.customFields.jwk,
            encryptedCard,
            isOverwrite,
            paymentMethod,
        };
    },
);

export type SetPaymentMethodPayload = {
    cartId: string;
    card: Card;
    paymentMethod: PaymentMethodName;
};

export const setPaymentByToken = createAsyncThunk(
    'ripleycard/setPaymentByToken',
    async ({ card, cvv, paymentMethod }: SetPaymentByTokenArg, { getState, dispatch }) => {
        const secret = await window.paymenthub.getITFEncryptedCard({ CVV2: cvv });
        if (secret == null) {
            throw new Error('cannot encrypt card');
        }

        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('cart, use, address or area store are empty');
        }
        let payments = getPayments({
            paymentMethod,
            cart: state.cart.data,
            user: state.user.data,
            addresses: state.address.data,
            areas: state.area.data,
            customFields: secret,
            card,
        });

        const isOverwrite = state.app.data?.paymentMode !== 'copago';

        if (!isOverwrite) {
            const giftCardData = state.paymentMethod.types.ripleyGiftCard.data;
            const cartData = state.cart.data;
            const grandTotal = cartData.grandTotal['discount'] || cartData.grandTotal['master'];

            if (giftCardData && grandTotal) {
                const grandTotalValue = Number(grandTotal.value);
                const amount = grandTotalValue - giftCardData.paymentAmount;
                payments = payments.map((payment) => {
                    return {
                        ...payment,
                        amount: {
                            currency: 'CLP',
                            value: amount.toString(),
                        },
                    };
                });
            }
        }

        const paymentInfo = await services.cart.setPaymentOnce(
            state.cart.data.id,
            payments,
            false,
            isOverwrite,
        );

        /* TODO: there should be a better way to identify a new payment */
        const payment = paymentInfo.find((p) => p.paymentMethod == paymentMethod);
        if (payment == null || payment.id == null || payment.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        const paymentMethodResponse = {
            id: payment.id,
            jwk: payment.customFields.jwk,
            encryptedCard: secret,
        };
        dispatch(setCardByToken({ card, payment: paymentMethodResponse }));
        return paymentMethodResponse;
    },
);

export const setPaymentWithoutCvv = createAsyncThunk(
    'ripleycard/setPaymentWithoutCvv',
    async (card: CardToken, { getState, dispatch }) => {
        const state = getState() as RootState;

        if (!state.rule.data.paymentWithoutCvv) {
            return null;
        }

        if (state.cart.data == null || state.billing.data == null) {
            throw new Error('datos incompletos');
        }

        const giftCard = state.paymentMethod.types.ripleyGiftCard;
        const isOverwrite = state.app.data?.paymentMode !== 'copago';

        let payments = getPaymentPayload(
            state.cart.data,
            state.billing.data,
            card,
            {},
            giftCard,
            isOverwrite,
        );

        const cartId = state.cart.data.id;

        // Se guarda el pago en el carro
        await services.cart.setPaymentOnce(cartId, payments, true, isOverwrite);

        // Se actualiza el carro para obtener las promociones del medio de pago
        const newCart = await dispatch(fetchCartById()).unwrap();

        // Se actualiza el payload a partir del carro obtenido
        payments = getPaymentPayload(newCart, state.billing.data, card, {}, giftCard, isOverwrite);

        // Se inicializa el pago final y se guarda en el carro
        payments = await services.cart.setPaymentOnce(cartId, payments, false, isOverwrite);

        // Se verifica que el pago se haya creado
        const payment = payments.find((p) => p.paymentMethod == 'RipleyCard');
        if (payment == null || payment.id == null || payment.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        const paymentMethod = {
            id: payment.id,
            jwk: payment.customFields.jwk,
            encryptedCard: {},
        };

        // Se configura la tarjeta para obtener las cuotas
        await dispatch(setCardByToken({ card, payment: paymentMethod })).unwrap();
        return { card, isOverwrite, paymentMethod };
    },
);

/**
 * Returns the payload to create a RipleyCard payment
 * @returns
 */
function getPaymentPayload(
    cart: Cart,
    billing: PartialPaymentInfo,
    card: CardToken,
    customFields: CustomFields,
    giftCard: RipleyGiftCardState,
    isOverwrite: boolean,
): PaymentInfo[] {
    let amount =
        cart.grandTotal['ripley_promo'] || cart.grandTotal['discount'] || cart.grandTotal['master'];

    if (!isOverwrite && giftCard.data) {
        amount = cart.grandTotal['discount'] || cart.grandTotal['master'];
        const value = Number(amount.value);
        const newValue = value - giftCard.data.paymentAmount;
        amount.value = newValue.toString();
    }

    return [
        {
            documentType: billing.documentType,
            paymentMethod: 'RipleyCard',
            billingInfo: {
                business: billing.billingInfo.business,
                customer: billing.billingInfo.customer,
                address: billing.billingInfo.address,
            },
            amount: amount,
            customFields,
            cardId: card.id,
        },
    ];
}

export type SetPaymentByTokenArg = {
    card: CardToken;
    cvv: string;
    paymentMethod: PaymentMethodName;
};

const initialState: RipleyCardState = {
    status: 'idle',
    data: null,
    error: null,
    isChek: false,
};

export const ripleyCardSlice = createSlice({
    name: 'ripleyCard',
    initialState: initialState,
    reducers: {
        setFakePaymentMethod: (state, action: PayloadAction<boolean>) => {
            state.isChek = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setPaymentDummy.fulfilled, (state: RipleyCardState) => {
            state.status = 'idle';
            state.error = null;
            state.data = null;
        });
        builder.addCase(setPaymentMethod.pending, (state: RipleyCardState) => {
            state.status = 'pending';
            state.error = null;
            state.data = null;
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: RipleyCardState, { payload }) => {
            state.status = 'ok';
            state.error = null;
            state.data = payload;
        });
        builder.addCase(setPaymentMethod.rejected, (state: RipleyCardState, { error }) => {
            state.status = 'error';
            state.data = null;
            state.error = error;
        });

        builder.addCase(setPaymentByToken.pending, (state: RipleyCardState) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentByToken.rejected, (state: RipleyCardState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(setPaymentByToken.fulfilled, (state: RipleyCardState, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });

        builder.addCase(setCard.pending, (state: RipleyCardState) => {
            state.status = 'pending';
        });
        builder.addCase(setCard.rejected, (state: RipleyCardState) => {
            state.status = 'error';
        });
        builder.addCase(setCard.fulfilled, (state: RipleyCardState) => {
            state.status = 'ok';
        });

        builder.addCase(setCardByToken.pending, (state: RipleyCardState) => {
            state.status = 'pending';
        });
        builder.addCase(setCardByToken.rejected, (state: RipleyCardState) => {
            state.status = 'error';
        });
        builder.addCase(setCardByToken.fulfilled, (state: RipleyCardState) => {
            state.status = 'ok';
        });

        builder.addCase(setPaymentWithoutCvv.pending, (state) => {
            state.status = 'pending';
        });
        builder.addCase(setPaymentWithoutCvv.rejected, (state, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(setPaymentWithoutCvv.fulfilled, (state, { payload }) => {
            state.status = 'ok';
            if (payload) state.data = payload.paymentMethod;
        });
    },
});

export type RipleyCardData = {
    id: string;
    jwk: string;
    encryptedCard: { [key: string]: unknown };
};

export type RipleyCardState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: RipleyCardData | null;
    error: SerializedError | null;
    isChek: boolean;
};

export const { setFakePaymentMethod } = ripleyCardSlice.actions;
