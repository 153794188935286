import React, { useEffect, useState } from 'react';
import { validate as validateRUT, format as formatRUT, clean as cleanRUT } from 'rut.js';

import { CL } from 'consts';
import styles from './TaxInstrumentForm.module.scss';
import Select, { Option } from '../../common/Select';
import TextInput from '../../common/TextInput';
import { AddressLevel } from 'components/pickup/LocationForm/LocationForm';
import { useTranslation } from 'react-i18next';

export type TaxInstrumentFormProps = React.HTMLAttributes<HTMLFormElement> & {
    shouldDisplayErrors: boolean;
    levels: AddressLevel[];
    city?: string;
    commercialActivity?: string;
    /**
     * Country of origin "CL" or "PE" default is "CL"
     */
    country?: 'CL' | 'PE';
    /**
     * Options available for installments
     */
    activities: Option[];
    /*
     *
     */
    company: TaxForm;
    /**
     * Form values
     */
    onFormChange?: ((value: TaxForm) => void) | undefined;
};

export const TaxInstrumentForm: React.FC<TaxInstrumentFormProps> = (
    props: TaxInstrumentFormProps,
) => {
    const {
        city = '',
        country,
        activities,
        levels,
        onFormChange = () => null,
        shouldDisplayErrors,
        company,
    } = props;

    const { t } = useTranslation();

    const [values, setValues] = useState<TaxForm>({
        rut: company?.rut || '',
        rutFormatted: company?.rutFormatted || '',
        rutIsValid: company?.rutIsValid || false,
        company: company?.company || '',
        companyIsValid: company?.companyIsValid || false,
        companyActivity: company?.companyActivity || '',
        companyActivityName: company?.companyActivityName || '',
        companyActivityIsValid: company?.companyActivityIsValid || false,
        streetName: company?.streetName || '',
        streetNameIsValid: company?.streetNameIsValid || false,
        streetNumber: company?.streetNumber || '',
        streetNumberIsValid: company?.streetNumberIsValid || false,
        region: company?.region || '',
        regionCode: company?.regionCode || '',
        regionCodeIsValid: company?.regionCodeIsValid || false,
        city: company?.city || '',
        cityCode: company?.cityCode || '',
        cityCodeIsValid: company?.cityCodeIsValid || false,
        homeNumber: company?.homeNumber || '',
        phoneNumber: company?.phoneNumber || '',
        phoneNumberFormatted: company?.phoneNumberFormatted || '',
        phoneNumberIsValid: company?.phoneNumberIsValid || false,
    });

    useEffect(() => onFormChange(values), [values, onFormChange]);

    const isLevelInvalid = (levelLabel: string, values: TaxForm) => {
        if (
            (levelLabel === 'Region' && !values.region) ||
            (levelLabel === 'Comuna' && !values.city)
        ) {
            return true;
        }
        return false;
    };

    /* Sets diferents DNI formats for diferent countries */
    const onChangeRutInput = (country?: 'CL' | 'PE') => {
        switch (country) {
            case 'CL':
                return (value: string) => {
                    const formatted = formatRUT(value);
                    setValues({
                        ...values,
                        rut: cleanRUT(value),
                        // formatRUT('') returns '-', but we want ''.
                        rutFormatted: formatted === '-' ? '' : formatted,
                        rutIsValid: validateRUT(cleanRUT(value)),
                    });
                };
            default:
                return (value: string) => {
                    setValues({
                        ...values,
                        rut: value,
                        rutFormatted: value,
                        rutIsValid: validarRucModulo11(cleanRUT(value)),
                    });
                };
        }
    };

    return (
        <div className={styles['TaxInstrumentForm']}>
            {country == CL && (
                <div className={styles['group']}>
                    Aseguráte de ingresar los datos exactos del documento
                    <b>&quot;Rol único tributario&quot; </b> de tu empresa para evitar errores.{' '}
                    {city}
                </div>
            )}
            <form>
                <div className={styles['input-space']}>
                    <TextInput
                        id="rut"
                        type="text"
                        label={`${t('dniLabel')} *`}
                        placeholder={t('dniPlaceholder')}
                        help={country == CL ? 'Sin punto ni guión' : ''}
                        maxlength={country == CL ? 20 : 11}
                        value={values.rutFormatted}
                        onChange={onChangeRutInput(country)}
                        name="rut"
                        invalid={
                            shouldDisplayErrors &&
                            (country == CL
                                ? !validateRUT(values.rut)
                                : !validarRucModulo11(values.rut))
                        }
                    />
                </div>
                <div className={styles['input-space']}>
                    <TextInput
                        id="company"
                        type="text"
                        pattern={'^[&®ñÑáéíóúÁÉÍÓÚa-zA-Z0-9_ ]*$'}
                        label="Nombre de la empresa *"
                        placeholder="Ej: Comercial ECCSA S.A."
                        help={country == CL ? 'Nombre inscrito en el SII' : ''}
                        maxlength={country === CL ? 20 : 128}
                        value={values.company}
                        onChange={(value) =>
                            setValues({
                                ...values,
                                company: value,
                                companyIsValid: value.length >= 2,
                            })
                        }
                        name="company"
                        invalid={shouldDisplayErrors && !values.companyIsValid}
                    />
                </div>
                {country === CL && (
                    <>
                        <div className={styles['input-space']}>
                            <Select
                                label="Giro de la empresa"
                                options={activities}
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        companyActivity: value,
                                        companyActivityName:
                                            activities.find((a) => a.value === value)?.label || '',
                                        companyActivityIsValid: value.length >= 2,
                                    })
                                }
                                value={values.companyActivity}
                                invalid={shouldDisplayErrors && !values.companyActivityIsValid}
                            />
                        </div>

                        <div className={styles['input-space']}>
                            <TextInput
                                id="streetName"
                                name="address-tax"
                                type="text"
                                label="Dirección*"
                                placeholder="Ej. Miguel Claro Providencia"
                                maxlength={20}
                                value={values.streetName}
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        streetName: value,
                                        streetNameIsValid: value.length >= 2,
                                    })
                                }
                                invalid={shouldDisplayErrors && !values.streetNameIsValid}
                            />
                        </div>

                        <div className={styles['input-space']}>
                            <TextInput
                                id="streetNumber"
                                name="streetNumber"
                                type="text"
                                label="Número*"
                                placeholder="Ej. Miguel Claro Providencia"
                                maxlength={20}
                                value={values.streetNumber}
                                onChange={(value) => {
                                    setValues({
                                        ...values,
                                        streetNumber: value,
                                        streetNumberIsValid: value.length >= 1,
                                    });
                                }}
                                invalid={shouldDisplayErrors && !values.streetNumberIsValid}
                            />
                        </div>

                        <div className={styles['input-space']}>
                            <TextInput
                                id="homeNumber"
                                type="text"
                                label="Depto/Casa/Oficina"
                                placeholder="Ej. 202"
                                maxlength={20}
                                value={values.homeNumber}
                                onChange={(value) => setValues({ ...values, homeNumber: value })}
                                name="homeNumber"
                            />
                        </div>

                        {/* Region and city selects */}
                        <div className={styles['input']}>
                            {levels.map((level) => (
                                <Select
                                    key={level.name}
                                    label={level.label}
                                    onChange={(val) => {
                                        if (level.label === 'Region') {
                                            setValues({
                                                ...values,
                                                regionCode: val,
                                                region:
                                                    level.options.find((o) => o.code === val)
                                                        ?.name || '',
                                            });
                                        } else if (level.label === 'Comuna') {
                                            setValues({
                                                ...values,
                                                cityCode: val,
                                                city:
                                                    level.options.find((o) => o.code === val)
                                                        ?.name || '',
                                            });
                                        }
                                    }}
                                    value={
                                        level.label === 'Region'
                                            ? values.regionCode
                                            : values.cityCode
                                    }
                                    options={level.options.map((option) => ({
                                        value: option.code,
                                        label: option.name,
                                    }))}
                                    // disabled={level.disabled || disabled}
                                    disabled={level.disabled}
                                    invalid={
                                        shouldDisplayErrors && isLevelInvalid(level.label, values)
                                    }
                                />
                            ))}
                        </div>

                        <div className={styles['input-space']}>
                            <TextInput
                                name="phoneNumber"
                                id="phoneNumber"
                                type="text"
                                label="Celular*"
                                placeholder="+56 _-____-____"
                                mask="_"
                                format="+56 #-####-####"
                                value={values.phoneNumber}
                                onChangeMasked={(value, formatted) =>
                                    setValues({
                                        ...values,
                                        phoneNumber: value,
                                        phoneNumberFormatted: formatted,
                                        phoneNumberIsValid: value.length === 9,
                                    })
                                }
                                invalid={shouldDisplayErrors && values.phoneNumber.length !== 9}
                            />
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

export const validarModulo11 = (ruc: string): number => {
    let suma = 0;
    let factor = 2;
    for (let i = ruc.length - 1; i >= 0; i--) {
        suma = suma + parseInt(ruc.substr(i, 1)) * factor;

        factor++;

        if (factor > 7) factor = 2;
    }
    const resto = suma % 11;
    const digitoChekeo = 11 - resto;
    if (digitoChekeo >= 10) {
        return digitoChekeo - 10;
    } else {
        return digitoChekeo;
    }
};

export const validarRucModulo11 = (ruc: string): boolean => {
    const digito11 = parseInt(ruc.substr(ruc.length - 1, 1));
    const digitoChekeo = validarModulo11(ruc.substr(0, ruc.length - 1));
    return digito11 === digitoChekeo && ruc.length === 11;
};

export default TaxInstrumentForm;
