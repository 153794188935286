import React, { useEffect } from 'react';

import ShippingGroup, { Skeleton } from 'components/aio/ShippingGroup';
import AlertBadge from 'components/common/AlertBadge';
import ShippingGroupContainer from 'containers/aio/ShippingGroupContainer';
import { ProductThumbProps } from 'components/common/ProductThumb/ProductThumb';
import { useAnalytics } from 'lib/hooks/analytics';
import { useAppSelector, useAppDispatch } from 'lib/hooks/redux';
import { fetchScheduleByCartId, setSchedule } from 'store/shipping';
import { fetchCartById } from 'store/cart';
import { updateSeparatedProducts } from 'store/separatedProducts';
import { resetPayments } from 'store/paymentMethod/actions';
import { redirectMixedGroup } from 'store/fulfillment/fulfillment.slice';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export const ShippingListContainer: React.FC = () => {
    const analytics = useAnalytics();
    const dispatch = useAppDispatch();
    const address = useAppSelector((state: RootState) => state.address);
    const cart = useAppSelector((state: RootState) => state.cart);
    const delivery = useAppSelector((state: RootState) => state.shipping);

    const selectedAddressId = address.data?.selected;
    const selectedAddress = selectedAddressId && address.data?.byId[selectedAddressId];
    useEffect(() => {
        (async () => {
            // Gets the schedules each time the selected address changes
            if (selectedAddress) {
                await dispatch(fetchScheduleByCartId());
                await dispatch(setSchedule());
                await dispatch(resetPayments());
                await dispatch(fetchCartById());
                await dispatch(updateSeparatedProducts());
                await dispatch(redirectMixedGroup());
            }
        })();
    }, [dispatch, selectedAddress]);

    if (delivery.data && cart.data) {
        const deliveryData = delivery.data;

        if (deliveryData.groups.allIds.length === 0) {
            sendErrorToGA4({
                channel: cart.data.channel,
                error: 'Productos no pueden ser enviados a la ubicación seleccionada',
                section: 'DeliverySection',
                type: 'Checkout_presentational_error',
            });

            return (
                <section style={{ marginBottom: '1em' }}>
                    <AlertBadge theme="warning">
                        Los productos no pueden ser enviados a tu ubicación, por favor elige otra
                        dirección.
                    </AlertBadge>
                </section>
            );
        }

        if (cart.data.shippingInfo?.length) {
            const unscheduled = deliveryData.unscheduled;
            const unscheduledIds = unscheduled.map((u) => u.sku);
            const groups: JSX.Element[] = [];

            if (unscheduled.length > 0) {
                analytics.push('checkout_error', 'noAgenda');

                sendErrorToGA4({
                    channel: cart.data.channel,
                    error: 'Se encontraron productos sin agenda',
                    section: 'DeliverySection',
                    type: 'Checkout_presentational_error',
                });

                groups.push(
                    <ShippingGroup
                        key="unavailable"
                        index={1}
                        storeName="No disponible"
                        productThumbs={cart.data.products
                            .filter((p) => unscheduledIds.includes(p.sku))
                            .map((p) => {
                                const t: ProductThumbProps = { src: p.details.thumbnail };
                                return t;
                            })}
                        isUnavailable
                    />,
                );
            }

            if (deliveryData.groups.allIds.length > 0) {
                delivery.data.groups.allIds.forEach((groupId) => {
                    groups.push(
                        <ShippingGroupContainer
                            key={groupId}
                            index={groups.length + 1}
                            groupId={groupId}
                        />,
                    );
                });
            }

            return <>{groups}</>;
        }
    }

    return <Skeleton index={1} />;
};
