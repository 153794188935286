import React, { useState } from 'react';
import styles from './ButtonField.module.scss';
import Button from '../Button';
import Spinner from '../Spinner';

export type ButtonFieldProps = {
    /**
     * Default value of the text field
     */
    value?: string;
    /**
     * Placeholder of the text field
     */
    placeholder?: string;
    /**
     * Text to be displayed before the value of the text field
     */
    prevValue?: string;
    /**
     * Applies a checked style
     */
    checked?: boolean;
    /**
     * Applies an invalid style
     */
    invalid?: boolean;
    /**
     * Error message displayed if `invalid` property is true
     */
    errorMsg?: string;
    /**
     * Allows to add additional classnames
     */
    className?: string;
    /**
     * Callback triggered when the text field changes
     */
    onChange?: (value: string) => void;
    /**
     * Callback triggered when we attempt to delete a value
     */
    onDelete?: (value: string) => void;
    /**
     * Callback triggered when we attempt to registry a value
     */
    onSubmit?: (value: string) => void;
    /**
     * Color of the button
     */
    theme?: 'primary' | 'secondary';
    /**
     * Text of the submit button
     */
    submitText?: React.ReactNode;
    /**
     * Disables the submit/delete button
     */
    disabled?: boolean;
    /*
     * Triggers the loading animation
     */
    isLoading?: boolean;
};

export const ButtonField: React.FC<ButtonFieldProps> = (props: ButtonFieldProps) => {
    const [value, setValue] = useState(props.value || '');
    const { theme = 'secondary', submitText = 'Aplicar', disabled = false, isLoading } = props;
    /**
     * Handle the text field changes
     * @param event
     */
    const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
        const currentValue = event.currentTarget.value;
        setValue(currentValue);

        if (props.onChange) {
            props.onChange(currentValue);
        }
    };

    /**
     * Handles the cases to registry a value
     * @param event
     */
    const handleOnSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        if (props.onSubmit) {
            props.onSubmit(value);
            setValue('');
        }
        event.preventDefault();
    };

    /**
     * Handles the cases to delete a value
     * @param event
     */
    const handleOnDelete = (event: React.FormEvent<HTMLButtonElement>) => {
        if (props.onDelete) {
            props.onDelete(value);
            setValue('');
        }
        event.preventDefault();
    };

    const renderButton = (isLoading: boolean) => {
        if (isLoading) {
            return (
                <Button theme="primary" size="small">
                    <Spinner size={25} theme="primary" />
                </Button>
            );
        } else if (!props.checked) {
            return (
                <Button disabled={disabled} onClick={handleOnSubmit} theme={theme}>
                    {submitText}
                </Button>
            );
        } else {
            return (
                <Button disabled={disabled} onClick={handleOnDelete} theme="secondary">
                    Eliminar
                </Button>
            );
        }
    };

    let classNames = styles['buttonField'];

    if (props.checked) {
        classNames += ' ' + styles['buttonField--checked'];
    }

    if (props.prevValue) {
        classNames += ' ' + styles['buttonField--prev'];
    }

    if (props.invalid) {
        classNames += ' ' + styles['buttonField--invalid'];
    }

    if (props.disabled) {
        classNames += ' ' + styles['buttonField--disabled'];
    }

    if (props.theme) {
        classNames += ' ' + styles[`buttonField--${props.theme}`];
    }

    if (props.className) {
        classNames += ' ' + props.className;
    }

    return (
        <div className={classNames}>
            <div className={styles['buttonField__control']}>
                <input
                    disabled={disabled}
                    type="text"
                    value={value}
                    onChange={handleOnChange}
                    placeholder={props.placeholder || 'Añadir cupón de descuento'}
                    size={1}
                />
                {renderButton(isLoading || false)}
            </div>

            {props.prevValue && (
                <span className={styles['buttonField__prevValue']}>{props.prevValue}</span>
            )}
            {props.errorMsg && (
                <span className={styles['buttonField__errorMsg']}>{props.errorMsg}</span>
            )}
        </div>
    );
};
