import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';
import { fetchUser } from 'store/user';

/**
 * This mess allow us to know the full information of the selected address in
 * other slices
 */
export const selectAddress = createAsyncThunk<
    string,
    ExternalAddress['nickname'],
    { state: RootState; fulfilledMeta: { addresses: NormalizedAddresses | null } }
>('user/selectAddress', (addressId, { fulfillWithValue, getState }) => {
    const state = getState();
    return fulfillWithValue(addressId, { addresses: state.address.data });
});

export interface AddressState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: NormalizedAddresses | null;
    error: SerializedError | null;
}

const initialState: AddressState = {
    status: 'idle',
    data: null,
    error: null,
};

export const createAddress = createAsyncThunk(
    'address/createAddress',
    async (paramsAddAddress: ExternalAddressComponents[]) => {
        const response = await services.user.createAddress(paramsAddAddress);
        return response;
    },
);

export const modifyAddress = createAsyncThunk(
    'address/modifyAddress',
    async (paramsAddAddress: ExternalAddressComponents[]) => {
        const id =
            paramsAddAddress.find(
                (addressComponents: ExternalAddressComponents) => addressComponents.key === 'id',
            )?.value || '';
        const response = await services.user.modifyAddress(paramsAddAddress, id);
        return response;
    },
);

export const deleteAddress = createAsyncThunk(
    'address/deleteAddress',
    async (idAddress: string) => {
        const response = await services.user.deleteAddress(idAddress);
        return response;
    },
);

const slice = createSlice({
    name: 'address',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state: AddressState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchUser.fulfilled, (state: AddressState, action) => {
            state.status = 'ok';
            state.data = action.payload.addresses;
        });
        builder.addCase(fetchUser.rejected, (state: AddressState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(selectAddress.fulfilled, (state: AddressState, action) => {
            const { payload } = action;
            if (state.data != null) {
                state.data.selected = payload;
            }
            // NOTE: In the store slice the result of this action will be used
            // to reselect the region and city used in Pickup.
        });

        builder.addCase(createAddress.pending, (state: AddressState) => {
            state.status = 'pending';
        });

        builder.addCase(createAddress.fulfilled, (state: AddressState, action) => {
            state.status = 'ok';
            const address = action.payload.data;
            if (state.data) {
                state.data.byId = { ...state.data.byId, [address.id]: address };
                state.data.allIds = [...state.data.allIds, address.id];
            }
        });

        builder.addCase(createAddress.rejected, (state: AddressState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(modifyAddress.pending, (state: AddressState) => {
            state.status = 'pending';
        });

        builder.addCase(modifyAddress.fulfilled, (state: AddressState, action) => {
            state.status = 'ok';
            const address = action.payload.data;
            if (state.data) {
                state.data.byId = { ...state.data.byId, [address.id]: address };
            }
        });

        builder.addCase(modifyAddress.rejected, (state: AddressState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        builder.addCase(deleteAddress.fulfilled, (state: AddressState, action) => {
            const addressId = action.meta.arg;
            if (state.data) {
                const updatedAddresses = { ...state.data.byId };
                delete updatedAddresses[addressId];
                state.data.byId = updatedAddresses;
                state.data.allIds = state.data.allIds.filter((i) => i !== addressId);
            }
        });
    },
});

export const createAddressSlice = createSlice({
    name: 'createAddress',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createAddress.pending, (state: AddressState) => {
            state.status = 'pending';
        });
        builder.addCase(createAddress.fulfilled, (state: AddressState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(createAddress.rejected, (state: AddressState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
    },
});

export const modifyAddressSlice = createSlice({
    name: 'modifyAddress',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(modifyAddress.pending, (state: AddressState) => {
            state.status = 'pending';
        });
        builder.addCase(modifyAddress.fulfilled, (state: AddressState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(modifyAddress.rejected, (state: AddressState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
    },
});

export const deleteAddressSlice = createSlice({
    name: 'deleteAddress',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(deleteAddress.pending, (state: AddressState) => {
            state.status = 'pending';
        });
        builder.addCase(deleteAddress.fulfilled, (state: AddressState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(deleteAddress.rejected, (state: AddressState, action) => {
            state.status = 'error';
            state.data = null;
            state.error = action.error;
        });
    },
});

export default slice;
