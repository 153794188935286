import React, { useContext } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { getIconType, getPaymentName, getCardType } from 'lib/utils/payments';

import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import { setPaymentByToken } from 'store/paymentMethod/niubiz.slice';
import NiubizOptions from 'components/payment/NiubizOptions';
import { NiubizCardNumber } from 'containers/payment/NiubizSelector/NiubizSelector.utils';
import { useTranslation } from 'react-i18next';
import { ErrorContext } from 'containers/aio/PaymentMethodsContainer/context';
import { RootState } from 'types/store';

export type TokenItemProps = {
    token: CardToken;
    disabled?: boolean;
};

export const NiubizTokenItem: React.FC<TokenItemProps> = ({ token, disabled }: TokenItemProps) => {
    const { t } = useTranslation('error');
    const dispatch = useAppDispatch();
    const ctx = useContext(ErrorContext);
    const isRipley = token.paymentMethod === 'RipleyNiubiz';

    const cards = useAppSelector((state: RootState) => state.card);
    const isOpen = useAppSelector((state: RootState) =>
        state.cardToken.data.selected.includes(token.id),
    );
    const optionsIsLoading = useAppSelector(
        (state) =>
            state.paymentMethod.types.niubiz.options.status == 'pending' ||
            state.paymentMethod.data.status == 'pending',
    );
    const isSelected = token.id === cards.selected;
    const isSelectedByToken = useAppSelector(
        (state: RootState) => state.paymentMethod.types.niubiz.selectedByToken,
    );
    const isLoading = cards.byId[token.id]?.status === 'pending';
    const couponLoading = useAppSelector(
        (state: RootState) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );

    if (isSelected || isOpen) {
        return null;
    }

    const isBlocked =
        (isLoading && isSelectedByToken) || couponLoading || optionsIsLoading || disabled;

    const handleOnClick = () => {
        (async () => {
            if (!isLoading) {
                try {
                    await dispatch(setPaymentByToken({ isRipley, token })).unwrap();
                } catch (e) {
                    ctx.setError(t('defaultPaymentError.message'));
                }
            }
        })();
    };
    const bin = token.maskedPan.slice(0, 6);
    const maskedPan = token.maskedPan;
    const paymentName = getPaymentName(token.paymentMethod, token.type);
    const iconType = getIconType(token.paymentMethod, getCardType(bin));
    const additionalInfo = <NiubizCardNumber bin={maskedPan} />;

    return (
        <PaymentMethodItem
            paymentMethodName={paymentName}
            type={iconType}
            widthIcon="63px"
            additionalInfo={additionalInfo}
            onClick={handleOnClick}
            selected={isSelected}
            isLoading={isLoading}
            blocked={isBlocked}
        >
            <NiubizOptions isRipley={isRipley} />
        </PaymentMethodItem>
    );
};
