import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'types/store';
import { GetOptionsPayload } from './options';

let niubizid = 0;

export const setCard = createAsyncThunk<NiubizCard, SetCardArg, { state: RootState }>(
    'card/niubiz/setCard',
    async ({ remember, isRipley }, { getState, dispatch }) => {
        const state: RootState = getState();

        const user = state.user.data;
        if (user == null) {
            throw { code: 'errInvalidCart' };
        }

        const paymentId =
            (isRipley
                ? state.paymentMethod.types.ripleyNiubiz.data?.id
                : state.paymentMethod.types.niubiz.data?.id) ?? '';

        const tokenResponse = await window.paymenthub.tokenizeNiubizCard(
            /* external     */ paymentId,
            /* name         */ user.firstname,
            /* lastname     */ user.lastname,
            /* email        */ user.email,
            /* phone number */ user.phoneNumber,
            /* alias        */ '',
            /* remember     */ remember ?? false,
        );

        if (tokenResponse.success == false || tokenResponse.status >= 400) {
            let message = null;

            try {
                JSON.parse(tokenResponse.message);
            } catch (_) {
                message = tokenResponse.message;
            }

            const st = parseInt(tokenResponse.status);
            throw {
                code: st >= 500 ? 'errExternalService' : 'errBadRequest',
                message: message,
            };
        }
        let bin = '';
        try {
            bin = tokenResponse.bin;
        } catch {
            throw { code: 'errBadRequest' };
        }
        const installments = 1;
        await window.paymenthub.updateInstallments(paymentId, installments);
        await dispatch(getOptions({ isRipley, paymentId, bin }));

        return {
            id: `${paymentId}-${++niubizid}`,
            bin,
            deferred: 0,
            installments,
            paymentId,
        };
    },
);

export const getOptions = createAsyncThunk(
    'card/niubiz/getOptions',
    async ({ paymentId, bin }: NiubizGetOptionsPayload): Promise<CardOptions> => {
        // Example `payload`: [1, 3, 6, 12, 24, 48]
        const installments = await window.paymenthub.getInstallments(paymentId, bin, '');

        if (installments.success === false) {
            throw new Error(installments.message || 'get installments error');
        }

        return { installments, deferred: [] };
    },
);

export const setCardByToken = createAsyncThunk(
    'niubiz/setCardByToken',
    async (
        { isRipley, paymentId, cardId, bin }: SetCardByTokenArg,
        { dispatch },
    ): Promise<NiubizCard> => {
        await window.paymenthub.setCard(paymentId, cardId);

        // set the installments options in the store
        await dispatch(getOptions({ isRipley, paymentId, bin })).unwrap();

        // deferred only if use for ripley payments so in external cards is hardcoded
        const deferred = 0;
        // set a default intallment of 1
        const installments = 1;

        await window.paymenthub.updateInstallments(paymentId, installments);
        // return { id: cardId, bin, deferred, installments, paymentId } as NiubizCard;
        return { id: cardId, bin, deferred, installments, paymentId };
    },
);

export type SetCardByTokenArg = {
    isRipley: boolean;
    paymentId: string;
    cardId: string;
    bin: string;
};

export type NiubizCard = {
    id: string;
    bin: string;
    deferred: number;
    installments: number;
    paymentId: string;
};

export type SetCardArg = {
    isRipley: boolean;
    remember?: boolean;
};

export type NiubizGetOptionsPayload = GetOptionsPayload & {
    isRipley: boolean;
};
