/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import styles from './Terms.module.scss';
import { country, PE, POLITICS_AND_PRIVACY } from 'consts';

import Checkbox from '../Checkbox';
import Link from '../Link';
import Alert from '../Alert';

export type TermsProps = {
    /**
     * Callback triggered when the checkbox or its label is clicked
     */
    onChange: (value: boolean) => void;
    /**
     * Link to terms and conditions page
     */
    termsLocation: string;
    /**
     * Unique identifier in DOM for checkbox element
     */
    id: string;
    /**
     * Display error when required.
     */
    hasError?: boolean;
    register?: boolean;
    withCheckbox?: boolean;
};

export const Terms: React.FC<TermsProps> = ({
    id,
    onChange,
    termsLocation,
    hasError,
    register,
    withCheckbox,
}: TermsProps) => {
    const [checked, setChecked] = React.useState(false);
    const handleChange = (value: boolean) => {
        setChecked(value);
        onChange(value);
    };

    return (
        <div className={styles['terms']}>
            <div className={styles['terms__checkbox']}>
                {withCheckbox && <Checkbox id={id} checked={checked} onChange={handleChange} />}
                <label htmlFor={id}>
                    <ShowTextTerms
                        register={Boolean(register)}
                        termsLocation={termsLocation}
                        withCheckbox={!!withCheckbox}
                    />
                </label>
            </div>

            {hasError && (
                <div className={styles['terms__error']}>
                    <Alert header="Debes aceptar los términos y condiciones." theme="danger" />
                </div>
            )}
        </div>
    );
};

type ShowTextTermsProps = {
    register: boolean;
    withCheckbox: boolean;
    termsLocation: string;
};

export const ShowTextTerms: React.FC<ShowTextTermsProps> = ({
    register,
    termsLocation,
    withCheckbox,
}: ShowTextTermsProps) => {
    if (register) {
        return (
            <>
                {'Al registrarte, aceptas los '}
                <Link to={termsLocation} title="Leer términos y condiciones">
                    <b>términos y condiciones</b>
                </Link>
                {' y la '}
                <Link to={termsLocation} title="Leer política de privacidad">
                    <b>política de privacidad</b>
                </Link>
                {' de acuerdo a la '} <b>Ley N° 19.628.</b>
            </>
        );
    } else {
        if (country == PE) {
            return (
                <>
                    {withCheckbox ? 'Acepto los ' : 'Al hacer click en “pagar”, aceptas nuestros '}
                    <Link to={termsLocation} title="términos y condiciones">
                        términos y condiciones
                    </Link>
                    {' y '}
                    <Link to={POLITICS_AND_PRIVACY ?? ''} title="Leer política de privacidad">
                        política de privacidad
                    </Link>
                </>
            );
        } else {
            return (
                <>
                    {withCheckbox ? 'Acepto los ' : 'Al hacer click en “pagar”, aceptas nuestros '}
                    <Link to={termsLocation} title="Leer términos y condiciones">
                        términos y condiciones
                    </Link>
                </>
            );
        }
    }
};
