import React, { FC } from 'react';
import { GiftCardPlasticProps } from './GiftCardPlastic.interface';
import styles from './GiftCardPlastic.module.scss';

export const GiftCardPlastic: FC<GiftCardPlasticProps> = (props: GiftCardPlasticProps) => {
    const { number, hideNumber, serial, hideSerial } = props;
    const isSerial = hideSerial ? serial : '******';
    const isNumber = hideNumber ? number : '******';
    return (
        <div className={styles['container']}>
            <div className={styles['serial']}>{isSerial}</div>
            <div className={styles['number']}>Nº {isNumber}</div>
        </div>
    );
};
