import React, { FC } from 'react';
import styles from './PageLayout.module.scss';

export type PageLayoutProps = {
    /** It renders the BreadCrumbsNav component */
    navBar?: React.ReactNode;

    /** It renders the content of the page */
    content: React.ReactNode;

    /** It renders the content of the right side of the page */
    side?: React.ReactNode;

    /** It sets the title of the page */
    title?: string | React.ReactNode;

    /* Renders content between the title and the breadcrumb */
    top?: React.ReactNode;
};

export const PageLayout: FC<PageLayoutProps> = (props: PageLayoutProps) => {
    const { content, side, navBar, title, top } = props;
    return (
        <div className={styles['layout']}>
            <div className={styles['layout_navigation']}>{navBar}</div>
            <div
                data-testid="layout-styles"
                className={side ? styles['layout_bottom'] : styles['layout_bottom_no_side']}
            >
                <div
                    className={
                        side
                            ? styles['layout_bottom_content']
                            : styles['layout_bottom_no_side_content']
                    }
                >
                    {top ? <div>{top}</div> : null}
                    {title && typeof title === 'string' ? (
                        <h1 className={styles['layout_title']}>{title}</h1>
                    ) : title ? (
                        <div className={styles['layout_title']}>{title}</div>
                    ) : null}
                    <div>{content}</div>
                </div>
                {side ? <div className={styles['layout_bottom_side']}>{side}</div> : null}
            </div>
        </div>
    );
};

export default PageLayout;
