import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from '../../services';
import api from '../../api';
import { BFFError } from 'api/bff/BFFConnector';
import { RootState } from 'types/store';
import { getAddressComponentsWithNames } from 'lib/utils/addresses';
import { fetchCartById } from 'store/cart';
import { resetPayments } from './paymentMethod.slice';
import { hasMarketplaceProducts } from 'lib/utils/cart';

/**
 * Adds RipleyGiftCard in the payment methods of the cart
 */
export const setPaymentMethodDummy = createAsyncThunk(
    'ripleyGiftCard/setPaymentMethodDummy',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('Datos incompletos para usar Gift Card');
        }

        const cart = state.cart.data;
        const cartId = cart.id;
        const amount = cart.grandTotal['discount'] || cart.grandTotal['master'];

        const payments = setPaymentPayload(
            amount,
            state.user.data,
            state.address.data,
            state.area.data,
            null,
        );

        const isOverwrite = state.app.data?.paymentMode !== 'copago';
        await services.cart.setPaymentOnce(cartId, payments, /*isDummy*/ true, isOverwrite);
        await dispatch(fetchCartById());
    },
);

export type SetPaymentMethodPayload = {
    number: string;
    password: string;
};

const isLongNumber = (giftCard: string): string =>
    giftCard.length === 20 ? 'codigo_barra' : 'numero_tarjeta';
/**
 * Creates a payment of type 'TRE' and configures it in the cart
 */
export const setPaymentMethod = createAsyncThunk(
    'ripleyGiftCard/setPayment',
    async (giftCard: SetPaymentMethodPayload, { getState }) => {
        const encryptedCard = await window.paymenthub.encryptTreCard({
            cardNumber: giftCard.number,
            password: giftCard.password,
            cardNumberType: isLongNumber(giftCard.number),
        });
        if (encryptedCard == null) {
            throw new Error('Error encriptando la Gift Card');
        }

        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('Datos incompletos para consultar Gift Card');
        }
        const cart = state.cart.data;
        const cartId = cart.id;
        const amount = cart.grandTotal['discount'] || cart.grandTotal['master'];

        const payload = setPaymentPayload(
            amount,
            state.user.data,
            state.address.data,
            state.area.data,
            encryptedCard,
        );

        const payments = await services.cart.setPaymentOnce(cartId, payload, false, true);

        const payment = payments.find((p) => p.paymentMethod == 'RipleyGiftCard');

        if (payment == null || payment.id == null || payment.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        const expiryDate = Number(payment.customFields.expiryDate);
        const initialAmount = Number(payment.customFields.initialAmount);
        const availableAmount = Number(payment.customFields.availableAmount);
        const paymentAmount = Number(payment.amount.value);
        const billingType = Number(payment.customFields.billingType);

        // Tipos de TRE:
        // Nota de Venta (billingType = 0)
        // Descuento (billingType = 1)

        // Reglas:
        // Nota de Venta: permite comprar productos Marketplace y Ripley con boleta o factura
        // Descuento: permite comprar solo productos Ripley con boleta (sin factura)

        // En caso que sea TRE descuento con productos marketplace mostramos error
        if (billingType == 1 && hasMarketplaceProducts(state.cart.data.products)) {
            throw new BFFError(
                'errTREBillingType',
                'unknown',
                'Esta Gift Card no puede ser utilizada en productos vendidos por las tiendas asociadas al Marketplace de Ripley.com.',
            );
        }

        // En caso que sea TRE descuento no se puede usar factura
        if (billingType == 1 && payment.documentType === 'factura') {
            throw new Error('Esta Gift Card solo puede ser usada en tiendas fisicas.');
        }

        return {
            cardNumber: giftCard.number,
            expiryDate: expiryDate,
            initialAmount: initialAmount,
            availableAmount: availableAmount,
            paymentAmount: paymentAmount,
            billingType: billingType,
        };
    },
);

/**
 * Creates a payment of type 'TRE' with copago
 */

export const setPaymentWithCopago = createAsyncThunk(
    'ripleyGiftCard/setPaymentCopago',
    async (giftCard: SetPaymentMethodPayload, { getState }) => {
        const encryptedCard = await window.paymenthub.encryptTreCard({
            cardNumber: giftCard.number,
            password: giftCard.password,
            cardNumberType: isLongNumber(giftCard.number),
        });

        if (encryptedCard == null) {
            throw new Error('Error encriptando la Gift Card');
        }

        const state = getState() as RootState;
        if (
            state.cart.data == null ||
            state.user.data == null ||
            state.address.data == null ||
            state.area.data == null
        ) {
            throw new Error('Datos incompletos para consultar Gift Card');
        }

        const cart = state.cart.data;
        const cartId = cart.id;
        const giftCardData = state.paymentMethod.types.ripleyGiftCard.data;

        if (!giftCardData) throw new Error('Datos incompletos para copago');

        const amount = {
            currency: 'CLP',
            value: giftCardData.availableAmount.toString(),
            type: 'DISCOUNT',
        } as Price;

        const payload = setPaymentPayload(
            amount,
            state.user.data,
            state.address.data,
            state.area.data,
            encryptedCard,
        );

        const payments = await services.cart.setPaymentOnce(cartId, payload, false, true);

        const payment = payments.find((p) => p.paymentMethod == 'RipleyGiftCard');

        if (payment == null || payment.id == null || payment.customFields == null) {
            throw new Error('El medio de pago no pudo ser configurado');
        }

        const expiryDate = Number(payment.customFields.expiryDate);
        const initialAmount = Number(payment.customFields.initialAmount);
        const availableAmount = Number(payment.customFields.availableAmount);
        const paymentAmount = Number(payment.amount.value);
        const billingType = Number(payment.customFields.billingType);

        // Tipos de TRE:
        // Nota de Venta (billingType = 0)
        // Descuento (billingType = 1)

        // Reglas:
        // Nota de Venta: permite comprar productos Marketplace y Ripley con boleta o factura
        // Descuento: permite comprar solo productos Ripley con boleta (sin factura)

        // En caso que sea TRE descuento con productos marketplace mostramos error
        if (billingType == 1 && hasMarketplaceProducts(state.cart.data.products)) {
            throw new BFFError(
                'errTREBillingType',
                'unknown',
                'Esta Gift Card no puede ser utilizada en productos vendidos por las tiendas asociadas al Marketplace de Ripley.com.',
            );
        }

        // En caso que sea TRE descuento no se puede usar factura
        if (billingType == 1 && payment.documentType === 'factura') {
            throw new Error('Esta Gift Card solo puede ser usada en tiendas fisicas.');
        }

        return {
            cardNumber: giftCard.number,
            expiryDate: expiryDate,
            initialAmount: initialAmount,
            availableAmount: availableAmount,
            paymentAmount: paymentAmount,
            billingType: billingType,
        };
    },
);

type ChangePasswordPayload = {
    cardNumber: string;
    password: string;
    newPassword: string;
};

export const changePassword = createAsyncThunk(
    'ripleyGiftCard/changePassword',
    async (p: ChangePasswordPayload) => {
        const resp = await api.bff.giftCardChangePassword(p);
        switch (resp.data.code) {
            case 'SUCCESS':
                return;
            case 'INVALID_PASSWORD':
                throw new BFFError(
                    'errTREInvalidPassword',
                    'PaymentMethodCustomAction',
                    resp.data.message,
                );
            default:
                throw new BFFError('errTREChangePasswordUnknown', 'PaymentMethodCustomAction');
        }
    },
);

export const resetPayment = createAsyncThunk(
    'ripleyGiftCard/resetPayment',
    async (_, { dispatch }) => {
        await dispatch(resetPayments());
    },
);

export type RipleyGiftCardData = {
    cardNumber: string;
    expiryDate: number;
    initialAmount: number;
    availableAmount: number;
    paymentAmount: number;
    billingType: number;
};

// status:
// "selected" Especifica si el medio de pago fue seleccionado y agregado al carro (usado para el setPaymentDummy)
// "ok" Especifica si el medio de pago fue inicializado (usado para el setPaymentNoDummy)
export type RipleyGiftCardState = {
    status: 'idle' | 'pending' | 'selected' | 'ok' | 'error';
    data: RipleyGiftCardData | null;
    error: SerializedError | null;
};

export const initialState: RipleyGiftCardState = {
    status: 'idle',
    data: null,
    error: null,
};

export default createSlice({
    name: 'ripleyCard',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPayment.pending, (state: RipleyGiftCardState) => {
            state.status = 'pending';
        });
        builder.addCase(resetPayment.fulfilled, (state: RipleyGiftCardState) => {
            state.status = 'idle';
            state.data = null;
        });
        builder.addCase(resetPayment.rejected, (state: RipleyGiftCardState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(setPaymentMethodDummy.pending, (state: RipleyGiftCardState) => {
            state.status = 'pending';
            state.error = null;
            state.data = null;
        });
        builder.addCase(setPaymentMethodDummy.fulfilled, (state: RipleyGiftCardState) => {
            state.status = 'selected';
            state.error = null;
            state.data = null;
        });
        builder.addCase(setPaymentMethodDummy.rejected, (state: RipleyGiftCardState, { error }) => {
            state.status = 'error';
            state.data = null;
            state.error = error;
        });
        builder.addCase(setPaymentMethod.pending, (state: RipleyGiftCardState) => {
            state.status = 'pending';
            state.error = null;
        });
        builder.addCase(setPaymentMethod.fulfilled, (state: RipleyGiftCardState, { payload }) => {
            state.status = 'ok';
            state.data = payload;
        });
        builder.addCase(setPaymentMethod.rejected, (state: RipleyGiftCardState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(setPaymentWithCopago.pending, (state: RipleyGiftCardState) => {
            state.status = 'pending';
            state.error = null;
        });
        builder.addCase(
            setPaymentWithCopago.fulfilled,
            (state: RipleyGiftCardState, { payload }) => {
                state.status = 'ok';
                state.data = payload;
            },
        );
        builder.addCase(setPaymentWithCopago.rejected, (state: RipleyGiftCardState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
        builder.addCase(changePassword.pending, (state: RipleyGiftCardState) => {
            state.status = 'pending';
        });
        builder.addCase(changePassword.fulfilled, (state: RipleyGiftCardState) => {
            state.status = 'selected';
        });
        builder.addCase(changePassword.rejected, (state: RipleyGiftCardState, { error }) => {
            state.status = 'error';
            state.error = error;
        });
    },
});

export const setPaymentPayload = (
    amount: Price,
    user: NormalizedUser,
    addresses: NormalizedAddresses,
    areas: ExtArea,
    customFields: CustomFields | null,
): PaymentInfo[] => {
    const address = addresses.byId[addresses.selected];

    return [
        {
            documentType: 'boleta',
            paymentMethod: 'RipleyGiftCard',
            billingInfo: {
                customer: {
                    nin: user.nin,
                    firstName: user.firstname,
                    lastName: user.lastname,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                },
                address: getAddressComponentsWithNames(address.addressComponents, areas),
            },
            amount: amount,
            customFields,
        },
    ];
};
