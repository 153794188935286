import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'lib/hooks/redux';
import useWindowSize from 'lib/hooks/useWindowSize';

import Cart, { Skeleton } from 'components/common/Cart';

import { KIOSKO, PE, country } from 'consts';
import { getProducts, getCartTotal, getCartSummary } from './CartContainer.utils';
import { RootState } from 'types/store';

type CartContainerProps = {
    // alert displayed when the user does not have a
    // payment method selected and the terms checked
    alert?: React.ReactNode;
    // checkbox to accept the terms
    terms?: React.ReactNode;
    // button used to confirm and pay
    children?: React.ReactNode;
};

const CartContainer: React.FC<CartContainerProps> = ({
    alert,
    terms,
    children,
}: CartContainerProps) => {
    const { t: trans } = useTranslation();
    const { t: format } = useTranslation('format');

    const [toggle, setToggle] = useState(false);
    const [width] = useWindowSize();

    const cart = useAppSelector((state: RootState) => state.cart);
    const dm = useAppSelector((state: RootState) => state.deliveryMethod);
    const pm = useAppSelector((state: RootState) => state.paymentMethod);
    const isCopago = useAppSelector((state: RootState) => state.app.data?.paymentMode === 'copago');
    const giftCard = pm.types.ripleyGiftCard;

    // We display the skeleton while the data is fetching
    if (!cart.data || !dm.data) {
        return <Skeleton />;
    }

    const products = getProducts(cart.data.products, format);
    const summary = getCartSummary(cart.data, pm.data.selected, dm.selected, format, trans);
    const totals = getCartTotal(cart.data, pm.data.selected, giftCard, format);

    return (
        <Cart
            onToggle={() => (width <= 780 ? setToggle(!toggle) : null)}
            open={width <= 780 ? toggle : true}
            totals={totals}
            products={products}
            alert={alert}
            terms={terms}
            button={children}
            details={summary}
            isCopago={isCopago}
            country={country == PE ? 'pe' : 'cl'}
            isKiosko={cart.data.channel == KIOSKO}
        />
    );
};

export default CartContainer;
