import { PayloadAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'services';
import { RootState } from 'types/store';

import { getAddressComponentValue, getAddressStoreForm } from 'lib/utils/addresses';
import { PickupStoreForm } from 'components/pickup/PickupStore/interface';

import { fetchUser } from 'store/user';
import { selectAddress } from 'store/address/address.slice';
import { separateProducts } from 'store/separatedProducts/separatedProducts.slice';
import { country } from 'consts';

export const fetchStores = createAsyncThunk('store/getStores', async () => {
    const response = await services.store.getStores(country.toLowerCase());
    return response;
});

export const getStocks = createAsyncThunk('store/getStocks', async (_, { getState }) => {
    const state = getState() as RootState;
    return await services.store.getStoresStock(country, {
        region_code: state.store.storeForm.regionId,
        city_code: state.store.storeForm.cityId,
        deliveryMethod: '1', // TODO: understand the possible values.
        products:
            state.cart.data?.products.map((p) => ({
                sku: p.skuPmm || p.sku,
                quantity: p.quantity.toString(),
            })) ?? [],
    });
});

/**
 * Sets stocks status to 'idle' to signal that stocks must be
 * fetched again after changing the pickup location.
 */
export const setStocksIdle = createAction('store/setStocksIdle');

/**
 * Actions used by the LocationForm.
 */
export const setStore = createAction<string>('store/setStore');

export const setProvisionalStore = createAction<string | null>('store/setProvisionalStore');

export const setPickupForm = createAction<PickupStoreForm>('store/setPickupForm');

export type StoreState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: NormalizedStores | null;
    error: SerializedError | null;
    stocks: {
        status: 'idle' | 'pending' | 'ok' | 'error';
        data: NormalizedStoresStock | null;
        error: SerializedError | null;
    };
    storeForm: SearchStoreForm;
    pickupForm: PickupStoreForm;
};

const initialState: StoreState = {
    status: 'idle',
    data: null,
    error: null,
    stocks: {
        status: 'idle',
        data: null,
        error: null,
    },
    pickupForm: {
        checkbox: 'me',
        nin: '',
        ninFormatted: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        phoneNumberFormatted: '',
        userPhoneNumber: '',
        userPhoneNumberFormatted: '',
    },
    storeForm: {
        regionId: '',
        provinceId: '',
        cityId: '',
    },
};

const slice = createSlice({
    name: 'store',
    initialState: initialState,
    reducers: {
        setRegionId(state: StoreState, { payload }) {
            state.storeForm.regionId = payload;
        },
        setProvinceId(state: StoreState, { payload }) {
            state.storeForm.provinceId = payload;
        },
        setCityId(state: StoreState, { payload }) {
            state.storeForm.cityId = payload;
        },
        setStoreForm(state: StoreState, { payload }: PayloadAction<ExternalAddress | undefined>) {
            state.storeForm = getAddressStoreForm(payload);
        },
    },
    extraReducers: (builder) => {
        // Set the initial location from the user address when it loads or changes.
        builder.addCase(fetchUser.fulfilled, (state: StoreState, { payload }) => {
            const address = payload.addresses.byId[payload.addresses.selected];
            if (address != null) {
                state.storeForm.regionId = getAddressComponentValue(address, 'region_code');
                state.storeForm.provinceId = getAddressComponentValue(address, 'district_code');
                state.storeForm.cityId = getAddressComponentValue(address, 'locality_code');
            }
        });
        // Reseting stocks status to force its refresh after address change
        builder.addCase(selectAddress.fulfilled, (state: StoreState, { payload, meta }) => {
            const address = meta.addresses?.byId[payload];
            if (address != null) {
                state.storeForm.regionId = getAddressComponentValue(address, 'region_code');
                state.storeForm.provinceId = getAddressComponentValue(address, 'district_code');
                state.storeForm.cityId = getAddressComponentValue(address, 'locality_code');
            }
            state.stocks.status = 'idle';
        });

        // Selects a store
        builder.addCase(setStore, (state: StoreState, { payload }) => {
            if (state.data) {
                state.data.selected = payload;
            }
        });

        builder.addCase(setProvisionalStore, (state: StoreState, { payload }) => {
            if (state.data) {
                state.data.provisional = payload;
            }
        });

        // Stores
        builder.addCase(fetchStores.pending, (state: StoreState) => {
            state.status = 'pending';
        });
        builder.addCase(fetchStores.fulfilled, (state: StoreState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(fetchStores.rejected, (state: StoreState, action) => {
            state.status = 'error';
            state.error = action.error;
        });

        // Stocks
        builder.addCase(getStocks.pending, (state: StoreState) => {
            state.stocks.status = 'pending';
        });
        builder.addCase(getStocks.fulfilled, (state: StoreState, action) => {
            state.stocks.status = 'ok';
            state.stocks.data = action.payload;
        });
        builder.addCase(getStocks.rejected, (state: StoreState, action) => {
            state.stocks.status = 'error';
            state.stocks.error = action.error;
        });
        builder.addCase(setStocksIdle, (state: StoreState) => {
            state.stocks.status = 'idle';
        });

        // Pickup form
        builder.addCase(setPickupForm, (state: StoreState, { payload }) => {
            state.pickupForm = payload;
        });

        // Invalidate stock after separatedProducts/separeProducts
        builder.addCase(separateProducts.fulfilled, (state: StoreState) => {
            state.stocks.status = 'idle';
        });
    },
});

export const getSelectedStore = (state: RootState): ExtStore | undefined => {
    return state.store?.data?.byId[state.store.data.selected];
};

export const { setRegionId, setProvinceId, setCityId, setStoreForm } = slice.actions;
export default slice;
