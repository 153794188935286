import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'types/store';
import services from '../../services';

export const doCheckout = createAsyncThunk(
    'checkout/doCheckoutProcess',
    async (cartId: string, { getState }) => {
        const state = getState() as RootState;
        const cart = state.cart.data;
        const selectedCard = state.card.byId[state.card.selected];
        const isAlignet = cart?.paymentInfo?.some((p) => p.paymentMethod == 'Alignet');
        const app = state.app.data;

        if (isAlignet) {
            const payment = cart?.paymentInfo?.filter((p) => p.paymentMethod == 'Alignet')[0];

            if (cart?.id && payment?.id)
                return await services.cart.getPaymentStatus(cart.id, payment.id);
        }

        const customFields: CustomFields = {};
        cart?.paymentInfo?.forEach((p) => {
            if (p.paymentMethod == 'Cybersource') {
                customFields['encryptedData'] = selectedCard?.data?.encryptedData;
            }
        });

        if (app?.paymentWithoutCvvEnabled && app?.deviceId) {
            customFields['deviceId'] = app.deviceId;
        }

        customFields['checkoutInit'] = app?.checkoutInit;
        return await services.cart.doCheckout(cartId, customFields);
    },
);

export const alignetCheckout = createAsyncThunk(
    'checkout/alignetCheckout',
    async (cartId: string) => {
        const response = await services.cart.doCheckout(cartId, {});
        return response;
    },
);

export const separateShipping = createAsyncThunk(
    'separatedProducts/separate',
    async (list: SeparatedProduct[], { getState }) => {
        const state = getState() as RootState;
        const cartId = state.cart.data?.id ?? '';
        return await services.cart.deleteProducts(cartId, list);
    },
);

export const resetCheckout = createAction('checkout/reset');

interface CheckoutData {
    [key: string]: string;
}

export type CheckoutState = {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: CheckoutData | null;
    error: SerializedError | null;
};

const initialState: CheckoutState = {
    status: 'idle',
    data: null,
    error: null,
};

export const slice = createSlice({
    name: 'checkout',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(doCheckout.pending, (state: CheckoutState) => {
            state.status = 'pending';
        });
        builder.addCase(doCheckout.fulfilled, (state: CheckoutState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(doCheckout.rejected, (state: CheckoutState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(alignetCheckout.rejected, (state: CheckoutState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(resetCheckout, (state: CheckoutState) => {
            state.data = null;
            state.status = 'idle';
            state.error = null;
        });
    },
});
