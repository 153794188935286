import * as React from 'react';
import styles from './Link.module.scss';
import { ReactComponent as Arrow } from './arrow.svg';
import { useHistory } from 'react-router-dom';

export type LinkProps = {
    type?: 'prev' | 'next';
    to: string | ((event: React.MouseEvent<HTMLAnchorElement>) => void);
    children: React.ReactNode;
    title: string;
    className?: string;
    theme?: 'nav' | 'primary';
    target?: string;
};

const uriRegExp = new RegExp('^(/|https?://)', 'i');

export const Link: React.FC<LinkProps> = (props: LinkProps) => {
    const history = useHistory();

    let computedClassNames = styles['link'];

    /**
     * Add the styles based on 'type' property
     */
    if (props.type) {
        computedClassNames += ' ' + styles[`link--${props.type}`];
    }

    /** Additional styles */
    switch (props.theme) {
        case 'nav':
            computedClassNames += ' ' + styles['link--nav'];
            break;
        case 'primary':
            computedClassNames += ' ' + styles['link--primary'];
            break;
    }

    /**
     * Add the class name received as property
     */
    if (props.className) {
        computedClassNames += ' ' + props.className;
    }

    /**
     * When `to` is an URL the component just create a good old anchor tag.
     * Otherwise it create an <a> that handles redirection as a callback.
     */
    const { to } = props;

    if (typeof to === 'string' && props.type !== 'prev' && uriRegExp.test(to)) {
        return (
            <a
                href={to}
                className={computedClassNames}
                title={props.title}
                target={props.target ?? '_blank'}
            >
                {props.children}
            </a>
        );
    }

    /**
     * Handle the click event
     * @param event
     */
    function handleOnClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        if (props.type === 'prev' && history) {
            history.goBack();
            return false;
        }

        if (typeof to === 'string' && history) {
            history.push(to);
        }
        if (typeof to === 'function') {
            to(event);
        }
        return false;
    }

    return (
        <a href="/" onClick={handleOnClick} className={computedClassNames} title={props.title}>
            {props.type === 'prev' && <Arrow />}
            {props.children}
        </a>
    );
};
