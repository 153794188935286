import React, { createContext, useContext } from 'react';
import Link from 'components/common/Link';
import { CL, KIOSKO, MOBILE, PE } from 'consts';

import styles from './AppLayout.module.scss';
import ayuda from './ayuda.svg';
import whatsapp from './whatsapp.svg';
import ripleyLogo from './logo.svg';

export type LayoutProps = {
    children?: React.ReactNode;
};
import { useTranslation } from 'react-i18next';
import { getEnvironment } from 'config';
export const ChannelContext = createContext('');

/**
 * Desktop layout
 * @param props
 */
export function AppLayout({ children }: LayoutProps): JSX.Element {
    const { app } = getEnvironment();
    const channel = useContext(ChannelContext);
    const sidebarClass = channel == KIOSKO ? ' ' + styles['sidebar'] : '';
    const mobileClass = channel == MOBILE ? ' ' + styles['mobile'] : '';

    const { t } = useTranslation('translation');
    const country = (app.country?.toUpperCase() as Country) || CL;
    const REACT_HELP = country === PE ? app.helpWhatsApp : app.help;

    return (
        <section className={styles['layout'] + sidebarClass + mobileClass}>
            <div className={styles['layout__header'] + sidebarClass + mobileClass}>
                <section>
                    <Link title="ir a R.com" to={app.tiendaUrl} target="_self">
                        <img className={styles['layout__logo']} src={ripleyLogo} alt="Ripley" />
                    </Link>
                    <Link to={REACT_HELP || ''} title="Ayúdanos a mejorar" data-testid="helpLink">
                        <img
                            className={styles['layout__ayuda']}
                            data-testid="Ayuda"
                            src={country === PE ? whatsapp : ayuda}
                            alt="Ayuda"
                        />
                        {t('help')}
                        <span className={styles['layout__numberWhastapp']}>
                            &nbsp;{t('helpWhastapp')}
                        </span>
                    </Link>
                </section>
            </div>
            <div className={styles['layout__content']}>{children}</div>
            <div className={styles['layout__footer'] + mobileClass}></div>
        </section>
    );
}

export default AppLayout;
