import { createSlice } from '@reduxjs/toolkit';
import { DateLimit } from '../../components/common/Clock';

type OrderSummaryState = {
    dateLimit: DateLimit;
};

const initialState: OrderSummaryState = {
    dateLimit: {
        minutes: '',
        hours: '',
        day: '',
        month: '',
        year: '',
    } as DateLimit,
};

const slice = createSlice({
    name: 'orderSummary',
    initialState: initialState,
    reducers: {
        setDateLimit: (state, action) => {
            state.dateLimit = action.payload;
        },
    },
});

export default slice;

export const { setDateLimit } = slice.actions;
