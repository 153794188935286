import * as React from 'react';
import styles from './LocationForm.module.scss';
import Select from '../../common/Select';
import { useTranslation } from 'react-i18next';

const labels: { [char: string]: string } = {
    Region: 'Región',
    Departamento: 'Departamento',
    Provincia: 'Provincia',
    Comuna: 'Comuna',
    Distrito: 'Distrito',
};

const selectLabels: { [level: string]: string } = {
    Region: 'locationFormSelectRegion',
    Departamento: 'locationFormSelectRegion',
    Provincia: 'locationFormSelectProvince',
    Comuna: 'locationFormSelectCity',
    Distrito: 'locationFormSelectCity',
};

export type LocationFormProps = {
    /**
     * Defines the territorial divitions of a country, and the list of
     * selectable divitions for the *currently selected* divition one level
     * above
     */
    levels: AddressLevel[];
    /**
     * Callback triggered when the smallest level is changed. Should change the
     * list of selectable stores
     */
    onSelect?: (level: AddressLevel) => (value: string) => void;
    /**
     * Callback triggered when an upper level is changed. Should change the
     * options of the next lower territorial level
     */
    onChange?: (level: AddressLevel) => (value: string) => void;
};

export const LocationForm: React.FC<LocationFormProps> = ({
    levels,
    onChange,
    onSelect,
}: LocationFormProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles['address-form']}>
            <h2 className={styles['title']}>{t('locationFormTitle')}</h2>
            <form className={styles['form']} onSubmit={(ev) => ev.preventDefault()}>
                {levels.map((level, index) => (
                    <Select
                        key={level.name}
                        label={labels[level.label]}
                        id={level.name}
                        onChange={
                            index === levels.length - 1 ? onSelect?.(level) : onChange?.(level)
                        }
                        value={level.selected}
                        options={level.options.map(({ code, name }) => ({
                            value: code,
                            label: name,
                        }))}
                        empty={`${t(selectLabels[level.label])}`}
                        disabled={level.disabled}
                    />
                ))}
            </form>
        </div>
    );
};

export interface AddressLevel {
    name: string;
    label: string;
    options: AddressComponent[];
    selected: string;
    disabled?: boolean;
}

export interface AddressComponent {
    code: string;
    name: string;
}
