import React, { useContext, useEffect, useState } from 'react';
import PaymentMethodItem from 'components/payment/PaymentMethodItem';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { setPaymentMethod as setNiubiz3ds } from 'store/paymentMethod/niubiz3ds.slice';
import { useTranslation } from 'react-i18next';
import { ErrorContext } from 'containers/aio/PaymentMethodsContainer/context';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';

/**
 * Niubiz3dsSelector shows the option to select Niubiz3Ds as payment method
 * @returns
 */
export const Niubiz3dsSelector: React.FC = () => {
    const { t } = useTranslation('error');
    const dispatch = useAppDispatch();
    const ctx = useContext(ErrorContext);
    const payments = useAppSelector((state) => state.paymentMethod);
    const delivery = useDeliveryMethod();
    const isCouponLoading = useAppSelector(
        (state) => state.coupon.status === 'pending' || state.cart.status !== 'ok',
    );
    const isSelected = payments.data.selected.includes('Niubiz3DS');
    const isLoading = payments.data.loading.includes('Niubiz3DS');
    const isBlocked =
        (payments.data.status === 'pending' && !isLoading) ||
        isCouponLoading ||
        !delivery.hasSchedules();
    const [openCollapsible, setOpenCollapsible] = useState<boolean>(false);

    useEffect(() => {
        if (!isSelected) {
            setOpenCollapsible(false);
        }
    }, [isSelected]);

    async function setNiubiz3dsPayment() {
        try {
            if (!openCollapsible) {
                setOpenCollapsible(!openCollapsible);
                await dispatch(setNiubiz3ds()).unwrap();
            } else {
                setOpenCollapsible(false);
            }
        } catch (e) {
            ctx.setError(t('defaultPaymentError.message'));
        }
    }

    return (
        <>
            <PaymentMethodItem
                paymentMethodName="Yape"
                type="yape"
                widthIcon="50px"
                onClick={setNiubiz3dsPayment}
                selected={isSelected}
                blocked={isBlocked}
                isLoading={isLoading}
                haveCollapse={true}
                openCollapsible={openCollapsible}
            />
        </>
    );
};
