import React from 'react';

import AddressItem, { Address } from 'components/aio/AddressItem';
import styles from './AddressList.module.scss';
import Skeleton from 'components/common/Skeleton';

export interface Item extends Address {
    loading?: boolean;
}

export type List = Item[];

export type AddressListProps = {
    /**
     * List of addresses saved by the current user
     */
    list: List;
    /**
     * Callback to change the address
     */
    onChange: (addressId: string) => void;
    /**
     * Callback to delete an address
     */
    onDelete: (addressId: string) => void;
    /**
     * Selected address id
     */
    onEdit: (itemId: string) => void;
    selectedAddress: string;
};

export const AddressList = ({
    list,
    selectedAddress,
    onChange,
    onDelete,
    onEdit,
}: AddressListProps): JSX.Element => {
    return (
        <div className={styles['section']} data-testid="address-list">
            <ul className={styles['list']}>
                {list.map((item) => (
                    <li className={styles['item']} key={item.id}>
                        {item.loading ? (
                            <Skeleton />
                        ) : (
                            <AddressItem
                                {...item}
                                checked={selectedAddress === item.id}
                                onChange={onChange}
                                onEdit={() => {
                                    onEdit(item.id);
                                }}
                                onDelete={list.length > 1 ? onDelete : null}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};
