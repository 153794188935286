import React from 'react';
import { useAppSelector } from 'lib/hooks/redux';
import Spinner from 'components/common/Spinner';
import { FullCard } from './FullCard';
import { IncompleteCard } from './IncompleteCard';
import { useDeliveryMethod } from 'lib/hooks/deliveryMethod';

export function CardSelector(): JSX.Element {
    const dm = useDeliveryMethod();
    const card = useAppSelector((state) => state.card.byId[state.card.selected]);
    const tokens = useAppSelector((state) => state.cardToken.data);
    const incompleteCards = tokens.selected.map((c) => {
        const card = tokens.byId[c];
        return <IncompleteCard key={card?.id} card={card} />;
    });

    if (!dm.hasSchedules()) {
        return <></>;
    }
    let fullCards: React.ReactNode = null;
    if (card?.status === 'pending') {
        fullCards = <Spinner margin="1em" />;
    }
    if (card?.data != null) {
        fullCards = <FullCard card={card.data} />;
    }
    return (
        <>
            {fullCards}
            {incompleteCards}
        </>
    );
}
