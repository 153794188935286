import React, { useEffect, useState } from 'react';

import AlertBadge from 'components/common/AlertBadge';
import ScheduleList from 'components/aio/ScheduleList';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';

import { getShippingDates, getShippingDescription } from 'lib/utils/schedules';
import { useTranslation } from 'react-i18next';

import { fetchCartById } from 'store/cart';
import { selectSchedule, setSchedule } from 'store/shipping';
import { useAppSelector, useAppDispatch } from 'lib/hooks/redux';
import { resetPayments } from 'store/paymentMethod/actions';
import { RootState } from 'types/store';

type ShippingSelectorContainerProps = {
    isOpen: boolean;
    groupId: string;
    serviceGroup: string;
    onToggle: () => void;
};

export const ShippingSelectorContainer: React.FC<ShippingSelectorContainerProps> = ({
    isOpen,
    groupId,
    serviceGroup,
    onToggle,
}: ShippingSelectorContainerProps) => {
    const { t } = useTranslation('format');
    const { t: terror } = useTranslation('error');

    const dispatch = useAppDispatch();
    const shipping = useAppSelector((state: RootState) => state.shipping.data);
    const group = shipping?.groups.byId[groupId];
    const selectedId = shipping?.selectedSchedules[groupId] || '';

    const [temporalSelectedId, setTemporalSelectedId] = useState(selectedId);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTemporalSelectedId(selectedId);
    }, [selectedId]);

    /**
     * Handles the change of schedules
     */
    function handleChangeSchedule() {
        (async () => {
            if (temporalSelectedId !== selectedId) {
                try {
                    setLoading(true);
                    dispatch(selectSchedule({ groupId, scheduleId: temporalSelectedId }));
                    await dispatch(setSchedule()).unwrap();
                    await dispatch(resetPayments()).unwrap();
                    await dispatch(fetchCartById()).unwrap();
                    setLoading(false);
                    onToggle();
                } catch (e) {
                    setLoading(false);
                    dispatch(selectSchedule({ groupId, scheduleId: selectedId }));
                    setError(terror('errSelectSchedule.message'));
                }
            } else {
                onToggle();
            }
        })();
    }

    const schedules =
        group?.schedules
            .filter((schedule) => schedule.serviceGroup == serviceGroup)
            .sort((a, b) => Date.parse(a.latestDate) - Date.parse(b.latestDate))
            .map((schedule) => {
                const [closestDate, latestDate] = getShippingDates(
                    schedule.closestDate,
                    schedule.latestDate,
                );

                return {
                    id: schedule.id,
                    description: getShippingDescription(
                        schedule.deliveryMethod,
                        closestDate,
                        latestDate,
                        schedule.typeOfService ?? '',
                        schedule.typeOfServiceDescription ?? '',
                    ),
                    price: t('amount', { value: schedule.price.value }),
                    selected: schedule.id == temporalSelectedId,
                };
            }) || [];

    return (
        <Modal
            isOpen={isOpen}
            onToggle={() => !loading && onToggle()}
            header="Modificar despacho"
            footer={
                <React.Fragment>
                    {error && (
                        <AlertBadge theme="danger" timeout={5000} callback={() => setError(null)}>
                            {error}
                        </AlertBadge>
                    )}

                    <Button
                        theme="primary"
                        size="large"
                        disabled={loading}
                        loading={loading}
                        onClick={handleChangeSchedule}
                    >
                        Guardar
                    </Button>
                </React.Fragment>
            }
        >
            <ScheduleList schedules={schedules} onClick={(id) => setTemporalSelectedId(id)} />
        </Modal>
    );
};
