import { AddressLevel } from 'components/pickup/LocationForm/LocationForm';
import { AddressFormData, SelectOptions } from 'components/aio/AddressForm';
import { capitalize } from 'lib/utils/strings';
import { CL, country, PE } from 'consts';
import { AnyObject, object, ObjectSchema, string, StringSchema } from 'yup';
import { Option } from 'components/common/Select';

/**
 * * the responsibility is return value of address component
 * @param selectedAddress address that's used
 * @param key identifier for address
 * @returns Array with key-value
 **/
export const getAddressComponentValue = (selectedAddress: ExternalAddress, key: string): string => {
    return (
        selectedAddress.addressComponents.find(
            (addressComponents: ExternalAddressComponents) => addressComponents.key === key,
        )?.value || ''
    );
};

/** Returns the value of component required */
export const getStoreComponentValue = (selectedStore: ExtStore, key: string): string => {
    return (
        selectedStore.location.find(
            (storeComponents: ExternalAddressComponents) => storeComponents.key === key,
        )?.value || ''
    );
};

/** Gets city name by matching a city ID with an area */
export const getCityName = (
    areas: ExtArea | null,
    regionId: string,
    districtId: string,
    cityId: string,
): string => {
    switch (areas?.id) {
        case 'CL':
            return (
                areas?.subAreas
                    ?.find((region) => region.id === regionId)
                    ?.subAreas?.find((city) => city.id === cityId)?.name || ''
            );
        case 'PE':
            return (
                areas?.subAreas
                    ?.find((region) => region.id === regionId)
                    ?.subAreas?.find((province) => province.id === districtId)
                    ?.subAreas?.find((city) => city.id === cityId)?.name || ''
            );
        default:
            return '';
    }
};

export const getAddressCityName = (address: ExternalAddress, areas: ExtArea | null): string => {
    const cityId = getAddressComponentValue(address, 'locality_code');
    const regionId = getAddressComponentValue(address, 'region_code');
    const districtId = getAddressComponentValue(address, 'district_code');
    return getCityName(areas, regionId, districtId, cityId);
};

export const getProvinceName = (
    provinceId: string,
    regionId: string,
    areas: ExtArea | null,
): string => {
    return (
        areas?.subAreas
            ?.find((region) => region.id === regionId)
            ?.subAreas?.find((province) => province.id === provinceId)?.name || ''
    );
};

export const getAddressProvinceName = (address: ExternalAddress, areas: ExtArea | null): string => {
    if (country !== PE) {
        return '';
    }
    const regionId = getAddressComponentValue(address, 'region_code');
    const provinceId = getAddressComponentValue(address, 'district_code');
    return getProvinceName(provinceId, regionId, areas);
};

export const getProvinceNameForStore = (stores: ExtStore, areas: ExtArea | null): string => {
    const regionId = getStoreComponentValue(stores, 'region_code');
    const provinceId = getStoreComponentValue(stores, 'district_code');
    return getProvinceName(provinceId, regionId, areas);
};

export const getRegionName = (regionId: string, areas: ExtArea | null): string => {
    return areas?.subAreas?.find((region) => region.id === regionId)?.name || '';
};

export const getAddressRegionName = (address: ExternalAddress, areas: ExtArea | null): string => {
    const regionId = getAddressComponentValue(address, 'region_code');
    return getRegionName(regionId, areas);
};

export const getRegionNameForStore = (stores: ExtStore, areas: ExtArea | null): string => {
    const regionId = getStoreComponentValue(stores, 'region_code');
    return getRegionName(regionId, areas);
};

export const getAddressComponentsWithNames = (
    addressComponents: ExternalAddressComponents[],
    areas: ExtArea | null,
): ExternalAddressComponents[] => {
    const regionId = addressComponents.find((ac) => ac.key === 'region_code')?.value || '';
    const districtId = addressComponents.find((ac) => ac.key === 'district_code')?.value || '';
    const cityId = addressComponents.find((ac) => ac.key === 'locality_code')?.value || '';

    const regionName = getRegionName(regionId, areas);
    const districtName = getProvinceName(districtId, regionId, areas);
    const cityName = getCityName(areas, regionId, districtId, cityId);

    return [
        ...addressComponents,
        { key: 'region', value: regionName },
        { key: 'city', value: cityName },
        { key: 'district', value: districtName },
    ];
};

// TODO: mover a consts
export const RM = '13';
export const STGO_COMUNE = '13101';

export const LIMA_DEPTO = '15';
export const LIMA_PROV = '1501';
export const LIMA_DISTRICT = '150101';

export const getLevels = (
    areas: ExtArea | null,
    regionId: string | undefined,
    provinceId: string | undefined,
    cityId: string | undefined,
): AddressLevel[] => {
    const country = areas?.id;
    const levels: AddressLevel[] = [];
    const DEFAULT_REGION = country === CL ? RM : LIMA_DEPTO;
    const DEFAULT_PROVINCE = LIMA_PROV;
    if (areas?.subAreas) {
        const label = areas.subAreas[0].type;
        const region = areas.subAreas.find((a) => a.id === regionId);
        const defaultRegion = areas.subAreas.find((a) => a.id == DEFAULT_REGION);

        if (defaultRegion) {
            // Fill first level.
            levels.push({
                name: label,
                label: capitalize(label),
                selected: regionId || '',
                disabled: false,

                // Filter out the default region to add it later down below.
                options: areas.subAreas
                    .filter((a) => a.id !== DEFAULT_REGION)
                    .map(({ id, name }) => ({ code: id, name })),
            });

            // Add default region at the beginning of the list.
            levels[0].options.unshift({ code: defaultRegion.id, name: defaultRegion.name });

            // Fill second level options.
            if (defaultRegion.subAreas) {
                let regions =
                    region?.subAreas
                        ?.map(({ id, name }) => ({ code: id, name }))
                        .sort((a, b) => a.name.localeCompare(b.name)) || [];
                const levelId = country === CL ? cityId : provinceId;

                if (regionId === RM) {
                    const defaultSubArea = regions.find((area) => area.code === STGO_COMUNE);
                    regions = regions.filter((area) => area.code !== STGO_COMUNE);
                    regions.unshift({
                        code: defaultSubArea?.code as string,
                        name: defaultSubArea?.name as string,
                    });
                }

                if (regionId === LIMA_DEPTO) {
                    const defaultSubArea = regions.find((area) => area.code === LIMA_PROV);
                    regions = regions.filter((area) => area.code !== LIMA_PROV);
                    regions.unshift({
                        code: defaultSubArea?.code as string,
                        name: defaultSubArea?.name as string,
                    });
                }

                levels.push({
                    name: defaultRegion.subAreas[0].type,
                    label: capitalize(defaultRegion.subAreas[0].type),
                    options: regions,
                    selected: levelId || '',
                    disabled: regions.length === 0 ? true : false,
                });

                // Fill third level options for Peru.
                if (country === PE) {
                    const defaultProvince = defaultRegion.subAreas.find(
                        (a) => a.id === DEFAULT_PROVINCE,
                    );

                    if (defaultProvince && defaultProvince.subAreas) {
                        const province = region?.subAreas?.find((a) => a.id === provinceId);
                        let districts =
                            province?.subAreas
                                ?.map(({ id, name }) => ({ code: id, name }))
                                .sort((a, b) => a.name.localeCompare(b.name)) || [];

                        if (levelId === LIMA_PROV) {
                            const defaultDistrict = districts.find(
                                (area) => area.code === LIMA_DISTRICT,
                            );
                            districts = districts.filter((area) => area.code !== LIMA_DISTRICT);
                            districts.unshift({
                                code: defaultDistrict?.code as string,
                                name: defaultDistrict?.name as string,
                            });
                        }

                        levels.push({
                            name: defaultProvince.subAreas[0].type,
                            label: capitalize(defaultProvince.subAreas[0].type),
                            options: districts,
                            selected: cityId || '',
                            disabled: districts.length === 0 ? true : false,
                        });
                    }
                }
            }
        }
    }

    return levels;
};

export const initialValuesFormAddress: AddressFormValues = {
    id: '',
    streetName: '',
    streetNumber: '',
    homeNumber: '',
    phoneNumber: '',
    phoneNumberFormatted: '',
    customMessage: '',
    nickname: '',
    city: '',
    cityCode: '',
    district: '',
    districtCode: '',
    region: '',
    regionCode: '',
    isModifying: false,
};

export const fieldsMaxLength: { [key: string]: number } = {
    streetName: 37,
    streetNumber: 5,
    homeNumber: 5,
    nickname: 50, // TODO: confirm with Customer Engine
};

export const fieldsMinLength: { [key: string]: number } = {
    streetName: 3,
    streetNumber: 1,
    homeNumber: 1,
    nickname: 3,
};

export const requiredFields = ['streetName', 'streetNumber'];

export const fieldsErrorTexts = (field: string, value: string): string => {
    if (requiredFields.includes(field) && value === '') {
        return 'Este campo es requerido.';
    }
    return `Debes ingresar entre ${fieldsMinLength[field]} y ${fieldsMaxLength[field]} caracteres.`;
};

/** Adapts an ExternalAddress to AddressFormValues for editing an address. */
export const loadFormAddress = (
    selectedAddress: ExternalAddress,
    formAddress: AddressFormValues,
    areas: ExtArea | null,
    isModifying: boolean,
): AddressFormValues => {
    const city = getAddressCityName(selectedAddress, areas);
    const province = getAddressProvinceName(selectedAddress, areas);
    const region = getAddressRegionName(selectedAddress, areas);

    return {
        ...formAddress,
        id: getAddressComponentValue(selectedAddress, 'addressId'),
        nickname: getAddressComponentValue(selectedAddress, 'nickname'),
        streetName: getAddressComponentValue(selectedAddress, 'streetName'),
        streetNumber: getAddressComponentValue(selectedAddress, 'streetNumber'),
        homeNumber: getAddressComponentValue(selectedAddress, 'homeNumber'),
        phoneNumber: getAddressComponentValue(selectedAddress, 'phoneNumber'),
        customMessage: getAddressComponentValue(selectedAddress, 'customMessage'),
        regionCode: getAddressComponentValue(selectedAddress, 'region_code'),
        districtCode: getAddressComponentValue(selectedAddress, 'district_code'),
        cityCode: getAddressComponentValue(selectedAddress, 'locality_code'),
        region,
        district: province,
        city,
        isModifying,
    };
};

export const validateFormFieldOnLoad = (field: string, value: string): boolean => {
    switch (field) {
        case 'nickname':
            return !!value;
        case 'streetName':
            return !!value;
        case 'streetNumber':
            return !!value;
        case 'homeNumber':
            return true;
        case 'region':
            return !!value;
        case 'regionCode':
            return !!value;
        case 'district':
            return !!value;
        case 'districtCode':
            return !!value;
        case 'city':
            return !!value;
        case 'cityCode':
            return !!value;
        case 'phoneNumber':
            // TODO: Confiamos si viene un numero, va a pasar aguas abajo OK.
            return !!value;
        case 'phoneNumberFormatted':
            return true;
        case 'customMessage':
            return true;
        default:
            return false;
    }
};

// TODO: Use react-hook-form or something.
export const validateFormField = (field: string, value: string): boolean => {
    switch (field) {
        case 'nickname':
            return (
                value.length >= fieldsMinLength.nickname && value.length <= fieldsMaxLength.nickname
            );
        case 'streetName':
            return (
                value.length >= fieldsMinLength.streetName &&
                value.length <= fieldsMaxLength.streetName
            );
        case 'streetNumber':
            return (
                value.length >= fieldsMinLength.streetNumber &&
                value.length <= fieldsMaxLength.streetNumber
            );
        case 'homeNumber':
            return value ? value.length <= fieldsMaxLength.homeNumber : true;
        case 'region':
            return !!value;
        case 'regionCode':
            return !!value;
        case 'district':
            return !!value;
        case 'districtCode':
            return !!value;
        case 'city':
            return !!value;
        case 'cityCode':
            return !!value;
        case 'phoneNumber':
            return value.length === 9;
        case 'phoneNumberFormatted':
            return true;
        case 'customMessage':
            return true;
        default:
            return false;
    }
};

export const validateForm = (
    f: AddressFormValues,
    termsChecked: boolean,
    user?: NormalizedUser | null,
): boolean => {
    if (country === PE && !termsChecked) {
        return false;
    }
    for (const [field, value] of Object.entries(f)) {
        // Exclude a couple of fields from validation.
        if (field === 'id' || typeof value === 'boolean') {
            continue;
        }
        if (!validateFormField(field, value)) {
            if ((field === 'district' || field == 'districtCode') && country !== PE) {
                continue;
            }
            if (field === 'nickname' && !user?.registered) {
                continue;
            }
            return false;
        }
    }
    return true;
};

export const validateFormOnLoad = (
    f: AddressFormValues,
    termsChecked: boolean,
    user?: NormalizedUser | null,
): boolean => {
    if (country === PE && !termsChecked) {
        return false;
    }
    for (const [field, value] of Object.entries(f)) {
        // Exclude a couple of fields from validation.
        if (field === 'id' || typeof value === 'boolean') {
            continue;
        }
        if (!validateFormFieldOnLoad(field, value)) {
            if ((field === 'district' || field == 'districtCode') && country !== PE) {
                continue;
            }
            if (field === 'nickname' && !user?.registered) {
                continue;
            }
            return false;
        }
    }
    return true;
};

/**
 * Yup Validation Schemas
 */

/**
 * Construct the error message for the validation scheme
 * @param type Indicates the type of validation
 * @param limit Indicates the limit of characters
 */
const getErrorMsg = (type: 'len' | 'max' | 'min' | 'req', limit?: number): string => {
    return {
        len: `Debes ingresar exactamente ${limit} caracteres`,
        max: `Debes ingresar máximo ${limit} caracteres`,
        min: `Debes ingresar mínimo ${limit} caracteres`,
        req: 'Este campo es requerido.',
    }[type];
};

const getAddressFormValidationSchema = (
    country: string,
    isRegistered: boolean,
): ObjectSchema<AddressFormData> => {
    const districtCode = {
        CL: string(),
        PE: string().required(getErrorMsg('req')),
    }[country] as StringSchema<string | undefined, AnyObject, undefined, ''>;

    const nickname = isRegistered
        ? string().required(getErrorMsg('req')).max(30, getErrorMsg('max', 30))
        : string();

    return object({
        streetName: string()
            .required(getErrorMsg('req'))
            .min(3, getErrorMsg('min', 3))
            .max(37, getErrorMsg('max', 37))
            .transform((value) => value.trim()),
        streetNumber: string()
            .required(getErrorMsg('req'))
            .max(5, getErrorMsg('max', 5))
            .transform((value) => value.trim()),
        homeNumber: string()
            .max(5, getErrorMsg('max', 5))
            .transform((value) => value.trim()),
        phoneNumber: string()
            .required(getErrorMsg('req'))
            .transform((value) => {
                return value.replace(/-/g, '').replace(/_/g, '').trim();
            })
            .length(9, getErrorMsg('len', 9)),
        regionCode: string()
            .required(getErrorMsg('req'))
            .transform((value) => value.trim()),
        districtCode: districtCode?.trim(),
        localityCode: string()
            .required(getErrorMsg('req'))
            .transform((value) => value.trim()),
        region: string().transform((value) => value.trim()),
        district: string().transform((value) => value.trim()),
        locality: string().transform((value) => value.trim()),
        customMessage: string()
            .max(47, getErrorMsg('max', 47))
            .transform((value) => value.trim()),
        nickname: nickname?.trim(),
    }) as ObjectSchema<AddressFormData>;
};

/** Registered User Validation Schema of CL */
export const registeredUserValidationSchema: ObjectSchema<AddressFormData> =
    getAddressFormValidationSchema(country, true);

/** Guest User Validation Schema of CL */
export const guestUserValidationSchema: ObjectSchema<AddressFormData> =
    getAddressFormValidationSchema(country, false);

export const getAddressStoreForm = (address: ExternalAddress | undefined): SearchStoreForm => {
    if (address == null) {
        return { regionId: '', provinceId: '', cityId: '' };
    }
    const cityId = getAddressComponentValue(address, 'locality_code');
    const provinceId = getAddressComponentValue(address, 'district_code');
    const regionId = getAddressComponentValue(address, 'region_code');
    return { regionId, provinceId, cityId };
};
/**
 * Return true if the address is valid, false if invalid
 * @param country
 * @param addressComponents
 */
export const validateAddress = (
    country: 'CL' | 'PE',
    addressComponents: ExternalAddressComponents[],
): boolean => {
    // Necesary fields of an address to be valid in CL
    const clFields = [
        'region_code',
        'locality_code',
        'district_code',
        'streetName',
        'streetNumber',
        'phoneNumber',
    ];
    // Necesary fields of an address to be valid in PE
    const peFields = ['region_code', 'locality_code', 'streetName', 'streetNumber', 'phoneNumber'];
    const necesaryFieldsToBeValid = {
        CL: clFields,
        PE: peFields,
    }[country];
    // For each necesary field check if that field is empty
    // if it is  empty return false, otherwise return true
    for (const field in necesaryFieldsToBeValid) {
        if (isAddressComponentEmpty(addressComponents, field)) {
            return false;
        }
    }
    return true;
};

/**
 * Find an ExternalAddressComponent in an array of ExternalAddressCompoenets.
 * If the ExternalAddressComponent is empty return false
 * @param externalAddressComponents
 * @param componentKey
 */
export const isAddressComponentEmpty = (
    externalAddressComponents: ExternalAddressComponents[],
    componentKey: string,
): boolean => {
    // Find the component by key
    // Extract the value
    // Check the lenght
    // Doble exclamation to convert to boolean. if length is 0 false, anything else is true
    return !!externalAddressComponents.find((c) => c.key === componentKey)?.value.length;
};

/**
 * Gets the payload to pass to modifyAddress action
 * @param user
 * @param formData
 * @param areas
 * @param id
 */
export const getCreateAddressPayload = (
    user: NormalizedUser,
    formData: AddressFormData,
    areas: ExtArea | null,
    id?: string,
): ExternalAddressComponents[] => {
    const addressComponentsNoNames = [
        { key: 'id', value: id ?? '' },
        { key: 'isPrimary', value: 'false' },
        { key: 'nickname', value: formData.nickname ?? '' }, // It can come empty depending on whether the user is a registered user or a guest user
        { key: 'country', value: country },
        { key: 'region_code', value: formData.regionCode },
        { key: 'district_code', value: formData.districtCode ?? '' }, // It can come empty depending on whether the country is PE or CL
        { key: 'locality_code', value: formData.localityCode },
        { key: 'region', value: formData.region ?? '' },
        { key: 'district', value: formData.district ?? '' },
        { key: 'locality', value: formData.locality ?? '' },
        { key: 'streetName', value: formData.streetName },
        { key: 'streetNumber', value: formData.streetNumber },
        { key: 'homeNumber', value: formData.homeNumber ?? '' }, // It can come empty since it is an optional field
        { key: 'customMessage', value: formData.customMessage ?? '' }, // It can come empty since it is an optional field
        { key: 'phoneNumber', value: formData.phoneNumber },
        { key: 'firstName', value: user.firstname },
        { key: 'lastName', value: user.lastname },
        { key: 'email', value: user.email },
    ];
    const addressComponentsWithNames = getAddressComponentsWithNames(
        addressComponentsNoNames,
        areas,
    );
    return addressComponentsWithNames;
};

export const getSelectOptions = (
    areas: ExtArea | null,
    selectedRegionCode?: string,
    selectedDistrictCode?: string,
): SelectOptions => {
    const selectOptions = {} as SelectOptions;

    // find the selected region area
    const selectedRegionArea = areas?.subAreas?.find((region) => region.id === selectedRegionCode);

    // find the selected district area
    const selectedDistrictArea = selectedRegionArea?.subAreas?.find(
        (district) => district.id === selectedDistrictCode,
    );

    // builds the options for region
    const regions: Option[] =
        areas?.subAreas
            ?.map((region) => {
                return { label: region.name, value: region.id } as Option;
            })
            .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    // builds the options for districts
    let districts: Option[] =
        selectedRegionArea?.subAreas
            ?.map((district) => {
                return { label: district.name, value: district.id } as Option;
            })
            .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    if (areas?.id === PE && selectedRegionArea?.id === LIMA_DEPTO) {
        const defaultProvince = districts.find((area) => area.value === LIMA_PROV);
        districts = districts.filter((area) => area.value !== LIMA_PROV);
        districts.unshift({
            label: defaultProvince?.label,
            value: defaultProvince?.value,
        } as Option);
    }

    //builds the options for localities
    let localities: Option[] = [];

    if (areas?.id == CL) {
        //In CL, localities are filtered by region and in PE by district
        localities =
            selectedRegionArea?.subAreas
                ?.map((locality) => {
                    return { label: locality.name, value: locality.id } as Option;
                })
                .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

        if (selectedRegionArea?.id === RM) {
            const defaultComune = localities.find((area) => area.value === STGO_COMUNE);
            localities = localities.filter((area) => area.value !== STGO_COMUNE);
            localities.unshift({
                label: defaultComune?.label,
                value: defaultComune?.value,
            } as Option);
        }
    } else {
        localities =
            selectedDistrictArea?.subAreas
                ?.map((locality) => {
                    return { label: locality.name, value: locality.id } as Option;
                })
                .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

        if (selectedDistrictArea?.id === LIMA_PROV) {
            const defaultDisctrict = localities.find((area) => area.value === LIMA_DISTRICT);
            localities = localities.filter((area) => area.value !== LIMA_DISTRICT);
            localities.unshift({
                label: defaultDisctrict?.label,
                value: defaultDisctrict?.value,
            } as Option);
        }
    }

    selectOptions.regions = regions;
    selectOptions.districts = districts;
    selectOptions.localities = localities;
    return selectOptions;
};
