import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import services from '../../services';

export type CouponPayload = {
    cartId: string;
    prevCoupons: string[];
    coupon: string;
};

export const addCoupon = createAsyncThunk('coupon/addCoupon', async (payload: CouponPayload) => {
    const response = await services.cart.addCoupon(
        payload.cartId,
        payload.prevCoupons,
        payload.coupon,
    );
    return response;
});

/**
 * Pass previous coupons and the coupon to delete
 */
export const deleteCoupon = createAsyncThunk(
    'coupon/deleteCoupon',
    async (payload: CouponPayload) => {
        const response = await services.cart.deleteCoupon(
            payload.cartId,
            payload.prevCoupons,
            payload.coupon,
        );
        return response;
    },
);

export interface CouponState {
    status: 'idle' | 'pending' | 'ok' | 'error';
    data: string[] | null;
    error: SerializedError | null;
}

const initialState: CouponState = {
    status: 'idle',
    data: null,
    error: null,
};

const slice = createSlice({
    name: 'coupons',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addCoupon.pending, (state: CouponState) => {
            state.status = 'pending';
        });
        builder.addCase(addCoupon.fulfilled, (state: CouponState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(addCoupon.rejected, (state: CouponState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
        builder.addCase(deleteCoupon.pending, () => {});
        builder.addCase(deleteCoupon.fulfilled, (state: CouponState, action) => {
            state.status = 'ok';
            state.data = action.payload;
        });
        builder.addCase(deleteCoupon.rejected, (state: CouponState, action) => {
            state.status = 'error';
            state.error = action.error;
        });
    },
});

export default slice;
