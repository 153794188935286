import { Option } from 'components/common/Select';
import { capitalize } from 'lib/utils/strings';

export const getInstallmentOptions = (installments: unknown): Option[] => {
    if (!Array.isArray(installments)) {
        return [];
    }

    return installments.map((n) => {
        const value = n.toString(10);
        return { value, label: n <= 1 ? 'Sin cuotas' : `${value} cuotas` };
    });
};

export const getDeferredOptions = (deferred: Deferred[]): Option[] => {
    if (deferred.length < 1) {
        return [];
    }
    return [{ value: '0', label: 'No' }].concat(
        deferred.map(({ months }) => {
            const d = new Date();
            d.setMonth(d.getMonth() + months);
            return {
                value: months.toString(10),
                label: capitalize(d.toLocaleString('es', { month: 'long' })),
            };
        }),
    );
};
