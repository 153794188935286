import React, { FC, useState, SetStateAction, Dispatch } from 'react';
import Button from '../Button';
import PasswordValidator from '../PasswordValidator';
import Terms from '../Terms';
import TextInput from '../TextInput';
import { Info } from 'containers/RegisterUser';
import styles from './RegisterForm.module.scss';
import { UserState } from 'store/user/user.slice';
import { country, getTermsAndConditionByCountry } from 'consts';

type RegisterFormProps = {
    user: UserState;
    info: Info;
    buttonText: string;
    dniLabel: string;
    setInfo: Dispatch<SetStateAction<Info>>;
    handleNewUser: () => void;
};

export const RegisterForm: FC<RegisterFormProps> = ({
    user,
    info,
    buttonText,
    dniLabel,
    setInfo,
    handleNewUser,
}: RegisterFormProps) => {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(true);
    const [termsChecked, setTermsChecked] = useState(false);

    const disabledButton = invalidPassword || !(info.password == repeatPassword);
    const displayCheck = !invalidPassword && info.password == repeatPassword;

    return (
        <form className={styles['register-form']} onSubmit={handleNewUser}>
            <TextInput
                disabled
                type="email"
                label="Correo *"
                value={user.data?.email}
                className={styles['input']}
            />
            <br />
            <TextInput
                disabled
                type="text"
                label={`${dniLabel} *`}
                value={user.data?.nin}
                className={styles['input']}
            />
            <br />
            <TextInput
                type="text"
                name="passwordForm"
                placeholder="Crea tu contraseña"
                label="Crea tu contraseña"
                onChange={(v) => setInfo({ ...info, password: v })}
                showPassword={showCurrentPassword}
                setShowPassword={setShowCurrentPassword}
                showPasswordLabelInside
                displayCheck={displayCheck}
            />
            <br />
            <TextInput
                type="text"
                name="repeatPasswordForm"
                placeholder="Repetir contraseña"
                label="Repetir contraseña"
                onChange={(v) => {
                    setRepeatPassword(v);
                }}
                showPassword={showNewPassword}
                setShowPassword={setShowNewPassword}
                showPasswordLabelInside
                displayCheck={displayCheck}
            />
            <br />
            {info.password && !displayCheck && (
                <>
                    <PasswordValidator
                        password={info.password}
                        onChange={setInvalidPassword}
                        register
                    />
                    <br />
                </>
            )}
            <Terms
                id="confirmation-terms-checkbox"
                onChange={setTermsChecked}
                termsLocation={getTermsAndConditionByCountry(country)}
                register={true}
            />
            <br />
            <Button
                theme="primary"
                size="large"
                type="submit"
                disabled={disabledButton || !termsChecked}
            >
                {buttonText}
            </Button>
        </form>
    );
};
