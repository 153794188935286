import React from 'react';
import Button from 'components/common/Button';
import Link from 'components/common/Link';
import styles from './ErrorTemplate.module.scss';

import imgBear from './oso.svg';
import imgCardError from './card-error.svg';
import imgEmptyCart from './empty-cart.svg';
import imgUnauthorizedBear from './oso-unauthorized.svg';
import imgUnavailableProduct from './unavailable-product.svg';
import { getEnvironment } from 'config';

export type ErrorTemplateProps = {
    image?: ErrorImage;
    /** Figure is a node that replaces image if present */
    figure?: React.ReactNode;
    title?: React.ReactNode;
    accept?: React.ReactNode;
    reject?: React.ReactNode;
    children?: React.ReactNode;
    link?: React.ReactNode;
    content?: React.ReactNode;
    id?: React.ReactNode;
};

export const ErrorTemplate: React.FC<ErrorTemplateProps> = (props: ErrorTemplateProps) => {
    const { children, title, figure, image, accept, reject, link, content, id } = props;
    return (
        <div className={styles['wrapper']}>
            {figure == null ? (
                <figure className={styles['figure']}>
                    <ErrImg code={image} />
                </figure>
            ) : (
                figure
            )}
            {typeof title !== 'string' ? title : <h2 className={styles['title']}>{title}</h2>}
            {typeof children !== 'string' ? children : <p className={styles['text']}>{children}</p>}
            {typeof content !== 'string' ? (
                <div className={styles['content']}>{content}</div>
            ) : (
                <p className={styles['text']}>{content}</p>
            )}
            {id && <p className={styles['id']}>{id}</p>}
            <div className={styles['actionblock']}>
                {accept && <div className={styles['action']}>{accept}</div>}
                {reject && <div className={styles['action']}>{reject}</div>}
                {link && <div className={styles['link']}>{link}</div>}
            </div>
        </div>
    );
};

export type ErrorButtonProps = {
    url?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    theme?: 'primary' | 'secondary';
};
export const ErrorButton: React.FC<ErrorButtonProps> = (props: ErrorButtonProps) => {
    const { url = '/', children = 'Volver a intentar', theme = 'primary', onClick = null } = props;
    return (
        <Button
            onClick={() => {
                if (onClick) {
                    onClick();
                } else {
                    window.location.href = url;
                }
            }}
            theme={theme}
            size="medium"
        >
            {children}
        </Button>
    );
};

export type ErrorLinkProps = {
    url?: string;
    children?: React.ReactNode;
};
export const ErrorLink: React.FC<ErrorLinkProps> = (props: ErrorLinkProps) => {
    const { app } = getEnvironment();

    const tiendaUrl = app.tiendaUrl;
    const { url = tiendaUrl, children = 'Volver a Ripley.com' } = props;
    return (
        <Link to={url} theme="primary" target="_self" title="Volver">
            {children}
        </Link>
    );
};

export type ErrorImage =
    | 'default'
    | 'badpayment'
    | 'emptycart'
    | 'errorshipping'
    | 'unauthorized'
    | 'unavailableproduct';

export type ErrImgProps = {
    code?: ErrorImage;
};

function ErrImg({ code }: ErrImgProps) {
    switch (code) {
        case 'emptycart':
            return <img src={imgEmptyCart} alt="Problema en la bolsa" />;
        case 'unauthorized':
            return <img src={imgUnauthorizedBear} alt="No autorizado" />;
        case 'unavailableproduct':
            return <img src={imgUnavailableProduct} alt="Problema en la bolsa" />;
        case 'badpayment':
            return <img src={imgCardError} alt="Problemas en el medio de pago" />;
        case 'errorshipping':
            return <img src="" alt="" />;
        default:
            return <img src={imgBear} alt="Osito Ripley con herramientas de reparación" />;
    }
}
