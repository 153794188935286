import React, { FC } from 'react';
import TextInput from '../../common/TextInput';
import styles from './GiftCardPasswordInput.module.scss';
import { ReactComponent as HelpIcon } from './assets/HelpIcon.svg';
import { GiftCardProps } from './GiftCardPasswordInput.interface';

export const GiftCardPasswordInput: FC<GiftCardProps> = (props: GiftCardProps) => {
    const { tooltipMsg, value, onChange } = props;
    return (
        <>
            <TextInput
                label="Clave"
                type={'text'}
                onBlur={() => {}}
                onChange={onChange}
                onFocus={function noRefCheck() {}}
                placeholder="Clave"
                className={styles['field']}
                required={true}
                maxlength={20}
                value={value}
            />
            <div className={styles['tooltip']}>
                <HelpIcon className={styles['help']} />
                <div className={styles['bottom']}>
                    <div>{tooltipMsg}</div>
                </div>
            </div>
        </>
    );
};
