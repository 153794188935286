import * as React from 'react';
import styles from './InstallmentsForm.module.scss';
// import CvvInput from '../../common/CvvInput';
import ButtonField from 'components/common/ButtonField';
import Spinner from 'components/common/Spinner';
import Select, { Option } from '../../common/Select';

export type InstallmentsFormProps = React.HTMLAttributes<HTMLFormElement> & {
    /**
     * Sets a callback that runs when CVV's value changes
     */
    changeCVV?: (value: string) => void;
    /**
     * Toggles a spinner inside cvv validation button
     */
    isPending?: boolean;
    /**
     * Options available for deferred payment
     */
    deferredPayments?: Option[];
    /**
     * Callback on selection of deferred payment
     */
    changeDeferredPayments?: (value: string) => void;
    /**
     * Options available for installments
     */
    installments?: Option[];
    /**
     * Callback on selection of installments
     */
    changeInstallments?: (value: string) => void;
    /**
     * Current state of the card wanting to be edited
     */
    card?: ExternalCard;
};

export const InstallmentsForm: React.FC<InstallmentsFormProps> = ({
    changeCVV,
    isPending,
    installments,
    changeInstallments,
    deferredPayments,
    changeDeferredPayments,
    card,
    ...args
}: InstallmentsFormProps) => {
    const [cvv, setCvv] = React.useState('');

    const handleCvvClick = () => {
        changeCVV?.(cvv);
    };
    const handleChangeCvv = (value: string) => {
        setCvv(value);
    };
    return (
        <form
            className={styles['form']}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                handleCvvClick();
            }}
            {...args}
        >
            {changeCVV && (
                <div className={styles['group']}>
                    <ButtonField
                        disabled={isPending}
                        onSubmit={handleCvvClick}
                        value={cvv}
                        // label="CVV"
                        placeholder="CVV"
                        onChange={handleChangeCvv}
                        theme="primary"
                        submitText={isPending ? <Spinner theme="secondary" /> : 'Verificar'}
                    />
                </div>
            )}
            {installments && installments.length > 0 && (
                <Select
                    label="Número de cuotas"
                    options={installments}
                    onChange={changeInstallments}
                    value={card?.installments?.toString()}
                    empty={false}
                />
            )}
            {deferredPayments && deferredPayments.length > 0 && (
                <Select
                    label="Pago diferido"
                    options={deferredPayments}
                    onChange={changeDeferredPayments}
                    value={card?.deferred?.toString()}
                    empty={false}
                />
            )}
        </form>
    );
};
