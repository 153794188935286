/**
 * https://try.jsonata.org/R9eL60Nwb
 */
const scheduleListSchema = `
{
    'unscheduled': unscheduled,
    'groups': {
        'byId': scheduleGroups{
            id: {
                'id': id,
                "consolidationDeliveryMethod": consolidationDeliveryMethod,
                "deliveryMethodDescription": deliveryMethodDescription,
                'schedules' : schedules[].{
                        'id':id,
                        'deliveryMethod': deliveryMethod,
                        'deliveryMethodDescription':deliveryMethodDescription,
                        'closestDate': closestDate,
                        'latestDate': latestDate,
                        'typeOfServiceDescription': typeOfServiceDescription,
                        'typeOfService': typeOfService,
                        'isFulfillment': isFulfillment,
                        'serviceGroup': serviceGroup,
                        'customer':customer,
                        'price': price{
                            'value': value,
                            'currency' : currency
                        },
                        'customFields': customFields{
                            'warehouse': warehouse,
                            'courier' : courier,
                            'shopId': shopId,
                            'shopName': shopName,
                            'shippingSku': shippingSku,
                            'coordinateIds': coordinateIds
                        },
                        'type': type,
                        'location': location{
                            'description': description,
                            'addressComponents': addressComponents[].{
                                'key': key,
                                'value': value
                            }
                        },
                        'planningDates': planningDates ? planningDates{
                            'pickingDate': pickingDate,
                            'deliveryDateToOpl': deliveryDateToOpl
                        } : null,
                        'key': key
            },
            'products': products
        }
        },
        'allIds': [$.scheduleGroups[].id]
    },
    'selectedSchedules': {}
}`;

export { scheduleListSchema };
