import React, { useEffect, useRef, useState } from 'react';

import LoadingPayment from 'components/common/LoadingPayment';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { alignetRetryOrRedirect } from 'lib/utils/payments';
import { useHistory, useLocation } from 'react-router-dom';
import { alignetCheckout } from 'store/checkout';
import { RootState } from 'types/store';

export const AlignetRedirect: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation<{ pm: string }>();
    const form = useRef<HTMLFormElement>(null);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const pm = useAppSelector((state: RootState) => state.paymentMethod);
    const [isRetrying, setRetrying] = useState(false);

    const orderId = cart?.orderId;
    const externalId = cart?.paymentInfo?.[0].id;
    const url = pm.types.alignet.data?.url;
    const acquireId = pm.types.alignet.data?.acquireId;
    const commerceCode = pm.types.alignet.data?.commerceCode;
    const purchaseVerification = pm.types.alignet.data?.purchaseVerification;
    const purchaseAmount = pm.types.alignet.data?.purchaseAmount;
    const purchaseCurrencyCode = pm.types.alignet.data?.purchaseCurrencyCode;
    const language = pm.types.alignet.data?.language;
    const logged = pm.types.alignet.data?.logged;
    const shippingFirstName = pm.types.alignet.data?.shippingFirstName;
    const shippingLastName = pm.types.alignet.data?.shippingLastName;
    const shippingEmail = pm.types.alignet.data?.shippingEmail;
    const shippingAddress = pm.types.alignet.data?.shippingAddress;
    const shippingZIP = pm.types.alignet.data?.shippingZIP;
    const shippingCity = pm.types.alignet.data?.shippingCity;
    const shippingState = pm.types.alignet.data?.shippingState;
    const shippingCountry = pm.types.alignet.data?.shippingCountry;
    const descriptionProducts = pm.types.alignet.data?.descriptionProducts;
    const clientIdentifier = pm.types.alignet.data?.clientIdentifier;
    const programmingLanguage = pm.types.alignet.data?.programmingLanguage;

    useEffect(() => {
        (async () => {
            if (location.state?.pm) {
                try {
                    if (!cart?.id) {
                        history.push('/');
                        return;
                    }
                    if (!cart?.shippingInfo?.length) {
                        history.push('/');
                        return;
                    }
                    if (!cart?.paymentInfo?.length) {
                        history.push('/');
                        return;
                    }

                    await dispatch(alignetCheckout(cart.id)).unwrap();
                    history.replace({ state: {} });
                    form.current?.submit();
                } catch {
                    history.push('/');
                }
            } else if (cart?.id && orderId) {
                // Get updated cart status to trigger redirection if status == 'pending'
                if (!isRetrying) {
                    setRetrying(true);
                    await alignetRetryOrRedirect(history, dispatch);
                }
            }
        })();
        // NOTE: The missing "location.state?.pm" in the deps array is on purpose.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, history, cart, orderId]);

    return (
        <React.Fragment>
            <form
                data-testid="alignet-form"
                name="alignet-redirect"
                method="post"
                id="alignet-form"
                action={url}
                ref={form}
            >
                <input type="hidden" name="acquirerId" value={acquireId} />
                <input type="hidden" name="idCommerce" value={commerceCode} />
                <input type="hidden" name="purchaseOperationNumber" value={orderId} />
                <input type="hidden" name="purchaseAmount" value={purchaseAmount} />
                <input type="hidden" name="purchaseCurrencyCode" value={purchaseCurrencyCode} />
                <input type="hidden" name="language" value={language} />
                <input type="hidden" name="shippingFirstName" value={shippingFirstName} />
                <input type="hidden" name="shippingLastName" value={shippingLastName} />
                <input type="hidden" name="shippingEmail" value={shippingEmail} />
                <input type="hidden" name="shippingAddress" value={shippingAddress} />
                <input type="hidden" name="shippingZIP" value={shippingZIP} />
                <input type="hidden" name="shippingCity" value={shippingCity} />
                <input type="hidden" name="shippingState" value={shippingState} />
                <input type="hidden" name="shippingCountry" value={shippingCountry} />
                <input type="hidden" name="descriptionProducts" value={descriptionProducts} />
                <input type="hidden" name="purchaseVerification" value={purchaseVerification} />
                <input type="hidden" name="reserved1" value={logged} />
                <input type="hidden" name="reserved2" value={orderId} />
                <input type="hidden" name="reserved3" value={clientIdentifier} />
                <input type="hidden" name="reserved21" value={externalId} />
                <input type="hidden" name="userCommerce" value={clientIdentifier} />
                <input type="hidden" name="programmingLanguage" value={programmingLanguage} />
            </form>
            <LoadingPayment content="Redireccionando..." />;
        </React.Fragment>
    );
};
