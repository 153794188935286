import * as yup from 'yup';
import { validate as validateRUT, clean as cleanRUT } from 'rut.js';
import { trans } from 'lib/translator';

const getErrorMsg = trans('validations');

export const formValidation = yup.object({
    checkbox: yup.string().required(),
    userPhoneNumber: yup.string().when('checkbox', {
        is: 'me',
        then: (schema) =>
            schema
                .required('El teléfono es requerido.')
                .transform((value) => value.replace(/-/g, '').replace(/_/g, ''))
                .length(9, getErrorMsg('min', { num: 9 })),
    }),
    firstName: yup.string().when('checkbox', {
        is: 'third',
        then: (schema) => schema.required('El nombre es requerido.'),
    }),
    lastName: yup.string().when('checkbox', {
        is: 'third',
        then: (schema) => schema.required('El apellido es requerido.'),
    }),
    nin: yup.string().when('checkbox', {
        is: 'third',
        then: (schema) =>
            schema
                .required('El rut es requerido.')
                .transform((value) => cleanRUT(value))
                .test('nin', 'el rut no es valido', (value) => validateRUT(value || '')),
    }),
    phoneNumber: yup.string().when('checkbox', {
        is: 'third',
        then: (schema) =>
            schema
                .required('El teléfono es requerido.')
                .transform((value) => value.replace(/-/g, '').replace(/_/g, ''))
                .length(9, getErrorMsg('min', { num: 9 })),
    }),
});
