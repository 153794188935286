import React from 'react';
import styles from './Skeleton.module.scss';

export type SkeletonProps = {
    height?: number;
    width?: number;
    className?: string;
    dark?: boolean;
};

const defaultProps: SkeletonProps = {
    width: 100,
    className: '',
};

export const Skeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
    let classNames = styles['element'];

    if (props.width) {
        classNames += ' ' + styles['element--width-' + props.width];
    }

    let style = {};
    if (props.height) {
        style = { height: props.height };
    }

    if (props.dark) {
        classNames += ' ' + styles['element--dark'];
    }

    if (props.className) {
        classNames += ' ' + props.className;
    }

    return <div className={classNames} style={style} data-testid="skeleton" />;
};

Skeleton.defaultProps = defaultProps;

export default Skeleton;
