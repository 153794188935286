import { toTitleCase } from 'lib/utils/strings';
import { country } from 'consts';

export const formatStoreName = (store: ExtStore): string => {
    if (store.type === 'pickupPoint') {
        return store.name;
    }
    return `Ripley ${toTitleCase(store.name)}`;
};

export const formatBusinessHours = (store: ExtStore): string => {
    const businessHours = store?.attributes.find((attribute) => attribute.key === 'businessHours')
        ?.value;
    return businessHours || '';
};

export const formatStoreAddress = (store: ExtStore): string => {
    const city = country === 'CL' ? `, ${store.location.find((l) => l.key === 'city')?.value}` : '';
    return `${store.address}${city}.`;
};
