import React from 'react';
import ProductItem, { CartProductItem } from '../ProductItem';
import WarrantyItem from '../WarrantyItem';
import { Price } from '../Prices';
import { ProductThumbAvailability } from '../ProductThumb/ProductThumb';

export type ProductWithWarrantyProps = {
    /**
     * Product comes with an array of related products to render
     */
    product: Product;
    /**
     * Identifies the section where the component is being rendered
     */
    type?: 'cart';
    /**
     * Sets the country
     */
    country?: 'cl' | 'pe';
};

export const ProductWithWarranty: React.FC<ProductWithWarrantyProps> = ({
    product,
    type,
    country,
}: ProductWithWarrantyProps) => {
    const { related } = product;

    return (
        <>
            {type === 'cart' ? (
                <CartProductItem product={product} country={country} />
            ) : (
                <ProductItem product={product} country={country} />
            )}
            {related &&
                related.map((warranty) => {
                    return <WarrantyItem warranty={warranty} key={product.sku} />;
                })}
        </>
    );
};

export interface Product {
    sku: string;
    name: string;
    type: string;
    tags: string[];
    thumbnail: string;
    quantity: number;
    prices?: Price[];
    availability?: ProductThumbAvailability;
    related: Product[] | null;
}
