import React from 'react';

import CreateAddressContainer from 'containers/aio/CreateAddressContainer';

import Layout from 'components/aio/Layout';
import CartContainer from 'containers/CartContainer';
import Terms from 'components/common/Terms';
import Button from 'components/common/Button';
import EmptySection from 'components/common/EmptySection';
import { useTranslation } from 'react-i18next';
import { country, getTermsAndConditionByCountry } from 'consts';

export const CreateAddress: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Layout
            content={
                <>
                    <CreateAddressContainer />
                    <EmptySection title="Envío" />
                    <EmptySection title="Cupones" />
                    <EmptySection title="Pago" />
                </>
            }
            side={
                <CartContainer
                    terms={
                        <Terms
                            id="confirmation-terms-checkbox"
                            onChange={() => {}}
                            termsLocation={getTermsAndConditionByCountry(country)}
                        />
                    }
                >
                    <Button
                        size="large"
                        theme="primary"
                        onClick={() => {}}
                        disabled={true}
                        loading={false}
                    >
                        {t('pay')}
                    </Button>
                </CartContainer>
            }
        />
    );
};
