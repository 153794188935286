import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useTranslation } from 'react-i18next';

import { DP } from 'consts';
import { formatStoreName } from 'lib/utils/pickup';

import {
    getIsFree,
    getProducts,
    getScheduleDescription,
    getShippingDates,
    getShippingOptions,
} from 'lib/utils/schedules';

import { selectSchedule, setSchedule } from 'store/shipping';
import { fetchCartById } from 'store/cart';
import { resetPayments } from 'store/paymentMethod/actions';

import ShippingGroup, { Skeleton } from 'components/aio/ShippingGroup';
import ShippingSelectorContainer from '../ShippingSelectorContainer';
import { RootState } from 'types/store';
import { getShippingCost } from 'containers/CartContainer/CartContainer.utils';

type ShippingGroupContainerProps = {
    index: number;
    groupId: string;
};

export const ShippingGroupContainer: React.FC<ShippingGroupContainerProps> = ({
    index,
    groupId,
}: ShippingGroupContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const delivery = useAppSelector((state: RootState) => state.shipping.data);
    const stores = useAppSelector((state: RootState) => state.store.data);

    const shippingGroup = delivery?.groups.byId[groupId];
    const scheduleId = delivery?.selectedSchedules[groupId];
    const schedule = shippingGroup?.schedules.find((s: Schedule) => s.id == scheduleId);

    const [selectedGroup, setSelectedGroup] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    const handleChangeSchedule = (id: string) => {
        (async () => {
            // we dispatch a change only when the id is different from the selected one
            if (scheduleId !== id) {
                try {
                    dispatch(selectSchedule({ groupId, scheduleId: id }));
                    await dispatch(setSchedule()).unwrap();
                    await dispatch(resetPayments()).unwrap();
                    await dispatch(fetchCartById()).unwrap();
                } catch (err) {
                    throw new Error('Ha ocurrido un error');
                }
            }
        })();
    };

    const handleToggleModal = (serviceGroup: string) => {
        if (!isModalOpen) {
            setModalOpen(true);
            setSelectedGroup(serviceGroup);
        } else {
            setModalOpen(false);
            setSelectedGroup('');
        }
    };

    if (cart && shippingGroup && scheduleId && schedule && stores) {
        const productThumbs = getProducts(shippingGroup, cart);

        const warehouse = schedule.customFields.warehouse;

        const store = stores.byId?.[warehouse];

        const storeName = warehouse && store ? formatStoreName(store) : 'Ripley';

        const shopName = schedule.customFields.shopName;

        const shipsFrom = schedule.deliveryMethod === DP && shopName ? shopName : storeName;

        const isFree = getShippingCost(cart) ? getIsFree(cart) : false;

        const shippingOptions = getShippingOptions(shippingGroup.schedules, scheduleId).map(
            (opt) => {
                const [closestDate, latestDate] = getShippingDates(opt.closestDate, opt.latestDate);

                const price =
                    opt.price.value === '0'
                        ? 'Gratis'
                        : t('amount', { ns: 'format', value: opt.price.value });

                const description = getScheduleDescription(
                    opt.deliveryMethod,
                    closestDate,
                    latestDate,
                    opt.typeOfService ?? '',
                    opt.typeOfServiceDescription ?? '',
                );

                const linkText =
                    shippingGroup.consolidationDeliveryMethod == 'MP' &&
                    shippingGroup.schedules.some(
                        (i) => i.typeOfServiceDescription?.match(/(retiro|tienda|ripley)/i),
                    )
                        ? 'Cambiar método de entrega'
                        : t('change');

                const showLink =
                    shippingGroup.schedules.filter((o) => o.serviceGroup == opt.serviceGroup)
                        .length > 1;

                return {
                    id: opt.id,
                    name: opt.serviceGroup,
                    method: opt.serviceGroup,
                    description: description,
                    price: price,
                    selected: opt.id === scheduleId,
                    showLink: showLink,
                    linkText: linkText,
                };
            },
        );

        return (
            <>
                <ShippingGroup
                    index={index}
                    productThumbs={productThumbs}
                    storeName={shipsFrom}
                    shippingOptions={shippingOptions}
                    isFree={isFree}
                    onChange={handleChangeSchedule}
                    onToggle={handleToggleModal}
                />

                <ShippingSelectorContainer
                    groupId={groupId}
                    serviceGroup={selectedGroup}
                    isOpen={isModalOpen}
                    onToggle={() => setModalOpen(!isModalOpen)}
                />
            </>
        );
    }

    return <Skeleton index={index} />;
};
