import * as React from 'react';
import styles from './PuntosGo.module.scss';

export type PuntosGoProps = {
    /**
     * Amount of RipleyPuntos Go won by an user because of this payment.
     * This component will render nothing if `points` is not present
     */
    points?: number;
};

export const PuntosGo: React.FC<PuntosGoProps> = ({ points }: PuntosGoProps) => {
    if (points == null || points === 0) {
        return null;
    }

    return (
        <div className={styles['go']}>
            <p className={styles['logo']}>
                <strong>Puntos Go</strong> <span className={styles['star']} />
            </p>
            <p className={styles['text']}>
                Acumulaste un total de <strong>{points} Ripley Puntos Go</strong>
            </p>
        </div>
    );
};
