import React, { useState } from 'react';
import styles from './CouponTag.module.scss';
import checkImg from './img/check.svg';
import alertImg from './img/error.svg';
import Spinner from 'components/common/Spinner';

export type CouponTagProps = {
    /** Coupon object. eg: {code: 'DESPACHOGRATIS', description: 'Cupon aplicado' } */
    coupon: Coupon;
    /** Handles coupon deletion */
    handleDeleteCoupon: () => void;
};

export const CouponTag: React.FC<CouponTagProps> = ({
    coupon,
    handleDeleteCoupon,
}: CouponTagProps) => {
    const [isLoading, setIsLoading] = useState<boolean>();

    const handleDeleteCouponWithLoading = () => {
        setIsLoading(true);
        handleDeleteCoupon();
    };

    let containerStyle = styles['coupon_tag__container'];
    if (isLoading) {
        containerStyle += ' ' + styles['coupon_tag__loading'];
    }
    return (
        <div className={containerStyle}>
            <div className={styles['coupon_tag__body']}>
                <div className={styles['coupon_tag__content']}>
                    <img
                        className={styles['coupon_tag__check']}
                        width={20}
                        height={20}
                        alt="Check"
                        src={coupon.error ? alertImg : checkImg}
                    />

                    <div>
                        <div
                            className={
                                styles[`coupon_tag__coupon_name${coupon.error ? '--err' : ''}`]
                            }
                        >
                            {coupon.coupon}
                        </div>
                        <div className={styles[`coupon_tag__coupon_description`]}>
                            <div>{coupon.error && coupon.error?.message}</div>
                            <div>{coupon.conditions}</div>
                        </div>
                    </div>
                </div>
                <button onClick={handleDeleteCouponWithLoading} />
            </div>
            {isLoading && (
                <div className={styles['coupon_tag__spinner']}>
                    <Spinner size={25} />
                </div>
            )}
        </div>
    );
};
