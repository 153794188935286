import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from 'lib/hooks/analytics';

import Link from '../Link';
import { ErrorTemplate, ErrorButton, ErrorLink } from './ErrorTemplate';
import { getEnvironment } from 'config';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';
import { useAppSelector } from '../../../lib/hooks/redux';

export type RipleyErrorProps = {
    error: SerializedError | string;
};

export const RipleyError: React.FC<RipleyErrorProps> = ({ error }: RipleyErrorProps) => {
    const { app, payments } = getEnvironment();
    const { data } = useAppSelector((state) => state.cart);

    const loginUrl = app.loginUrl ?? '/';
    const url =
        app.tiendaUrl ||
        (app.country == 'pe' ? 'https://simple.ripley.com.pe' : 'https://simple.ripley.cl');
    const bankUrl =
        payments.bank ||
        (app.country == 'pe' ? 'https://www.bancoripley.com.pe/' : 'https://www.bancoripley.cl/');
    const bolsaUrl =
        app.bolsaUrl ||
        (app.country == 'pe'
            ? 'https://simple.ripley.com.pe/bolsa'
            : 'https://simple.ripley.cl/bolsa');

    const { t } = useTranslation('error');
    const analytics = useAnalytics();

    if (typeof error === 'string') {
        return (
            <ErrorTemplate accept={<ErrorButton />} reject={<ErrorLink />}>
                {error}
            </ErrorTemplate>
        );
    }

    let id = '';
    if (app.isActiveErrorId) {
        try {
            const raw = JSON.parse(String(error?.message));
            if (raw.id) id = raw.id;

            sendErrorToGA4({
                channel: data?.channel,
                error: raw as HttpError,
                section: 'Oso',
                type: 'Checkout_oso_error',
            });
        } catch (_) {
            //
        }
    }

    if (error.name === 'GetAuthToken') {
        return (
            <ErrorTemplate
                id={id}
                image="unauthorized"
                accept={
                    <ErrorButton url={loginUrl}>
                        {t(`GetAuthToken.${error.code}.buttonReturn`)}
                    </ErrorButton>
                }
                title={t(`GetAuthToken.${error.code}.title`)}
            >
                {t(`GetAuthToken.${error.code}.message`)}
            </ErrorTemplate>
        );
    }
    if (error.code === 'errRiskyGiftCard') {
        return (
            <ErrorTemplate
                id={id}
                image="badpayment"
                reject={<ErrorButton url="/">{t(`errRiskyGiftCard.buttonReturn`)}</ErrorButton>}
                title={t(`errRiskyGiftCard.title`)}
            >
                {t(`errRiskyGiftCard.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errInvalidSession') {
        return (
            <ErrorTemplate
                id={id}
                image="unauthorized"
                accept={<ErrorButton url={loginUrl}>Ir a Ripley.com</ErrorButton>}
                title={t(`errInvalidSession.title`)}
            >
                {t(`errInvalidSession.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errCartNotFound') {
        return (
            <ErrorTemplate
                id={id}
                accept={<ErrorButton url={url}>Ir a Ripley.com</ErrorButton>}
                title={t(`errCartNotFound.title`)}
            >
                {t(`errCartNotFound.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errEmptyCart') {
        analytics.push('checkout_error', 'noProducts');
        return (
            <ErrorTemplate
                image="emptycart"
                accept={<ErrorButton url={url}>Ir a Ripley.com</ErrorButton>}
                title={t(`errEmptyCart.title`)}
                id={id}
            >
                {t(`errEmptyCart.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errBlockedPayment') {
        analytics.push('checkout_error', 'card_block');
        return (
            <ErrorTemplate
                image="badpayment"
                accept={<ErrorButton url="/">{t(`errBlockedPayment.buttonReturn`)}</ErrorButton>}
                title={t(`errBlockedPayment.title`)}
                link={
                    <Link title="Ripley" theme="primary" to={bankUrl} target="_self">
                        {t(`errBlockedPayment.linkName`)}
                    </Link>
                }
                id={id}
            >
                {t(`errBlockedPayment.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errFailedPayment') {
        analytics.push('checkout_error', 'bank_problem');
        return (
            <ErrorTemplate
                id={id}
                image="badpayment"
                reject={<ErrorButton url="/">{t(`errFailedPayment.buttonRetry`)}</ErrorButton>}
                title={t(`errFailedPayment.title`)}
                link={
                    <Link title="Ripley" theme="primary" to={url} target="_self">
                        {t(`errFailedPayment.linkName`)}
                    </Link>
                }
            >
                {t(`errFailedPayment.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errInvalidPayment') {
        return (
            <ErrorTemplate
                image="badpayment"
                accept={<ErrorButton url="/">{t(`errInvalidPayment.buttonReturn`)}</ErrorButton>}
                title={t(`errInvalidPayment.title`)}
                link={
                    <Link title="Ripley" theme="primary" to={url} target="_self">
                        {t(`errInvalidPayment.linkName`)}
                    </Link>
                }
                id={id}
            >
                {t(`errInvalidPayment.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errNoFundsPayment') {
        analytics.push('checkout_error', 'tarjetasincupo');
        return (
            <ErrorTemplate
                id={id}
                image="badpayment"
                accept={<ErrorButton url="/">{t(`errNoFundsPayment.buttonReturn`)}</ErrorButton>}
                title={t(`errNoFundsPayment.title`)}
                link={
                    <Link title="Ripley" theme="primary" to={bankUrl} target="_self">
                        {t(`errNoFundsPayment.linkName`)}
                    </Link>
                }
            >
                {t(`errNoFundsPayment.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errUnauthorizedPayment') {
        analytics.push('checkout_error', 'bank_problem');
        return (
            <ErrorTemplate
                image="badpayment"
                accept={
                    <ErrorButton url="/">{t(`errUnauthorizedPayment.buttonRetry`)}</ErrorButton>
                }
                title={t(`errUnauthorizedPayment.title`)}
                link={
                    <Link title="Ripley" theme="primary" to={bankUrl} target="_self">
                        {t(`errUnauthorizedPayment.linkName`)}
                    </Link>
                }
                id={id}
            >
                {t(`errUnauthorizedPayment.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errPriceIsChanged') {
        return (
            <ErrorTemplate
                id={id}
                image="emptycart"
                accept={
                    <ErrorButton url={bolsaUrl}>{t(`errPriceIsChanged.buttonReturn`)}</ErrorButton>
                }
                title={t(`errPriceIsChanged.title`)}
            >
                {t(`errPriceIsChanged.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'CartCheckout') {
        return (
            <ErrorTemplate
                accept={<ErrorButton />}
                reject={<ErrorLink />}
                title={t(`errCartCheckout.title`)}
                id={id}
            >
                {t(`errCartCheckout.message`)}
            </ErrorTemplate>
        );
    }

    if (error.code == 'errEvalRisk') {
        return (
            <ErrorTemplate id={id} reject={<ErrorLink />} title={t(`errEvalRisk.title`)}>
                {t(`errEvalRisk.message`)}
            </ErrorTemplate>
        );
    }

    return (
        <ErrorTemplate
            id={id}
            accept={<ErrorButton />}
            reject={<ErrorLink />}
            title={t([`${error.code}.title`, 'errUnknown.title'])}
        >
            <p>{t([`${error.code}.message`, 'errUnknown.message'])}</p>
        </ErrorTemplate>
    );
};
