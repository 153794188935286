import area from './area.json';
import auth from './auth.json';
import billingInfo from './billingInfo.json';
import cards from './cards.json';
import cart from './cart.json';
import checkout from './checkout.json';
import coupons from './coupons.json';
import deliveryMethods from './deliveryMethods.json';
import ok from './ok.json';
import payment from './payment.json';
import rules from './rules.json';
import schedule from './schedule.json';
import schedulePickup from './schedulePickup.json';
import scheduleSetted from './scheduleSetted.json';
import setPayment from './setPayment.json';
import setPaymentChek from './setPaymentChek.json';
import setPaymentWebpay from './setPaymentWebpay.json';
import setInStore from './setInStore.json';
import setRipleyGiftCard from './setRipleyGiftCard.json';
import store from './store.json';
import storeStock from './storeStock.json';
import user from './user.json';
import cts from './cts.json';

export default {
    area,
    auth,
    billingInfo,
    cards,
    cart,
    checkout,
    coupons,
    deliveryMethods,
    ok,
    rules: rules as Rules,
    schedule,
    schedulePickup,
    scheduleSetted,
    setPayment,
    setPaymentWebpay,
    setPaymentChek,
    setInStore,
    setRipleyGiftCard,
    store,
    storeStock,
    user,
    payment,
    cts,
};
