import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { doCheckout } from 'store/checkout';
import { resetPayments } from 'store/paymentMethod/paymentMethod.slice';
import { useHistory } from 'react-router-dom';
import ChekLoadingBar from 'components/chek/ChekLoadingBar';
import LoadingPayment from 'components/common/LoadingPayment';
import { getAlignetStatus, getWebpayStatus } from './Checkout.utils';
import { alignetRetryOrRedirect } from 'lib/utils/payments';
import { fetchLightCartById } from 'store/cart';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export function Checkout(): JSX.Element {
    const dispatch = useAppDispatch();
    const pm = useAppSelector((state: RootState) => state.paymentMethod.data);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const selectedPaymentMethods = pm.selected;
    const history = useHistory();
    const [done, setDone] = useState(false);
    const [isRetrying, setRetrying] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                if (
                    done ||
                    !cart?.id ||
                    !cart?.shippingInfo?.length ||
                    !cart?.paymentInfo?.length
                ) {
                    history.push('/');
                    return;
                }
                if (
                    ['WebpayCredito', 'WebpayDebito'].includes(cart?.paymentInfo[0]?.paymentMethod)
                ) {
                    if (getWebpayStatus() !== 'ok') {
                        await dispatch(resetPayments());
                        history.push('/');
                        return;
                    }
                } else if (cart?.paymentInfo[0]?.paymentMethod === 'Alignet') {
                    if (getAlignetStatus() !== 'ok') {
                        if (!isRetrying) {
                            setRetrying(true);
                            await alignetRetryOrRedirect(history, dispatch);
                            return;
                        }

                        if (!sent) {
                            setSent(true);
                            sendErrorToGA4({
                                channel: cart.channel,
                                error: 'Error en el medio de pago Alignet',
                                section: 'CheckoutValidation',
                                type: 'Checkout_network_error',
                            });
                        }
                        return;
                    }
                }
                await dispatch(doCheckout(cart.id)).unwrap();
                setDone(true);
                await dispatch(fetchLightCartById()).unwrap();
                history.push('/order-summary');
            } catch {
                history.push('/');
            }
        })();
    }, [dispatch, history, cart, done, isRetrying, sent]);

    if (getAlignetStatus() !== 'ok' && isRetrying) {
        return <LoadingPayment content={`Compra no finalizada,\nRedireccionando al sitio…`} />;
    }

    if (selectedPaymentMethods.includes('RipleyChek')) {
        return <ChekLoadingBar />;
    }

    return <LoadingPayment />;
}

export default Checkout;
