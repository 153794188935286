import * as React from 'react';
import styles from './Select.module.scss';
import arrowDownImg from './chevron.svg';

export type SelectProps = Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange'> & {
    label: string;
    options: Option[];
    value?: string;
    onChange?: (value: string) => void;
    name?: string;
    disabled?: boolean;
    /** Shows the control is in an error state. */
    invalid?: boolean;
    empty?: boolean | string;
};

const defaultProps = {
    options: [],
    empty: true,
};

export const Select: React.FC<SelectProps> = ({
    label,
    options,
    name,
    disabled,
    invalid,
    value,
    onChange,
    empty = true,
    ...args
}: SelectProps) => {
    const [open, setOpen] = React.useState(false);

    const handleOnChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const currentValue = event.currentTarget.value;
        onChange?.(currentValue);
    };

    const handleOnFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
        setOpen(true);
        if (typeof args.onFocus === 'function') {
            args.onFocus(event);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
        setOpen(false);
        if (typeof args.onBlur === 'function') {
            args.onBlur(event);
        }
    };

    let controlClassName = styles['control'];
    if (value === '' && options[0]?.label === '') {
        controlClassName += ' ' + styles['control--empty'];
    } else {
        controlClassName += ' ' + styles['control--selected'];
    }

    let fieldsetClassName = styles['group'];
    if (open) {
        fieldsetClassName += ' ' + styles['group--open'];
    }
    if (invalid) {
        fieldsetClassName += ' ' + styles['invalid'];
    }

    let legendClassName = styles['legend'];
    if (invalid) {
        legendClassName += ' ' + styles['invalid'];
    }

    return (
        <fieldset className={fieldsetClassName}>
            <select
                {...args}
                name={name}
                className={controlClassName}
                value={value}
                disabled={disabled}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                style={{ backgroundImage: `url(${arrowDownImg})` }}
            >
                {empty && <option value="">{empty}</option>}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <legend className={legendClassName}>
                <span>{label}</span>
            </legend>
        </fieldset>
    );
};

Select.defaultProps = defaultProps;

export interface Option {
    value: string;
    label: string;
}
