import React from 'react';
import styles from './Checkbox.module.scss';
import { ReactComponent as CheckIcon } from './check.svg';

export type CheckboxProps = {
    id?: string;
    checked: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
};

const Checkbox = (props: CheckboxProps): JSX.Element => {
    let calculatedClassName = styles['checkbox'];

    if (props.checked) {
        calculatedClassName += ' ' + styles['checkbox--selected'];
    } else {
        calculatedClassName += ' ' + styles['checkbox--unselected'];
    }

    if (props.disabled) {
        calculatedClassName += ' ' + styles['checkbox--disabled'];
    }

    const onChangeHandler = () => {
        props.onChange(!props.checked);
    };

    return (
        <span className={calculatedClassName}>
            <CheckIcon className={styles['checkbox__icon']} />
            <input
                id={props.id}
                className={styles['checkbox__input']}
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
                onChange={onChangeHandler}
                data-testid={props.id}
            />
        </span>
    );
};

export default Checkbox;
