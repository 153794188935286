import React from 'react';
import styles from './OrderSteps.module.scss';
import OrderStepItem from '../OrderStepItem';

export type OrderStepsProps = {
    /**
     * Order shipping type
     * Note: the steps are shown based on shipping type
     */
    shippingType: 'shipping' | 'pickup';
    /**
     * Order country
     * Note: the steps are shown based on country
     */
    country: 'cl' | 'pe';
    /**
     * Number of steps that will show active
     */
    activeSteps?: number;
    /**
     * URL used to redirect to `Mi cuenta`
     */
    link?: string;
};

export const OrderSteps: React.FC<OrderStepsProps> = ({
    shippingType,
    country,
    activeSteps = 1,
}: OrderStepsProps) => {
    /**
     * Generates the steps
     * @param num
     * @returns
     */
    const getSteps = (num: number) => {
        return Array.from(Array(num).keys()).map((i) => {
            const step = i + 1;

            return (
                <OrderStepItem
                    key={i}
                    step={step}
                    country={country}
                    shippingType={shippingType}
                    disabled={activeSteps < step ? true : false}
                />
            );
        });
    };

    return (
        <div className={styles.container}>
            <h2>Pasos de tu compra</h2>

            {country == 'cl' && shippingType == 'shipping' && (
                <div className={styles.steps}>{getSteps(4)}</div>
            )}
            {country == 'cl' && shippingType == 'pickup' && (
                <div className={styles.steps}>{getSteps(3)}</div>
            )}
            {country == 'pe' && shippingType == 'shipping' && (
                <div className={styles.steps}>{getSteps(4)}</div>
            )}
            {country == 'pe' && shippingType == 'pickup' && (
                <div className={styles.steps}>{getSteps(4)}</div>
            )}
        </div>
    );
};
