import { getAddressComponentsWithNames } from 'lib/utils/addresses';
import { SetPaymentMethodPayload } from 'store/paymentMethod/cybersource.slice';

export const setPaymentPayload = (
    cart: Cart,
    user: NormalizedUser,
    addresses: NormalizedAddresses,
    areas: ExtArea,
    docType?: DocType,
): SetPaymentMethodPayload => {
    const address = addresses.byId[addresses.selected];
    const region = address.addressComponents.find((x) => x.key === 'region');
    const city = address.addressComponents.find((x) => x.key === 'city');
    const zipCode = address.addressComponents.find((x) => x.key === 'zipCode');

    const customFields = {
        'customer-firstname': user.firstname,
        'customer-lastname': user.lastname,
        'customer-email': user.email,
        'billing-address': address.formattedAddress,
        'billing-locality': city?.value || '',
        'billing-administrative-area': region?.value || '',
        'billing-postal-code': zipCode?.value || '',
        'customer-phone-number': address.phoneNumber,
    };

    // TODO: centralize price selection
    let price = cart.grandTotal['discount'];
    if (price == null) price = cart.grandTotal['master'];

    return {
        cartId: cart.id,
        paymentInfo: [
            {
                documentType: docType || 'boleta', // TODO: hardcoded
                paymentMethod: 'Cybersource',
                billingInfo: {
                    customer: {
                        nin: user.nin,
                        firstName: user.firstname,
                        lastName: user.lastname,
                        email: user.email,
                        phoneNumber: user.phoneNumber,
                    },
                    address: getAddressComponentsWithNames(address.addressComponents, areas),
                },
                amount: price,
                customFields,
            },
        ],
    };
};
