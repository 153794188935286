import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ShippingGroup.module.scss';

import AlertBadge from 'components/common/AlertBadge';
import Card from 'components/common/Card';
import Link from 'components/common/Link';
import { ProductThumb, ProductThumbProps } from 'components/common/ProductThumb/ProductThumb';

import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { PaymentMethodIcon } from 'components/payment/PaymentMethodIcon/PaymentMethodIcon';
import { ScheduleDescriptionProps } from 'components/shipping/ScheduleDescription';
import ScheduleDescription from 'components/shipping/ScheduleDescription/ScheduleDescription';
import RadioButton from 'components/common/RadioButton';

export type ShippingOption = {
    id: string;
    name: string;
    method: string;
    description: ScheduleDescriptionProps;
    price: string;
    selected: boolean;
    linkText?: string;
    showLink?: boolean;
};

export type ShippingGroupProps = {
    /**
     * Shipping group number
     */
    index: number;
    /**
     * Store name. Could be a Ripley store location or a third-party seller.
     */
    storeName: string;
    /**
     * Shipping group images
     */
    productThumbs: ProductThumbProps[];
    /**
     * Component to list shipping options
     */
    shippingOptions?: ShippingOption[];
    /**
     * True for the special case of a gift card purchase.
     */
    isGiftCard?: boolean;
    /**
     * True in case that the group does not have schedules
     */
    isUnavailable?: boolean;
    /**
     * True if the shiping cost is free because of discounts
     */
    isFree?: boolean;
    /**
     * Callback to change shipping option
     */
    onChange?: (id: string) => void;
    /**
     * Callback to show or hide pop-up window
     */
    onToggle?: (method: string) => void;
};

export const ShippingGroup: FC<ShippingGroupProps> = (props: ShippingGroupProps) => {
    const { index, isGiftCard, isUnavailable, productThumbs, storeName, shippingOptions, isFree } =
        props;

    const [maxThumbs] = useState(4);
    const [isExpanded, setIsExpanded] = useState(false);

    const { t } = useTranslation();

    const hasShowMore = (index: number) =>
        productThumbs.length > maxThumbs && index === maxThumbs - 1 && !isExpanded;

    const productThumbsClassNameTop = isExpanded
        ? `${styles['sg__product-thumbs']} ${styles['sg__product-thumbs--expanded-top']}`
        : styles['sg__product-thumbs'];

    const productThumbsClassNameBottom = `${styles['sg__product-thumbs']} ${styles['sg__product-thumbs--expanded-bottom']}`;

    const handleOnChange = (id: string) => (props.onChange ? props.onChange(id) : null);

    return (
        <Card className={styles['sg__card']} title={`Envío ${index}`}>
            <h3 className={styles['sg__subtitle']}>
                {isUnavailable ? (
                    'No disponible'
                ) : (
                    <>
                        desde <span className={styles['sg__store-name']}>{storeName}</span>
                    </>
                )}
            </h3>

            <section className={productThumbsClassNameTop}>
                {isGiftCard ? (
                    <PaymentMethodIcon type="giftcard" width="66px" />
                ) : (
                    productThumbs
                        .slice(0, maxThumbs)
                        .map((props, index) => (
                            <ProductThumb
                                key={index}
                                hasRightMargin={index !== productThumbs.length - 1}
                                showMore={
                                    hasShowMore(index)
                                        ? productThumbs.length - maxThumbs
                                        : undefined
                                }
                                onClick={hasShowMore(index) ? () => setIsExpanded(true) : undefined}
                                onKeyPress={
                                    hasShowMore(index) ? () => setIsExpanded(true) : undefined
                                }
                                {...props}
                            />
                        ))
                )}
            </section>

            {isExpanded ? (
                <>
                    <section className={productThumbsClassNameBottom}>
                        {productThumbs.slice(maxThumbs).map((props, index) => (
                            <ProductThumb
                                key={index}
                                hasRightMargin={index !== productThumbs.length - 1}
                                className={styles['sg__product-thumb']}
                                {...props}
                            />
                        ))}
                    </section>
                    <section className={styles['sg__collapse']}>
                        <Link
                            className={styles['sg__collapse__link']}
                            to={() => setIsExpanded(false)}
                            title="Mostrar menos"
                        >
                            <span className={styles['sg__collapse__link__text']}>
                                Mostrar menos
                            </span>{' '}
                            <ArrowUp />
                        </Link>
                    </section>
                </>
            ) : null}

            {isUnavailable && (
                <AlertBadge theme="warning">
                    {productThumbs.length > 1
                        ? 'Estos productos no pueden ser enviados'
                        : 'Este producto no puede ser enviado'}{' '}
                    a tu ubicación, por favor elige otra dirección.
                </AlertBadge>
            )}

            {shippingOptions && (
                <form>
                    {shippingOptions.map((option) => (
                        <div key={option.id} className={styles['sg__shipping-option']}>
                            <div
                                className={styles['sg__button']}
                                onClick={() => handleOnChange(option.id)}
                                onKeyPress={() => handleOnChange(option.id)}
                                role="radio"
                                aria-checked={option.selected}
                                tabIndex={0}
                            >
                                <RadioButton
                                    id={option.id}
                                    name="option"
                                    checked={option.selected || false}
                                    value={option.id}
                                    onChange={() => handleOnChange(option.id)}
                                />

                                <div className={styles['sg__description']}>
                                    <span className={styles['sg__name']}>{option.name}</span>
                                    <ScheduleDescription {...option.description} />
                                </div>

                                {isFree && option.price !== 'Gratis' ? (
                                    <div className={styles['pricefree']}>{'Gratis'}</div>
                                ) : (
                                    <div className={styles['sg__price']}>{option.price}</div>
                                )}
                            </div>

                            {option.showLink && (
                                <Link
                                    className={styles['sg__link']}
                                    to={() => props.onToggle && props.onToggle(option.method)}
                                    title={option.linkText ?? t('change')}
                                >
                                    {option.linkText ?? t('change')}
                                </Link>
                            )}
                        </div>
                    ))}
                </form>
            )}
        </Card>
    );
};

export default ShippingGroup;
