import React, { FC } from 'react';

import styles from './Card.module.scss';
import { ReactComponent as EditIcon } from './edit.svg';

import Link from '../Link';

export type CardProps = {
    /**
     * Card content.
     */
    children: React.ReactNode;
    /**
     * Extra classes.
     */
    className?: string;
    /**
     * Card variant
     * Note: `white` variant has box shadow and `gray` doesn't;
     */
    variant?: 'white' | 'gray';
    /**
     * Title to display in the top-left. Optional.
     */
    title?: string;
    /**
     * Path or callback for top-right link "Modificar".
     */
    link?: string | (() => void);
    /**
     * Sets the link text by default is "Modificar"
     */
    linkText?: string;
    /**
     * Allows to disable the link
     */
    linkDisabled?: boolean;
    /**
     * Adapt the style for a section
     */
    isSection?: boolean;
    /**
     * Help text displayed when link is disabled
     */
    helpText?: string;
};

export const Card: FC<CardProps> = (props: CardProps) => {
    const {
        isSection = false,
        variant = 'white',

        title = '',
        link,
        linkText = 'Modificar',
        linkDisabled = false,
        helpText,

        children,
        className = '',
    } = props;

    const computedClassName = `${styles['card']} ${className} ${styles['card--' + variant]}`;

    let titleClassName = styles['card__title'];
    if (isSection) {
        titleClassName += ' ' + styles['card__title--section'];
    }

    let linkClassName = styles['card__link'];
    if (linkDisabled) {
        linkClassName += ' ' + styles['card__link--disabled'];
    }

    return (
        <div className={computedClassName}>
            {title ? (
                <div className={styles['card__header']}>
                    <h2 className={titleClassName}>{title}</h2>
                    {link && (
                        <Link
                            className={linkClassName}
                            title={linkText}
                            to={!linkDisabled ? link : '#'}
                        >
                            <span className={styles['card__link__text']}>{linkText}</span>
                            <EditIcon className={styles['card__link__icon']} />
                            {linkDisabled && helpText && (
                                <span className={styles['card__link__help']}>{helpText}</span>
                            )}
                        </Link>
                    )}
                </div>
            ) : null}
            {children}
        </div>
    );
};

export default Card;
