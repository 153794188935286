import slice from './app.slice';
export {
    isMobile,
    initialize,
    updateDeviceId,
    initializeOauth,
    extraInitialize,
} from './app.slice';
export const { actions } = slice;
export default slice.reducer;
