import * as yup from 'yup';
import React, { FormEvent } from 'react';
import styles from './ChekPhoneNumberForm.module.scss';

import { trans } from 'lib/translator';
import { UseFormReturn } from 'react-hook-form';

import chekImage from './chek.svg';
import PhoneField from 'components/common/PhoneField';
import FieldWrapper from 'components/common/FieldWrapper';

const getErrorMsg = trans('validations');

export type FormData = {
    phoneNumber: string;
    phoneNumberFormatted?: string;
};

export const formValidation = yup.object({
    phoneNumber: yup
        .string()
        .required(getErrorMsg('req'))
        .min(9, getErrorMsg('min', { num: 9 }))
        .transform((value) => value.replace(/-/g, '').replace(/_/g, '')),
    phoneNumberFormatted: yup.string().optional(),
});

export type ChekPhoneNumberFormProps = {
    country?: 'CL' | 'PE';
    form: UseFormReturn<FormData>;
    children?: React.ReactNode;
    onSubmit: (event: FormEvent) => void;
};

export const ChekPhoneNumberForm: React.FC<ChekPhoneNumberFormProps> = ({
    form,
    country,
    onSubmit,
    children,
}: ChekPhoneNumberFormProps) => {
    const errors = form.formState.errors;
    const values = form.formState.defaultValues;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <img src={chekImage} alt="Logo chek" data-testid="image" />
                </div>

                <form className={styles.form} onSubmit={onSubmit}>
                    <p>Para recibir el cobro en Chek, ingresa tu número</p>

                    <FieldWrapper error={errors.phoneNumber?.message}>
                        <PhoneField
                            label="Teléfono"
                            country={country}
                            defaultValue={values?.phoneNumber}
                            onChangeMasked={(_, formatted) => {
                                form.setValue('phoneNumberFormatted', formatted);
                            }}
                            isInvalid={errors.phoneNumber ? true : false}
                            {...form.register('phoneNumber')}
                        />
                    </FieldWrapper>

                    {children}
                </form>
            </div>
        </div>
    );
};
