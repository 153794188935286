import React from 'react';
import styles from './ChekLoadingBar.module.scss';
import chek_img from './chek.svg';
import AlertBadge from 'components/common/AlertBadge';
import { CL, country } from 'consts';

export const ChekLoadingBar: React.FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.imageChek}>
                    <img src={chek_img} alt="Logo de chek" />
                </div>
                <div className={styles.textProcess}>Procesando el pago</div>
                <div>
                    <div className={styles.bar}></div>
                </div>
                {country == CL && (
                    <div className={styles.alert}>
                        <AlertBadge theme="info">
                            Si la compra no se confirma, el cobro será reversado a Chek en los
                            próximos 10 min.
                        </AlertBadge>
                    </div>
                )}
            </div>
        </div>
    );
};
