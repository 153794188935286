import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';

import AlertBadge from 'components/common/AlertBadge';
import Modal from 'components/common/Modal';
import SeparatedProductsContainer from '../SeparatedProductsContainer';
import LocalitySelectorContainer from '../LocalitySelectorContainer';
import StockSelectorContainer from '../StockSelectorContainer';
import StoreModalContainer from '../StoreModalContainer';

import {
    separateProducts as deleteProducts,
    setSeparatedProductsOpen,
    updateSeparatedProducts,
} from 'store/separatedProducts';
import { toggleStoreModal } from 'store/deliveryMethod';
import { getStocks, setProvisionalStore, setStoreForm } from 'store/store';
import { fetchScheduleByCartId } from 'store/pickup';
import { fetchCartById } from 'store/cart';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

type PickupModalContainerProps = {
    isOpen: boolean;
    onToggle: () => void;
};

export const PickupModalContainer: React.FC<PickupModalContainerProps> = ({
    isOpen,
    onToggle,
}: PickupModalContainerProps) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<null | string>(null);

    const address = useAppSelector((state: RootState) => state.address.data);
    const provisionalStore = useAppSelector((state: RootState) => state.store.data?.provisional);
    const showSeparatedProducts = useAppSelector(
        (state: RootState) => state.separatedProducts.isOpen,
    );
    const { isOpenStoreModal } = useAppSelector((state: RootState) => state.deliveryMethod);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);

    useEffect(() => {
        (async () => {
            if (address) {
                const selectedAddressId = address.selected;
                const addressInfo = address.byId[selectedAddressId];
                dispatch(setStoreForm(addressInfo));
                await dispatch(getStocks());
            }
        })();
    }, [address, dispatch]);

    useEffect(() => {
        if (error) {
            sendErrorToGA4({
                channel: channel,
                error: error,
                section: 'DeliverySection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [error]);

    const selectStore = async (storeId: string) => {
        dispatch(setProvisionalStore(storeId));

        try {
            const schedule = await dispatch(fetchScheduleByCartId()).unwrap();
            const unavailable = await dispatch(updateSeparatedProducts()).unwrap();

            if (unavailable.length) {
                dispatch(setSeparatedProductsOpen(true));
                return;
            }

            if (Object.entries(schedule.selectedSchedules).length == 0) {
                setError('No hay agenda disponible para la tienda seleccionada.');
                return;
            }

            dispatch(toggleStoreModal(true));
        } catch (e) {
            setError('Hubo un problema al consultar agenda.');
        }
    };

    const separateProducts = async () => {
        try {
            if (provisionalStore) {
                await dispatch(deleteProducts()).unwrap();
                await dispatch(fetchCartById()).unwrap();
                await dispatch(getStocks()).unwrap();
                await selectStore(provisionalStore);
                dispatch(setSeparatedProductsOpen(false));
            }
        } catch (e) {
            setError('Hubo un problema al eliminar productos.');
            dispatch(setSeparatedProductsOpen(false));
        }
    };

    const cancelSeparateProducts = async () => {
        dispatch(setSeparatedProductsOpen(false));
        dispatch(setProvisionalStore(null));
    };

    if (showSeparatedProducts) {
        return (
            <SeparatedProductsContainer
                onContinue={separateProducts}
                onClose={cancelSeparateProducts}
            />
        );
    }

    if (isOpenStoreModal) {
        return (
            <StoreModalContainer
                isOpen={isOpenStoreModal}
                onContinue={() => {
                    dispatch(toggleStoreModal(false));
                    onToggle();
                }}
                onToggle={() => {
                    dispatch(toggleStoreModal(false));
                    dispatch(setProvisionalStore(null));
                }}
            />
        );
    }

    return (
        <Modal isOpen={isOpen} onToggle={onToggle} header="Retiro en tienda">
            <LocalitySelectorContainer />

            {error && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <AlertBadge theme="danger" timeout={5000} callback={() => setError(null)}>
                        {error}
                    </AlertBadge>
                </div>
            )}

            <StockSelectorContainer onSetStore={selectStore} />
        </Modal>
    );
};
