import * as React from 'react';
import styles from './Acknowledgement.module.scss';
import Clipboard from '../../common/Clipboard';
import successImg from './img/success-primary.svg';

export type AcknowledgementProps = {
    /**
     *
     */
    orderId: string;
    shopping: string;
};

export const Acknowledgement: React.FC<AcknowledgementProps> = ({
    orderId,
    shopping,
}: AcknowledgementProps) => (
    <div className={styles['acknowledgement']}>
        <img className={styles['success-img']} alt="Éxito" src={successImg} />
        <h3 className={styles['title']}>
            {shopping == 'Kiosko' ? '¡Reserva exitosa!' : '¡Gracias por tu compra!'}
        </h3>
        <p className={styles['text']}>
            Tu número de orden es{' '}
            <Clipboard>
                <b>{orderId}</b>
            </Clipboard>
        </p>
        <hr className={styles['separator']} />
    </div>
);
