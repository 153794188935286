import React from 'react';

import { useAppSelector } from 'lib/hooks/redux';
import { useAnalytics } from 'lib/hooks/analytics';

import RipleyError from 'components/common/RipleyError';
import SoldOutProductsContainer from 'containers/SoldOutProductsContainer';
import ErrorShippingProductsContainer from 'containers/ErrorShippingProductsContainer';
import { RootState } from 'types/store';

export type ErrorContainerProps = { children: React.ReactNode };

export const ErrorContainer = ({ children }: ErrorContainerProps): JSX.Element => {
    const analytics = useAnalytics();
    const app = useAppSelector((state: RootState) => state.app);
    const area = useAppSelector((state: RootState) => state.area);
    const auth = useAppSelector((state: RootState) => state.auth);
    const cart = useAppSelector((state: RootState) => state.cart);
    const user = useAppSelector((state: RootState) => state.user);
    const rule = useAppSelector((state: RootState) => state.rule);
    const checkout = useAppSelector((state: RootState) => state.checkout);
    const separatedProducts = useAppSelector((state: RootState) => state.separatedProducts);
    const shippingCompatibility = useAppSelector((state: RootState) => state.shippingCompatibility);
    const deliveryMethod = useAppSelector((state: RootState) => state.deliveryMethod);

    if (app.error != null) {
        return <RipleyError error={app.error} />;
    }

    if (area.error != null) {
        return <RipleyError error={area.error} />;
    }

    if (auth.error != null) {
        return <RipleyError error={auth.error} />;
    }

    if (cart.error != null) {
        return <RipleyError error={cart.error} />;
    }

    if (deliveryMethod.error != null) {
        return <RipleyError error={deliveryMethod.error} />;
    }

    if (rule.error != null) {
        return <RipleyError error={rule.error} />;
    }

    if (user.error != null) {
        return <RipleyError error={user.error} />;
    }

    if (separatedProducts.error != null) {
        return <RipleyError error={separatedProducts.error} />;
    }

    if (shippingCompatibility.error != null) {
        return <RipleyError error={shippingCompatibility.error} />;
    }

    if (checkout.error != null) {
        if (checkout.error.code === 'errEmptyOrderId') {
            window.location.href = '/';
            return <></>;
        }

        if (checkout.error.code == 'errProductStock') {
            analytics.push('checkout_error', 'outStockProducts');
            return <SoldOutProductsContainer />;
        }
        if (checkout.error.code == 'errValidationShipping') {
            return <ErrorShippingProductsContainer />;
        }
        return <RipleyError error={checkout.error} />;
    }

    return <>{children}</>;
};
