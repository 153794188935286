import { TFunction } from 'i18next';
import { Product } from 'components/common/ProductWithWarranty';
import { DD } from 'consts';
import { RootState } from 'types/store';
import { PriceDetail, Total } from 'components/common/PriceSummary';
import { RipleyGiftCardState } from 'store/paymentMethod/ripleyGiftCard.slice';
import { isRipleyPayment } from 'lib/utils/payments';

export type Totals = { [type: string]: number };

/*
 * Transform globalThis.Product (the original product form the BFF) to Product (
 * the prop type that requires ProductWithWarranty())
 */
export const transformProduct = (product: globalThis.Product, t: TFunction): Product => {
    let relatedProduct = null;
    if (product.related != null) {
        relatedProduct = product.related.map((product) => transformProduct(product, t)) as any;
    }

    const tags = product.details.attributes.map((attr): string => {
        return `${attr.name} ${attr.value}`;
    });

    const prices = [];
    const normal = product.prices['discount'] || product.prices['master'];
    if (normal)
        prices.push({
            type: 'normal' as const,
            amount: t('amount', { value: normal.value }),
        } as never);

    const ripley = product.prices['ripley_promo'];
    if (ripley)
        prices.push({
            type: 'ripley' as const,
            amount: t('amount', { value: ripley.value }),
        } as never);
    return {
        sku: product.sku,
        quantity: product.quantity,
        name: product.details.name,
        type: product.type,
        thumbnail: product.details.thumbnail,
        tags,
        prices,
        related: relatedProduct || null,
    };
};

/**
 * Returns the cart's products with the structure required
 * by the pure component.
 * @param cart
 * @returns
 */
export const getProducts = (products: globalThis.Product[], t: TFunction): Product[] => {
    const reply = products.map((product) => transformProduct(product, t));
    return reply;
};

/**
 * Returns the cart's totals
 * @param cart
 * @returns
 */
export const getTotals = (cart: Cart): Totals => {
    const totals: Totals = {};

    const normal = cart.grandTotal['discount'] || cart.grandTotal['master'];
    if (normal) totals['normal'] = Number(normal.value);

    const ripley = cart.grandTotal['ripley_promo'];
    if (ripley) totals['ripley'] = Number(ripley.value);

    return totals;
};

/**
 * Returns the shipping cost of the cart
 * @param schedules
 * @returns
 */
export const getShippingCost = (cart: Cart): number | null => {
    if (cart.shippingInfo && cart.shippingInfo.length > 0) {
        return cart.shippingInfo.reduce((cost, schedule) => {
            const price = Number(schedule.shippingPrice.value);
            return cost + price;
        }, 0);
    }
    return null;
};

/**
 * Returns the subtotal generated with the price of the products
 * @param cart
 * @returns
 */
export const getSubtotals = (cart: Cart): Totals => {
    const totals: Totals = {};

    const normal = cart.products.reduce((acc, prod) => {
        const price = prod.prices['discount'] || prod.prices['master'];
        return acc + prod.quantity * Number(price.value);
    }, 0);

    const ripley = cart.products.reduce((acc, prod) => {
        const price =
            prod.prices['ripley_promo'] || prod.prices['discount'] || prod.prices['master'];
        return acc + prod.quantity * Number(price.value);
    }, 0);

    if (normal) totals['normal'] = normal;
    if (ripley) totals['ripley'] = ripley;

    return totals;
};

export const getGuarantee = (cart: Cart): globalThis.Product[] => {
    const guarantee: globalThis.Product[] = cart.products
        .map((product) => product.related || [{} as globalThis.Product]) // array of related (array also)
        .flat() // flat the array to get one level with all the related products
        .filter((product) => product.id); // get rid of arrays with empty objects
    return guarantee.length ? guarantee : [{} as globalThis.Product]; // if guarantee is an empty array return an array with an empty object
};

export const getExtendedWarranty = (guarantee: globalThis.Product[]): number => {
    const prices = guarantee.map((warranty) => warranty.prices);
    const extendedWarranty = prices
        .map((price) => +price['discount'].value || +price['master'].value) // array of prices (number)
        .reduce((prevPrice, currPrice) => prevPrice + currPrice, 0); // sum all the prices
    return extendedWarranty;
};

/**
 * Returns true if the store has all necessary data to print a cart.
 */
export function getLoaded(state: RootState): boolean {
    if (state.deliveryMethod.selected == DD) {
        return (
            state.app.status === 'ok' &&
            state.shipping.status === 'ok' &&
            state.shippingCompatibility.status === 'ok'
        );
    }
    return state.app.status === 'ok' && state.store.stocks.status === 'ok';
}

/**
 * Returns the object to show the total amounts
 * @param cart
 * @param methods
 * @param giftCard
 * @param t
 * @returns
 */
export function getCartTotal(
    cart: Cart,
    methods: PaymentMethodName[],
    giftCard: RipleyGiftCardState,
    format: TFunction,
): Total[] {
    const totals = getTotals(cart);
    const transformedTotals: Total[] = [];
    const isCopago = methods.length >= 2;

    const appliedAmountGiftCard = isCopago
        ? giftCard.data?.availableAmount
        : giftCard.data?.paymentAmount;

    if (methods.length === 0 || methods.includes('InStore')) {
        Object.entries(totals).forEach(([type, amount]) => {
            transformedTotals.push({
                type,
                amount: format('amount', { value: amount }),
            } as Total);
        });
    } else if ('ripley' in totals && isRipleyPayment(methods) && !isCopago) {
        transformedTotals.push({
            type: 'ripley',
            amount: format('amount', { value: totals['ripley'] }),
        } as Total);
    } else if (isCopago && appliedAmountGiftCard) {
        transformedTotals.push({
            type: 'normal',
            amount: format('amount', { value: totals['normal'] }),
        } as Total);
        transformedTotals.push({
            type: 'giftcard',
            amount: `-${format('amount', {
                value: appliedAmountGiftCard,
            })}`,
        } as Total);
        transformedTotals.push({
            type: 'difftopay',
            amount: format('amount', {
                value: totals['normal'] - appliedAmountGiftCard,
            }),
        } as Total);
    } else {
        transformedTotals.push({
            type: 'normal',
            amount: format('amount', { value: totals['normal'] }),
        } as Total);
    }

    return transformedTotals;
}

/**
 * Returns the object to show the cart summary
 * @param cart
 * @param methods
 * @param deliveryMethod
 * @param format
 * @param trans
 * @returns
 */
export function getCartSummary(
    cart: Cart,
    methods: PaymentMethodName[],
    deliveryMethod: string | null,
    format: TFunction,
    trans: TFunction,
): PriceDetail[] {
    const summary: PriceDetail[] = [];

    const isCopago = methods.length >= 2;
    const subtotals = getSubtotals(cart);

    let subtotal = subtotals['normal'];
    if (isRipleyPayment(methods) && subtotals['ripley'] && !isCopago) {
        subtotal = subtotals['ripley'];
    }

    summary.push({
        label: 'Subtotal',
        value: format('amount', { value: subtotal }),
    });

    const guarantee = cart ? getGuarantee(cart) : [{} as globalThis.Product];
    const extendedWarranty = guarantee[0].prices ? getExtendedWarranty(guarantee) : 0;
    if (extendedWarranty)
        summary.push({
            label: trans('extendedWarranty'),
            value: format('amount', { value: extendedWarranty }),
        });

    const shippingCost = getShippingCost(cart);
    if (shippingCost) {
        summary.push({
            label: deliveryMethod === DD ? trans('shippingCost') : trans('pickupCost'),
            value: format('amount', { value: shippingCost }),
        });
    } else if (shippingCost === 0) {
        summary.push({
            label: deliveryMethod === DD ? trans('shippingCost') : trans('pickupCost'),
            value: trans('free'),
        });
    }

    if (cart.discounts.length > 0) {
        summary.push({
            label: trans('discounts'),
            value: '',
            children: cart.discounts.map((discount) => ({
                label: discount.description,
                value: format('discount', { value: discount.value }),
            })),
        });
    }

    return summary;
}
