import React, { FormEvent, ChangeEvent, RefObject, useState } from 'react';
import styles from './TextAreaField.module.scss';

import { getComputedClassName } from 'lib/utils/inputs';

export type TextAreaFieldProps = {
    /**
     * Sets the name of the text field
     */
    name?: string;
    /**
     * Sets the label that describes the text field
     */
    label?: string;
    /**
     * Sets the identifier of the text field
     */
    id?: string;
    /**
     * Sets whether the text field should be shown disabled
     */
    disabled?: boolean;
    /**
     * Sets a class name on the text field
     */
    className?: string;
    /**
     * Sets whether the text field is invalid
     */
    isInvalid?: boolean;
    /**
     * Sets a placeholder on the text field
     */
    placeholder?: string;
    /**
     * Sets a default value for the text field
     */
    defaultValue?: string;
    /**
     * Sets a callback for the change event
     */
    onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    /**
     * Sets a callback for the focus event
     */
    onFocus?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    /**
     * Sets a callback for the blur event
     */
    onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    /**
     * Specifies the maximum number of characters allowed in the input
     */
    maxLength?: number;
    /*
     * HTML textarea attributes
     */
    rows?: number;
    cols?: number;
};

export const TextAreaField = React.forwardRef(function TextAreaField(
    props: TextAreaFieldProps,
    ref,
) {
    const {
        name,
        id,
        defaultValue,
        disabled,
        className,
        label,
        placeholder,
        isInvalid,
        onChange,
        onFocus,
        onBlur,
        rows = 3,
        cols,
        maxLength = 200,
    } = props;

    const [shouldOpen, setShouldOpen] = useState(!!defaultValue?.length);

    /**
     * Handles the input's change event.
     * @param event
     */
    const handleOnChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const currentValue = event.currentTarget.value;

        const er =
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
        if (er.test(currentValue)) return;
        if (props.maxLength && currentValue.length > props.maxLength) {
            event.currentTarget.value = currentValue.slice(0, props.maxLength);
        }
        // Custom onChange passed in from outside
        onChange?.(event);
    };

    /**
     * Handles the input's focus event.
     * @param event
     */
    const handleOnFocus = (event: React.FormEvent<HTMLTextAreaElement>) => {
        setShouldOpen(true);

        // Custom onFocus passed in from outside
        onFocus?.(event);
    };

    /**
     * Handles the input's blur event.
     * @param event
     */
    const handleOnBlur = (event: React.FormEvent<HTMLTextAreaElement>) => {
        // If the field is not empty it should remain open
        if (!event.currentTarget.value) setShouldOpen(false);

        // Custom onFocus passed in from outside
        onBlur?.(event);
    };

    /**
     * Builds the className to be passed to the component
     */
    const computedClassName = getComputedClassName(
        !!isInvalid,
        !!disabled,
        shouldOpen,
        className ?? '',
        styles,
    );

    /**
     * Builds the attributes passed to the HTML textarea
     */
    const textAreaAttributes = {
        rows: rows,
        cols: cols,
        name: name,
        id: id,
        defaultValue: defaultValue,
        maxLength: maxLength,
        ref: ref as RefObject<HTMLTextAreaElement>,
        placeholder: shouldOpen ? placeholder : undefined,
        className: styles['textfield__control'],
        'data-testid': name,
        onChange: (e: ChangeEvent<HTMLTextAreaElement>) => handleOnChange(e),
        onFocus: (e: ChangeEvent<HTMLTextAreaElement>) => handleOnFocus(e),
        onBlur: (e: ChangeEvent<HTMLTextAreaElement>) => handleOnBlur(e),
    };
    return (
        <div className={computedClassName}>
            <span className={styles['textfield__label']}>{label}</span>
            <textarea {...textAreaAttributes} />
            <fieldset className={styles['textfield__fill']}>
                <legend className={styles['textfield__legend']}>
                    <span>{label}</span>
                </legend>
            </fieldset>
        </div>
    );
});
