import { combineReducers } from '@reduxjs/toolkit';

import { slice as paymentMethodSlice } from './paymentMethod.slice';
import cybersourceSlice from './cybersource.slice';
import { ripleyCardSlice } from './ripleyCard.slice';
import ripleyGiftCardSlice from './ripleyGiftCard.slice';
import { slice as niubizSlice } from './niubiz.slice';
import { slice as ripleyNiubizSlice } from './ripleyNiubiz.slice';
import { slice as ripleyChekSlice } from './ripleyChek.slice';
import { slice as webpayCreditoSlice } from './webpayCredito.slice';
import { slice as webpayDebitoSlice } from './webpayDebito.slice';
import { slice as alignetSlice } from './alignet.slice';
import { slice as inStoreSlice } from './inStore.slice';
import { slice as niubiz3DsSlice } from './niubiz3ds.slice';

const reducer = combineReducers({
    data: paymentMethodSlice.reducer,
    types: combineReducers({
        cybersource: cybersourceSlice.reducer,
        inStore: inStoreSlice.reducer,
        niubiz: niubizSlice.reducer,
        ripleyNiubiz: ripleyNiubizSlice.reducer,
        ripleyCard: ripleyCardSlice.reducer,
        ripleyChek: ripleyChekSlice.reducer,
        ripleyGiftCard: ripleyGiftCardSlice.reducer,
        webpayCredito: webpayCreditoSlice.reducer,
        webpayDebito: webpayDebitoSlice.reducer,
        alignet: alignetSlice.reducer,
        niubiz3Ds: niubiz3DsSlice.reducer,
    }),
});

export default reducer;
