import React, { useEffect, useState } from 'react';
import styles from './GiftCardForm.module.scss';
import GiftCardPlastic from '../GiftCardPlastic';
import GiftCardPasswordInput from '../GiftCardPasswordInput';
import TextInput from 'components/common/TextInput';
import Button from 'components/common/Button';
import Link from 'components/common/Link';
import Spinner from 'components/common/Spinner';
import AlertBadge from 'components/common/AlertBadge';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'lib/hooks/analytics';

export type Form = {
    number: string;
    password: string;
};
export type GiftCardFormProps = {
    haveBalance: boolean;
    pending?: boolean;
    error?: SerializedError | null;
    form: Form;
    setForm: (form: Form) => void;
    onSubmit: (form: Form) => void;
    onCancel: () => void;
};

export const GiftCardForm: React.FC<GiftCardFormProps> = (props: GiftCardFormProps) => {
    const [form, setForm] = useState({ number: '', password: '' });
    const { error } = props;
    const { t: translateError } = useTranslation('error');
    const errorMessage = translateError([`${error?.code}.message`, 'errUnknown.message']);
    const analytics = useAnalytics();

    const erroTheme =
        error?.code === 'errTREInvalidNIN' || error?.code === 'errTREInvalidLengthCode'
            ? 'warning'
            : 'danger';

    useEffect(() => {
        if (error?.code === 'errInternal') analytics.push('checkout_error', 'internalProblem');
        if (error?.code === 'errTREBillingType') analytics.push('checkout_error', 'TREproblem');
        if (error?.code === 'errITFOwnership') analytics.push('checkout_error', 'validationTRE');
    }, [error, form, analytics]);

    useEffect(() => {
        if (props.error || !props.haveBalance) setForm({ number: '', password: '' });
    }, [props.error, props.haveBalance, setForm]);

    function handleOnSubmit(event: React.FormEvent) {
        props.onSubmit(form);
        event.preventDefault();
    }
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <GiftCardPlastic
                    number={form.number}
                    serial={form.password}
                    hideNumber={form.number.length > 0}
                    hideSerial={form.password.length > 0}
                />
            </div>
            <form className={styles.form} onSubmit={(e) => handleOnSubmit(e)}>
                <div className={styles.field}>
                    <TextInput
                        type="text"
                        className={styles.input}
                        label="Número de la Gift Card"
                        placeholder="Número de la Gift Card"
                        required={true}
                        maxlength={20}
                        value={form.number}
                        onChange={(v) => setForm({ ...form, number: v })}
                    />
                </div>
                <div className={styles.field}>
                    <GiftCardPasswordInput
                        value={form.password}
                        tooltipMsg="Si la Gift Card es digital la clave esta en el mail de compra. Si es física la clave esta impresa adelante en la tarjeta."
                        onChange={(v) => setForm({ ...form, password: v })}
                    />
                </div>
                {!props.haveBalance && (
                    <AlertBadge theme="warning">Esta Gift Card no tiene saldo</AlertBadge>
                )}
                {error?.code && (
                    <AlertBadge theme={erroTheme} size="large">
                        {errorMessage}
                    </AlertBadge>
                )}
                <div className={styles.button}>
                    <Button theme="primary" size="large" type="submit" disabled={props.pending}>
                        {props.pending ? <Spinner size={20} theme="none" /> : 'Continuar'}
                    </Button>
                </div>
                <div className={styles.link}>
                    <Link to={props.onCancel} title="cancelar">
                        Cancelar
                    </Link>
                </div>
            </form>
        </div>
    );
};
