import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { bff } from 'api/bff/BFFConnector';

export const sendLog = createAsyncThunk('logs/sendLog', (payload: LogPayload) => {
    return bff.sendLog(payload);
});

export type LogState = {
    status: 'idle' | 'pending' | 'error' | 'ok';
};

const initialState: LogState = {
    status: 'idle',
};

export const slice = createSlice({
    name: 'logs',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder.addCase(sendLog.pending, (state: LogState) => {
            state.status = 'pending';
        });
        builder.addCase(sendLog.rejected, (state: LogState) => {
            state.status = 'error';
        });
        builder.addCase(sendLog.fulfilled, (state: LogState) => {
            state.status = 'ok';
        });
    },
});
